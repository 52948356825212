import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CommonSettingSection from "../CommonSettingSection";
import useTranslation from "../../../localization/useTranslation";
import { Box, Stack, Typography, Checkbox, Link, Button } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useDispatch, useSelector } from "react-redux";
import { userCredsSelector } from "../../../redux/selectors";
import { useEffectOnce } from "../../../hooks/useEffectOnce";
import { enforceSameAzureTenant } from "../../../redux/ducks/userDucks";
import { TableCompanyMembersContainer } from "./CompanyMembersTable/TableCompanyMembersContainer";

const TrialTip = styled.div`
  border-radius: 7px;
  background: ${(props) => props.theme.primaryColorMidLight};
  padding: 10px;
  width: fit-content;
  max-width: 300px;
`;

const SubUsers = ({
  allSeats = 0,
  isSeatsUpdateInProgress = false,
  companyMembersData,
  onChangeMemberRoleModal, 
  onDeleteMemberModal,
  onInviteNewTeamMemberModal
}) => {
  const { labelStrings } = useTranslation();
  const dispatch = useDispatch();

  const { seatsUsed, teamMembers, isLoading } = companyMembersData || {};

  const { azureAdData } = useSelector(userCredsSelector) || {};

  const availableSeats = allSeats - seatsUsed <= 0 ? 0 : allSeats - seatsUsed;
  const canNotAddNewTeamMember = availableSeats === 0

  const [checked, setChecked] = useState(false);

  const handleChange = ({ target }) => {
    const { checked } = target || {};
    setChecked(checked);
    dispatch(
      enforceSameAzureTenant({
        isEnforced: checked,
      }),
    );
  };

  useEffectOnce(() => {
    setChecked(azureAdData.azureSameTenantEnforced);
  }, azureAdData.azureSameTenantEnforced);

  const Content = (
    <>
      <Box sx={{ width: "100%", position: "relative" }}>
        <Stack direction='row' justifyContent='space-between'>
          <Stack>
            <Typography
              sx={{ fontFamily: "Inter", color: "#212B36", fontWeight: 500 }}
            >
              {labelStrings.companyMembersSeatsUsed(
                seatsUsed,
                allSeats,
                availableSeats,
              )}
            </Typography>
            <Typography sx={{ fontFamily: "Inter", fontSize: "12px" }}>
              {labelStrings.pleaseReach}
            </Typography>
          </Stack>
          <Button
            size='small'
            disabled={canNotAddNewTeamMember}
            onClick={onInviteNewTeamMemberModal}
            sx={{
              fontFamily: "Inter",
              fontSize: "13px",
              borderRadius: "10px",
              textTransform: "uppercase",
              width: "fit-content",
              color: "#FF5C00",
              borderColor: "#FF5C00",
              backgroundColor: "#fff",
              "&:hover": {
                color: "white",
                backgroundColor: "#FF5500",
              },
            }}
            startIcon={<AddRoundedIcon />}
            variant='outlined'
          >
            {labelStrings.inviteNewTeamMembers}
          </Button>
        </Stack>

        <TableCompanyMembersContainer
          tableData={teamMembers}
          isLoading={isLoading}
          onChangeMemberRoleModal={onChangeMemberRoleModal}
          onDeleteMemberModal={onDeleteMemberModal}
        />
      </Box>

      {azureAdData.azureTenantId && (
        <Stack sx={{ mt: 4 }}>
          <Typography
            sx={{ fontFamily: "Inter", fontWeight: 600, fontSize: "15px" }}
          >{`${labelStrings.azureAdAccountSettingsLabel} (Tenant ID: ${azureAdData.azureTenantId})`}</Typography>
          <Stack direction='row' alignItems='center' spacing={1}>
            <Checkbox
              sx={{
                "& .MuiSvgIcon-root": {
                  color: "#FF5C00",
                },
              }}
              checked={checked}
              onChange={handleChange}
            />
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                whiteSpace: "pre-wrap",
              }}
            >
              {labelStrings.azureAdAccountSettingsCheckBoxLabel}
            </Typography>
          </Stack>
        </Stack>
      )}
    </>
  );

  const DisabledTip = <TrialTip>{labelStrings.notAvailableInTrial}</TrialTip>;

  const mainTitle = (
    <Stack spacing={0.5}>
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: "30px",
          fontWeight: 600,
          minWidth: "20%",
        }}
      >
        {labelStrings.companyMembers}
      </Typography>
      <Link
        target='_blank'
        rel='noopener'
        href='https://faq.infludata.com/roles'
        underline='none'
        sx={{
          fontFamily: "Inter",
          fontSize: "12px",
          color: "#212B36",
          "&:hover": {
            color: "#212B36",
          },
        }}
      >
        {labelStrings.companyMembersSubtitle}
      </Link>
    </Stack>
  );

  return (
    <CommonSettingSection
      title={mainTitle}
      Content={Content}
      withLoading
      isLoading={isSeatsUpdateInProgress}
      DisabledTip={DisabledTip}
    />
  );
};

SubUsers.propTypes = {
  allSeats: PropTypes.number,
  isSeatsUpdateInProgress: PropTypes.bool,
  companyMembersData: PropTypes.object,
  onChangeMemberRoleModal: PropTypes.func,
  onDeleteMember: PropTypes.func,
  onInviteNewTeamMemberModal: PropTypes.func
};

export default SubUsers;
