import React from "react";
import PropTypes from "prop-types";
import useTranslation from "../../../localization/useTranslation";
import { Typography, Stack, Button, Box } from "@mui/material";
import LoadingSpinner from "../spinners/LoadingSpinner";

const ChangePasswordDialog = ({ close, confirm, isLoading = false }) => {
  const { labelStrings } = useTranslation();

  return (
    <Box sx={{ pb: 3 }}>
      <Stack spacing={2} alignItems='center'>
        <Typography textAlign='center' sx={{ fontFamily: "Inter" }}>
          {labelStrings.weWillSendPassChangeLink}
        </Typography>

        <Stack
          sx={{ width: "100%" }}
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Button
            onClick={close}
            variant='outlined'
            color='primary'
            sx={{ borderColor: "#FF5C00", color: "#FF5C00" }}
          >
            {labelStrings.cancel}
          </Button>
          <Button
            disabled={false}
            onClick={confirm}
            variant='contained'
            color='primary'
            sx={{
              backgroundColor: "#FF5C00",
            }}
          >
            {labelStrings.confirm}
          </Button>
        </Stack>

        {isLoading && (
          <LoadingSpinner isTransparent height='100%' width='100%' />
        )}
      </Stack>
    </Box>
  );
};

ChangePasswordDialog.propTypes = {
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default ChangePasswordDialog;
