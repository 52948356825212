import React from "react";
import { ModalComponent } from "../../../../new-ui/components/modal/ModalComponent";
import useTranslation from "../../../../localization/useTranslation";
import {
  Stack,
  Typography,
  Box,
  Button,
} from "@mui/material";

export const RemoveTeamMember = (props) => {
  const { isOpenModal, closeModal, username, onConfirmDeleteMember } = props || {};

  const { labelStrings } = useTranslation();

  const onConfirm = (e) => {
    e.preventDefault();
    onConfirmDeleteMember();
    closeModal();
  };

  return (
    <ModalComponent
      isOpenModal={isOpenModal}
      closeModal={closeModal}
      title={labelStrings.removeTeamMember}
      contentWidth={"520px"}
      contentHeight='auto'
      isCenterTitle
      withoutScrollWrapper
      isGradientTitle
      isGradientBg
    >
      <Box>
        <Stack spacing={2}>
          <Stack alignItems='center' spacing={1}>
            <Typography sx={{ fontFamily: "Inter", fontWeight: 500 }}>
              {labelStrings.removeTeamMemberWithEmail(username)}
            </Typography>
            <Typography sx={{ fontFamily: 'Inter', fontSize: 14 }}>
              {labelStrings.privateCollectionsOfThatMember}
            </Typography>
          </Stack>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{ pb: 3, pt: 2 }}
          >
            <Button
              onClick={closeModal}
              variant='outlined'
              color='primary'
              sx={{ borderColor: "#FF5C00", color: "#FF5C00" }}
            >
              {labelStrings.cancel}
            </Button>
            <Button
              disabled={false}
              onClick={onConfirm}
              variant='contained'
              color='primary'
              sx={{
                backgroundColor: "#FF5C00",
              }}
            >
              {labelStrings.confirm}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </ModalComponent>
  );
};
