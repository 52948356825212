import React from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import useTranslation from "../../../../localization/useTranslation";

export const CompanyMembersTableRow = ({
  row,
  onChangeMemberRoleModal,
  onDeleteMemberModal,
}) => {
  const { labelStrings } = useTranslation();

  const { username, displayName, roles, canBeChanged } = row;

  return (
    <>
      <TableRow hover>
        <TableCell align='left' sx={{ textTransform: "capitalize" }}>
          {displayName}
        </TableCell>

        <TableCell align='left'>{username}</TableCell>

        <TableCell align='left' sx={{ textTransform: "capitalize" }}>
          {roles?.length ? roles?.join(", ") : ""}
        </TableCell>

        <TableCell align='right'>
          <Button
            disabled={!canBeChanged}
            size='small'
            sx={{ FontFamily: "Inter", color: "#FF5C00" }}
            onClick={onChangeMemberRoleModal}
            variant='text'
          >
            {labelStrings?.changeRole}
          </Button>
        </TableCell>

        <TableCell align='right'>
          <IconButton
            disabled={!canBeChanged}
            onClick={onDeleteMemberModal}
            sx={{ color: "#FF5C00" }}
          >
            <DeleteRoundedIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
