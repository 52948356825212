import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Switch, Route, useParams, Redirect, useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import PageContainer from './PageContainer'
import ProfilePage from './ProfilePage'
import SearchResultsPage from './SearchResultsPage'
import CollectionsPage from './CollectionsPage'
import SettingsPage from './SettingsPage'
import ResetPasswordPage from './ResetPasswordPage'
import CompareProfilesPage from './CompareProfilesPage'
import ToolsForBrandsPage from './ToolsForBrandsPage'
import CampaignsTrial from './CampaignsTrial.jsx'

import { withReduxActions } from '../hocs/withRedux'
import useQuery from '../hooks/useQuery'
import { fetchStartUpData } from '../redux/ducks/startupActions'
import { toggleSignIn, toggleSignUp } from '../redux/ducks/appSettingsDucks'
import {
  setUserEmailFromLanding,
  setGoogleAnalyticsDataFromLanding,
} from '../redux/ducks/userDucks'
import {
  isProcessSignupSelector,
  isUserAuthenticatedSelector,
  isUserLoggedInSelector,
} from '../redux/selectors'
import { validateEmail } from '../utils'
import NewCampaignsPage from '../new-ui/pages/CampaignsPage'
import { DashboardPreview } from '../new-ui/pages/CampaignsSubpages'
import { SignupOmrContainer } from '../new-ui/components/signup-omr/SignupOmrContainer'

const multipleInArray = (arr, values) => {
  return values?.every(value => {
    return arr.indexOf(value) !== -1
  })
}

// This page component now hosts the state that was previously in AppWithRouting.
// This is so we can make full use of routing functionality (things like url/route parameters) without
// resorting to hacks.

const ListHostRoutingPage = ({
  baseUrl,
  fetchStartUpData,
  toggleSignUp,
  toggleSignIn,
  setUserEmailFromLanding,
  setGoogleAnalyticsDataFromLanding,
}) => {
  const { collectionId = '', profileId = '', campaignId = '' } = useParams()
  const { pathname, search } = useLocation()
  const history = useHistory()
  const query = useQuery()
  const params = new URLSearchParams(search)

  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const isUserLoggedIn = useSelector(isUserLoggedInSelector)
  const isProcessSignup = useSelector(isProcessSignupSelector)

  useEffect(() => {
    const collectionPage = +query.get('collectionPage') || 1
    const splittedSearchQuery = search.split('=')
    const checkedValuesInSearchQuery = ['?action', 'openProfile&profileId']

    if (
      isUserLoggedIn &&
      !!multipleInArray(splittedSearchQuery, checkedValuesInSearchQuery) &&
      pathname.includes('/search')
    ) {
      const getProfileIdFromURL = search.split('=')
      if (getProfileIdFromURL?.length === 3) {
        history.push(`/profile/${getProfileIdFromURL[2]}`)
      }
    }

    if (pathname?.includes(`/profile/${profileId}`) && !isUserLoggedIn) {
      history.push(`/search?action=openProfile&profileId=${profileId}`)
      toggleSignIn()
    }

    if(pathname?.includes('changeemail') && !isUserLoggedIn) {
      toggleSignIn()
    }

    if (!isUserLoggedIn) {
      if (pathname.includes('signin')) toggleSignIn()
      if (pathname.includes('signup')) toggleSignUp()
    }

    if (isUserLoggedIn) {
      fetchStartUpData({ collectionId, collectionPage, profileId, campaignId })
    }
  }, [isUserAuthenticated, collectionId, profileId, campaignId])

  useEffect(() => {
    const userEmailFromLanding = query.get('email') || ''

    if (!isUserLoggedIn && userEmailFromLanding && validateEmail(userEmailFromLanding)) {
      setUserEmailFromLanding(userEmailFromLanding)
    }
  }, [])

  useEffect(() => {
    const utmSource = query.get('utm_source')
    const utmMedium = query.get('utm_medium')
    const utmCampaign = query.get('utm_campaign')
    const utmContent = query.get('utm_content')
    const utmTerm = query.get('utm_term')
    const gclid = query.get('gclid')
    const liFatId = query.get('li_fat_id')
    const nab = query.get('nab')


    setGoogleAnalyticsDataFromLanding({
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
      utm_content: utmContent,
      utm_term: utmTerm,
      gclid: gclid,
      li_fat_id: liFatId,
      nab: nab
    })
  }, [])

  const showNavbar = !pathname.includes('password')

  const showFooter = (() => {
    if (pathname.includes('plans') || pathname.includes('password') || profileId) return false
    return true
  })()

  const renderComponentWithAuthCheck = (Component, routeProps, paramProp = '') => {
    if (isProcessSignup) {
      return
    }
    return isUserLoggedIn ? (
      <Component {...(paramProp && { [paramProp]: routeProps.match.params[paramProp] })} />
    ) : (
      <Redirect to={`${baseUrl}/search`} />
    )
  }

  const onlyAuthorizedRoutesList = [
    {
      path: `${baseUrl}/collections/:collectionId?`,
      render: routeProps =>
        renderComponentWithAuthCheck(CollectionsPage, routeProps, 'collectionId'),
    },

    {
      path: `${baseUrl}/campaigns/:campaignId?`,
      render: routeProps =>
        renderComponentWithAuthCheck(NewCampaignsPage, routeProps, 'campaignId'),
    },

    {
      path: `${baseUrl}/settings`,
      render: routeProps => renderComponentWithAuthCheck(SettingsPage, routeProps),
    },
    {
      path: `${baseUrl}/compare-profiles`,
      render: routeProps => renderComponentWithAuthCheck(CompareProfilesPage, routeProps),
    },
    {
      path: `${baseUrl}/tools-for-brands`,
      render: routeProps => renderComponentWithAuthCheck(ToolsForBrandsPage, routeProps),
    },
  ]

  const redirectedRoutesList = [
    {
      path: `${baseUrl}/collections`,
      redirectPath: `${baseUrl}/collections/`,
      exact: true,
    },
    {
      path: `${baseUrl}/signin`,
      redirectPath: `${baseUrl}/search`,
    },
    {
      path: `${baseUrl}/signup`,
      redirectPath: `${baseUrl}/search`,
    },
    {
      path: `${baseUrl}/signup-trial`,
      redirectPath: `${baseUrl}/search`,
    },
  ]

  return (
    <Switch>
      <PageContainer isNavbar={showNavbar} isFooter={showFooter}>
        <Route path={`${baseUrl}/search`}>
          <SearchResultsPage />
        </Route>
        <Route path={`${baseUrl}/campaigns-trial`}>
          <CampaignsTrial />
        </Route>

        <Route path={`${baseUrl}/collect-lead`}>
          <SignupOmrContainer />
        </Route>

        <Route path={`${baseUrl}/dashboardPreview`}>
          <DashboardPreview />
        </Route>

        {redirectedRoutesList.map(item => {
          const isUtmSourceParams = params.get('utm_source')
          const redirectPath = isUtmSourceParams
            ? `${item.redirectPath}${search}`
            : item.redirectPath
          return (
            <Route key={item.path} path={item.path} exact={!!item.exact}>
              <Redirect to={redirectPath} />
            </Route>
          )
        })}

        {onlyAuthorizedRoutesList.map(item => (
          <Route key={item.path} path={item.path} render={item.render} />
        ))}

        <Route
          path={`${baseUrl}/profile/:profileId?`}
          render={routeProps => <ProfilePage profileId={routeProps.match.params.profileId} />}
        />

        <Route path={`${baseUrl}/password`} render={routeProps => <ResetPasswordPage />} />
      </PageContainer>
    </Switch>
  )
}

ListHostRoutingPage.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  fetchStartUpData: PropTypes.func,
  toggleSignUp: PropTypes.func,
  toggleSignIn: PropTypes.func,
  setUserEmailFromLanding: PropTypes.func,
  setGoogleAnalyticsDataFromLanding: PropTypes.func,
}

export default withReduxActions({
  fetchStartUpData,
  toggleSignIn,
  toggleSignUp,
  setUserEmailFromLanding,
  setGoogleAnalyticsDataFromLanding,
})(ListHostRoutingPage)
