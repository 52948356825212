import React, { useEffect, useState } from "react"
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Divider as MuiDivider,
  Box,
  ListItemButton,
  Popover,
  IconButton,
  Tooltip,
} from "@mui/material"
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded"
import PublicRoundedIcon from "@mui/icons-material/PublicRounded"
import useTranslation from "../../../../../localization/useTranslation"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import {
  filterTruthyKeys,
  hasMatchingValues,
} from "../utils"
import { CountryFilter } from "../filters/CountryFilter"
import { GrowthRateFilter } from "../filters/GrowthRateFilter"
import { GenderFilter } from "../filters/GenderFilter"
import { FollowersRangeFilter } from "../filters/FollowersRangeFilter"
import { EngRateSvgIcon, FollowersSvgIcon, GenderSvgIcon } from "../../icons"
import { searchContentTypes } from "../../../../../constants/search"
import { animationHideSearchFilter, animationShowSearchFilter } from "../../../../theme/animations"
import { infludataThemes } from "../../../../../themes/infludataThemes"

export const ContentCreatorSearchParams = (props) => {
  const {
    query,
    permissions,
    selectedPlatform,
    handlersMap,
    searchType,
    currentOpenSearchParam,
    setCurrentOpenSearchParam,
  } = props || {}
  const { labelStrings } = useTranslation()

  const [anchorEl, setAnchorEl] = useState(null)
  const [currId, setCurrId] = useState(null)

  const activeQueryParams = filterTruthyKeys(query)
  const isChangedFollowerMinsQuery =
    query?.followerMin !== 2000 && query?.followerMin !== ""
  const isChangedFollowerMaxQuery =
    query.followerMax !== Infinity && query.followerMax !== ""
  const isChangedFollowersQuery =
    isChangedFollowerMinsQuery || isChangedFollowerMaxQuery

  const finedActiveParam = (searchedParam) => {
    if (searchedParam === "followers") {
      const isActive = hasMatchingValues(
        {
          ["followers"]: ["followerMin", "followerMax"],
        },
        activeQueryParams,
      )
      return isChangedFollowersQuery && isActive
    }
    return activeQueryParams?.includes(searchedParam)
  }

  const handlePopoverOpen = (event, id) => {
    setAnchorEl(event.currentTarget)
    setCurrId(id)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setCurrId(null)
    setCurrentOpenSearchParam("")
  }

  const menuForCreator = [
    {
      name: labelStrings.country,
      icon: <PublicRoundedIcon />,
      id: "country",
    },
    {
      name: labelStrings.gender,
      icon: <GenderSvgIcon />,
      id: "gender",
    },
    {
      name: labelStrings.followers,
      icon: <FollowersSvgIcon />,
      id: "followers",
    },
    {
      name: labelStrings.growthRate,
      icon: <EngRateSvgIcon />,
      id: "growthRate",
    },
  ]

  const filterActions = {
    ["country"]: (
      <CountryFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        selectedPlatform={selectedPlatform}
        isModalOpen={!!anchorEl}
      />
    ),
    ["gender"]: (
      <GenderFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        selectedPlatform={selectedPlatform}
        isModalOpen={!!anchorEl}
        closeModal={handlePopoverClose}
      />
    ),
    ["followers"]: (
      <FollowersRangeFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        selectedPlatform={selectedPlatform}
        searchType={searchType}
        isContent
      />
    ),
    ["growthRate"]: (
      <GrowthRateFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        selectedPlatform={selectedPlatform}
      />
    ),
  }

  const allSearchParamsIds = menuForCreator?.map((el) => el.id)
  const idsForFollowersSelect = [
    ...allSearchParamsIds,
    "followerMin",
    "followerMax",
  ]
  useEffect(() => {
    if (
      currentOpenSearchParam &&
      idsForFollowersSelect?.includes(currentOpenSearchParam) &&
      searchType === searchContentTypes.CONTENT
    ) {
      const id = idsForFollowersSelect?.includes(currentOpenSearchParam)
        ? "followers"
        : currentOpenSearchParam
      const getActiveListItem = document.getElementById(id)
      setAnchorEl(getActiveListItem)
      setCurrId(id)
    }
  }, [currentOpenSearchParam, idsForFollowersSelect?.length, searchType])

  return (
    <Stack>
      <Typography
        sx={{
          fontSize: "10px",
          fontWeight: 700,
          fontFamily: "Inter",
          textTransform: "uppercase",
          ml: 1.5,
          color: "#442424",
        }}
      >
        {labelStrings.creatorFilter}
      </Typography>
      <MuiDivider sx={{ height: "1px", borderColor: "#E3C5C5", ml: 1.5 }} />
      <List dense={false}>
        {menuForCreator?.map((el) => {
          if (!el.id) return

          const isActiveMenuItem = finedActiveParam(el.id)
          return (
            <Tooltip
              arrow
              placement='right'
              title={el.isDisabled ? el.tipText : ""}
              key={el.id}
            >
              <ListItem
                aria-haspopup='true'
                onClick={(event) =>
                  el?.isDisabled ? null : handlePopoverOpen(event, el.id)
                }
                id={el.id}
                disablePadding
                sx={{
                  pointerEvents: el.isDisabled ? "none" : "auto",
                  "& .MuiListItemText-primary": {
                    textTransform: 'capitalize',
                    fontSize: "12px",
                    fontWeight: 700,
                    fontFamily: "Inter",
                    color:
                      (currId === el.id && anchorEl) || finedActiveParam(el.id)
                        ? infludataThemes[selectedPlatform]?.platformColor
                        : "#442424",
                    "&:hover": {
                      color: infludataThemes[selectedPlatform]?.platformColor,
                    },
                  },
                  color:
                    (currId === el.id && anchorEl) || finedActiveParam(el.id)
                      ? infludataThemes[selectedPlatform]?.platformColor
                      : "#442424",
                  "&:hover": {
                    color: infludataThemes[selectedPlatform]?.platformColor,
                    "& .MuiTypography-root": {
                      color: infludataThemes[selectedPlatform]?.platformColor,
                    },
                  },
                  backgroundColor:
                    currId === el.id && anchorEl ? "white" : "transparent",
                  borderRadius: "8px",
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  boxShadow:
                    currId === el.id && anchorEl
                      ? `2px 4px 5px 0px rgba(107, 69, 69, 0.20)`
                      : "unset",
                  border:
                    currId === el.id && anchorEl
                      ? "0.5px solid #FBEBEB"
                      : "0.5px solid transparent",
                  borderRight: "unset",
                }}
                {...(isActiveMenuItem
                  ? {
                      secondaryAction: (
                        <Box>
                          <FiberManualRecordRoundedIcon
                            sx={{ fontSize: "12px" }}
                          />
                        </Box>
                      ),
                    }
                  : {})}
              >
                <ListItemButton sx={{ pl: 1 }} disabled={el?.isDisabled}>
                  <ListItemIcon>{el.icon}</ListItemIcon>
                  <ListItemText primary={el.name} />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          )
        })}
      </List>

      <Popover
        sx={{
          ".MuiPopover-paper": {
            boxShadow: `unset`,
            borderTopRightRadius: "16px",
            borderBottomRightRadius: "16px",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: "16px",
            border: "1px solid #FBEBEB",
            borderLeft: "unset",
            p: 1,
          },
          animation: anchorEl
            ? `${animationShowSearchFilter} 0.2s ease-in-out 0s 1 normal both`
            : `${animationHideSearchFilter} 0.3s ease-in-out forwards`,
        }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Stack sx={{ p: 1, position: "relative" }}>
          {!!anchorEl && (
            <Stack
              direction='row'
              justifyContent='end'
              alignItems='start'
              sx={{ position: "absolute", top: "-10px", right: "-8px" }}
            >
              <IconButton size='small' onClick={handlePopoverClose}>
                <CloseRoundedIcon sx={{ fill: "#6B4545", fontSize: "14px" }} />
              </IconButton>
            </Stack>
          )}
          {filterActions[currId]}
        </Stack>
      </Popover>
    </Stack>
  )
}
