import React, { useEffect, useMemo, useState } from 'react'
import useTranslation from '../../../../../localization/useTranslation'
import { Box, Autocomplete, TextField, Chip } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

export const CityFilter = props => {
  const { query, handlersMap, availableCountriesCities, isModalOpen } = props || {}

  const { labelStrings } = useTranslation()

  const citiesFromQuery = query?.city

  const [open, setOpen] = useState(false)

  const countryCitiesOptions = useMemo(() => {
    if (!query.country || !availableCountriesCities[query.country]) return []
    return availableCountriesCities[query.country].map(city => ({
      value: city,
      label: city,
    }))
  }, [query.country, availableCountriesCities])

  const onChange = (event, value) => {
    handlersMap?.city(value.map(v => v.value))
  }

  const onDelete = deleteValue => {
    const payload = citiesFromQuery?.split(',')?.filter(el => el !== deleteValue)
    if (payload?.length) {
      handlersMap?.city(payload)
    } else {
      handlersMap?.city([])
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isModalOpen) {
        setOpen(true)
      } else {
        setOpen(false)
      }
    }, 220)

    return () => clearTimeout(timer)
  }, [isModalOpen])

  return (
    <Box sx={{ maxWidth: '250px' }}>
      <Autocomplete
        sx={{ width: '250px' }}
        size="small"
        multiple
        disableCloseOnSelect
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        options={countryCitiesOptions}
        getOptionLabel={option => option.label}
        onChange={onChange}
        value={
          query.city
            ? query.city
                .split(',')
                .map(city => countryCitiesOptions.find(opt => opt.value === city))
            : []
        }
        renderOption={(props, option) => (
          <Box
            component="li"
            {...props}
            sx={{
              justifyContent: 'space-between',
              fontFamily: 'Inter',
              fontWeight: 'bold !important',
              color: '#442424',
            }}
          >
            {option.label}
          </Box>
        )}
        renderInput={params => (
          <TextField
            {...params}
            label={labelStrings.selectMultiCities}
            variant="outlined"
            size="small"
            sx={{
              '& .MuiOutlinedInput-root': {
                outline: 'unset',
                '& fieldset': {
                  borderColor: '#BC9797',
                },
                '&:hover fieldset': {
                  borderColor: '#784E4E',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#784E4E',
                },
              },
            }}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              size="small"
              key={option.value}
              label={option.label}
              variant="contained"
              onDelete={() => onDelete(option.value)}
              deleteIcon={<CloseRoundedIcon sx={{ fill: '#442424' }} />}
              {...getTagProps({ index })}
              sx={{
                color: '#442424',
                fontWeight: 'bold',
                backgroundColor: '#F6DBDB',
              }}
            />
          ))
        }
      />
    </Box>
  )
}
