import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useTranslation from '../../../localization/useTranslation'
import { FAV_COLLECTION_NAME } from '../../../constants/collections'

import { AddPersonToCollectionIcon } from '../../common/icons'
import WithTip from '../../common/popups/WithTip'
import WithUpgradeTip from '../../common/popups/WithUpgradeTip'
import AddToCollectionModal from '../../collections/collectionTools/AddToCollectionModal'
import EditCollectionModal from '../../collections/collectionTools/EditCollectionModal'

const IconWrapper = styled.span`
  position: relative;
  ${props => props.count && props.theme.withBubbledCouter(props)}

  ${props => !props.enableCollections && props.theme.disableBlock};
`

const ProfileCollectionsTool = ({ user, enableCollections, isUserAuthenticated }) => {
  const { labelStrings } = useTranslation()

  const [isAddToCollectionOpened, setIsAddToCollectionOpened] = useState(false)
  const [isCreateCollectionOpened, setIsCreateCollectionOpened] = useState(false)

  const currentAddedCollectionsCount = !!user?.collectionArray?.length
    ? [...new Set(user?.collectionArray)]?.filter(c => !c?.startsWith(FAV_COLLECTION_NAME))?.length
    : 0 //excude "fav", because we have "hearts" for favorites

  const AddIcon = (
    <IconWrapper
      enableCollections={enableCollections}
      count={currentAddedCollectionsCount}
      userindex={user._index}
    >
      <AddPersonToCollectionIcon userindex={user._index} isActiveIcon={false} />
    </IconWrapper>
  )

  return (
    <>
      {enableCollections ? (
        <div onClick={() => setIsAddToCollectionOpened(true)}>
          <WithTip
            tipText={labelStrings.addToCollections}
            userindex={user._index}
            position="top center"
          >
            {AddIcon}
          </WithTip>
        </div>
      ) : (
        <WithUpgradeTip
          tipText={labelStrings.addUserToCollections}
          userindex={user._index}
          withSignup={!isUserAuthenticated}
        >
          {AddIcon}
        </WithUpgradeTip>
      )}
      {isAddToCollectionOpened && (
        <AddToCollectionModal
          profile={user}
          platform={user._index}
          collectionArray={user.collectionArray}
          closeModal={() => setIsAddToCollectionOpened(false)}
          openCreateCollectionModal={() => {
            setIsCreateCollectionOpened(true)
            setIsAddToCollectionOpened(false)
          }}
          withoutPortal
        />
      )}

      {isCreateCollectionOpened && (
        <EditCollectionModal
          isOpened={isCreateCollectionOpened}
          platform={user._index}
          isNewCollection
          closeModal={() => setIsCreateCollectionOpened(false)}
          withoutPortal
        />
      )}
    </>
  )
}

ProfileCollectionsTool.propTypes = {
  user: PropTypes.object.isRequired,
  enableCollections: PropTypes.bool,
  isUserAuthenticated: PropTypes.bool,
}

export default ProfileCollectionsTool
