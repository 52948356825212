import React, { useEffect, useState } from 'react'
import useTranslation from '../../../../../localization/useTranslation'
import { Box, Stack, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

export const GenderFilter = props => {
  const { query, handlersMap, isModalOpen, closeModal } = props || {}

  const { labelStrings, genderOptions } = useTranslation()

  const [open, setOpen] = useState(false)

  const onChange = (event, value) => {
    handlersMap?.gender(value ? value.value : null)
    setOpen(false)
    closeModal && closeModal(null)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(isModalOpen)
    }, 220)

    return () => clearTimeout(timer)
  }, [isModalOpen])

  return (
    <Box
      sx={{
        width: 250,
      }}
    >
      <Autocomplete
        size="small"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        options={genderOptions}
        getOptionLabel={option => option.label}
        onChange={onChange}
        renderInput={params => (
          <TextField
            size="small"
            {...params}
            variant="outlined"
            label={labelStrings.selectGender}
            sx={{
              width: 250,
              '& .MuiOutlinedInput-root': {
                outline: 'unset',
                '& fieldset': {
                  borderColor: '#BC9797',
                },
                '&:hover fieldset': {
                  borderColor: '#784E4E',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#784E4E',
                },
              },
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                textTransform: 'capitalize',
                fontWeight: 'bold',
                fontFamily: 'Inter',
                color: '#442424',
              }}
            >
              {option.label}
            </Stack>
          </li>
        )}
        value={genderOptions.find(option => option.value === query.gender) || null}
        isOptionEqualToValue={(option, value) => option.value === value.value}
      />
    </Box>
  )
}
