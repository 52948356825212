import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Button, Typography } from "@mui/material"
import WithUpgradeTip from "../../../components/common/popups/WithUpgradeTip"
import { animationHideHoverText, animationShowHoverText } from "../../theme/animations"

export const NavbarLinkItem = ({
  linkPath,
  text,
  Icon,
  withSignup,
  signupTipText,
  is_active,
  is_disabled,
  isMinWidth,
  hoverText,
  disableRipple
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const onNavClick = (event) => event.preventDefault()

  const _createNavItem = ({ isUserAuthorized = false } = {}) => (
    <Button
      disabled={is_disabled}
      disableRipple={disableRipple}
      sx={{
        minWidth: isMinWidth ? "40px" : "auto",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        "&:hover": {
          backgroundColor: disableRipple ? 'transparent' : 'rgba(255, 93, 90, 0.08)',
          ".hover-text": {
            visible: "visible",
            animation: `${animationShowHoverText} 0.3s ease-in-out forwards`,
          },
        },
      }}
      onMouseEnter={() => setIsHovered(true)} 
      onMouseLeave={() => setIsHovered(false)}
    >
      <Link
        to={`/${linkPath}`}
        {...(!isUserAuthorized && { onClick: onNavClick })}
        disabled={is_disabled}
      >
        <Icon sx={{ fontSize: 26 }} isActive={is_active || isHovered} />
        {text && <span>{text}</span>}
      </Link>
      {hoverText && (
        <Typography
          className='hover-text'
          sx={{
            visible: "hidden",
            width: "65px",
            fontSize: "10px",
            fontFamily: "Inter",
            fontWeight: 500,
            color: "#A77D7D",
            position: "absolute",
            bottom: -12,
            animation: `${animationHideHoverText} 0.3s ease-in-out forwards`,
          }}
        >
          {hoverText}
        </Typography>
      )}
    </Button>
  )

  return withSignup ? (
    <WithUpgradeTip
      withSignup={withSignup}
      tipText={signupTipText}
      position='bottom center'
    >
      {_createNavItem({ isUserAuthorized: false })}
    </WithUpgradeTip>
  ) : (
    _createNavItem({ isUserAuthorized: true })
  )
}
