import { actionProvider } from './actionsProvider'
import { modifyProfileElementEverywhere } from './profilesCommonActions'
import { setError } from './errorDucks'
import { ERROR_MSG } from '../../constants/errorMessages'
import { COLLECTION_CHANGES_TYPES } from '../../constants/collections'
import { COLLECTIONS_SORTING_TYPES, SORTING_DIRECTIONS } from '../../constants/sortings'
import { API_STATUS_CODES, USER_INDEXES } from '../../constants/appSettings'
import {
  setSelectedUsersCommonAction,
  modifyElementsInCollectionsEverywhere,
} from './profilesCommonActions'
import {
  collectionsSelector,
  openedCollectionSelector,
  openedCollectionUsersSelector,
  searchResultsUsersSelector,
  selectedProfileDetailsSelector,
  openedCollectionSortingSelector,
  isLoadingCollectionSelector,
  navigateHistory,
} from '../selectors'
import httpService from '../../services/httpService'
import localStorageService from '../../services/localStorageService'
import { sortArrayOfObjects } from '../../utils'
import { limitAccess } from './appSettingsDucks'
import { changeAudienceTokens } from './userDucks'

// action types
const SET_COLLECTIONS_LIST = 'SET_COLLECTIONS_LIST'
const SET_STARTUP_COLLECTIONS = 'SET_STARTUP_COLLECTIONS'
const SET_OPENED_COLLECTION = 'SET_OPENED_COLLECTION'
const CHANGE_USERDATA_IN_OPENED_COLLECTION = 'CHANGE_USERDATA_IN_OPENED_COLLECTION'
const CHANGE_COLLECTIONS_LOADING_STATUS = 'CHANGE_COLLECTIONS_LOADING_STATUS'
const RESET_COLLECTIONS = 'RESET_COLLECTIONS'
const CHANGE_OPENED_COLLECTION_SORTING = 'CHANGE_OPENED_CAMPAIGN_SORTING'
// const CHANGE_COLLECTION_ORDER = 'CHANGE_COLLECTION_ORDER'
// const CHANGE_SORT_TYPE = 'CHANGE_SORT_TYPE'
const CHANGE_INITIAL_OVERLAP_MODAL = 'CHANGE_INITIAL_OVERLAP_MODAL'
const CHANGE_CONFIRM_OVERLAP_MODAL = 'CHANGE_CONFIRM_OVERLAP_MODAL'
const CHANGE_OVERLAPS_DATA = 'CHANGE_OVERLAPS_DATA'
const CHANGE_OVERLAPS_LOADING = 'CHANGE_OVERLAPS_LOADING'
const CHANGE_OVERLAPS_ERROR = 'CHANGE_OVERLAPS_ERROR'

const defaultSorting = {
  sortKey: COLLECTIONS_SORTING_TYPES.position.sortKey,
  sortDirection: SORTING_DIRECTIONS.ascend,
}

const initState = {
  collectionsList: [],
  openedCollection: {
    id: '',
    users: {
      userdata: [],
      metadata: {},
    },
    sorting: {
      ...defaultSorting,
    },
    overlap: {
      isLoading: false,
      isError: null,
      initialOverlapModal: false,
      confirmOverlapModal: false,
      overlapsData: [],
    },
  },
  isLoading: false,
}

// reducer
export const collectionsReducer = (state = initState, action = {}) => {
  switch (action.type) {
    case SET_COLLECTIONS_LIST:
      return {
        ...state,
        collectionsList: action.payload,
      }
    case SET_STARTUP_COLLECTIONS:
      const { collectionsList, openCollection: id, data } = action.payload
      return {
        ...state,
        collectionsList,
        openedCollection: { ...state.openedCollection, id, users: data },
      }
    case SET_OPENED_COLLECTION:
      const { collectionId, users } = action.payload
      return {
        ...state,
        openedCollection: { ...state.openedCollection, id: collectionId, users },
      }
    case CHANGE_USERDATA_IN_OPENED_COLLECTION:
      return {
        ...state,
        openedCollection: {
          ...state.openedCollection,
          users: {
            userdata: action.payload,
            metadata: state.openedCollection.users.metadata,
          },
        },
      }

    case CHANGE_OPENED_COLLECTION_SORTING:
      return {
        ...state,
        openedCollection: {
          ...state.openedCollection,
          sorting: action.payload,
        },
      }

    case CHANGE_COLLECTIONS_LOADING_STATUS:
      return { ...state, isLoading: !state.isLoading }
    case CHANGE_INITIAL_OVERLAP_MODAL:
      return {
        ...state,
        openedCollection: {
          ...state.openedCollection,
          overlap: {
            ...state.openedCollection.overlap,
            initialOverlapModal: action.payload,
          },
        },
      }
    case CHANGE_CONFIRM_OVERLAP_MODAL:
      return {
        ...state,
        openedCollection: {
          ...state.openedCollection,
          overlap: {
            ...state.openedCollection.overlap,
            confirmOverlapModal: action.payload,
          },
        },
      }
    case CHANGE_OVERLAPS_DATA:
      return {
        ...state,
        openedCollection: {
          ...state.openedCollection,
          overlap: {
            ...state.openedCollection.overlap,
            overlapsData: action.payload,
          },
        },
      }
    case CHANGE_OVERLAPS_LOADING:
      return {
        ...state,
        openedCollection: {
          ...state.openedCollection,
          overlap: {
            ...state.openedCollection.overlap,
            isLoading: action.payload,
          },
        },
      }
    case CHANGE_OVERLAPS_ERROR:
      return {
        ...state,
        openedCollection: {
          ...state.openedCollection,
          overlap: {
            ...state.openedCollection.overlap,
            isError: action.payload,
          },
        },
      }
    case RESET_COLLECTIONS:
      return initState
    default:
      return state
  }
}

// sync actions
export const setCollectionsList = payload => actionProvider(SET_COLLECTIONS_LIST, payload)
export const setCollectionsData = payload => actionProvider(SET_STARTUP_COLLECTIONS, payload)
export const setOpenedCollection = payload => actionProvider(SET_OPENED_COLLECTION, payload)
export const changeUserDataInOpenedCollection = payload =>
  actionProvider(CHANGE_USERDATA_IN_OPENED_COLLECTION, payload)
export const changeLoadingCollectionsStatus = () =>
  actionProvider(CHANGE_COLLECTIONS_LOADING_STATUS)
export const resetCollections = () => actionProvider(RESET_COLLECTIONS)
export const setOpenedCollectionSorting = payload =>
  actionProvider(CHANGE_OPENED_COLLECTION_SORTING, payload)
export const changeOpenedCollectionOverlapModal = payload =>
  actionProvider(CHANGE_INITIAL_OVERLAP_MODAL, payload)
export const changeOpenedCollectionOverlapConfirmModal = payload =>
  actionProvider(CHANGE_CONFIRM_OVERLAP_MODAL, payload)

export const changeOpenedCollectionOverlapsData = payload =>
  actionProvider(CHANGE_OVERLAPS_DATA, payload)
export const changeOpenedCollectionOverlapLoading = payload =>
  actionProvider(CHANGE_OVERLAPS_LOADING, payload)
export const changeOpenedCollectionOverlapError = payload =>
  actionProvider(CHANGE_OVERLAPS_ERROR, payload)

// async actions
export const getCollectionUsers = (
  collectionId,
  collectionPage,
  disableLoadingStatus = false,
) => async (dispatch, getState) => {
  const reduxHistory = navigateHistory(getState())
  const collections = collectionsSelector(getState())
  const firstCollectionIdInList = collections[0]?.collectionId

  const isLoadingCollection = isLoadingCollectionSelector(getState())

  if(isLoadingCollection) return

  let isSucceeded = false
  const isFirstCollectionId = !collectionId || collectionId === 'fav' || collectionId === '_fav'
  const currentCollectionId = collectionId ? collectionId: firstCollectionIdInList
  
  if(!collections?.length && !collectionId) return

  if(isFirstCollectionId) {
    reduxHistory && reduxHistory?.replace(`/collections/${firstCollectionIdInList}`)
  }

  try {
    !disableLoadingStatus && dispatch(changeLoadingCollectionsStatus())
    const users = await httpService.fetchCollection(currentCollectionId, collectionPage)
    const { userdata, metadata, contentData } = users
    dispatch([
      setOpenedCollection({
        collectionId: currentCollectionId,
        users: {
          userdata: dispatch(sortUsersInOpenedCollection({ userdata })),
          metadata,
          contentData,
        },
      }),
      !disableLoadingStatus && changeLoadingCollectionsStatus(),
    ])
    isSucceeded = true
  } catch (err) {
    dispatch([setError(ERROR_MSG.failLoadCollections), changeLoadingCollectionsStatus()])
  } finally {
    (isLoadingCollection || disableLoadingStatus) && dispatch(changeLoadingCollectionsStatus())
  }
  return isSucceeded
}

export const getCollectionFromExplorePage = ({ collectionId, callback }) => async dispatch => {
  let isSucceeded = false
  try {
    dispatch(changeLoadingCollectionsStatus())
    const users = await httpService.fetchCollection(collectionId, 1)
    const { userdata, metadata, contentData } = users
    dispatch([
      setOpenedCollection({
        collectionId,
        users: {
          userdata: dispatch(sortUsersInOpenedCollection({ userdata })),
          metadata,
          contentData,
        },
      }),
      changeLoadingCollectionsStatus(),
    ])

    if (userdata?.length) {
      const filteredByPlatform = userdata?.filter(
        creator => creator._index === USER_INDEXES.instagram
      )
      callback(filteredByPlatform)
    }

    isSucceeded = true
  } catch (err) {
    dispatch([setError(ERROR_MSG.failLoadCollections), changeLoadingCollectionsStatus()])
  }
  return isSucceeded
}

export const createNewCollection = ({
  name: collectionName,
  description,
  access,
}) => async dispatch => {
  try {
    dispatch(changeLoadingCollectionsStatus())
    await httpService.fetchCreateCollection({ collectionName, description, access })
    await dispatch(getAllCollections())
    dispatch(changeLoadingCollectionsStatus())
  } catch (err) {
    dispatch([setError(ERROR_MSG.failOperation), changeLoadingCollectionsStatus()])
  }
}

export const editCollection = ({ name: newValue, description, collectionId }) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(changeLoadingCollectionsStatus())
    await httpService.fetchEditCollection(collectionId, newValue, description)
    const collectionsList = collectionsSelector(getState())
    const updatedCollectionsList = collectionsList.map(el =>
      el.collectionId === collectionId ? { ...el, name: `${newValue}`, description } : el
    )
    dispatch([setCollectionsList(updatedCollectionsList), changeLoadingCollectionsStatus()])
  } catch (err) {
    dispatch([setError(ERROR_MSG.failOperation), changeLoadingCollectionsStatus()])
  }
}

export const deleteCollection = collectionId => async (dispatch, getState) => {
  try {
    await httpService.deleteCollection(collectionId)
    const collectionsList = collectionsSelector(getState())
    const updatedCollectionsList = collectionsList.filter(el => el.collectionId !== collectionId)
    dispatch(setCollectionsList(updatedCollectionsList))
    localStorageService.removeCollectionSorting(collectionId)
  } catch (err) {
    dispatch(setError(ERROR_MSG.failDeleteCollection))
  }
}

export const getAllCollections = () => async dispatch => {
  try {
    const response = await httpService.fetchAllCollections()
    if (response) dispatch(setCollectionsList(response))
  } catch (err) {
    dispatch(setError(ERROR_MSG.failOperation))
  }
}

export const makeChangesToRemoteCollection = (type, ids) => async (dispatch, getState) => {
  const { objectId, contentId, collectionId, targetCollectionId } = ids
  const isAddType = type === COLLECTION_CHANGES_TYPES.add
  const isRemoveType = type === COLLECTION_CHANGES_TYPES.remove
  const isMoveType = type === COLLECTION_CHANGES_TYPES.move

  const objectIds = typeof objectId === 'string' ? [objectId] : objectId

  const contentIds = typeof contentId === 'string' ? [contentId] : contentId
  const body = contentId ? { contentIds, collectionId } : { objectIds, collectionId }

  try {
    if (isAddType) await httpService.addElementToCollection(body)
    if (isRemoveType) await httpService.removeElementFromCollection(body)
    if (isMoveType) {
      await httpService.addElementToCollection({ objectIds, collectionId: targetCollectionId })
      await httpService.removeElementFromCollection({ objectIds, collectionId })
    }
    return true
  } catch (err) {
    const isAddOrMoveType =
      type === COLLECTION_CHANGES_TYPES.add || type === COLLECTION_CHANGES_TYPES.move
    const errorsMap = {
      [API_STATUS_CODES.notModified]: ERROR_MSG.notModifiedCollection,
      [API_STATUS_CODES.forbidden]: ERROR_MSG.collectionLimit,
      [API_STATUS_CODES.tooManyRequests]: ERROR_MSG.exceedRequestsLimit,
    }
    if (err.response && isAddOrMoveType) {
      const { status } = err.response
      if (status === API_STATUS_CODES.upgradeRequired) {
        dispatch(limitAccess())
      } else {
        dispatch(setError(errorsMap[status] || ERROR_MSG.failOperation))
      }
    } else {
      dispatch(setError(ERROR_MSG.failOperation))
    }
    return false
  }
}

export const addSelectedProfileToCollection = (objectId, collectionId, contentId) => async (
  dispatch,
  getState
) => {
  const profile = selectedProfileDetailsSelector(getState())
  const success = await dispatch(
    makeChangesToRemoteCollection(COLLECTION_CHANGES_TYPES.add, {
      objectId,
      collectionId,
      contentId,
    })
  )
  if (success) {
    dispatch(modifyElementsInCollectionsEverywhere(collectionId, objectId, profile, contentId))
  }
  return success
}

export const removeSelectedProfileFromCollection = (
  objectId,
  collectionId,
  contentId
) => async dispatch => {
  const success = await dispatch(
    makeChangesToRemoteCollection(COLLECTION_CHANGES_TYPES.remove, {
      objectId,
      collectionId,
      contentId,
    })
  )
  if (success) {
    dispatch(modifyElementsInCollectionsEverywhere(collectionId, objectId, '', contentId))
  }
  return success
}

export const addElementToCollection = (objectId, collectionId, user = null) => async (
  dispatch,
  getState
) => {
  let userToAdd = null
  if (user) {
    userToAdd = user // when user is present, just use it for add
  } else {
    const searchUsersList = searchResultsUsersSelector(getState()) // by default we find user in search results
    // TODO: when multi-users select is implemented, objectId must be array, use includes in find func
    userToAdd = searchUsersList.find(u => u._id === objectId)
  }

  const success = await dispatch(
    makeChangesToRemoteCollection(COLLECTION_CHANGES_TYPES.add, { objectId, collectionId })
  )
  if (success) {
    dispatch(modifyElementsInCollectionsEverywhere(collectionId, objectId, userToAdd))
  }
  return success
}

export const removeElementFromCollection = (
  objectId,
  collectionId,
  isContentId
) => async dispatch => {
  let success

  if (isContentId) {
    success = await dispatch(
      makeChangesToRemoteCollection(COLLECTION_CHANGES_TYPES.remove, {
        contentId: isContentId,
        collectionId,
      })
    )
    if (success) return dispatch(modifyElementsInCollectionsEverywhere(collectionId, isContentId))
  } else {
    success = await dispatch(
      makeChangesToRemoteCollection(COLLECTION_CHANGES_TYPES.remove, { objectId, collectionId })
    )
    if (success) return dispatch(modifyElementsInCollectionsEverywhere(collectionId, objectId))
  }
}

export const addElementToOpenedCollection = (objectId, collectionId, isContentId) => async (
  dispatch,
  getState
) => {
  const {
    users: { userdata },
  } = openedCollectionSelector(getState())
  const objectIds = Array.isArray(objectId) ? objectId : [objectId]
  const contentIds = Array.isArray(isContentId) ? isContentId : [isContentId]
  const userToAdd = userdata.filter(u => objectIds.includes(u._id))

  let success

  if (isContentId) {
    success = await dispatch(
      makeChangesToRemoteCollection(COLLECTION_CHANGES_TYPES.add, {
        contentId: contentIds,
        collectionId,
      })
    )
  } else {
    success = await dispatch(
      makeChangesToRemoteCollection(COLLECTION_CHANGES_TYPES.add, {
        objectId: objectIds,
        collectionId,
      })
    )
  }

  if (success) {
    return dispatch(modifyElementsInCollectionsEverywhere(collectionId, objectIds, userToAdd))
  }
}

export const removeElementFromOpenedCollection = (objectId, collectionId) => async dispatch => {
  const success = await dispatch(
    makeChangesToRemoteCollection(COLLECTION_CHANGES_TYPES.remove, { objectId, collectionId })
  )

  if (success) return dispatch(modifyElementsInCollectionsEverywhere(collectionId, objectId))
}

export const moveElementFromOpenedCollection = (objectIds = [], targetCollectionId) => async (
  dispatch,
  getState
) => {
  if (!Array.isArray(objectIds)) objectIds = [objectIds]
  const {
    users: { userdata },
    id: openedCollectionId,
  } = openedCollectionSelector(getState())
  const usersToAdd = userdata.filter(u => objectIds.includes(u._id))
  const success = await dispatch(
    makeChangesToRemoteCollection(COLLECTION_CHANGES_TYPES.move, {
      objectId: objectIds,
      collectionId: openedCollectionId,
      targetCollectionId,
    })
  )

  if (success) {
    dispatch([
      modifyElementsInCollectionsEverywhere(openedCollectionId, objectIds),
      modifyElementsInCollectionsEverywhere(targetCollectionId, objectIds, usersToAdd),
    ])
    return true
  }
}

// //
export const changeUserInOpenedCollection = (
  idToUpdate,
  changeField,
  newValue
) => async dispatch => {
  dispatch(modifyProfileElementEverywhere({ profileId: idToUpdate, changeField, newValue }))
}

export const setOpenCollectionUsersSelected = ({ userId = null, selectAll = true }) => (
  dispatch,
  getState
) => {
  const openedCollection = openedCollectionSelector(getState())
  const { userdata } = dispatch(
    setSelectedUsersCommonAction({
      users: openedCollection.users,
      userId,
      selectAll,
    })
  )
  // debugger
  dispatch(changeUserDataInOpenedCollection(userdata))
}

export const changeOpenedCollectionSorting = ({ newSortKey = '', newSortDirection = '' }) => (
  dispatch,
  getState
) => {
  const { id: collectionId, sorting: currentSorting } = openedCollectionSelector(getState())
  const sortKeyToSet = newSortKey || currentSorting.sortKey
  const sortDirectionToSet = newSortDirection || currentSorting.sortDirection

  dispatch([
    setOpenedCollectionSorting({
      sortKey: sortKeyToSet,
      sortDirection: sortDirectionToSet,
    }),
    sortUsersInOpenedCollection({ withDispatch: true }),
  ])

  localStorageService.setCollectionSorting(
    collectionId,
    COLLECTIONS_SORTING_TYPES[sortKeyToSet].type,
    sortDirectionToSet
  )
}

export const setCollectionInitSorting = collectionId => dispatch => {
  if (!collectionId) return
  const sortTypeFromStorage = localStorageService.getCollectionSorting(collectionId)
  if (sortTypeFromStorage === null) {
    dispatch(setOpenedCollectionSorting({ ...defaultSorting }))
  } else {
    const { sortType, sortDirection } = sortTypeFromStorage

    const sortKey = Object.keys(COLLECTIONS_SORTING_TYPES).find(
      k => COLLECTIONS_SORTING_TYPES[k].type === sortType
    )
    if (sortKey) {
      dispatch(
        setOpenedCollectionSorting({
          sortKey,
          sortDirection,
        })
      )
    }
  }
}

export const sortUsersInOpenedCollection = ({ userdata = null, withDispatch = false }) => (
  dispatch,
  getState
) => {
  if (!userdata) userdata = openedCollectionUsersSelector(getState())
  if (!userdata.length) return userdata
  const { sortKey, sortDirection } = openedCollectionSortingSelector(getState())

  const sortedUsers = sortArrayOfObjects({
    array: userdata,
    sortKey:
      sortKey === COLLECTIONS_SORTING_TYPES.profileScore.sortKey
        ? COLLECTIONS_SORTING_TYPES.profileScore.sortNestedKey
        : sortKey,
    sortDirection,
  })
  return withDispatch ? dispatch(changeUserDataInOpenedCollection(sortedUsers)) : sortedUsers
}

export const changeCollectionAccess = ({ collectionId, access }) => async (dispatch, getState) => {
  try {
    dispatch(changeLoadingCollectionsStatus())
    await httpService.changeCollectionAccess({ collectionId, accessType: access })
    const collectionsList = collectionsSelector(getState())
    const updatedList = collectionsList.map(c =>
      c.collectionId === collectionId ? { ...c, access } : c
    )
    dispatch([setCollectionsList(updatedList), changeLoadingCollectionsStatus()])
  } catch (err) {
    dispatch([setError(ERROR_MSG.failChangeCollectionAccess), changeLoadingCollectionsStatus()])
  }
}

export const unlockAudienceReports = () => async (dispatch, getState) => {
  try {
    const openedCollection = openedCollectionSelector(getState())
    const {
      id,
      users: {
        userdata,
        metadata: {
          audienceReport: { toUnlockArray },
        },
      },
    } = openedCollection

    if (!toUnlockArray.length) return

    let subtractedTokens = 0
    const toUnlockObject = {
      [USER_INDEXES.instagram]: [],
      [USER_INDEXES.tiktok]: [],
    }

    toUnlockArray.forEach(userId => {
      const platform = userdata.find(user => user._id === userId)?._index
      if (!platform) return
      toUnlockObject[platform].push(userId)
      if (platform !== USER_INDEXES.tiktok) subtractedTokens += 1
    })

    if (toUnlockObject[USER_INDEXES.instagram].length) {
      await httpService.unlockAudienceAnalysisReport({
        objectIds: toUnlockObject[USER_INDEXES.instagram],
        platform: USER_INDEXES.instagram,
      })
    }
    if (toUnlockObject[USER_INDEXES.tiktok].length) {
      await httpService.unlockAudienceAnalysisReport({
        objectIds: toUnlockObject[USER_INDEXES.tiktok],
        platform: USER_INDEXES.tiktok,
      })
    }
    if (subtractedTokens) {
      await dispatch(changeAudienceTokens({ tokensToAdd: subtractedTokens }))
    }
    await dispatch(getCollectionUsers(id))
  } catch (err) {
    dispatch(setError(ERROR_MSG.failUnlockAudienceReport))
  }
}

export const changeCollectionOrder = payload => async dispatch => {
  try {
    await httpService.changeOrderCollection(payload)
  } catch (err) {
    dispatch(setError('Something went wrong'))
  }
}
export const changeCollectionView = payload => async dispatch => {
  try {
    await httpService.changeSortCollectionView(payload)
  } catch (err) {
    dispatch(setError('Something went wrong'))
  }
}

export const addProfilesToOverlap = profileIds => async dispatch => {
  try {
    await httpService.addProfilesToOverlap({ profileIds })
    dispatch([getAllOverlaps(), changeOpenedCollectionOverlapModal(true)])
  } catch (err) {
    const resStatus = err?.response?.status

    if(resStatus === 403) {
      dispatch([changeOpenedCollectionOverlapError(err), setError(ERROR_MSG.dontHaveAudienceReports)])
    } else {
      dispatch(changeOpenedCollectionOverlapError(err))
    }
  }
}

export const getAllOverlaps = () => async dispatch => {
  try {
    dispatch(changeOpenedCollectionOverlapLoading(true))
    const overlapsData = await httpService.getAllOverlaps()

    if (overlapsData?.length) {
      dispatch(changeOpenedCollectionOverlapsData(overlapsData))
    }

    dispatch(changeOpenedCollectionOverlapLoading(false))
  } catch (err) {
    dispatch([changeOpenedCollectionOverlapError(err), setError('Something went wrong')])
  }
}
