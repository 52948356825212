import React, { useEffect, useState } from 'react'
import useTranslation from '../../../../../localization/useTranslation'
import { Box, Autocomplete, TextField, Stack } from '@mui/material'

const keywordsCategoriesOptions = [
  { value: 'fashion', label: 'Fashion & Style' },
  { value: 'fitness', label: 'Fitness & Wellness' },
  { value: 'sports', label: 'Athletics & Sports' },
  { value: 'beauty', label: 'Beauty & Cosmetics' },
  { value: 'food', label: 'Food & Drink' },
  { value: 'diet', label: 'Healthy Nutrition & Diet' },
  { value: 'veganism', label: 'Veganism & Vegetarianism' },
  { value: 'travel', label: 'Travel & Adventure' },
  { value: 'interior', label: 'Home & Interior Design' },
  { value: 'tech', label: 'Technology & Gadgets' },
  { value: 'art', label: 'Art & Creativity' },
  { value: 'comedy', label: 'Comedy' },
  { value: 'education', label: 'Education & Learning' },
  { value: 'family', label: 'Parenting & Family' },
  { value: 'media', label: 'Entertainment & Media' },
  { value: 'music', label: 'Music & Performing Acts' },
  { value: 'lgbtq', label: 'LGBTQ+' },
  { value: 'gaming', label: 'Gaming' },
  { value: 'business', label: 'Business & Finance' },
  { value: 'automotive', label: 'Automotive & Vehicles' },
  { value: 'sustainability', label: 'Sustainability & Environment' },
  { value: 'animals', label: 'Animals & Pets' },
  { value: 'charity', label: 'Charity & Activism' },
  { value: 'politics', label: 'Politics' },
]

export const CategoriesFilter = props => {
  const { query, handlersMap, isModalOpen, closeModal } = props || {}

  const { labelStrings } = useTranslation()

  const [open, setOpen] = useState(false)

  const onChange = (event, value) => {
    handlersMap?.categories(value ? value.value : null)
    setOpen(false)
    closeModal && closeModal(null)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isModalOpen) {
        setOpen(true)
      } else {
        setOpen(false)
      }
    }, 200)

    return () => clearTimeout(timer)
  }, [isModalOpen])

  return (
    <Box sx={{ width: 250 }}>
      <Autocomplete
        open={open}
        value={keywordsCategoriesOptions.find(option => option.value === query?.categories) || null}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        options={keywordsCategoriesOptions}
        getOptionLabel={option => option.label}
        onChange={onChange}
        renderInput={params => (
          <TextField
            {...params}
            label={labelStrings.pleaseSelectCategories}
            variant="outlined"
            size="small"
            sx={{
              '& .MuiOutlinedInput-root': {
                outline: 'unset',
                '& fieldset': {
                  borderColor: '#BC9797',
                },
                '&:hover fieldset': {
                  borderColor: '#784E4E',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#784E4E',
                },
              },
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                textTransform: 'capitalize',
                fontWeight: 'bold',
                fontFamily: 'Inter',
                color: '#442424',
              }}
            >
              {option.label}
            </Stack>
          </li>
        )}
      />
    </Box>
  )
}
