import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ModalComponent } from "../../../new-ui/components/modal/ModalComponent";
import useTranslation from "../../../localization/useTranslation";
import { Stack, Typography, Box, Button, TextField } from "@mui/material";
import { validateEmail } from "../../../utils";
import { requestChangeEmail } from "../../../redux/ducks/userDucks";

export const ChangePasswordModal = (props) => {
  const { isOpenModal, closeModal } = props || {};

  const { labelStrings } = useTranslation();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [isErrorEmail, setIsErrorEmail] = useState("");
  const [isConfirmedEmail, setIsConfirmedEmail] = useState(false);

  const onChangeEmail = ({ target }) => {
    setEmail(target.value);
  };

  const onCloseEmailModal = () => {
    closeModal();
    setIsErrorEmail("");
    setEmail("");
  };

  const onConfirm = (e) => {
    e.preventDefault();
    if (!isErrorEmail?.length) {
      dispatch(requestChangeEmail({ email }));
      setIsConfirmedEmail(true);
      onCloseEmailModal();
    }
  };

  const handleBlur = () => {
    if (!validateEmail(email) && email?.length) {
      setIsErrorEmail(labelStrings.enterAValidEmailAddress);
    } else {
      setIsErrorEmail("");
    }
  };

  return (
    <ModalComponent
      isOpenModal={isOpenModal}
      closeModal={onCloseEmailModal}
      title={
        isConfirmedEmail
          ? labelStrings.confirmNewEmail
          : labelStrings.changeYourEmail
      }
      contentWidth={isConfirmedEmail ? "460px" : "520px"}
      contentHeight='auto'
      isCenterTitle
      withoutScrollWrapper
      isGradientTitle
      isGradientBg
    >
      {isConfirmedEmail ? (
        <Box sx={{ pb: 3 }}>
          <Stack spacing={2} alignItems='center'>
            <Typography textAlign='center' sx={{ fontFamily: "Inter" }}>
              {labelStrings.clickLinkEmail}
            </Typography>
            <Button
              variant='contained'
              color='primary'
              sx={{ minWidth: "100px", borderColor: "#FF5C00" }}
              onClick={onCloseEmailModal}
            >
              OK
            </Button>
          </Stack>
        </Box>
      ) : (
        <Box>
          <Stack spacing={2}>
            <Stack spacing={0.5} alignItems='center'>
              <Typography sx={{ fontFamily: "Inter" }}>
                {labelStrings.enterNewEmail}
              </Typography>
              <TextField
                sx={{ width: "80%" }}
                size='small'
                autoComplete='off'
                value={email}
                onChange={onChangeEmail}
                onBlur={handleBlur}
                error={!!isErrorEmail}
                helperText={isErrorEmail}
                focused
                autoFocus
              />
            </Stack>
            <Typography
              textAlign='center'
              sx={{ fontFamily: "Inter", fontSize: "14px" }}
            >
              {labelStrings.changeEmailNotice}
            </Typography>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              sx={{ pb: 3 }}
            >
              <Button
                onClick={closeModal}
                variant='outlined'
                color='primary'
                sx={{ borderColor: "#FF5C00", color: '#FF5C00' }}
              >
                {labelStrings.cancel}
              </Button>
              <Button
                disabled={!!isErrorEmail?.length || !email?.length}
                onClick={onConfirm}
                variant='contained'
                color='primary'
                sx={{
                  backgroundColor: "#FF5C00"
                }}
              >
                {labelStrings.confirm}
              </Button>
            </Stack>
          </Stack>
        </Box>
      )}
    </ModalComponent>
  );
};
