import React, { useEffect, useState } from 'react'
import useTranslation from '../../../../../localization/useTranslation'
import { Stack, Box, TextField, Autocomplete } from '@mui/material'
import { getPngCountryFlags } from '../../../../../utils'

const langLabels = {
  ['en']: 'gb',
  ['da']: 'dk',
  ['iw']: 'il',
  ['ja']: 'jp',
  ['el']: 'gr',
  ['zh']: 'cn',
  ['hi']: 'in',
  ['ko']: 'kr',
  ['fa']: 'ir',
  ['sw']: 'za',
  ['uk']: 'ua',
  ['ur']: 'pk',
  ['ta']: 'lk',
  ['te']: 'in',
}

export const LanguageFilter = props => {
  const { query, handlersMap, isModalOpen, closeModal } = props || {}

  const { labelStrings, languageOptions } = useTranslation()

  const [open, setOpen] = useState(false)

  const currValue = languageOptions?.find(lang => lang.value === query.language) || null

  const onChange = (event, value) => {
    handlersMap?.language(value ? value.value : null)
    setOpen(false)
    closeModal && closeModal(null)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isModalOpen) {
        setOpen(true)
      } else {
        setOpen(false)
      }
    }, 220)

    return () => clearTimeout(timer)
  }, [isModalOpen])

  return (
    <Box sx={{ width: '250px' }}>
      <Autocomplete
        size="small"
        options={languageOptions || []}
        getOptionLabel={option => option.label}
        onChange={onChange}
        value={currValue}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderInput={params => (
          <TextField
            size="small"
            {...params}
            label={labelStrings.selectLanguage}
            variant="outlined"
            sx={{
              width: 250,
              '& .MuiOutlinedInput-root': {
                outline: 'unset',
                '& fieldset': {
                  borderColor: '#BC9797',
                },
                '&:hover fieldset': {
                  borderColor: '#784E4E',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#784E4E',
                },
              },
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                textTransform: 'capitalize',
                fontWeight: 'bold',
                fontFamily: 'Inter',
                color: '#442424',
              }}
            >
              {getPngCountryFlags(langLabels[option.value] || option.value)}
              {option.label}
            </Stack>
          </li>
        )}
      />
    </Box>
  )
}
