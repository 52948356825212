import React, { useState, useMemo, memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, shallowEqual } from 'react-redux'
import { userGrantSelector, allCampaignsSelector } from '../../../../redux/selectors'
import useTranslation from '../../../../localization/useTranslation'

import { DEFAULT_CAMPAIGN_SEATS, SEATS_PRICES_MAP } from '../../../../constants/campaigns'
import { PRICING_PLANS_ELEMENT_ID } from '../../../../constants/appSettings'
import { scrollElementIntoView } from '../../../../utils'

import ReusableAddOnSection from './ReusableAddOnSection'
import BuyCampaignsSeatsContainer from '../changeAddOns/BuyCampaignsSeatsContainer'

const CampaignsTokensAddOnSection = () => {
  const { labelStrings } = useTranslation()

  const [isModalOpened, setIsModalOpened] = useState(false)
  const toggleIsModalOpened = () => setIsModalOpened(!isModalOpened)

  const {
    max_users_trackable,
    purchased_tracking_seats,
    package_advanced,
    enable_tracking,
  } = useSelector(userGrantSelector, shallowEqual)
  const { trackMax, trackNow } = useSelector(allCampaignsSelector, shallowEqual)

  const defaultTokens = package_advanced
    ? DEFAULT_CAMPAIGN_SEATS.advanced
    : DEFAULT_CAMPAIGN_SEATS.pro

  const tokensLimit = max_users_trackable || trackMax // trust grants first

  const tokensLeft = trackNow > tokensLimit ? 0 : tokensLimit - trackNow

  const alreadyPurchasedSeats = purchased_tracking_seats || 0

  const pricePerSeat = useMemo(() => {
    if (!alreadyPurchasedSeats) return SEATS_PRICES_MAP['0']
    const pricesKeysList = Object.keys(SEATS_PRICES_MAP)
    const nextPriceIdx = pricesKeysList.findIndex(item => parseInt(item) > alreadyPurchasedSeats)
    const currentPrice = nextPriceIdx
      ? SEATS_PRICES_MAP[pricesKeysList[nextPriceIdx - 1]]
      : SEATS_PRICES_MAP['0']
    return currentPrice
  }, [alreadyPurchasedSeats])

  const tokensTexts = {
    currentTokens: `${labelStrings.seatsFromYourPlan(max_users_trackable)}`,
    pricePerSeat,
    priceTotal: pricePerSeat ? Math.round(pricePerSeat * alreadyPurchasedSeats) : 0,
  }

  const onClickUpgrade = () => {
    scrollElementIntoView(PRICING_PLANS_ELEMENT_ID)
  }

  if (enable_tracking) {
    return (
      <>
        <ReusableAddOnSection
          title={labelStrings.influencerTrackingSeats}
          changeTokenLabel={labelStrings.upgradeToProToAddMoreSeats}
          isChangeTokenDisabled={false}
          onClickChangeToken={onClickUpgrade}
          tokensLeft={`${labelStrings.seatsLeft(tokensLeft)} (${trackNow}/${tokensLimit} ${
            labelStrings.used
          })`}
          tokensTexts={tokensTexts}
          progressbar={{
            limit: tokensLimit,
            current: trackNow,
          }}
        />
        <BuyCampaignsSeatsContainer
          isModalOpened={isModalOpened}
          closeModal={toggleIsModalOpened}
        />
      </>
    )
  }

  return null
}


export default memo(CampaignsTokensAddOnSection)
