import React, { useState } from "react";
import { ModalComponent } from "../../../../new-ui/components/modal/ModalComponent";
import useTranslation from "../../../../localization/useTranslation";
import {
  Stack,
  Typography,
  Box,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Link,
} from "@mui/material";
import { validateEmail } from "../../../../utils";

export const InviteTeamMemberModal = (props) => {
  const { isOpenModal, closeModal, onInviteTeamMember } = props || {};

  const { labelStrings } = useTranslation();

  const [email, setEmail] = useState("");
  const [isErrorEmail, setIsErrorEmail] = useState("");
  const [roleValue, setRoleValue] = useState("");

  const onChangeEmail = ({ target }) => {
    setEmail(target.value);
  };

  const onCloseModal = () => {
    closeModal();
    setIsErrorEmail("");
    setEmail("");
    setRoleValue("");
  };

  const onConfirm = (e) => {
    e.preventDefault();
    if (!isErrorEmail?.length && !!roleValue?.length) {
      const payload = {
        email: email?.trim(),
        role: roleValue
      }
      onInviteTeamMember(payload);
    }
    onCloseModal();
  };

  const handleBlur = () => {
    if (!validateEmail(email) && email?.length) {
      setIsErrorEmail(labelStrings.enterAValidEmailAddress);
    } else {
      setIsErrorEmail("");
    }
  };

  const onChangeRole = ({ target }) => {
    setRoleValue(target?.value);
  };

  return (
    <ModalComponent
      isOpenModal={isOpenModal}
      closeModal={onCloseModal}
      title={labelStrings.inviteNewTeamMembers}
      contentWidth={"520px"}
      contentHeight='auto'
      isCenterTitle
      withoutScrollWrapper
      isGradientTitle
      isGradientBg
    >
      <Box>
        <Stack spacing={2}>
          <Stack spacing={0.5} alignItems='center'>
            <Typography sx={{ fontFamily: "Inter" }}>
              {labelStrings.addEmailForTeamMember}:
            </Typography>
            <TextField
              sx={{ width: "80%" }}
              size='small'
              autoComplete='off'
              value={email}
              onChange={onChangeEmail}
              onBlur={handleBlur}
              error={!!isErrorEmail}
              helperText={isErrorEmail}
              focused
              autoFocus
            />
          </Stack>

          <Stack alignItems='center'>
            <Stack alignItems='center'>
              <Typography sx={{ fontFamily: "Inter" }}>
                {labelStrings.whatIsTheRoleOfTheTeamMember}
              </Typography>
              <Link
                target='_blank'
                rel='noopener'
                href='https://faq.infludata.com/roles'
                underline='none'
                sx={{
                  fontFamily: "Inter",
                  fontSize: "12px",
                  color: "#212B36",
                  "&:hover": {
                    color: "#212B36",
                  },
                }}
              >
                {labelStrings.companyMembersSubtitle}
              </Link>
            </Stack>
            <FormControl fullWidth sx={{ maxWidth: "80%", pt: 0.5 }}>
              <Select size='small' value={roleValue} onChange={onChangeRole}>
                <MenuItem value={"member"}>{labelStrings.member}</MenuItem>
                <MenuItem value={"admin"}>{labelStrings.admin}</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{ pb: 3, pt: 2 }}
          >
            <Button
              onClick={closeModal}
              variant='outlined'
              color='primary'
              sx={{ borderColor: "#FF5C00", color: "#FF5C00" }}
            >
              {labelStrings.cancel}
            </Button>
            <Button
              disabled={
                isErrorEmail?.length || (!email?.length && !roleValue?.length)
              }
              onClick={onConfirm}
              variant='contained'
              color='primary'
              sx={{
                backgroundColor: "#FF5C00",
              }}
            >
              {labelStrings.invite}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </ModalComponent>
  );
};
