// @ts-nocheck
import availableLangs from './availableLangs'
import { ADD_USER_RESPONSE_STATUS_MAP, USER_INDEXES } from '../constants/appSettings'

export default {
  [availableLangs.en]: {
    all: 'All',
    search: 'Search',
    followerMin: 'Follower (Min)',
    followerMax: 'Follower (Max)',
    city: 'City',
    country: 'Country',
    countryCompany: 'Country of Business',
    age: 'Age',
    keywords: 'Keywords & Mentions',
    matchall: 'Match',
    gender: 'Gender',
    language: 'Language',
    languages: 'Languages',
    username: 'Username',
    enterUsername: 'Enter username',
    collection: 'Collection',
    selectMultiCountries: 'Select (multiple) country',
    selectLanguage: 'Select language',
    selectKeywords: 'Select keywords',
    selectCurrency: 'Select currency',
    selectGender: 'Select gender',
    selectMultiCities: 'Select (multiple) cities',
    chooseCollection: 'Choose your collection...',
    numberProfiles: 'Number of profiles',
    creationDate: 'Date of creation',
    removeFromCollection: 'Remove from Collection?',
    removeAllUsersFromCollection:
      'If you proceed, all users in this collection will be lost. Are you sure you want to remove this collection?',
    location: 'Location',
    locationLanguage: 'Location/Language',
    notes: 'Private Notes',
    yourCollections: 'Your Collections',
    content: 'Content',
    learning: 'Learning Center',
    analyze: 'Analyze',
    results: 'Results',
    selectCollection: 'Select a collection',
    editCollection: 'Edit Collection',
    removeCollection: 'Remove Collection',
    exportCollection: 'Export collection',
    editCollectionTitle: 'Edit the collection',
    createNewCollection: 'Create new collection',
    createUnlimitedCollections: 'create unlimited collections',
    create: 'Please press enter to add',
    collectionName: 'Collection name',
    collectionMustHaveName: 'Collections must have a name',
    collectionDescription: 'Collection description',
    previous: 'Previous',
    next: 'Next',
    scoreOverall: 'Score',
    scorePerformance: 'Authenticity',
    scoreLikes: 'Likes',
    scoreComments: 'Comments',
    scoreQuantity: 'Post Quantity',
    scoreFollowing: 'Following Count',
    scoreRhytm: 'Posting Regularity',
    copiedToClipboard: 'Copied to Clipboard!',
    failCopyToClipboard: 'Failed to copy to clipboard',
    failGetCSV: 'Failed to get CSV',
    addPrivateComment: 'Add private comment',
    privateNote: 'Private Note',
    favorites: 'Favorites',
    mustIncludeEmail: 'Must include email',
    email: 'E-Mail',
    emailAddress: 'E-Mail Address',
    copyEmail: 'Copy the email',
    seeUserEmail: "see user's email",
    upgradeToSeeUserEmail: "Upgrade to see user's email",
    userHasNoEmail: 'Sorry, no email available',
    password: 'Password',
    yes: 'Yes',
    yesSure: "Yes, I'm sure",
    no: 'No',
    rename: 'Rename',
    addToCollections: 'Add to collections',
    addUserToCollections: 'add user to collections',
    removeFromCollectionTitle: 'Remove {0} from collection',
    exportAsCSV: 'Export as CSV',
    industryType: 'Industry Type',
    followersHistory: 'Followers History',
    infludataQualityRating: 'influData Quality Rating',
    bulkActionText: 'What do you want to do with the selected items?',
    remove: 'Remove',
    move: 'Move',
    copy: 'Copy',
    removedNItems: n => `You removed ${n} items successfully`,
    movedNItems: n => `You moved ${n} items successfully`,
    copiedNItems: n => `You copied ${n} items successfully`,
    alreadyInCollectionItems: n =>
      !!n
        ? `${n} ${n > 1 ? 'items' : 'item'} ${n > 1 ? 'are' : 'is'} already in target collection`
        : '',
    renameCollection: 'Rename collection',
    cancel: 'Cancel',
    bulkAddToCollections: 'Add to collection',
    bulkManageInCollections: 'Manage Collections',
    logout: `Log out`,
    login: 'Log in',
    signup: 'Sign Up',
    wrongCredentials: 'Wrong password or user name. Please try again.',
    clearSearch: 'Clear search results',
    removeFromCollectionTitleSingular: 'Remove this creator from Collection?',
    removeFromCollectionTitlePlural: 'Remove these creators from Collection?',
    back: 'Back',
    backToSearchResults: 'Back to search results',
    useSearch: 'Use search engine on the left to search for creators.',
    analyzeProfile: 'Enter a username to analyze their profile',
    analyzeAction: 'Analyze',
    chartLoading: 'Chart is loading',
    upgrade: '7 Day Trial',
    upgradeToSeeDetailedScores: 'Please upgrade to view unlimited detailed scores',
    update: 'Update',
    downgrade: 'Change Plan',
    changePlan: 'Change Plan',
    downgradePlan: 'Downgrade Plan',
    upgradePlan: 'Upgrade Plan',
    changeCancelPlan: 'Change/Cancel Plan',
    yourPlan: 'Your Plan',
    cancelPlan: 'Cancel Plan',
    managePlan: 'Manage Plan',
    freeTrial: 'Start Free Trial',
    warning: 'Warning',
    close: 'Close',
    clickHere: 'Click here',
    agree: 'I agree',
    audienceAnalysis: 'Audience Analysis',
    audienceSmartMediaValue: 'Smart Media Value',
    audienceAnalysisNSmartMediaValue: 'Audience Analysis & Smart Media Value',
    audienceSubTitleF: (username, followers) =>
      `Data about the ${followers} followers of ${username}`,
    influencers: 'creators',
    massFollowers: 'mass followers',
    realPeople: 'real people',
    suspicious: 'suspicious accounts',
    male: 'male',
    female: 'female',
    other: 'Other',
    audienceType: 'Audience type',
    ageGroups: 'Age groups',
    ageIs: 'Age is',
    audienceMentions: 'Most common audience interests',
    proFeature: 'Pro Feature',
    subscribeToPro: {
      text: 'Please subscribe to our Pro Package to view the audience analysis',
      link: 'Pro Package',
    },
    categoriesAI: 'Categories (AI-Detected)',
    noDataforChart: "Sorry, we don't have enough data to show a graph.",
    noResultsFound: 'No results found. Try to change search parameters',
    useSearchEngine: 'Use our AI text search above',
    notLoggedInTitle: 'You are currently not logged in',
    useSearchEngineText: 'Find the most suitable creators for your next campaign.',
    notLoggedInText: 'Please log in or sign up to influData and get access to over 30 Million creators and over 1 Billion Content Pieces.',
    searchPageFreeUser:
      'We will get in contact with you as soon as you signed up. Please also use the chat to get in contact with us.',
    notActivatedAccountTipTitle: 'Your plan is currently inactive.',
    notActivatedAccountTipText:
      'If you just signed up, we will get in contact with you shortly. Feel free to reach out to use in case you have any questions.',
    collectionsAreDeactivated: 'Collections are deactivated',
    campaignsAreDeactivated: 'Campaign Tracking is deactivated',
    pleaseGetInContact:
      'Please get in contact with us using the chat on the bottom right to discuss our features.',
    pleaseContactSupport: 'Please get in contact with us using the chat on the bottom right.',
    brandAccounts: {
      brandSearch: 'Search for brands accounts',
      brand: 'Brands accounts',
    },
    appearOn: 'Creators appears on',
    nameNBio: 'Name & Bio',
    statistics: 'Statistics',
    contact: 'Contact',
    personalWebsite: 'Personal Website',
    socialMedia: 'Social Media',
    followers: 'Followers',
    following: 'Following',
    followersWithK: 'Followers (in K)',
    plays: 'Plays',
    playsPerPost: 'Plays per post',
    totalPlays: 'Total Plays',
    playsWithK: 'Plays per post (in K)',
    views: 'Views',
    viewsPerPost: 'Views per post',
    averageReach: 'Views per Post',
    engagementMean: 'Engagement Rate',
    viewsPerStory: 'Views per story',
    viewsWithK: 'Views per Post (in K)',
    faqLinkDefaultTip: 'Please click the link below to show more information.',
    faqKeywordsMentions: 'Explanation ...',
    faqLinkClickText: 'Click here for more info',
    useCollections: 'use collections',
    useSearchUpgrade: 'use Creator search',
    to: 'to',
    myPlan: 'My Plan',
    tryForFree: 'Try for free',
    scheduleDemo: 'Schedule demo',
    startTrial: 'Start Trial',
    tellUsAbout: 'Tell us about',
    yourBusiness: 'your business',
    for7Days: 'for 7 Days ',
    noPaymentNeeded: 'non-binding and no payment details needed',
    seeMoreResults: 'see more results',
    paginate: {
      next: 'Next',
      prev: 'Previous',
    },
    qualityScore: 'Quality Score',
    qualityRating: 'Quality Rating',
    options: 'Options',
    geodata: 'Geodata',
    name: 'Name',
    bio: 'Biography',
    post: 'Post',
    posts: 'Posts',
    postsReels: 'Posts & Reels',
    stories: 'Stories',
    postsPerWeek: 'Posts per week',
    viralPosts: 'Viral posts',
    oneHitWonder: 'One hit wonder',
    inAverage: 'in average',
    outOfLast: 'out of last',
    profileScore: 'Profile Score',
    expectedAudiencePerPost: 'Expected views per post',
    engagementRate: 'Engagement Rate',
    engagement: 'Engagement',
    impressions: 'Impressions',
    rating: 'Rating',
    unlockedStatus: 'Unlocked Status',
    unlockedOn: 'Unlocked on',
    status: 'Status',
    available: 'Avaliable',
    requested: 'Requested',
    audienceAnalysisProgress:
      'We are currently analyzing the data for you. This will take up to an hour and will be displayed here automatically',
    audienceReportWillBeAvailableInstantly: 'The report will be available instantly',
    audienceReportWillBeAvailableIn30Min:
      'Your audience report will be available in around 10 minutes and will appear here automatically',
    audienceReportWillBeAvailableInDay: 'The report will be available in maximum one day',
    askNotifyAudienceAnalysisAvailable: 'Do you want to be notified once the report is available?',
    askNotifyAudienceAnalysisConfirm: 'Yes, please notify me via mail',
    weWillNotifyYou: 'We will notify you via Email',
    audienceReportInfluencerDisabled: `We're sorry, but we can't share any insights about this creator.`,
    noAudienceAnalysisYet:
      'We don’t have any audience data for this creator yet. Please add this creator to your Favorites or one of your collections and we will provide you with the audience data in a few days.',
    noAnalysisTechnicalIssue:
      'Audience Analysis is currently not available due to technical issues. We are working on a solution',
    last30days: 'Last 30 days',
    description: 'Description',
    favsDescription:
      "Here are your favorites. Your favorites will always stay private and can't be shared within your company.",
    unlockedDescription:
      'Here you find the reports that you already unlocked. You find all reports that were unlocked by you or by anyone in your company.',
    outOf10: 'out of 10',
    recentBrandsMentions: 'Recent Brands Mentions',
    showOn: 'Show on',
    similarAccounts: 'Similar accounts based on Audience',
    toSeeSimilarAccounts: 'to see similar accounts',
    seeDetailedScores: 'to see detailed scores',
    whatAreSimilarAccounts: 'What are similar accounts and why are they helpful?',
    noSimilarAccountsFound: 'Currently there were no similar accounts found',
    noMoreSimilarAccounts: 'Sorry, no more similar accounts',
    showMore: 'Show More',
    showLess: 'Show Less',
    goBack: 'Go Back',
    goBackToResults: 'Go back',
    profile: 'Profile',
    noUserFound: 'No users found',
    removeNItemsWarning: n =>
      `You are going to remove ${n} creators from your list. You can't undo the action.`,
    areYouSureToProceed: 'Are you sure you want to proceed?',
    moveToCollection: 'Move to a collection',
    copyToCollection: 'Copy to a collection',
    addToCollection: 'Add to a collection',
    giveFeedback: 'Give Feedback',
    contactUs: 'Contact Us',
    thanksForFeedback: 'Thank you for your feedback',
    thanksForMessage: 'Thank you for your message. We will get back to you soon.',
    notValidEmail: 'Not valid email',
    reenterNotValidEmail: 'Email is not valid. Please re-enter email.',
    message: 'Message',
    enterYourName: 'Please enter your name',
    yourMessage: 'Your Message',
    enterYourMessage: 'Please enter your message',
    send: 'Send',
    technicalIssue: 'Technical Issue',
    dataQuality: 'Data Quality',
    yourProfile: 'Your Profile',
    payment: 'Payment',
    appStrUIX: 'App Structure/UX',
    selectTopic: 'Please select topic',
    getBackCheckboxText:
      'Check this box if you would like us to get back to you regarding this issue.',
    loginToInfludata: 'LOG IN TO INFLUDATA',
    loginFail: 'Failed to log in. Please check your credentials',
    passwordCantBeEmpty: "Password can't be empty",
    alreadyExistAccount:
      'There is already an account associated with this email.\n Please log-in below.',
    passRecoveryInstructions:
      'We have sent you a letter with a password recovery link. Please check your email and follow the instructions.',
    forgotPassword: 'Forgot password',
    noAccountYet: "Don't have an account yet?",
    restorePassword: 'Restore password',
    noUserWithSuchEmail: 'There is no user found with this email.',
    weWillSendPassRecoveryLink: 'We will send password recovery link to your email',
    setNewPassword: 'set new password',
    passwordsShouldBeEqual: 'Passwords should match',
    enterPassword: 'enter password',
    notValidPassword: 'Not valid password',
    repeatPassword: 'repeat password',
    pleaseRepeatPassword: 'Please repeat password',
    newPasswordWasSet: 'Your new password has been successfully set',
    backToApp: 'Back to app',
    tryInfludata: 'try all our features',
    forFree: 'for free',
    bySigningUpIAgreeTo: 'By signing up, I agree to the',
    privacyPolicy: 'Privacy Policy',
    termsOfService: 'Terms of Service',
    and: 'and',
    doSendOffersToEmail: 'Yes, please send offers and updates about your products (non-required)',
    firstName: 'First Name',
    lastName: 'Last Name',
    company: 'Company',
    phone: 'Phone',
    companySize: 'Company Size',
    selectCompanySize: 'Select Employees',
    position: 'Position',
    selectPosition: 'Select',
    required: 'required',
    passwordLimit:
      'Password must have minimum 6 characters and should have at least one big letter.',
    finish: 'Finish',
    couldntFind: 'Couldn’t find what you are looking for?',
    termsAndConditions: 'Terms & Conditions',
    privacy: 'Privacy',
    impressum: 'Impressum',
    notEnoughDataForChart: 'Sorry, the metric is not available for this creator right now',
    notAvaliableProfile: 'This profile is not available.',
    unlimited: 'unlimited',
    profileBecamePrivate:
      'Account is set to private, therefore no analytics available. Not correct? Please click the "Update Profile Manually" Button.',
    profileSetToPrivate: 'Account is set to private',
    thereforeCantUpdateThisProfile: "Therefore can't update this profile",
    analyzeYourInfluencer: 'Enter Username',
    influencerTracking: 'Campaign Management',
    useTracking: 'use Creator Tracking',
    toolsForBrands: 'Tools',
    useToolsForBrands: 'use Tools For Brands',
    noInfluencersInCollection: 'Currently, there are no creators in the collection',
    toAddInfluencersGoTo: 'To add creators, please go to',
    searchView: 'Search view',
    andAddInfluencers: 'and add creators',
    canCreateCollectionByClicking: 'You can create a new collection by clicking',
    inLeftMenu: 'in the left menu',
    useLeftMenuToCreateCampaign: 'Use the menu on the left to create the campaign',
    addMoreInfluncersDynamic: n => `You can add ${n} more creators`,
    campaigns: 'Campaigns',
    campaign: 'Campaign',
    createNewCampaign: 'Create new campaign',
    createUnlimitedCampaigns: 'create unlimited campaigns',
    createCampaignTip:
      'Include all the information regarding the campaign. You can edit the details again later.',
    editCampaign: 'Edit campaign',
    deleteCampaign: 'Delete campaign',
    archiveCampaign: 'Archive campaign',
    campaignName: 'Campaign name',
    campaignDescription: 'Campaign description',
    mentionsAndHashtags: 'Mentions & Hashtags',
    startCampaign: 'Start the campaign',
    updateCampaign: 'Update campaign',
    optional: 'Optional',
    campaignMustHaveName: 'Campaign must have a name',
    mentionHashtagsTip:
      'Type mentions and hashtags. Mentions should start with "@" and hashtags should start with "#". Mentions and hashtags should be unique.',
    mentionsHashTagValidationTip: 'Please start with "@" or "#"',
    removeFromCampaign: 'Remove from campaign?',
    removeAllUsersFromCampaign:
      'If you proceed, all users in this campaign will be lost. Are you sure you want to remove this campaign?',
    noDescriptionYet: 'No description yet',
    noMentionsAndHashtagsYet: 'No mentions and hashtags yet',
    created: 'Created',
    addInfluencers: 'Add creators',
    addAnInfluncer: 'Add an creator',
    noInfluencersInCampaign:
      'Currently there are no creators in this campaign. Add an creator on top of the screen.',
    searchByUsername: 'Search by username',
    addInfluencerFromInstagram: 'Add creators from Instagram',
    addInfluencerFromTikTok: 'Add creators from TikTok',
    addToCampaign: 'Add to campaign',
    trackInfluencers: 'track creators',
    notAvaliableCampaignTracking: platformName =>
      `Campaign Tracking for ${platformName} not available (coming soon)`,
    trackingSince: 'Tracking since',
    lastMentions: 'Last mentions',
    lastHit: 'Last Hit',
    alreadyInCampaign: 'Already in campaign',
    campaignUpgradeToAddUsers: 'Upgrade package to add more users',
    campaignBuyTokens: 'Buy tokens to add more users',
    addMoreUsers: 'add more users',
    trackedPosts: 'Hits/Posts',
    trackedStories: 'Hits/Stories',
    noMentions: '-',
    noHashtags: '-',
    noData: '-',
    date: 'Date',
    mentions: 'Mentions',
    hashtags: 'Hashtags',
    likes: 'Likes',
    comments: 'Comments',
    goToPost: 'to post',
    linkedTo: 'Story Link',
    goToStory: 'to story',
    storyExipred: 'story expired',
    showTrackingDetails: 'Show user tracking details',
    hideTrackingDetails: 'Hide user tracking details',
    noTrackingDetails: "We don't have tracking details to show",
    help: 'Help',
    reportForInfluencer: 'Report for the influencer',
    reportForInfluencerPlatform: platform => `Report for creator's ${platform} account`,
    dateOfReport: 'Date of Report',
    exportAsPdf: 'Export as PDF',
    exporting: 'Exporting...',
    page: 'Page',
    profileInformation: 'Profile Information',
    qualityRating: 'Quality Rating',
    qualityRatingAndMetrics: 'Quality Rating and Metrics',
    qualityRatingDetail: 'Quality Rating Detail',
    audienceAnalysisGeneral: 'Audience Analysis',
    outdatedPdf: 'This PDF might be outdated.',
    ifWantUpToDateUserInfo: 'If you want up to date information about this user',
    sortedBy: 'Sorted by',
    default: 'Default',
    previewStory: 'Preview',
    previewPost: 'Preview',
    cantShowPreview: "Sorry, we can't show preview image",
    storyCTALabel: 'Story Link ("Swipe up")',
    storyLink: 'Story Link',
    storyCTAOptionalLabel: 'Optional - works also with parts of the domain',
    pricing: 'Pricing',
    reasonNotAdd: 'This is the reason why we can\'t add this content piece',
    collections: 'Collections',
    confirmRemoveUserFromCampaign:
      'Do you really want to remove this user from the campaign? You will lose all data and adding this user later will not retrieve old data.',
    noStoryLinkYet: 'no story link yet',
    showOnlyHits: 'Show hits only ',
    upgradeToProToAddMoreUsers: 'Upgrade to Pro to add more users',
    relevance: 'Relevance',
    dateAdded: 'Date added',
    useSorting: 'use sorting',
    buyTokens: 'Buy Seats',
    changeSeatPlan: 'Change Seat Plan',
    chooseNumberOfSeats: 'Choose the number of seats',
    upgradeLabel: 'Upgrade',
    downgradeLabel: 'Downgrade',
    plusPlan20SeatsIncluded: '+20 seats included in your premium plan',
    needMoreSeatsContactUs: 'Contact us and get your individual rate!',
    seats: 'seats',
    euroPerSeat: price => `${price} Euro per seat`,
    purchaseNewSeatsTip:
      'Your plan will be activated immediately and the prorated amount for this term will be charged immediately.',
    confirm: 'Confirm',
    allowedDowngradeSeatsTip:
      "Your changes will take effect immediately. Please note that you need to delete any users from your campaigns that would exceed your new limits and that we can't give any refunds for possibly unused period.",
    forbiddenDowngradeSeatsTip: count =>
      `Your current used seats would exceed your new limits. Please delete ${count} ${
        count === 1 ? 'creator' : 'creators'
      } from your campaigns.`,
    purchaseSeatsMonthlyCharge: price =>
      `Your monthly charges for this add-on will be ${price} Euros`,
    seatsSuccessChange: 'Seats have been changed successfully.',
    currentSeatsPlan:
      'This is your current seat plan. Please move slider to the right to upgrade or to the left to downgrade.',
    minGrowthRate: 'Minimum Growth Rate',
    minEngagementRate: 'Minimum Engagement Rate',
    growthRate: 'Growth (# of Followers)',
    off: 'Off',
    onlyAvailableInPro: 'Only available in our Pro Plan',
    onlyAvailableForAdmins: 'Only available for admins',
    thanksForUseFreeVersion: 'Thanks for using our free version',
    freeUserAccessLimitMessage:
      'To continue using our product, please wait for a few days or start a free trial',
    showPlans: 'Show Plans',
    reachTrialLimits: 'You reached the limits of your trial',
    activateTrial: 'Please activate your plan to continue using our platform',
    activate: 'Activate',
    askToActivate: 'Please activate your account',
    activateYourAccount: 'Activate your account',
    notAvailableInTrial: 'Not available in trial',
    activateTrialFeature: 'Please activate your plan to get access to this feature.',
    useCitySearch: 'use the city search',
    useKeywordsSearch: 'use keywords search',
    editYourAccount: 'Open Profile',
    audienceReportTokens: 'Audience report tokens',
    remaining: 'remaining',
    of: 'of',
    influencerTrackingSeats: 'Creator Tracking Seats',
    backToTop: 'Back To Top',
    deleteMyAccount: 'Delete My Account',
    deleteYourAccount: 'Delete Your Account',
    confirmDeleteAccount: 'Please confirm that you want to delete your account.',
    weWillClearFollowingDatain60Days:
      'We cancel possible subscriptions immediately without refunds and remove the following data in 60 days:',
    dataToDelete: {
      billingAndPaymentInfo: 'Billing and payment information',
      allCollectionCampaignInfluencerData: 'All collections, campaigns and any creator data',
      //weMayCancelAnySubscriptions: 'we may cancel any running subscriptions (no refunds)',
      allMailContentAndActivityLogs:
        'All mailing content and all activity logs, if it is not required',
      encryptedPasswords: 'Encrypted passwords or access tokens',
    },
    forLegalSecurityReasonsWeKeep:
      'For legal and security reasons, we keep parts of your data, as long as it is required:',
    dataToKeepWhenDelete: {
      invoices: 'Invoices',
      signup: 'E-Mail Address',
      //mail: 'Mail',
      companyName: 'Company name',
    },
    deleteAccountReactivateTip:
      'Please keep in mind that you will lose access to your account. You are able to reactivate this account within 60 days.',
    goToInfludataHome: 'Go To influData Home',
    yourAccountWasdeactivated: 'Your account was deactivated',
    willBeDeletedIn60Days: 'will be deleted in 60 days',
    contactUsToReactivateDeletedAccount: 'If you would like to reactivate it, please contact us',
    deletedAccountWarning:
      'Your account is deactivated and will be removed soon. Please contact our support team to reactivate your account',
    yourAccount: 'Your Account',
    yourSubscription: 'Subscription',
    please: 'Please',
    ifYouLikeToChangeEmail: 'if you would like to change your email address',
    changePassword: 'Change password',
    billingDetails: 'Billing Details',
    saveDetails: 'Save details',
    zip: 'ZIP',
    state: 'City',
    vatId: 'VAT ID',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    billingEmail: 'Billing Email',
    changeMyPassword: 'Change My Password',
    weWillSendPassChangeLink: 'We will send you an email with a link to change your password.',
    editDetails: 'Edit Details',
    editCountry: 'edit the country',
    payments: 'Payments',
    invoices: 'Invoices',
    invoice: 'Invoice',
    nextBillingDate: 'Next Billing Date',
    editPaymentMethod: 'Edit Payment Method',
    amount: 'Amount',
    download: 'Download',
    yourAddOns: 'Your Add-Ons',
    usage: 'Usage',
    changeAudienceTokens: 'Upgrade To Add Tokens',
    tokens: 'tokens',
    changeSeatPackage: 'Change Seat Package',
    seatsLeft: n => `${n} Seats left`,
    used: 'used',
    tokensLeft: n => `${n} tokens left`,
    seatsFromYourPlan: n => `${n} Seats From Your Plan`,
    audienceTokensFromProPackage: tokens => `${tokens} tokens from Pro Package`,
    audienceTokensFromEnterprisePackage: tokens => `${tokens} tokens from Enterprise Package`,
    perMonth: 'per month',
    perYear: 'per year',
    perSeat: 'per seat',
    upgradeToProToAddMoreSeats: 'Upgrade to Pro to add more seats',
    yourSeatsWillBeActivatedImmediately:
      'Your plan will be activated immediately and the prorated amount for this term will be charged immediately.',
    eurPerMonth: 'EUR/month',
    eur: 'EUR',
    euro: 'EURO',
    currentPlan: 'Current Plan',
    notAvailableBillingDetails: 'Sorry, billing details are not currently available',
    notAvailablePayments: 'Sorry, payments are not currently available',
    billingEmailTip: 'If not specified, we will send invoices to your account email',
    notInHereYet: 'Not in here yet',
    noProblem: 'No Problem',
    toAddUserToInfludata: 'to add creator to influData.',
    addInfluencerToInfludata: 'Add creator to influData',
    weWillAddProfileToInfludata: platform => `We will add the ${platform} profile.`,
    weCantAddInfluencerToInfludataIfAccount: 'We can’t add a creator, if the account',
    hasLessFollowers: followersNumber => `has less than ${followersNumber} followers`,
    hasLowPosts: 'has a low amount of posts',
    isPrivateOrRestricted: 'is private',
    processCanTakeFewHours: 'This process can take up to 10 minutes.',
    shouldNotifyAboutProfileAdd: 'Should we notify you via mail when the profile is available?',
    notifyMe: 'Notify me',
    addedToInfludataStatus: {
      [ADD_USER_RESPONSE_STATUS_MAP.created]:
        'Profile will be added to influData within a few minutes',
      [ADD_USER_RESPONSE_STATUS_MAP.alreadyExists]:
        'Profile has already been added to influData or is in adding progress',
      [ADD_USER_RESPONSE_STATUS_MAP.invalid]:
        'Profile name is invalid. Please change name and try again',
    },
    notAllowedToDropToCollection: "You can't add profile to this collection",
    notAllowedToDropToCollectionReasonuserExists: 'This profile is already in collection',
    notAllowedToDropToCollectionReasonLimit:
      'You have reached the limit of profiles in this collection',
    contactSales: 'Contact Sales',
    unlockAnalysis: 'Unlock Report',
    analysisIsAlreadyUnlocked: 'The report is unlocked',
    avaliableInstantly: 'available instantly',
    available24h: 'avaliable in 24h',
    avaliableWithin24h: 'avaliable within 24h',
    unlockAnalysisTip: 'Once unlocked, the report will be updated and available forever.',
    unlockReportSpendTokenTip: '1 token will be subtracted from your monthly tokens',
    reportImpossibleTitle: 'Audience Report & CPMs are not avaliable for this creator.',
    reportImpossibleContentFirst:
      'The reason is that the engaging audience is too small to provide reliable data.',
    reportImpossibleContentLast: 'We will add this information, once the audience will increase.',
    unableToProvideAboutAudience:
      'We are unable to provide any information about the audience of this creator.',
    influencersRestrictsAccessToLikersFollowers:
      'This happens when the creator restricts access to likers and followers, actively requested to remove the audience report, our data basis is not sufficient or we removed the report for other reasons.',
    unlockReportSpendNoTokenTip: 'No token will be subtracted from your monthly tokens',
    noAudienceTokensLeft: 'You have no available tokens left',
    toUseMoreTokens: 'to use more tokens',
    manageYourProPlan: 'Manage Your Professional Plan',
    chooseHowManyTokensShouldBe: 'Choose how many Audience Reports should be included',
    canAjustAnyTime: 'You can adjust the plan at any time',
    billedMonthly: 'Billed monthly',
    billedAnnually: 'Billed annually',
    compareProfiles: 'Compare Profiles',
    useComapreProfiles: 'use Compare Profiles',
    noProfilesToCompare: 'No profiles to compare yet',
    noProfilesToCompareTip:
      'Please add profiles for comparison from search results, collections or directly in single profiles',
    billed: 'billed',
    audienceTokensTrialLimit: 'You reached the token limit available in the trial plan.',
    toActivateTrialForAudienceTokens:
      'to activate your subscription to use all tokens available in your plan.',
    activateTrialForAudienceTokens:
      'activate your subscription to use all tokens available in your plan.',
    comparedProfiles: 'Compared Profiles',
    comparedParams: 'Compared Parameters',
    category: 'Category',
    website: 'Website',
    monthlyGrowthFollowers: 'Last month followers growth',
    locked: 'locked',
    unlocked: 'unlocked',
    unlockedReports: 'Unlocked Reports',
    postsFrequencyDays: 'Post frequency(days)',
    needMoreTokens: 'Need more Audience Reports',
    comparePlansFeatures: 'Compare plans and see all features',
    changePlanTip: 'to make any changes to your plans',
    audienceTokensMonthly: 'tokens per month',
    typeYourKeyword: 'Type your keyword',
    addNewValueToSelect: value => `Click or press Tab to add "${value}"`,
    cantAddManyPlatformsToCompare: "You can't add users from more than one platform",
    useAnotherCollection: 'Please use another collection',
    toClearAllComparedProfiles: 'to clear all profiles from compared profiles list',
    or: 'or',
    comparedProfilesLimitReach: 'The compared profiles list limit is reached',
    compareTiktokCommingSoon: 'Compare TikTok profiles is coming soon...',
    compareYouTubeComingSoon: 'Compare YouTube profiles is coming soon...',
    audienceSearchForTikTokCommingSoon: 'Audience Search for TikTok is coming soon...',
    addToFavorites: 'Add to favorites',
    getAddToFavorites: isAdded => (isAdded ? 'Added to favorites' : 'Add to favorites'),
    removeFromFavorites: 'Remove from favorites',
    noPublicEmail: 'Profile has no public e-mail address',
    addToCompareProfiles: 'Add to compare list',
    removeFromCompareProfiles: 'Remove from compare list',
    clearAll: 'Clear All',
    clearList: 'Clear List',
    basicInfo: 'Basic Info',
    scores: 'Scores',
    contactToChangeCountry: 'Please contact us to change the country.',
    monthGrowthFollowers: 'Growth by Followers 30d',
    averageLikes: 'Average Likes',
    averageComments: 'Average Comments',
    quality: 'Quality',
    audience: 'Audience',
    averageAgeIs: age => `Ø ${age} years`,
    noCompareForPrivateProfile:
      "This profile has become private and can't be added to compared list",
    searchByAudience: 'Search by Audience',
    useAudienceSearch: 'use Audience Search',
    useMinimumGrowthRate: 'use Minimum Growth Rate',
    useEngagementGrowthRate: 'use Minimum Engagement Rate',
    pro: 'Pro',
    commingSoon: 'Comming soon...',
    clearInput: 'Clear input',
    minAudienceFromCountry: 'Min Audience From Country',
    minAudienceUsingLanguage: 'Min Audience Using Language',
    pleaseSelectCountry: 'Please select country',
    pleaseSelectLanguage: 'Please select language',
    audienceByRealPeople: 'Audience By Real People',
    real: 'real',
    audienceByAvgAge: 'Audience By Age (AVG.)',
    audienceByGender: 'Audience By Gender',
    audienceByLanguage: 'Audience by Using Language',
    subUserSettingsTip: 'You are not an admin in this organization.\nOnly admins are able to make changes to the subscription, team members and billing. Please reach out to your organization\'s admin account.',
    companyMembers: 'Company Members',
    companyMembersAbleTo: 'Company Members are able to',
    companyMembersNotAbleTo: 'Company Members are NOT able to',
    companyMembersRules: {
      able: [
        'use all the features of your account',
        'use the tokens of your account',
        'add/delete creators to/from public collections',
        'add public comments and give ratings',
        'create their own private collections',
      ],
      notAble: [
        'see/change payments or edit plans',
        'add other company members',
      ],
    },
    ableToAddMoreMembers: num =>
      `You are able to add up to ${num} more company members. Please add the email and they will be notified`,
    unableToAddMoreMembers: 'You have reached the limit of company members',
    enterCompanyMemberEmail: 'Member Email',
    publicCollection: 'Public collection',
    privateCollection: 'Private collection',
    makePublic: 'Make Public',
    makePrivate: 'Make Private',
    makeCollectionPublic: 'Make collection public',
    makeCollectionPrivate: 'Make collection private',
    areYouSure: 'Are you sure',
    collectionWillBecomePrivateMainUser:
      'Collection will become private and company members will not be able to access it.',
    collectionWillBecomePublicMainUser: 'Collection will become public and shared company-wide.',
    collectionWillBecomePublicSubUser:
      'Your collection will be available company-wide. This action can’t be reversed.',
    resetsOn: 'Resets on',
    tokensWillBeAddedOnReports: 'Reports reset on',
    tokensWillBeAddedOnAnalytics: 'Limits reset on',
    trialEndsOn: 'Trial ends on',
    tokensResetOn: 'Tokens reset on',
    moreTokensNow: count => `${count} more tokens now for ${count === 50 ? 299 : 399} eur`,
    paymentForTokens:
      'Your payment method will be changed immediately and your extra tokens will be available instantly.',
    wantToContinue: 'Do you want to continue?',
    getValidTokens: tokens => `+${tokens} tokens valid until `,
    valid30Days: 'Valid for 30 days - one-time charge - not recurring - use as often as you want',
    getMoreTokens: 'Out of tokens? Get more!',
    thanksForSignUp: 'Thank you for signing up!',
    weWillGetInTouch:
      'We will get in touch with you shortly. Please make sure that you receive our calls and emails and once we discussed your needs, we will activate your individual trial.',
    youAreWelcomeToSchedule:
      'You are welcome to schedule a demo with us, so we can get in touch as soon as possible.',
    scheduleAppDemo: 'Schedule Demo',
    invitationToFreeDemo: 'Invitation to a free demo.',
    invitationText:
      'Hi, nice to have you on board! Your trial is now active for 7 days and you can test all features of influData. ',
    salesManagerText: sale =>
      `I'm ${sale} and I would be happy to show you the full scope and possibilities of our platform. Interested? Just schedule a quick demo with me. Everyone in your team is welcome as well :)`,
    scheduleLater: 'schedule later',
    latestInstagramPictures: 'Latest Instagram Pictures',
    latestTikTokPosts: 'Latest TikTok Posts',
    latestYoutuBeVideos: 'Latest YouTube Videos',
    latestYouTubeShorts: 'Latest YouTube Shorts',
    privateNotes: 'Private notes',
    additionalInfo: 'Additional information',
    addNewField: 'Add new field',
    youCanAddPrivateNotes: 'Here you can add notes to yourself or for your team members',
    fieldName: 'Field Name',
    createField: 'Create field',
    saveField: 'Save field',
    editField: 'Edit Field',
    duplicateField: 'Duplicate Field',
    deleteField: 'Delete Field',
    detailedQualityRating: 'Detailed influData quality rating',
    avg: 'avg',
    fieldType: 'Field Type',
    typeInText: 'Type in Text',
    typeInNumber: 'Type in a number',
    pasteLink: 'Paste a link',
    addAnOption: 'Add an option',
    comparisonToSimilarInfluencers: 'comparison to similar creators',
    whatDoesThisScoreMean: 'What does this score mean',
    infludataRating: 'influData Rating',
    activity: 'Activity',
    everySecondDay: 'Every second day',
    audienceEngagement: 'Audience Engagement',
    audienceEngagementOverall: 'Audience Engagement Overall',
    audienceEngagementVideos: 'Audience Engagement Videos',
    audienceEngagementShorts: 'Audience Engagement Shorts',
    ofViewers: 'of viewers',
    lastNDays: n => `last ${n} days`,
    orLess: 'or less',
    orMore: 'or more',
    authenticity: 'Authenticity',
    moreThan: 'More than',
    lessThan: 'Less than',
    brandValue: 'Brand value',
    sponsoredPostsAndStories: 'posts, stories & reels with brand mentions',
    sponsoredPostsDesc: 'Brands that were tagged in stories, posts and reels in the last 180 days:',
    none: 'none',
    perWeek: 'per week',
    ofAllPostsAreSponsored: 'of all posts are sponsored',
    ofAllStoriesAreSponsored: 'of all stories are sponsored',
    ofAllReelsAreSponsored: 'of all reels are sponsored',
    smartMediaValue: 'Smart media value',
    perPost: 'per post',
    perStory: 'per story',
    perReel: 'per reel',
    perVideo: 'per video',
    perShort: 'per short',
    per1000Views: 'per 1000 views',
    inCurrency: (c = 'USD') => `in ${c}`,
    found: 'found',
    notFound: 'not found',
    possible: 'possible',
    appearsIn: 'appears in',
    reels: 'Reels',
    yourTargetAudience: 'Your target audience',
    editValues: 'Edit values',
    followersWithinTargetGroup: 'Followers within your target group',
    countries: 'Countries',
    age: 'Age',
    fromNToN: (from, to) => `From ${from} to ${to}`,
    audienceCountries: "audience's countries",
    audienceGender: "audience's gender",
    audienceAge: "audience's age",
    apply: 'Apply',
    profileData: 'Profile data',
    playsPerReel: 'Plays per reel',
    engagementPosts: 'Engagement posts',
    engagementReels: 'Engagement reels',
    engagementSponsored: 'ER (Sponsored Content)',
    engagementCombined: 'Engagement (combined)',
    month: 'Month',
    months: 'Months',
    year: 'Year',
    years: 'Years',
    thisIsProFeature: 'This is a Pro feature',
    checkTheProPackage: 'Check the pro package',
    pleaseSubscribeToPro: 'Please subscribe to our Pro Package to view the Audience Analysis',
    influencerAlreadyInCollection: 'Creator is already in this collection',
    youReachedTheLimitOfAdditionalFields: 'You reached the limit of additional fields',
    maxAvailableAdditionalFields: 'Please get in contact with us to increase the fields available',
    youReachedTheLimitOfAdditionalFieldsInYourPlan:
      'You reached the limit of additional fields in your plan',
    getSpaceForAdditionalFields: 'Upgrade to Pro and get space for 20 additional fields',
    upgradeToPro: 'Upgrade to pro',
    toSeePrivateNotes: 'to see Private Notes',
    whatAreThePrivateNotes: 'What are the Private Notes and why are they helpful?',
    addNote: 'Add note',
    pinNote: 'Pin note',
    unpinNote: 'Unpin note',
    editNote: 'Edit note',
    deleteNote: 'Delete note',
    save: 'Save',
    from: 'From',
    noDataForQualityRating: 'No data available for this score.',
    noDataForSmartMediaValue: "Sorry, we don't have enough data to show Smart Media Value.",
    nPostsPerWeek: n => `${n} posts per week`,
    nStoriesPerDay: n => `${n} stories per day`,
    nReelsPerWeek: n => `${n} reels per week`,
    nOfFollowers: n => `${n} of Followers`,
    nOfFollowersSusp: n => `${n} of Followers suspicious`,
    nComments10000Followers: n => `${n} comments per 10,000 Followers`,
    nComments100000Followers: n => `${n} comments per 100,000 Followers`,
    nLast30Days: n => `${n} (Last 30 Days)`,
    nPlaysOnAverage: n => `${n} Plays on Average`,
    nViewsOnAverage: n => `${n} Views on Average`,
    nSecondsOnAverage: n => `${n} Second on Average`,
    nOfViewers: n => `${n} of Viewers`,
    nHoursPerReel: n => `${n} Hours per Reel`,
    nEverySecondDay: n => `${n ? n : ''} Every Second Day`,
    nCommentsPositive: n => `${n} of comments are positive`,
    nCommentsNegative: n => `${n} of comments are negative`,
    nVideosPerWeek: n => `${n} videos per week`,
    nShortPerWeek: n => `${n} shorts per week`,
    nVideoViewsGrowth: n => `${n} View Count Growth`,
    nFollowerGrowth: n => `${n} Follower Growth`,
    postEngagement: 'Post Engagement',
    toSeeDetailedInfludataRating: 'to see Detailed influData rating',
    whatIsDetailedInfludataRating: 'What is the Detailed influData Rating and why is it helpful?',
    average: 'average',
    toSeeStatistic: 'to see Statistic',
    whatIsInfludataStatistic: 'What is the influData Statistic and why is it helpful?',
    confirmAdditionalFieldDeletion:
      'Are you sure you want to delete this field? If you delete an Additional Field it will disappear from all creators and all stores values are lost. Do you want to continue?',
    generatedOn: 'Generated on',
    thisPdfMightBeOutdated: 'This PDF might be outdated',
    ifYouWantUpToDateInformation: 'If you want up to date information on this user',
    unlockReportToShowScore: 'Please unlock the audience report to show this score',
    valuesBasedOnEstimations: 'based on estimations — real values may be differ',
    valuesBasedOnSubscribers: 'based on the subscribers',
    inK: 'in k',
    pageNotFound: 'Page not found',
    week: 'week',
    day: 'day',
    days: 'days',
    list: 'List',
    audienceCollection: 'Audience',
    overlapCollection: 'Overlap',
    totalInfluencers: 'Creators included',
    onlyNFromNInfluencersAreUnlocked: (n1, n2) => `Only ${n1} of ${n2} reports are unlocked.`,
    includingNFromNOfInfluencers: (n1, n2) => `Report includes ${n1} of ${n2} creators.`,
    unlockAll: 'Unlock all',
    toShowExactMetrics: 'to show exact metrics.',
    followersInTotal: 'Total Followers',
    expectedReach: 'Total Reach',
    allInfluencersAreUnlocked: 'All creator reports are unlocked.',
    overlapUpTo50Influencers:
      'We can only show the overlap of up to 50 creators, please create a new collection to see the overlap of more creator',
    overallComparison: 'Overall Comparison',
    individualComparison: 'Individual Comparison',
    graphDescrOveral: `This graph shows the
    homogeneity of all followers within this collection.`,
    graphDescrIndividual: `This graph shows which part of the followers are equal when comparing two creators.`,
    individualComparisonHoverDescr:
      'Hover the intersect between two infuencers to see details on the right',
    collectionAudienceOverlapTitle: 'Collection audience overlap (%)',
    overalChartCalculatedTitle: 'How is this calculated?',
    vennDiagramBottomTitle: 'of the followers overlap',
    overallCompareModalTitle: 'How is this calculated?',
    overallCompareModalText:
      'The percentage shows the number of followers that appear on two or more creators within your collection.',
    uniqueFollowers: 'Unique Followers',
    uniqueReach: 'Unique Reach',
    missingReports: 'Missing reports will be available shortly',
    modalUnlockReportsTitle: `Unlock audience reports`,
    modalUnlockReportsBody: reports =>
      `These reports will be subtracted from your available reports. Number of reports used: ${reports}`,
    updateProfileManually: 'Update profile manually',
    added: 'Added',
    updateProfileManuallyWasClicked: 'Please refresh in a few minutes',
    featureIsNotAvailable: 'This feature is not available in your plan.',
    blockUserTipsFirstPart: 'Block from future searches. You can always unblock users.',
    blockUserTipsLastPart: 'Show Blacklist',
    removeFromBlackList: 'Remove from blacklist',
    blockCollectionDecription: `These are the blacklisted creators that won't appear in your search. You can whitelist them at anytime. Please keep in mind that these creators are only blacklisted for you and not company-wide.`,
    accountType: 'Account Type',
    regularShortsAndCreators: 'Shorts & Regular Creators',
    shortCreatorsOnly: 'Shorts Creators Only',
    videoViews: 'Video views',
    shortsViews: 'Shorts views',
    subscribers: 'Subscribers',
    videos: 'videos',
    viewsMedian: 'Views - Median',
    statisticsShorts: 'STATISTICS - SHORTS',
    statisticsVideos: 'STATISTICS - VIDEOS',
    averageViews: 'Average Views',
    negative: 'negative',
    positive: 'positive',
    neutral: 'neutral',
    wordCloudComments: 'Word Cloud - Comments',
    disabledFunctionInYourPlanTip:
      'This function is not included in your plan, please contact our customer support.',
    socialPlatformDisabledTip:
      'Platform is not included in your plan, please contact our customer support.',
    profileToolDisabledTip:
      'This function is not included in your plan. Please contact our customer support.',
    collectionDisabledTip: 'Collections are not included in your plan, please contact our support.',
    addUserToBlackListTip: 'add user to blacklist',
    reportIsUpToDate: 'Report is up to date',
    reportIsOutDated: 'Report is outdated!',
    refreshingReport:
      'We are refreshing this report right now and the data might change within the next 15 minutes.',
    noDataAvailable: 'No data available',
    logInForUseSearchUsers:
      'Sorry, you need to be logged in and have an active subscription to use our search',
    isNoAuthViewProfilePageOrWithoutPlan:
      'Sorry, you need to be logged in and have an active subscription to view creator profiles',
    wordCloudTip:
      'This Word Cloud shows the most frequently mentioned words in the comments on the videos/shorts. The larger the word is shown here, the more often it appeared in the comments.',
    whatDoesThisWordCloudMean: 'What does this word cloud mean',
    whatDoesThisAudienceTypeMean: 'What does this audience type mean',
    audienceTypeTip: {
      [USER_INDEXES.instagram]: `This breakdown shows what proportion of a Creator's followers are Real People, Suspicious/Inactive Accounts, Mass Followers, or Creators. Real People/Followers show active and healthy behavior, as expected from normal accounts. Suspicious/Inactive Accounts/Followers have accounts that will not be of real people because, for example, they do not have a bio or profile picture or are inactive on the platform. Mass Followers follow more than 1,500 people and are therefore not classic followers. Creators have accounts that themselves have more than 2,000 followers and are therefore also not regular followers.`,
      [USER_INDEXES.youtube]:
        'This breakdown shows what proportion of comments on the videos/shorts tended to be positive, neutral or negative.',
    },
    reach: 'Reach',
    valueInEur: 'Value in EUR',
    uploaded: 'Uploaded',
    estimatedReach: 'Estimated Reach',
    contentSearchNotAvailable: 'Our content search is not available for TikTok right now.',
    locationRadius: 'Location Radius',
    tipSelectLocation: 'Please select location',
    selectLocation: 'Select Location',
    noLocationFound: 'No location found',
    showMap: 'Show Map',
    hideMap: 'Hide Map',
    pleaseSelectCategories: 'Please select a category',
    sponsoredPostsAndStoriesPdf: 'SPONSORED POSTS / STORIES / REELS',
    sponsoredPostsAndStoriesDescPdf:
      'These are brands that were tagged in stories and posts in the last 30 days:',
    timelineIsNotAvailable: 'Timeline is not available',
    exportProfileAsAPdf: 'Export profile as a pdf',
    pleaseChoseYourOption: 'Please choose your options',
    modalPdfOptions: {
      ['hideYourCustomFields']: 'Hide your custom fields',
      ['showQualityScores']: 'Show Quality Scores',
      ['showAudienceReport']: 'Show Audience Report',
      ['showFirstPage']: 'Show First Page',
      ['showFollowerTrendGraph']: 'Show follower trend graph',
      ['includeInfludataBranding']: 'Include influData branding',
      ['showBrandMentions']: 'Show Brand Mentions',
      ['showSmartMediaValue']: 'Show Smart Media Value',
    },
    exportAsPPX: 'Export as PPX',
    tableHeadLabels: {
      username: 'username',
      displayName: 'display name',
      profileScore: 'profile score',
      country: 'country',
      city: 'city',
      followers: 'followers',
      language: 'language',
      engagementMean: 'engagement mean',
      monthlyGrowthFollowers: 'monthly growth followers',
      monthlyGrowthPercentage: 'monthly growth percentage',
      impressions: 'impressions',
      dateAdded: 'date added',
      stars: 'stars',
      _index: 'platform',
      comment: 'comment',
    },
    editFields: 'edit fields',
    viral: 'Viral Content',
    uploadedContent: 'Most recent',
    previewVideo: 'Preview Video',
    expectedAudiencePerPosts: 'Expected audience per post',
    downloads: 'Downloads',
    infoForCollaborations: 'Info for collaborations',
    contactInformation: 'Contact information',
    management: 'Management',
    contactForm: 'Contact Form',
    verifiedInformation: 'Verified Information',
    shorts: 'shorts',
    averageViewsGrowthVideos: 'Growth average views (videos)',
    averageViewsGrowthShorts: 'Growth average views (shorts)',
    growthByAverageViews30dVideos: 'Growth by average views 30D (videos)',
    growthByAverageViews30dShorts: 'Growth by average views 30D (shorts)',
    loggedMultiplePartFirst: 'You are logged in multiple times with your accounts.',
    loggedMultiplePartLast: 'Please log out of all current sessions and try again.',
    addAllToComparison: 'Add All To Comparison',
    amountOfInfluencers: 'AMOUNT OF INFLUENCERS YOU ARE CURRENTLY WORKING WITH',
    yearsInInfluencerMarketing: 'How many years is your brand active in influencer marketing?',
    egAmountOfInfluencers: 'e.g. in running campaigns, in collaboration and so on',
    googleAuthErrorText: 'An error occurred, try authorization/registration via email.',
    removeContentPieces: `If you remove the content piece from the reporting, it won't be included in dashboards
    and analytics.`,
    /* new campaigns */
    filedLabels: {
      cpm_organic_all: 'CPM (Organic) All',
      cpm_organic_ig: 'CPM (Organic) Instagram',
      cpm_organic_tt: 'CPM (Organic) TikTok',
      cpm_organic_yt: 'CPM (Organic) YouTube',
      cpe_organic_all: 'CPE (Organic) All',
      cpe_organic_ig: 'CPE (Organic) Instagram',
      cpe_organic_tt: 'CPE (Organic) TikTok',
      cpe_organic_yt: 'CPE (Organic) YouTube',
      custom_tracking_counts: 'Custom Trackings',
      goal_reached_widgets: 'Goals Reached',
      reached_audience_country: 'By Country',
      reached_audience_language: 'By Language',
      reached_audience_gender: 'By Gender',
      reached_audience_age: 'Reached Audience By Age',
      reached_audience_city: 'By City',
      creators_in_campaigns_count: 'Active Creators',
      published_content_count: 'Published Content Pieces',
      reached_users_sum: 'Reached Audience',
      received_likes_count: 'Received Likes',
      received_comments_count: 'Received Comments',
      most_liked_comments: 'Most Liked Comments',
      latest_comments_with_brand: 'Latest Comments with Brand Mentions',
      latest_comment_summaries_with_brand: 'Latest Summaries of Comments',
      published_content_elements: 'Latest Published Content & Reach (Carousel)',
      published_content_time_stacked_bar: 'Published by Time and Format',
      most_successful_creators_reach_stacked_bar: 'Most Successful Creators by Reach (Top 15)',
      most_used_keywords_bar: 'Most used keywords, Links and Mentions',
      uploaded_content_news: 'Upload Content',
      published_content_reach_linechart: 'Published Content & Reach',
      widget_brand_account_growth: 'Follower Change',
      widget_earned_media_value: 'Earned Media Value',
      emv_all: 'EMV ALL',
      emv_ig: 'EMV Instagram',
      emv_tt: 'EMV TikTok',
      emv_yt: 'EMV YouTube',
      content_by_reach_bucket_graph: 'Published Content Pieces by Reach',
      geolocation_map: 'Tagged Place Of Your Campaign',
      semantic_graph_brandfocus: 'Comment Focus Distribution',
      semantic_graph_controversial: 'Controversy Rate',
      semantic_graph_positivity: 'Positivity Rate',
      main_metrics_table: 'Metrics Table'
    },
    ofTotalSelectedCreators: '(of total selected Creators)',
    pageNotAllowed: 'Page Not Allowed',
    goHome: 'Go Home',
    addANewCreator: 'Add a New Creator',
    typeUsername: 'Type Username',
    noCreatorsFound: 'No Creators Found',
    somethingWentWrong: 'Something Went Wrong',
    keywordsMentionInputHelperTextTop: 'will be automatically added.',
    keywordsMentionInputHelperTextMiddle: 'Links will also works as part of links',
    keywordsMentionInputHelperTextBottom: 'All terms are case insensitive.',
    editA: 'Edit a',
    addNew: 'Add New',
    searchTerm: 'Search Term',
    selectType: 'Select Type',
    inputSearchTerm: 'Input Search Term',
    mention: '@ Mention',
    freeText: 'Free Text',
    keywordsMentionsManager: 'Keywords & Mentions Manager',
    keywordsMentionsManagerSubTitle:
      'Set up hashtags, mentions, likes, or custom text to track content from creators in your campaign.\nYou can modify these elements at any time, and the changes will retroactively apply from the start of your campaign.\n Please note, any adjustments will also immediately impact your current dashboards!',
    addNewTerm: 'Add New Term',
    type: 'Type',
    lastActivity: 'last Activity',
    rowsPerPage: 'Rows per page:',
    dense: 'Dense',
    notAllCreatorsAssignedPricing: c =>
      `Not all creators have been assigned a pricing yet.\n Please be aware that this could potentially skew your actual ${c} calculations`,
    cpmInfo:
      "CPM, or Cost Per Mille, represents the cost for 1,000 impressions of your content. It's a common efficiency metric in digital advertising.\n\nIn our platform, we calculate the CPM based on this formula:\n\nCPM = (Creator's price / Reach) * 1000\n\nThis means we take the price set for each creator and divide it by the reach achieved, then multiply by 1000.\n\nNote that if you set a flat price for a campaign, the CPM decreases as more content gets posted. This is because the overall cost is distributed over a greater number of impressions, reducing the cost per thousand.",
    cpeInfo:
      "CPE, or Cost Per Engagement, represents the cost for one engagement (comment, like, share) of the content. It's a common efficiency metric in social media marketing.\n\nIn our platform, we calculate the CPE based on this formula:\n\nCPE = (Creator's price / Total Engagement Sum\n\nThis means we take the price set for each creator and divide it by the sum of the likes, comments and shares achieved.\n\nNote that if you set a flat price for a campaign, the CPE decreases as more content gets posted. This is because the overall cost is distributed over a greater number of interactions, reducing the cost per engagement.",
    emvInfo:
      "EMV, or Earned Media Value, estimates the worth of non-paid exposure and interactions on social media.\n\nOn our platform, we calculate EMV using this formula:\n\nEMV = (Reach (estimated or real) / 1000) * Monetary Value Per 1000 Views\n\nThis means we multiply the reach by a set monetary value for 1000 reached users. The assigned value can vary based on the target group (audience), engagement rate, creator industry, creator growth, and more.\n\nWhile EMV isn't a precise reflection of actual revenue, it serves as a useful metric to compare the effectiveness of different marketing strategies.",
    delete: 'Delete',
    edit: 'Edit',
    missingCreators: 'Missing Creators',
    createBtnText: 'Create',
    aCustomField: 'a Custom Field',
    newCustomFields: 'New Custom Fields',
    customFields: 'Custom Fields',
    customFieldPageTitle: `You can set up different custom fields that you want to track for your campaigns.\n This can be used for purchases, clicks, web site visits and so on.\n You can either track the events manually or set up a webhook for automated tracking.`,
    customFieldName: 'Custom Field Name',
    metric: 'Metric',
    metricName: 'Metric Name',
    appliesTo: 'Applies To',
    trackingStatus: 'Tracking Status',
    lastTracking: 'Last Tracking',
    selectDataFormat: 'Select Data Format',
    customFieldTitle: 'Custom Field Title',
    typeMetricName: 'Type Metric Name',
    customFieldTitleHelperText:
      'This will be the name of the field. E.g. “Story Clicks to Website”, “Purchase amount“ etc. We recommend to be as precise as possible, to avoid confusion later on',
    typeMetricNameHelperText:
      'This will be the name of the metric. E.g. “Visits”, “EUR“, “Clicks“ etc.',
    customFieldSpecificTitle:
      'Does your field apply to content pieces directly (clicks, views, etc.), creators individually (sales per creator, etc.) or only on a campaign level (traffic though campaign, etc.).',
    customFieldSpecificSubTitle:
      'Please choose the most granular type that is possible for you to track individually. Higher levels can always be calculated and displayed with underlying data. E.g. if you have the sales created by each content piece, metrics for creators and campaigns can be calculated, but not the other way around.',
    customFieldMetricAppliesTitle: 'Please select for what content this metric applies to',
    youCanChooseMoreThanOneOption: 'You can choose more than one option.',
    addCreatorsLimitMessage: 'You have reached your limit of creators.\n Please contact support.',
    addValueManuallyHoverText: `Adding values manually here is only possible for campaigns or creator-wide custom fields.\n To add values for content specific fields, please go to "Content Overview" and select the content piece for you would like to add a value for.`,
    value: 'Value',
    addValueManuallyFormHelperText:
      'You can add values manually for your custom fields, so we can include these values in your dashboard.\n You can also add negative values to correct for false values.',
    chooseCreators: 'Choose Creators',
    forCustomField: 'for custom field',
    addValueManually: 'Add Value Manually',
    editCustomField: 'Edit Custom Field',
    deleteCustomField: 'Delete Custom Field',
    addWebhookTracking: 'Add Webhook Tracking',
    reachedCreatorLimit: `You reached your creator limit.\n Please purchase more seats or remove creators.`,
    airTableIsNotCreated: `Your Airtable is not yet created. This usually takes up to 24 hours after creating your campaign. Please contact us, if you don't see any data after one day.`,
    airTableCustomFields: `Airtable View`,
    airTableCustomFieldPageTitle: `You can view and export all your data also in Airtable. You are able to sort, filter, search and customize your view.`,
    airTableLimitStringLessThousand: n =>
      `The history of your content pieces is visible for ${n} days. Please contact our support to view unlimited content.`,
    airTableLimitStringMoreThousand: 'Your content view is unlimited for the full campaign.',
    airTableUseAllFeatures:
      'Contact us to get edit access to your Airtable base and create custom interfaces, conditional workflows, extentions and more. Please visit airtable.com to get the full list of features.',
    airTableAccessLink: `Click here to get to your Airtable Base (access required)`,
    geolocationMapTitle: 'Tagged Place of Your Campaign',
    geolocationMapSubheader: 'Only for Instagram',
    geolocationMapClickToOpenInNewTab: 'Click to open in new tab',
    notificationsIntervalOptions: {
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
      quarterly: 'Quarterly',
    },
    selectDashboard: 'Select Dashboard',
    selectInterval: 'Select Interval',
    emailRecipients: 'Email Recipients (add up to 10 mails)',
    notificationPageTitle: 'Notification Manager',
    notificationsPageDescription: `Configure email notifications to select team members or external users and set triggers.\nFor advanced alerts and automations, like content milestones or integration with tools like Slack, use the 'conditional automations' in Airtable.com, available in the left menu.`,
    clickEnterWarningNotifications:
      'Please click enter after you typed your email adress in the field.',
    addNewNotifications: 'Add New Notifications',
    notification: 'Notification',
    notificationTableHead: {
      dashboard: 'Dashboard',
      interval: 'Interval',
      recipients: 'Recipients',
    },
    notificationPageRowInterval: n =>
      n === 'daily'
        ? 'Daily'
        : n === 'weekly'
        ? 'Weekly'
        : n === 'monthly'
        ? 'Monthly'
        : n === 'quarterly'
        ? 'Quarterly'
        : 'Yearly',
    sentimentCharts: {
      brandFocus: {
        label: 'Comment Focus Distribution (by Creator)',
        legend: {
          brandFocusProduct: 'Brand/Product Focus',
          creatorFocus: 'Creator Focus',
          lowQuality: 'Low Quality',
          other: 'Other',
        },
        helperText:
          'This metric displays the average distribution of comment focus for each analyzed content piece, categorizing them as related to the brand or product, the creator, or of low quality.\n\nIt provides insight into the proportion of commenters actually referencing the advertised product. This can indicate whether the branded content is relevant enough. Conversely, a high brand focus might suggest content that is overly product-centric.\n\nThe number in brackets indicates the total content pieces used for this calculation.',
      },
      positivityRate: {
        label: 'Positivity Rate (by Creator)',
        legend: {
          positiveSentiment: 'Positive Sentiment',
          negativeSentiment: 'Negative Sentiment',
        },
        helperText:
          "The positivity rate represents the average percentage of comments that exhibit a positive sentiment or favorable reactions towards the content pieces.\n\nNote that this doesn't necessarily refer to the brand or advertised product.\n\nThe number in parentheses indicates the total content pieces used for this calculation.",
      },
      controversialRate: {
        label: 'Controversy Rate (by Creator)',
        legend: {
          controversial: 'Controversial',
          nonControversial: 'Non-Controversial',
        },
        helperText:
          'This metric represents the average percentage of comments categorized as "controversial" by creators.\nA "controversial" comment typically criticizes or questions the content.\nThe number in parentheses indicates the total count of content pieces added to the calculation.',
      },
    },
    campaignCreatorOverview: {
      tableHead: {
        name: 'Name',
        platform: 'Platform',
        reachInViews: 'Reach in Views',
        likes: 'Likes',
        matchMethod: 'Match Method',
        lastActivity: 'Last Activity',
        added: 'Added',
        status: 'Status',
        collaborationPrice: `Total Collaboration Price`,
        priceForPost: `Price per Post (Video)`,
        priceForReel: `Price per Reel (YT Short)`,
        priceForStory: `Price per Story`,
        additionalCosts: `Total Additional Costs`
      },
      statusOptions: {
        all: 'all',
        active: 'active',
        archived: 'archived',
        onboarding: 'onboarding',
      },
      popoverMenu: {
        viewEdit: 'View / Edit Content',
        viewCreatorAnalytics: 'View Creator Analytics',
        editCreator: 'Edit Creator',
        unarchiveCreator: 'Unarchive Creator',
        archiveCreator: 'Archive Creator',
        addNonRecognizedContent: 'Add non-recognized content',
        addAsBrandAccount: 'Add as brand account',
        removeAsBrandAccount: 'Remove as brand account',
        addOnlyOneBrandAccount: `You can only add one brand account right now.\n Please remove the current account.`,
        activateCreator: 'Activate Creator',
        deleteCreator: 'Delete Creator'
      },
      matchModal: {
        title: 'Set Content Match Options for Creators',
        contentTitle: `Manually added content stays on the dashboard and won't be overwritten.\n\n"Phrases" is the default and matches keywords set in Keyword & Mentions Manager.\n"None" means only manually added content shows.\n"All" pulls every published content since the creator joined the campaign.\n\nNote: Changes take up to 24 hours to reflect on the dashboard.`,
        selectTitle: 'Select Match Method',
        selectOptionsTitles: {
          phrase: 'phrase',
          all: 'all',
          none: 'none',
        },
      },
      deleteModal: {
        attention: 'attention',
        bodyText: `This will remove all data for this creator from the campaign.`
      }
    },
    campaignContentOverview: {
      addToCampaign: 'Add to Campaign +',
      addedToCampaign: 'Added to Campaign',
      titleRecognizedContent: 'Published Content & Reach',
      titleNonRecognizedContent: '- Unmatched Content',
      subTitleRecognizedContent: 'content pieces found (incl. all stories from story groups)',
      subTitleTakesTimeUntilVisible: 'Please note that it may take up to 25 hours after publishing for the content to appear!',
      subTitleNonRecognizedContent: `All content pieces that are added will appear in your campaign, unless you remove the
      creator from the campaign.`,
      export: 'Export',
      exportTooltipText: `To export this view, please click "Airtable View" on the left menu, then click "Views" > "Gallery" and then "Gallery" dropdown to export CSVs and export PDFs of the content. You are able to filter the content beforehand.`,
      creators: 'Creators',
    },
    cpmSettings: {
      titleModal: `Adjust all earned media values based on your business case`,
      titleInsideModal: `influData's EMV calculation is based on audience location and creator scores. It might not fit every business. You can adjust our values if they seem off, affecting all future calculations, but only for your account only. Please adjust only if multiple EMVs seem inaccurate!`,
      confirmText: `*default values are EMVs for creators with German audience and good profile score`,
    },
    attentionDashboardModal: {
      titleModal: 'Attention',
      titleInsideModal: 'No content will be tracked for your campaign yet.',
      subtitleInsideModal: `Add keywords to your campaign or go to "Creator Overview" and change the "Match Method" to "All" for your creators to track content.`,
      btnText: 'Go to "Keywords & Mentions Manager"',
    },
    wrongCampaign: {
      errorText: 'Requested Campaign Id is not available',
      selectText: 'Please select an available Campaign',
    },
    campaignPipeline: {
      assignCreators: 'Assign creators',
      assigned: 'Assigned',
    },
    collectionOverlap: {
      btnTooltipTextDefault: 'Please select between two and ten profiles to create an overlap.',
      btnTooltipTextDontMeetCriteria:
        'You can add up to 10 profiles from only one platform. Please keep in mind that audience reports must be unlocked. ',
      btnText: number => `Create Overlap${number > 0 ? ` (${number})` : ''}`,
      modalOverlapContentText: `We will calculate the overlap for the selected profiles.\nThis will take up to 30 minutes and you will find the overlap in the left menu in "Your Overlaps"`,
      modalOverlapCofirmContentText: number =>
        `Not all audience reports of the selected creators are unlocked. We will use ${number} audience reports to unlock all users and create the overlap report.`,
      yourOverlaps: 'Your Overlaps',
      tableFields: {
        platform: 'Platform',
        creators: 'Creators',
        created: 'Created',
        overlap: 'Overlap',
      },
    },
    createCampaign: {
      createCampaignMainLabel: 'Create Your Next Campaign in less then 2 minutes',
      skip: 'Skip',
      continueWithoutBrandAccount: `Continue Without Brand Account`,
      add: 'Add',
      steps: {
        one: {
          label: `What is the name of your campaign?`,
          helperText: `This will be used to identify your campaign in the future.`,
          fieldLabel: `Campaign Name`,
        },
        two: {
          label: `When should your campaign start?`,
          optionLabels: {
            now: 'Now',
            later: 'Later, keep inactive and manually start',
            laterKeepInactive: 'Later, keep inactive and set starting date',
            subLabelLaterKeepInactive: `Your campaign will only start, if enough campaign seats are available at the time.`,
            inThePast: 'In the past (older content will be added)',
            subLabelInThePast: `Not recommended. Mostly likely not all content will be added, especially Instagram Stories might not be available anymore. Note: It might take up to a few hours until the content appears.`,
            selectedDate: 'Selected Date'
          },
        },
        three: {
          label: `What is the brand mention (@username) you would like to track?`,
          optionLabels: {
            true: `Yes, include all content from brand account to the campaign`,
            false: `Yes, but include only content with matching keywords/hashtags`,
            null: `No, I don't have a brand account`,
          },
          helperText: 'You can change this later any time.',
        },
        four: {
          label: creators => `Do you want to add the mention ${creators} also as a brand account to this campaign?`,
          optionLabels: {
            null: 'No, do not include this account in my tracking.'
          }
        },
        five: {
          label: `Select your currency`,
          helperText: `We need this for CPMs and Earned Media Values.`,
          fieldLabel: `Select Currency`,
        },
        six: {
          label: `Add some creators you want to track`,
          suggestionLabel: 'Here are some suggestions that used the brand mentions in the past.',
          helperText: `Please click ‘Creator Overview’ to add more than 10 creators or to edit the creators after finishing the setup.`,
        },
        seven: {
          label: `Add some hashtags, mentions or links to track`,
          suggestionLabel: ' Based on your brand account, here are some hashtag suggestions:',
          fieldLabel: `Type Name`,
          helperText: `Please click ‘Keywords and Mentions manager’ to add more than 10 items or to edit the list after finishing the setup.`,
        },
        eight: {
          label: `Almost done. Please add your brand name as text \n(e.g. how your brand would appear in the comments).\nThis way, we can find if users commented about you.`,
          fieldLabel: `Type Name`,
          helperText: `Examples: ‘Adidas’ or ‘Nike’ \n Please skip this step, if you already added your brand as free
          text before.\n
          Please click "Keywords & Mentions Manager" and select type
          "Free Text" to add more or edit.`,
        },
        finish: {
          label: `Thanks! You are all set!`,
          helperText: `We will track your creators and vou will receive weekly updates via mail. Of course, vou are
            able to update or change all the settings and a lot more custom options within your
            campaign.`,
          createCampaignTitleStatusCode202: `Attention: Not all creators are active!`,
          createCampaignTextStatusCode202: `We set up your campaign, but since you don't have enough seats, one or more creators were added to your campaign as ONBOARDING and will not be tracked. You need to empty or purchase more seats and activate the creators manually in your Creator Manager.`
        },
      },
    },
    campaignSettings: 'Campaign Settings',
    saveChanges: 'Save Changes',
    activateCampaign: 'Activate Campaign',
    running: 'Running',
    inactive: 'Inactive',
    inactiveCampaignText: `Your campaign has not yet started.\n Please active your campaign to track content.`,
    latestPosts: 'Latest Posts',
    mostViewedPosts: 'Most Viewed Posts',
    modalDemoCampaign: {
      label: 'This is demo campaign with real data!',
      subtitleOne: `You can click through demo campaigns and explore all possibilities "Campaigns" has to offer.`,
      subtitleTwo: `Please keep in mind that you can't change or update anything here.`,
      btnLabel: `Remove Demo Campaigns from Campaign List`
    },
    disabledTextForCampaignLimit: `You don't have enough campaign seats for this action. Please archive creators or contact us to increase your creator seats.`,
    confirmArchivingCampaign: 'Confirm archiving campaign',
    confirmDeletingCampaign: 'Confirm deleting campaign',
    archivingModalBodyText: `This campaign will be stopped and creators won't be tracked anymore and set to inactive. Campaign Seats will be available again for other campaigns.\n\n Attention: Once archived, it is not possible to activate this campaign again!`,
    deletingModalBodyText: `All data within this campaign will be lost and we won't be able to recover any data.\n\n We recommended to archive this campaign, if you want to access the data in future.`,
    switchFollowersLikes: 'Switch Followers/Likers',
    audienceReportsLikers: `Audience Report Likers`,
    audienceReportsFollowers: `Audience Report Followers`,
    audienceReport: 'Audience Report',
    editCPMs: 'Edit CPMs',
    disabledText: 'You are only able to add a price for the full collaboration OR per content piece + additinonal costs. Please remove other pricings to set a value here.',
    campaignOverview: 'Campaign Overview',
    filteredByContentLabel: 'Filtered by Content',
    brandsTimelineText: {
      withinTheLast365Days: username => `Within the last 365 days, @${username} collaborated with`,
      brands: countBrands => `${countBrands} brands`,
      shared: num => `${num} branded content pieces`,
      generated: (num, currency) => `${num} ${currency} EMV (Earned Media Value)`,
      reach: (num) => `${num} Views (Reach)`,
      creators: 'Creators',
      timeline: 'Timeline',
      contentPcs: 'Content Pcs.',
      creatorsThatMentionedTheBrand: 'CREATORS THAT MENTIONED THE BRAND',
      withinTheLast365DaysWasTaggedBy: username => `Within the last 365 days,
      @${username} was tagged by`,
      differentCreators: 'different creators',
      brandedContentPieces: 'branded content pieces',
      werePublished: 'were published',
      emv: `EMV (EARNED MEDIA VALUE)`,
      wasGenerated: 'was generated',
      wereGenerated: 'were generated',
      partnershipTypes: 'Partnership Types',
      oneOff: 'one-off',
      longTerm: 'long-term',
      brandAmbassadors: 'brand ambassadors',
      month: 'month',
      top5CreatorLocations: 'TOP 3 creator locations',
      from: `from`,
      top3CreatorLanguages: 'TOP 3 creator LANGUAGES',
      speak: `speak`,
      creatorGender: `CREATOR GENDER`,
      are: `are`,
      creatorsType: 'CREATORS TYPES',
      creatorInfludataScores: 'CREATOR INFLUDATA SCORES',
      reachFromCreatorsLabelChart: 'REACH FROM CREATOR MENTIONS OVER TIME',
      emvLabelChart: 'EARNED MEDIA VALUE OVER TIME (IN EUR)',
      brandedContent: 'BRANDED CONTENT',
      filterContentLabel: 'Please click creator above to filter other content.',
      clickToFilterContent: 'Click here to filter content.',
      wereShared: 'were shared',
      contentStrategy: 'Content Strategy',
      campaignHashtags: 'CAMPAIGN HASHTAGS'
    },
    confirmedAdsText: 'Paid Partnership - We found keywords that indicate a (paid) patnership',
    goToProfile: 'Go To Profile',
    reachedAudience: 'Reached Audience',
    latest: 'Latest',
    addToCampaignPipeline: `Add to Campaign Pipeline`,
    campaignPipelineModalSubtext: `Your Campaign Pipelines can be accessed within the Campaign Management. You can collect your creators and activate them for your campaigns.`,
    chooseCampaign: `Choose Campaign`,
    selectYourCampaign: `Select your campaign`,
    activateDirectlyInCampaign: `Activate directly in Campaign`,
    orChoosePipelineStage: `or choose Pipeline Stage`,
    activateInCampaigns: 'Activated in Campaigns',
    noCampaignCreatedYet: 'No campaigns are created yet.',
    noPipelineStagesCreatedYet: 'No Pipeline Stages are created',
    dontHaveAnyCampaignSeatsLeft: `You don't have any campaign seats left.`,
    importantAddingToPipeline: ' Important: Adding to Pipeline is still possible.',
    removeCreator: `Please go into your campaign to remove this creator.`,
    signupLocalization: {
      alreadyHaveAnAccount: 'Already have an account?',
      createYouFreeAccount: 'Create your free account.',
      noCreditNoCardNeeded: 'No credit card needed.',
      workEmailAddress: 'Please type in your work email address.',
      emailError: 'Your email address seems to be in the wrong format.',
      signupFree: 'Sign up free',
      verifyYourEmailAddress: 'Verify your email address',
      weveSentDigitalCode: email => `We've sent a 6-digit code to ${email}.`,
      lovedByTheWorld: `We are the Preferred Creator Platform for World-Class Brands and Agencies`,
      topRated: `Top Rated on OMR Reviews and Capterra`,
      whatCustomersSaying: 'In Their Own Words: Our Customers\' Experiences',
      emailSent: 'Email Sent',
      TheEmailIsOnItsWay: 'The email is on its way!',
      expiredCode: `This code is invalid or has expired. Please try again.`,
      makeSureToKeepThisWindowOpen: 'Make sure to keep this window open while you check your inbox.',
      resendNow: 'Resend now',
      enterYourDigitCode: 'Enter your 6-digit code',
      letsGetUpYouAndRunning: `Let's get you up and running`,
      thankYouForVerify: 'Thank you for verifying your email address.',
      continue: `Continue`,
      welcome: 'Welcome',
      whichPlatformAreRelevant: 'Which platforms are relevant for you?',
      whatAreYouLooking: 'What are you looking for in influData ?',
      pleaseClickAllAnswers: 'Please click all answers that apply to your business',
      inviteTeammates: `Invite teammates to your influData trial`,
      theyllbeAbleToUse: `They'll be able to use all the features available and you can collaborate on campaigns and
      collections`,
      enterEmailAddress: 'Enter Email Addresses',
      skipForNow: 'Skip for now',
      lastStep: 'Last Step: Please schedule a quick demo call with our team',
      aQuickDemo: `A quick demo is required, prior to starting a trial for our discovery and analytics, so we
      can set it up based on your requirements.`,
      ifYouAreOnlyInterested: `If you are only interested in testing our Campaign Reporting, you can just start right
      away`,
      thankYouForSigningUp: `Thank you for signing up!`,
      weAreLookingForward: `We look forward to getting to know you and elevating your creator marketing to the next level. We will get in contact with you shortly.`,
      followingPersonalDemo: `Following your personal demo, we will unlock your trial for discovery and analysis`,
      inTheMeanTimePlease: `In the mean time please check out Campaigns by influData - without commitment and for a
      full month.`,
      weAlreadyAddedSomeDemoCamp: `We already added some demo campaigns with real data to your account.`,
      setUpMyFirstCampaign: `Set up my first campaign now`,
      featuresObject: {
        discovery: 'Creator Discovery & Search',
        audience: 'Creator Audience Insights',
        analytics: 'Creator Profile Analytics',
        overlaps: 'Creator Overlaps & Comparisons',
        reporting: 'Campaign Reporting',
        management: 'Campaign Management & Planning',
        sentiment: 'Sentiment Analytics (e.g. "Shit-Storm Detection")',
        exports: 'Data Export & Mailings'
      },
      trialCampaignsLabel: 'Start your 30-days Campaigns trial now',
      describeYourBusiness: 'How would you describe your business?',
      consumerBrand: 'Consumer Brand',
      agency: 'Agency',
      marketingFreelancer: 'Marketing Freelancer',
      other: `Other`,
      collectLeadFirstPageTitle: `You Next Creator Marketing Platform!`,
      collectLeadFirstPageSubtitle: `Among all sign-ups during the OMR 2024, we are giving away a one-year influData All-In license (valued at approximately €15,000).`,
      notes: 'Notes',
      collectLeadQuickDemoSubtitle: 'A quick demo is required, prior to starting a trial for our discovery and analytics, so we can set it up based on your requirements.',
      restart: 'restart',
      startYour7DayTrialNow: 'Start your 7 Day Trial now',
      startTrialNow: 'Start Trial Now'
    },
    detailed: 'detailed',
    customFieldWebHook: {
      popoverText: {
        creatorId: `Go to "Creator Overview" and click the option to "Edit Creator", then on the left side you find a box with the label "CreatorId". Use this value for this variable.`,
        contentId: 'Go to "Content Overview" and click on the right side of the image, then on the left side you find a box with the label "ContentId". Use this value for this variable. We use the contentId that is also used by the platforms, so you can also get the contentId from the platform-specific url.'
      },
      applyLevelTitles: {
        creator: `This custom field is specific to creators. This means that you always need to add the creator id to your request. In your report, you are able to filter the values by creator and date.`,
        content: `This custom field is specific to content pieces. This means that you always need to add the content id to your request. You are able to filter the values by content and date.`,
        campaign: `This custom field is specific to the full campaign. You are not able to add individual values for creators or content pieces and you are only able to filter by dates.`
      },
      modalTitle: 'Connect your Webhook to Custom Field',
      sendAPostReq: `Send a POST request using this url`,
      addTheFollowingVariables: `Add the following variables to the "body" of your request`,
      noteText: `You are able to send negative values for variable "value".`,
      noteSubText: `Dates might be important to report values from the past so reports are created correctly.`,
      required: `required`
    },
    hideCreatorsInCollectionLabel: 'Do not show Accounts in Collections / Favorites',
    limitCreateCampaign: `You reached the limit of your campaigns.`,
    notIncludedInYourCurrentPlan: `This is not included in your current plan.`,
    youCanViewUpToDays: days => `You can view up to ${days} days of past content`,
    campaignsMetricsTable: {
      learnMore: 'The reach of Instagram posts and stories is not publicly accessible and can therefore only be released by the creator themselves. You can enter these values for the post or story manually in Content Overview, or simply keep our estimated values.',
      overview: {
        reach: 'Reach',
        engagements: 'Engagements',
        contentPieces: 'Content Pieces',
        engagementRate: 'Engagement Rate'
      },
      engagementReach: {
        likes: 'Likes',
        comments: 'Comments',
        shares: 'Shares',
        saves: 'Saves',
        rateOfLikes: 'Rate of Likes'
      },
      cpm: {
        costPreMille: 'Cost per Mille',
        expenses: 'Expenses',
        reachedAudience: 'Reached Audience',
        publishedContent: 'Published Content',
        rangeMin: 'Range (min. of Creator in',
        rangeMax: 'Range (max. of Creator in',
        missingData: 'Missing Data'
      },
      cpe: {
        costPerEngagement: 'Cost per Engagement',
        interactions: 'Interactions',
      },
      emv: {
        emvFull: 'Earned Media Value in',
        averagePerContent: 'Average per Content',
        averagePerEngagement: 'Average per Engagement'
      },
      tabsLabels: {
        overview: 'Overview',
        engagementReach: 'Engagement & Reach',
        cpm: 'CPM',
        cpe: 'CPE',
        emv: 'EMV'
      },
      starLabel: '*this calculation includes at least one estimated number.',
      learnMore: 'Learn More'
    },
    create: 'Create',
    downloadHighQuality: `Download High Quality Images & Videos`,
    campaignSettingsObj: {
      adjust: 'Adjust',
      setUpYourCampaign: 'Set-up Your Campaign',
      additionalSettings: 'Additional Settings',
      trackReach: 'Track Reach for Instagram Stories and Posts Manually',
      trackReachSubtitle: `Since this is not publically avaialable data, we can only estimate these metrics. It
      will be more exact, if the platform data is used. If you set this to “Automated and
      Manually“, then we will add the estimated data, but you are still able to do
      corrections. In both settings you can choose to get the data by requesting it from
      the influencer.`,
      trackNonOrganic: 'Track Non-Organic Metrics',
      trackNonOrganicSubtitle: `You can also track reach of content that came from non-organic reach, like sponsored
      content. We will add the fields to each content and you are able to insert the data.`,
      includeCostPricing: 'Include Influencer Costs & Pricing',
      includeCostPricingSubtitle: `You are able to set the costs of each content format and we are able to provide the
      costs per 1000 views and deliver additional statistics about the creator's
      performance. Additionally you are able to track custom fields for pricing, like ROI,
      revenue per post etc.`,
      adjustEMVCalc: 'Adjust EMV Calculation',
      adjustEMVCalcSubtitle: `You are able to adjust the default earned media values and increase or decrease
      them based on your needs by format and platform. This will take up to 6 hours
      for the dashboard to reflect.`,
      onlyManually: 'Only Manually',
      automatedAndManually: 'Automated and Manually',
      selectCurrency: 'Change Campaign Currency',
    },
    user: 'User',
    campaignsGoal: {
      customGoalForEachInfluencerTooltipTitle: 'This option is only possible for goals that are tracked on influencer level.',
      goalTitle: 'Goal Title',
      isPaused: 'Is Paused',
      selectAGoal: 'Select a Goal',
      whenIsThisGoalReached: 'When is this goal reached?',
      metricLabel: 'Metric Label',
      whichLevel: 'To which level should this goal be connected?',
      levelCant: ` The level can’t be more granular than what is defined in the custom field. Please
      keep in mind that you are not able to select a time period for goals per one
      content piece.`,
      perContentPiece: 'Per Content Piece',
      perInfluencer: 'Per Influencer',
      perCampaign: 'Per Campaign',
      timePeriod: 'In what time period should this goal be reached?',
      setQuarterly: 'Set quarterly, monthly, weekly or goals for the full campaign.',
      fullCampaign: 'Full Campaign',
      perWeek: 'Per Week (Mo-Su)',
      perMonth: 'Per Month (1st of Month)',
      perQuarter: 'Per Quarter (1st day of quarter)',
      customGoal: 'Custom goal for each influencer?',
      whenAnAddingInfluencer: ` When adding an influencer to a campaign, we ask for the expected value. If the
      value is not set, then the default value above is used.`,
      editGoal: 'Edit a Goal',
      addGoal: 'Add a Goal',
      createGoal: 'Create a Goal',
    },
    emvForContent: 'Earned Media Value (EMV) of Content',
    sponsoredDetails: 'The reach of this content piece partly comes from anorganic activity (Paid Ads)',
    mostViewed: 'Most Viewed',
    duration: 'Duration',
    reactions: 'Reactions',
    adLikeCountTooltip: 'Likes gained through sponsored reach. This number is included in the overall likes.',
    adCommentCountTooltip: 'Comments gained through sponsored reach. This number is included in the overall comments.',
    adSaveCountTooltip: 'Saves gained through sponsored reach. This number is included in the overall saves.',
    top5AudiencesCountries: 'Top 5 Audiences Country',
    top5AudiencesCities: 'Top 5 Audiences Cities',
    top5AudiencesLanguages:'Top 5 Audiences Languages',
    explorePage: {
      explore: 'Explore',
      suggestions: 'Suggestions',
      recentSearches: `Recents`,
      basedOn: `Based on`,
      updatedOn: 'Updated on',
      searchedOn: 'Searched on',
      createdOn: 'Created on',
      similarAccounts: `Similar Accounts`,
      newlyAddedTo: `Newly Added to`,
      suggestionsTitle: `Account based on`,
      suggestionsSubtitle: type => `We’ve found ${type} accounts based on the creators in your collection. We are updating this list weekly and show creators that you could be interested in.`,
      applyToSearch: `Apply to search`,
      useRecentSearchesOnTheLeft: `Select Recent Searches`,
      noInfluencersInSuggestions: 'Please select a list of suggestions.',
    },
    widgetBrandAccountGrowthHelperText: 'The date and followers are displayed when you hover your mouse over the chart',
    thanDashboardStart: 'since start date',
    couldntFindAnyContent: `We're sorry, but we couldn't find any content.`,
    emvInCurrency: currency => `EMV (in ${currency})`,
    reachTotal: 'Reach (total)',
    active: 'Active',
    buildLookalike: {
      btnText: 'Build Lookalike-List by AI',
      titleModal: 'Build Creator Lookalike list by AI',
      subtitleModal: `You can choose between finding Lookalikes of Creators that collaborated with brands (select "Brands") or you can choose to find lookalikes directly from creator accounts (select "Creators").`,
      brandsHelperText: `Please add up to three brands to find Lookalikes. Keep in mind to add similar brands to get better results.`,
      creatorsHelperText: `Please add a minimum of three creators (up to 20 creators) to find Lookalikes.`,
      addToCreatorsLimitText: `You reached the maximum number of accounts.`,
      pleaseAddALabel: `Please add a label to identify the lookalike list:`,
      yourLookalikeList: `Your Lookalike List`,
      lookalikeSubLabel: `These are the lookalikes that we found based on the analyzed accounts.`,
      lookalikeTipText: `Accounts that are similar, but not found within the brand account collaborations and not added to the list.`,
      creatorBaseTipText: `Accounts that are found as collaborations of brands you added or creators that you added to the list.`,
      creatorBase: 'Creator Base',
      lookalikes: 'Lookalikes',
      inProgress: 'In Progress',
      finished: 'Finished',
      willTake5Minutes: 'It will take around 5 minutes to complete. You will be notified via mail when we are finished.',
      deleteSuggestionsList: 'Do you really want to delete this list?',
      selectedSuggestionsNotFound: `The selected suggestions were not found`
    },
    createOverlap: 'Create Overlap',
    createOverlapHelperText: `Please select the platform and add up 10 creators (minimum 2).`,
    downloadResultsAsCSV: `Download results as CSV`,
    downloadResultsAsCSVNotPossible: `Only the first 500 results will be downloaded.`,
    percent: 'Percent',
    creatorOverview: {
      editCreator: 'Edit a Creator',
      creatorHandle: 'Creator Handle',
      internalName: 'Internal Name',
      bitlyLinkName: `Bitly Link - Name`,
      setCreatorAsArchived: 'Set Creator as archived',
      setCreatorAsArchivedSubtitle: `The creator will not be added to the dashboard and reports and we will not collect any more data from this influencer. You can reverse this at any time.`,
      overwriteAudienceDemographics: `Overwrite Audience Demographics`,
      overwriteAudienceDemographicsSubtitle: 'We use the audience data from influData, but if you have access to data from the creator, you can manually add more accurate information.',
      enablePricingAndCosts: `Enable Pricing & Costs`,
      enablePricingAndCostsSubtitle: `You can enable the pricing for each content piece of this creator. We will provide additional statistics, when enabled.`,
      requestMetrics: 'Request Metrics to be added by Creator',
      requestMetricsSubtitle: 'We will contact the creator 24 hours (story) or 3 days (any other content) after a content piece was posted and request metrics, like views, reach or clicks. You need to add a contact method for our notifications.',
      mergeOtherSocialMedia: 'Merge Other Social Media',
      audienceDemographics: 'Audience Demographics',
      audienceDemographicsSubtitle: 'Please only add the metrics for the non-organic traffic.',
      countriesTopLocation: 'Countries (Top Locations)',
      ageRange: 'Age Range',
      pricingSubtitle: 'You are able to add your costs for either the entire collaboration with this creator or each individual content piece here. Please note that you need to create custom fields, in case you want to track the ROI (“return on investment“) of this collaboration of of each content pieces.',
      pricingRecommended: 'We recommend setting up content goals on influencer level and defining the goals in the “overall” tab, so we are able to calculate the total planned budget in your dashboard.',
      createBitLinkForThisCreator: 'Create Bit.ly Link for this Creator',
      priceForEntireCollaboration: `Price for Entire Collaboration`,
      trackEntireCollaboration: 'Track Entire Collaboration',
      trackEachContentPiece: 'Track Each Content Piece',
      pricingForReel: 'Price for one Instagram Reel',
      pricingForStory: 'Price for one Instagram Story',
      pricingForPost: 'Price for one Instagram Post',
      pricingForTikTok: 'Price for one TikTok Video',
      pricingForYtVideo: 'Price for one YouTube Video',
      pricingForYtShorts: 'Price for one YouTube Shorts',
      additionalPayments: 'Additional Payments (Optional)',
      saveChanges: 'Save Changes'
    },
    createBitLyField: 'Create Bit.ly Field',
    editBitlyField: 'Edit Bit.ly Field',
    deleteBitlyField: 'Delete Bit.ly Field',
    bitLyFieldModal: {
      createNewField: 'Create Custom Bit.ly Links for Creators',
      step1: `Generate unique Bit.ly links for each creator in your campaign with just one setup. These links enable precise tracking of each click and comprehensive statistics collection.`,
      step2: `How to Use:`,
      step3: `- Access in Creator Manager: Click the three dots next to a creator and select "Show Bit.ly Link." or within "Edit Creator"`,
      step4: `- Send the creator specific link to the creator to use in their bio or swipe up links.`,
      step5: `- The user will be redirected to your forward URL and the click will be tracked automatically.`,
      step6: `- Easily change the forward URL at any time and it will be changed for all creators.`,
      yourForwardUrl: `Your Forward URL`,
      yourForwardUrlExtended: `Your Forward URL (e.g. Website, Product Page etc.)`,
      yourLinkName: `Your Link Name`,
      enterValidUrl: `Please enter a valid HTTPS URL`
    },
    yourCampaignIsNowSetUp: `Your Campaign is now set up and ready`,
    weAreAlreadyStarted: `We are already started tracking your content.`,
    yourDashboardWillAppearHere: `Your dashboard will appear here, once the first content is detected.`,
    bitlyDisableBtnText: `Only one bitly field per campaign is allowed. Please remove or change your current bit.ly field.`,
    campaignAllowBitlyEdit: `Please contact us to change the redirect of existing bit.ly urls. Please consider deleting the field and create a new bit.ly field, if you didn't use this field yet.`,
    customFieldDeleteAttention: `Attention: All tracked data for this field will be removed and this field will not
      appear in your dashboard anymore. This can't be reversed.`,
    customFieldDeleteBitlyTracking: `Bitly-Tracking or Webhook-Tracking will not work for this field anymore.`,
    customFieldDeleteConfirm: customFieldName => `Please type the name "${customFieldName}" to confirm.`,
    deleteField: 'Delete Field',
    confirmToDeleteField: customFieldName => `Do you really want to delete the custom field "${customFieldName}"?`,
    createBitlyGrantText: `Creating Bit.Ly fields is not included in your current plan.`,
    bitlyTrackingTable: {
      title: 'Bit.Ly Link Tracking',
      audienceCountry: 'Audience-Country',
      audienceCity: 'Audience-City',
      audienceReferral: 'Audience-Referral',
      audienceReferralDomain: 'Audience-Referral Domain',
      audienceDevice: 'Audience-Device',
      clicks: 'Clicks',
      clicksPDay: 'Clicks p. Day (avg)',
      lastClick: 'Last Click',
      topCountry: 'Top Country',
      topReferral: 'Top Referral',
      featureIsNotIncluded: `This feature is not included in your plan.`
    },
    showAndCopyBitlyLink: `Show & Copy Bit.ly Link`,
    outCampaignSeats: `You are out of campaign seats.`,
    addCreateToCampaignPopup: `Attention: You are out of seats and the creator is added as ONBOARDING and will not yet be tracked. Please empty or purchase seats and activate this creator manually.`,
    specifyNameCampaign: `Please specify a name for your new campaign.`,
    copyCampaign: {
      first: {
        title: `Copy from Existing Campaign`,
        selectTitle: `Select Campaign you would like to copy`,
        helperText: `We copy creators, keywords & mentions, goals, settings, roles, dashboards and creators in pipelines.`,
        btnText: `Copy into new campaign`
      },
      finish: {
        title: `New Campaign is created`,
        helperText: `Please note that your campaign is created in onboarding mode and you need to start your campaign to start tracking!`
      }
    },
    importCreatorsFromCollection: `Import Creators from Collection`,
    selectYourCollection: `Select your Collection`,
    noMatchingCreators: `No matching creators found, please select another collection.`,
    activateCampaignFirst: `Please activate the campaign first to start tracking creators.`,
    onceArchived: ` Once archived, you are not to able to reactivate the creator for this campaign and we will stop tracking content for this creator.`,
    allDataWillStillAppear: `All data will still appear in your dashboard up to the day the creator was archived.`,
    showHide: `Show/Hide`,
    searchSuggestions: {
      suggestionsPlaceholder: 'Find any creator - Try "Vegan influencers from Austria with minimum 100k followers"',
      suggestionsContentPlaceholder: 'Find any content - Try "Woman with curly hair"',
      suggestionsBrandPlaceholder: `Find any brand - Try "Fashion brands based in EU"`,
      resetSearch: 'Reset Search',
      recentSearchTooltip: 'View and recover your past searches.',
      lookalikeTooltip: 'Build Lookalike lists by AI and get similar creators that fit your brand.',
      uploadTooltip: 'Upload an image or logo to get similar images using AI.',
      creatorCollaborationsTips: `Insert one or more creators and find brands these creators have collaborated in the past.`,
      promptsByChatGPT: `Use Prompts by ChatGPT (Beta Version)`,
      receiveSurveyResultLabel: 'Are you happy with the results?',
      receiveSurveyResultPositive: `Yes, all good :)`,
      receiveSurveyResultNegative: `No, could be better`,
      disabledCity: `Please select a country first before choosing a city.`,
      paramLabels: {
        city: "",
        country: "",
        language: "",
        followerMin: "",
        followerMax: "",
        gender: "",
        skipCount: "",
        additionalSkipsForBlacklisting: "",
        keywords: "",
        categories: "",
        matchAnyKeyword: "",
        mustIncludeEmail: "",
        appearsOn: "",
        index: "",
        sorting: "",
        growthRate: "",
        engagementRate: "",
        audienceCountries: "",
        audienceGender: "",
        audienceLanguages: "",
        audienceRealPeople: "",
        audienceAgeRanges: "",
        isShortsCreator: "",
        contentTypes: "",
        uploadedFrom: "",
        uploadedTo: "",
        placesId: "",
        radiusKM: "",
        hideCreatorsInCollection: "",
      }
    },
    invalidUser: 'Failed to log in. Please check your credentials!',
    try: 'Try',
    previousSearch: 'Previous Search',
    createLookalike: 'Create Lookalike',
    notABrandAccount: `Not a brand account?`,
    toChangeBrandAccount: ` to change this profile to a creator profile.`,
    notACreator: `Not a creator?`,
    toChangeCreatorAccount: `if this profile is a brand account.`,
    inTrial: 'In Trial',
    cancelled: 'Cancelled',
    subscriptionStatus: `Subscription Status`,
    defaultSubscriptionsLabel: d => `Your subscription is active.\nYour next renewal date is on ${d}`,
    statusDescription: date => `Cancels automatically on ${date}\nYou won't be charged when your trial ends.`,
    addPaymentMethodAndSubScribe: `Add Payment Method and Subscribe Now`,
    addPaymentMethod: `Add Payment Method`,
    addPaymentMethodContinue: `Add a Payment Method To Continue with your Subscription`,
    pleaseAddBilling: `please add billing details below first`,
    pleaseAddBillingErrorMsg: 'Please add and save your billing information',
    creatorFilter: 'Creator Filter',
    contentFilter: 'Content Filter',
    brandFilter: 'Brand Filter',
    otherSettings: 'Other Settings',
    menuKeywordslabel: 'Keywords',
    misc: 'Miscellaneous',
    categories: 'Categories',
    engagementRate: 'Eng. Rate',
    brandMentions: 'Brand Mentions',
    creatorCollaborations: `Creator Collaborations`,
    keywords: 'Keywords',
    hideBrandAccounts: 'Do not show Brand Accounts',
    sortingBy: 'SORTING BY',
    similarContentTooltip: 'Get similar images by AI',
    show: 'Show',
    hide: 'Hide',
    queryMatchedTooltipText: 'text',
    found: 'Found',
    contentPiecesContaining: 'content pieces containing',
    creator: 'Creators',
    brand: 'Brands',
    similarContent: 'Similar Content',
    signInAzureAdLabel: 'Sign in with Azure AD',
    azureAdAccountSettingsLabel: `Your organization is authenticated via Azure AD`,
    azureAdAccountSettingsCheckBoxLabel: `Enforce Azure AD Tenant Authentication for Team Members.\nCheck this box to only allow users from your tenant to be added to your account.\nAll other authentication methods will be deactivated for team members.`,
    no_plan: {
      thankYouForTesting: `Thank you for testing influData`,
      yourTrial: `Your trial expired and you are not able to use influData anymore. Please activate your plan to continue using influData.`,
      cancelAnyTime: 'Cancel any time',
      perMonth: 'per month',
      seeAllFeatures: `See all features`,
      subscribeNow: `subscribe now`,
      allPlatformsAccess: `All platforms access`,
      searchBy: `Search by creators & content`,
      fullAccess: `Full access to collections, AI-lookalikes & more`,
      profileAnalytics: `150 profile analytics with audience data (monthly)`,
      searchResults: `500 search result pages (monthly)`,
      campaignsTracking: `10 campaign tracking seats`,
    },
    profileAnalyticsLimit: 'Profile Analytics',
    searchResultsLimit: 'Search Results (Pages)',
    searchOrAddContentByLink: `Search or Add Content by Link`,
    searchOrAddContentByLinkSubtitle: `Paste the platform link here.\n You can search for the content piece here and add it, if it's unmatched.`,
    fullPlatformLink: `Full Platform Link`,
    weCouldntFind: "We couldn't find any content. Please keep in mind that it might take up to 25 hours after posting.",
    theLinkYouPostedNotValid: "The link you posted is not valid and doesn't belong to a content piece. Please try again.",
    deleteCreatorFromCampaignsTipLabel: `Please first archive, then you are able to delete the creator`,
    activateSubscriptions: 'Activate Subscription Now',
    subscriptionStatus: 'Subscription Status',
    activateSubscriptionsSub: '199 Euros per Month. Renews automatically. VAT might be added.',
    activateSubscriptionsLabel: `Your trial is still active and will automatically convert to a paid plan on`,
    activateSubscriptions201: `You successfully activated your plan.`,
    activateSubscriptions400: `Attention: Activation not successful. Please check your payment method or get in contact with us.`,
    cancelSubscription: 'Cancel Subscription',
    subscriptionSuccessfullyCancelled: `Subscription successfully cancelled`,
    errorWithCancelledSubscription: `An error occurred while canceling your subscription.`,
    attention: 'Attention',
    cancelSubscriptionAttentionText: `If you confirm, we will cancel your plan at the end of your term. Please note that your account data will be lost after cancellation.`,
    removeCancellation: `Remove Cancellation`,
    removeCancellationLabel: date => `Your plan will be cancelled on ${date}\nClick here, to remove the cancellation.`,
    removeCancellationSuccessful: `Remove Cancelation successful`,
    removeCancellationError: `An error occurred while remove cancelation.`,
    manageYourPlan: `Manage Your Plan`,
    cancelledPlanLabel: `Your plan is currently cancelled and not active anymore. You can activate your plan in the settings or reach out to us via chat.`,
    createBitlyFieldTip: `This feature is not available in your current plan. Please get in contact with us.`,
    showAll: 'Show All',
    alreadyAddedToCampaign: 'Already added to Campaign',
    backToSearch: 'Back To Search',
    typeLabelName: `Type Label Name`,
    buildLookalikesBasedOnBrandAccount: 'Build Creator Lookalike based on brand account',
    matchRate: 'Match Rate',
    notificationTopic: {
      addUser: obj => {
        if (obj.additionalInfo?.includes('No Influencer')) {
          return `Your Request to add <b>"${obj.username}"</b> on ${obj.platform[0].toUpperCase() + obj.platform.slice(1)} failed. Account has not enough followers or is inactive/private.`
        } else if (obj.additionalInfo?.includes('Not Found')) {
          return `Your Request to add <b>"${obj.username}"</b> on ${obj.platform[0].toUpperCase() + obj.platform.slice(1)} failed. Username not found or incorrect.`
        } else {
          return `<b>"${obj.username}"</b> on ${obj.platform[0].toUpperCase() + obj.platform.slice(1)} successfully added/updated. Open Profile.`
        }
      },
      audience: obj => `AUDIENCE REPORT for <b>"${obj.username}"</b> on ${obj.platform[0].toUpperCase() + obj.platform.slice(1)} is finished. Open Report.`,
      lookalikeDone: obj => `LOOKALIKE List <b>"${obj.additionalInfo}"</b> created. Go to List.`,
      newOverlapCreated: obj => `Your <b>OVERLAP</b> was successfully created! Check Overlap.`,
      tagged: obj => `You were @TAGGED by <b>${user.additionalInfo}</b> on creator ${obj.username}.`,
      newCampaignsContent: obj => `NEW CAMPAIGNS CONTENT was posted on campaign <b>${obj.additionalInfo}</b>. Open Dashboard.`,
      suggestions: obj => 'SUGGESTIONS', 
      emptyNotificationList: 'Your Notification List is currently empty'
    },
    generalAccess: 'General Access',
    collectionsAccessDescriptions: {
      anyone: 'Anyone you share the link has access.',
      restricted: 'Only people that you shared it with have access.',
      organization: 'Only people within your company have access.'
    },
    changeEmail: 'Change E-Mail',
    changeYourEmail: `Change your E-Mail`,
    enterNewEmail: `Please enter your new email here`,
    changeEmailNotice: `Please keep in mind that your changes only will be effective, after you confirm the new email address. Please note that if you log in via Google or Azure SSO (Single-Sign-On), your new email also needs to be enabled for SSO.`,
    confirmNewEmail: `Please confirm you new E-Mail`,
    clickLinkEmail: `Please click the link we sent to your new mail to confirm the changes.`,
    changeEmailSuccess: 'E-Mail Change Success',
    weSuccessfullyChangedEmail: `We succesfully changed your email.`,
    continueToInfludata: `Continue to Infludata`,
    emailChangedFailed: `E-Mail Change Failed`,
    pleaseTryAgain: `Please try again.`,
    yourEmailIsNotValid: `Your email is not valid, please use your Company Email to sign up for the Trial.`,
    signupIsNotAllowed: `Sign up is not allowed for generic emails, please sign up with your company email.`,
    companyMembersSeatsUsed: (usedSeats, maxSeats, availableSeats) => `Seats used ${usedSeats} of ${maxSeats} (${availableSeats} seats available)`,
    pleaseReach: `Please reach out to us via chat to increase the maximum seats`,
    inviteNewTeamMembers: `Invite New Team Members`,
    changeRole: `Change Role`,
    companyMembersSubtitle: `Click here to see the difference between the roles of team members.`,
    role: `Role`,
    addEmailForTeamMember: `Please add the email of the team member`,
    whatIsTheRoleOfTheTeamMember: `What is the role of the team member?`,
    member: 'Member',
    admin: 'Admin',
    invite: 'Invite',
    enterAValidEmailAddress: `Please enter a valid email address.`,
    changeTeamMemberRole: `Change Team Member Role`,
    selectRoleMember: `Please Select the role that should apply for the team member:`,
    removeTeamMember: `Remove Team Member`,
    removeTeamMemberWithEmail: username => `Do you really want to remove your team member with mail ${username}?`,
    privateCollectionsOfThatMember: `All private collections of that member will become accessible to your organization.`,
    yourTrialsEnds: daysXX => `Your trial ends in ${daysXX} days.`,
    yourTrialsEndsSubtitle: `Please click here to subscribe to influData.`,
    yourSubscriptionsEnds: daysXX => `Your subscription ends in ${daysXX} days.`,
    yourSubscriptionsEndsSubtitle: `Please click here to continue your subscription.`,
    removeCancellationScheduled: `You are about to remove your scheduled cancellation and your subscription will renew after the current period.`
  },
  /**
   * ************************
   * GERMAN TRANSLATIONS
   * ************************
   */

  [availableLangs.de]: {
    all: 'alle',
    search: 'Suche',
    followerMin: 'Follower (Min)',
    followerMax: 'Follower (Max)',
    city: 'Städte',
    country: 'Land',
    countryCompany: 'Land des Unternehmens',
    age: 'Alter',
    keywords: 'Keywords & Mentions',
    matchall: 'Match',
    gender: 'Geschlecht',
    language: 'Sprache',
    languages: 'Sprachen',
    username: 'Username',
    enterUsername: 'Username eingeben',
    collection: 'Collection',
    selectMultiCountries: 'Wähle (mehrere) Länder',
    selectLanguage: 'Sprache auswählen',
    selectKeywords: 'Keywords eingeben',
    selectGender: 'Wähle Geschlecht',
    selectCurrency: 'Währung auswählen',
    selectMultiCities: 'Wähle (mehrere) Städte',
    chooseCollection: 'Wähle eine Collection...',
    numberProfiles: 'Anzahl der Profile',
    creationDate: 'Erstellungsdatum',
    removeFromCollection: 'Aus Collection entfernen?',
    removeAllUsersFromCollection:
      'Alle Creator in dieser Collection gehen verloren, wenn du fortfährst. Möchtest du diese Collection wirklich entfernen?',
    location: 'Herkunft',
    locationLanguage: 'Herkunft/Sprache',
    notes: 'Private Notizen',
    yourCollections: 'Deine Collections',
    content: 'Content',
    learning: 'Learning Center',
    analyze: 'Analyse',
    results: 'Ergebnisse',
    selectCollection: 'Wähle eine Collection',
    editCollection: 'Collection bearbeiten',
    removeCollection: 'Collection entfernen',
    exportCollection: 'Collection exportieren',
    editCollectionTitle: 'Bearbeite die Collection',
    createNewCollection: 'Erstelle eine neue Collection',
    createUnlimitedCollections: 'Erstelle unbegrenzte Collections',
    collectionName: 'Name der Collection',
    collectionMustHaveName: 'Collection muss einen Namen haben',
    collectionDescription: 'Beschreibung',
    create: 'Bitte drücke Enter, um hinzuzufügen',
    previous: 'Zurück',
    next: 'Weiter',
    scoreOverall: 'Score',
    scorePerformance: 'Authentizität',
    scoreLikes: 'Likes',
    scoreComments: 'Comments',
    scoreQuantity: 'Anzahl der Posts',
    scoreFollowing: 'Followingzahl',
    scoreRhytm: 'Aktivität',
    copiedToClipboard: 'Kopiert',
    failCopyToClipboard: 'Kopieren in die Zwischenablage fehlgeschlagen',
    failGetCSV: 'CSV konnte nicht abgerufen werden',
    addPrivateComment: 'Private Notiz hinzufügen',
    privateNote: 'Private Notiz',
    favorites: 'Favoriten',
    mustIncludeEmail: 'E-Mail vorhanden',
    email: 'E-Mail',
    emailAddress: 'E-Mail Adresse',
    copyEmail: 'Kopiere die E-Mail',
    seeUserEmail: 'E-Mail des Benutzers anzuzeigen',
    upgradeToSeeUserEmail: "Mach' ein Upgrade, um die E-Mail-Adresse des Benutzers anzuzeigen",
    userHasNoEmail: 'Keine Mail vorhanden',
    password: 'Passwort',
    yes: 'Ja',
    yesSure: 'Ja, ich bin mir sicher',
    no: 'Nein',
    rename: 'Umbenennen',
    addToCollections: 'Zur Collections hinzufügen',
    addUserToCollections: 'Benutzer zu Collections hinzufügen',
    removeFromCollectionTitle: '{0} von Collection entfernen',
    exportAsCSV: 'Export als CSV',
    industryType: 'Branche',
    followersHistory: 'Followerverlauf',
    infludataQualityRating: 'influData Qualitätsscores',
    bulkActionText: 'Was möchtest du mit der Auswahl machen?',
    remove: 'Entfernen',
    move: 'Verschieben',
    copy: 'Kopieren',
    removedNItems: n => `Du hast ${n} Elemente erfolgreich entfernt`,
    movedNItems: n => `Du hast ${n} Elemente erfolgreich verschoben`,
    copiedNItems: n => `Du hast ${n} Elemente erfolgreich kopiert`,
    alreadyInCollectionItems: n =>
      !!n ? `${n} ${n > 1 ? 'Elemente' : 'Element'} ist bereits in der Zielsammlung` : '',
    renameCollection: 'Collection umbenennen',
    cancel: 'Abbrechen',
    bulkAddToCollections: 'Zu Collection hinzufügen',
    bulkManageInCollections: 'Collections verwalten',
    logout: `Ausloggen`,
    login: 'Anmelden',
    signup: 'Registrieren',
    wrongCredentials: 'Falsches Passwort oder Benutzername. Bitte versuche es noch einmal.',
    clearSearch: 'Suchergebnisse löschen',
    removeFromCollectionTitleSingular: 'Diesen Creator aus der Collection entfernen?',
    removeFromCollectionTitlePlural: 'Diese Creator aus der Collection entfernen?',
    backToSearchResults: 'Zurück zur Suche',
    back: 'Zurück',
    useSearch: 'Benutze die Suche um nach Creatorn zu suchen.',
    analyzeProfile: 'Gib einen Username ein um das Profil zu analysieren',
    analyzeAction: 'Analysieren',
    chartLoading: 'Analyse wird geladen',
    upgrade: '7 Tage Testen',
    upgradeToSeeDetailedScores: "Bitte mach' ein Upgrade, um unbegrenzte Analysen zu sehen",
    update: 'Update',
    downgrade: 'Abo wechseln',
    downgradePlan: 'Downgrade Abo',
    upgradePlan: 'Upgrade Abo',
    changeCancelPlan: 'Abo ändern/abbrechen',
    yourPlan: 'Dein Abo',
    cancelPlan: 'Abo kündigen',
    changePlan: 'Abo wechseln',
    managePlan: 'Plan verwalten',
    freeTrial: 'Kostenlos testen',
    warning: 'Warnung',
    close: 'Schließen',
    clickHere: 'Klicke hier',
    agree: 'Ok',
    audienceAnalysis: 'Audience-Analyse',
    audienceSmartMediaValue: 'Smart Media Wert',
    audienceAnalysisNSmartMediaValue: 'Audience-Analyse & Smart Media-Value',
    audienceSubTitleF: (username, followers) => `Daten der ${followers} Follower von ${username}`,
    influencers: 'Creators',
    massFollowers: 'Massenfollower',
    realPeople: 'Echte Follower',
    suspicious: 'Verdächtig/Inaktiv',
    male: 'männlich',
    female: 'weiblich',
    other: 'Andere',
    audienceType: 'Audience-Aufteilung',
    ageGroups: 'Altersgruppen',
    ageIs: 'Alter ist',
    audienceMentions: 'Die häufigsten Interessen der Audience',
    proFeature: 'Pro Feature',
    subscribeToPro: {
      text: "Hol' dir das Pro-Paket, um die Audience-Analyse zu sehen",
      link: 'Pro-Paket',
    },
    noDataforChart: 'Leider haben wir nicht genügend Daten, um die Analyse anzuzeigen.',
    noResultsFound: 'Keine Ergebnisse gefunden. Bitte ändere die Suchparameter.',
    useSearchEngine: 'Nutze unsere AI-Suche und tippe deine Suche oben ein.',
    notLoggedInTitle: 'Du bist nicht eingeloggt',
    useSearchEngineText: 'Finde die besten Creator für deine nächste Kampagne.',
    notLoggedInText: 'Bitte nutze den Button oben rechts, um dich anzumelden oder einzuloggen und Zugriff auf über 30 Mio. Creator und eine Milliarde Content Pieces zu bekommen.',
    searchPageFreeUser:
      'Bitte registriere dich bei uns und wir werden so schnell wie möglich mit dir in Kontakt treten und deinen Testzugang aktivieren.',
    notActivatedAccountTipTitle: 'Ihr Plan ist derzeit inaktiv.',
    notActivatedAccountTipText:
      'Wenn Sie sich gerade angemeldet haben, werden wir uns in Kürze mit Ihnen in Verbindung setzen. Bei Fragen können Sie sich jederzeit an uns wenden.',
    collectionsAreDeactivated: 'Collections sind deaktiviert',
    campaignsAreDeactivated: 'Campaign Tracking ist deaktiviert',
    pleaseGetInContact:
      'Bitte nutze den Chat unten rechts um dich mit uns in Verbindung zu setzen. Wir helfen dir gerne weiter :)',
    pleaseContactSupport: 'Bitte nutze den Chat unten rechts und wir helfen dir gerne weiter.',
    brandAccounts: {
      brandSearch: 'Suche nach Brand-Accounts',
      brand: 'Brand-Accounts',
    },
    appearOn: 'Creator erscheinen auf',
    nameNBio: 'Name & Bio',
    statistics: 'Statistics',
    contact: 'Kontakt',
    personalWebsite: 'Persönliche Webseite',
    socialMedia: 'Social Media',
    followers: 'Follower',
    following: 'Folgt',
    followersWithK: 'Follower (in K)',
    plays: 'Plays',
    playsPerPost: 'Plays pro post',
    totalPlays: 'Plays (gesamt)',
    playsWithK: 'Plays pro Post (in K)',
    views: 'Plays',
    viewsPerPost: 'Aufrufe pro Post',
    averageReach: 'Reichweite',
    engagementMean: 'Engagement %',
    viewsPerStory: 'Aufrufe pro Story',
    viewsWithK: 'Aufrufe pro Post (in K)',
    faqLinkDefaultTip: 'Bitte klicke auf den Link unten, um mehr Informationen zu bekommen.',
    faqKeywordsMentions: 'Explanation ...',
    faqLinkClickText: 'Klicke hier für weitere Informationen',
    useCollections: 'Collections verwenden',
    useSearchUpgrade: 'die Creator-Suche zu nutzen',
    to: 'um',
    myPlan: 'Mein Abo',
    tryForFree: 'Kostenlos Testen',
    scheduleDemo: 'Termin vereinbaren',
    startTrial: 'Trial Starten',
    tellUsAbout: 'Über',
    yourBusiness: 'dein Unternehmen',
    for7Days: 'für 7 Tage ',
    noPaymentNeeded: 'unverbindlich und ohne Zahlungsdaten',
    seeMoreResults: 'weitere Ergebnisse anzuzeigen',
    paginate: {
      next: 'Nächste',
      prev: 'Zurück',
    },
    qualityScore: 'Qualität',
    qualityRating: 'Qualitätsbewertung',
    options: 'Optionen',
    geodata: 'Geodaten',
    name: 'Name',
    bio: 'Biografie',
    post: 'Post',
    posts: 'Posts',
    postsReels: 'Posts & Reels',
    stories: 'Storys',
    reasonNotAdd: 'Aus diesem Grund können wir das Content Piece nicht hinzufügen.',
    postsPerWeek: 'Posts pro Woche',
    viralPosts: 'Virale Posts',
    oneHitWonder: 'One Hit Wonder',
    inAverage: 'im Schnitt',
    outOfLast: 'der letzten',
    profileScore: 'Profile Score',
    expectedAudiencePerPost: 'Erwartete Aufrufe pro Post',
    engagementRate: 'Engagement',
    engagement: 'Engagement',
    impressions: 'Impressionen',
    rating: 'Bewertung',
    unlockedStatus: 'Status',
    unlockedOn: 'Freigeschalten am',
    status: 'Status',
    available: 'Verfügbar',
    requested: 'Angefordert',
    audienceAnalysisProgress:
      'Wir analysieren derzeit die Daten für Dich. Dies kann bis zu einer Stunde dauern und wird hier automatisch angezeigt',
    audienceReportWillBeAvailableInstantly: 'Der Report ist sofort verfügbar',
    audienceReportWillBeAvailableIn30Min:
      'Dein Report wird in ungefähr 10 Minuten verfügbar sein und hier automatisch angezeigt',
    audienceReportWillBeAvailableInDay: 'Der Report wird in maximal einem Tag verfügbar sein',
    askNotifyAudienceAnalysisAvailable:
      'Möchtest du benachrichtigt werden, sobald der Report verfügbar ist?',
    askNotifyAudienceAnalysisConfirm: 'Ja, bitte benachrichtige mich',
    weWillNotifyYou: 'Wir werden dich benachrichtigen!',
    audienceReportInfluencerDisabled: `Es tut uns leid, aber wir können für diesen Creator keine Insights bereitstellen.`,
    noAudienceAnalysisYet:
      'Wir haben noch keine Analyse für diesen Creator. Bitte füge diesen Creator zu Ihren Favoriten oder einer deiner Collection hinzu, und wir werden Ihnen die Analyse in wenigen Tagen zur Verfügung stellen',
    noAnalysisTechnicalIssue:
      'Die Audience Analyse ist aufgrund technischer Probleme derzeit nicht verfügbar. Wir arbeiten an einer Lösung.',
    last30days: 'Letzte 30 Tage',
    description: 'Beschreibung',
    favsDescription:
      'Hier sind deine Favoriten. Diese werden immer privat sein und du kannst sie nicht innerhalb deines Unternehmens teilen.',
    unlockedDescription:
      'Hier findest du Reports, die innerhalb deines Unternehmens von dir selbst oder einem Kollegen bereits freigeschalten wurden.',
    outOf10: 'von 10',
    recentBrandsMentions: 'Aktuelle Brand-Mentions',
    showOn: 'Zum Profil',
    similarAccounts: 'Ähnliche Profile (nach Audience)',
    toSeeSimilarAccounts: 'ähnliche Konten zu sehen',
    seeDetailedScores: 'um detaillierte Ergebnisse zu sehen',
    whatAreSimilarAccounts: 'Was sind ähnliche Profile und warum sind sie hilfreich?',
    noSimilarAccountsFound: 'Leider konnten wir keine ähnlichen Profile finden',
    noMoreSimilarAccounts: 'Wir haben leider keine ähnlichen Profile mehr',
    showMore: 'Mehr',
    showLess: 'Zeige weniger',
    goBack: 'Zurück',
    goBackToResults: 'Zurück',
    profile: 'Profil',
    noUserFound: 'Keine Creator gefunden',
    removeNItemsWarning: n =>
      `Du entfernst ${n} Creator aus deiner Liste. Diese Aktion kann nicht rückgängig gemacht machen.`,
    areYouSureToProceed: 'Bist du sicher, dass du fortfahren möchtest?',
    moveToCollection: 'in eine Collection verschieben',
    copyToCollection: 'in eine Collection kopieren',
    addToCollection: 'Einer Collection hinzufügen',
    giveFeedback: 'Feedback geben',
    contactUs: 'kontaktiere uns',
    thanksForFeedback: 'Vielen Dank für dein Feedback',
    thanksForMessage: 'Danke für deine Nachricht. Wir werden uns bald bei dir melden.',
    notValidEmail: 'Keine gültige E-Mail',
    reenterNotValidEmail: "Email ist ungültig. Bitte gib' die E-Mail-Adresse erneut ein.",
    message: 'Nachricht',
    enterYourName: "Bitte gib' deinen Namen ein",
    yourMessage: 'Deine Nachricht',
    enterYourMessage: "Bitte gib' deine Nachricht ein",
    send: 'Senden',
    technicalIssue: 'Technisches Problem',
    dataQuality: 'Datenqualität',
    yourProfile: 'Dein Profil',
    payment: 'Zahlung',
    appStrUIX: 'App Structure / UX',
    selectTopic: 'Bitte Thema auswählen',
    getBackCheckboxText: 'Klicke diese Box, wenn du eine Antwort von uns erwartest.',
    loginToInfludata: 'Melde dich bei influData an',
    loginFail: 'Anmeldung fehlgeschlagen. Bitte überprüfe deine Anmeldeinformatione',
    passwordCantBeEmpty: 'Das Passwort darf nicht leer sein',
    alreadyExistAccount:
      'Mit dieser E-Mail ist bereits ein Konto verknüpft.\n Bitte melde dich an.',
    passRecoveryInstructions:
      'Wir haben dir eine Mail mit einem Link zur Passwortwiederherstellung gesendet. Bitte überprüfe deine E-Mail und folge den Anweisungen.',
    forgotPassword: 'Passwort vergessen',
    noAccountYet: 'Noch keinen Account?',
    restorePassword: 'Passwort wiederherstellen',
    noUserWithSuchEmail: 'Es gibt keinen Benutzer mit dieser E-Mail.',
    weWillSendPassRecoveryLink:
      'Wir senden einen Link zur Passwortwiederherstellung an deine E-Mail',

    setNewPassword: 'Bestätigen',
    passwordsShouldBeEqual: 'Passwörter sollten übereinstimmen',
    enterPassword: 'Passwort eingeben',
    notValidPassword: 'Kein gültiges Passwort',
    repeatPassword: 'Passwort wiederholen',
    pleaseRepeatPassword: 'Bitte wiederhole dein Passwort',
    contactToChangeCountry: 'Bitte kontaktiere uns, um das Land zu ändern.',
    newPasswordWasSet: 'Neues Passwort wurde erstellt',
    backToApp: 'Zur App',
    tryInfludata: 'alle Funktionen testen',
    forFree: 'kostenlos',
    bySigningUpIAgreeTo: 'Mit meiner Anmeldung stimme ich zu:',
    privacyPolicy: 'Datenschutz-Bestimmungen',
    termsOfService: 'Allgemeine Geschätsbedingungen',
    and: 'und',
    doSendOffersToEmail:
      "Ja, bitte schick' mir Angebote und Updates zu Ihren Produkten (nicht erforderlich).",
    firstName: 'Vorname',
    lastName: 'Nachname',
    company: 'Unternehmen',
    phone: 'Telefon',
    companySize: 'Firmengröße',
    selectCompanySize: 'Wählen Sie Unternehmensgröße ',
    position: 'Position',
    selectPosition: 'Wählen Sie Position ',
    required: 'erforderlich',
    passwordLimit:
      'Das Passwort muss aus mindestens 6 Zeichen bestehen und mindestens einen großen Buchstaben enthalten.',
    finish: 'Fertig',
    couldntFind: 'Du hast nicht gefunden, wonach du suchst?',
    termsAndConditions: 'Allgemeine Geschätsbedingungen',
    privacy: 'Datenschutz-Bestimmungen',
    impressum: 'Impressum',
    notEnoughDataForChart: 'Es tut uns leid, aber diese Analyse ist im Moment nicht vorhanden',
    notAvaliableProfile: 'Dieses Profil ist nicht verfügbar.',
    unlimited: 'unbegrenzt',
    profileBecamePrivate:
      'Konto ist privat, daher sind keine Analysen verfügbar. Nicht korrekt? Bitte "Profil manuell aktualisieren" klicken.',
    profileSetToPrivate: 'Konto ist auf privat eingestellt',
    thereforeCantUpdateThisProfile: 'Daher kann dieses Profil nicht aktualisiert werden',
    analyzeYourInfluencer: 'Username eingeben',
    influencerTracking: 'Campaign Management',
    useTracking: 'Infuencer-Tracking zu verwenden',
    toolsForBrands: 'Tools',
    useToolsForBrands: 'Tools für Marken verwenden',
    noInfluencersInCollection: 'Es sind keine Creator in der Collection',
    toAddInfluencersGoTo: 'Um Creator hinzuzufügen, gehe bitte zu',
    searchView: 'Suchansicht',
    andAddInfluencers: 'und Creator hinzufügen',
    canCreateCollectionByClicking: 'Du kannst eine neue Collection erstellen. Klicke dazu auf',
    inLeftMenu: 'im linken Menü',
    useLeftMenuToCreateCampaign: 'Verwende das Menü links, um eine Kampagne zu erstellen',
    addMoreInfluncersDynamic: n => `Du kannst ${n} weitere Creator hinzufügen`,
    campaigns: 'Kampagnen',
    campaign: 'Kampagne',
    createNewCampaign: 'Neue Kampagne erstellen',
    createUnlimitedCampaigns: 'Erstelle unbegrenzte Kampagnen',
    createCampaignTip:
      'Hinterlege alle Informationen zur Kampagne. Du kannst Details auch später bearbeiten.',
    editCampaign: 'Kampagne bearbeiten',
    deleteCampaign: 'Kampagne löschen',
    campaignName: 'Kampagnenname',
    campaignDescription: 'Kampagnenbeschreibung',
    mentionsAndHashtags: 'Mentions & Hashtags',
    startCampaign: 'Starte die Kampagne',
    updateCampaign: 'Kampagne aktualisieren',
    optional: 'Optional',
    campaignMustHaveName: 'Die Kampagne muss einen Namen haben',
    mentionHashtagsTip:
      'Gib Mentions und Hashtags ein. Mentions sollten mit "@" beginnen und Hashtags sollten mit "#" beginnen. Mentions und Hashtags sollten eindeutig sein.',
    mentionsHashTagValidationTip: 'Bitte beginne mit "@" oder "#"',
    removeFromCampaign: 'Aus Kampagne entfernen?',
    removeAllUsersFromCampaign:
      'Alle Benutzer in dieser Kampagne gehen verloren. Möchtest du diese Kampagne wirklich entfernen?',
    noDescriptionYet: 'Noch keine Beschreibung',
    noMentionsAndHashtagsYet: 'Noch keine Mentions und Hashtags',
    created: 'Erstellt',
    addInfluencers: 'Creator hinzufügen',
    addAnInfluncer: 'Füge einen Creator hinzufügen',
    noInfluencersInCampaign:
      'Derzeit gibt es keine Creator in dieser Kampagne. Füge oben einen Creator hinzu.',
    searchByUsername: 'Suche nach Benutzername',
    categoriesAI: 'Kategorien (AI-Detected)',
    addInfluencerFromInstagram: 'Füge Creator von Instagram hinzu',
    addInfluencerFromTikTok: 'Füge Creator von TikTok hinzu',
    addToCampaign: 'Zur Kampagne hinzufügen',
    trackInfluencers: 'Influncer tracken',
    notAvaliableCampaignTracking: platformName =>
      `Kampagnen-Tracking für ${platformName} nicht verfügbar (in Kürze verfügbar)`,
    trackingSince: 'Tracking seit',
    lastMentions: 'Letzte Mentions',
    lastHit: 'Letzter Treffer',
    alreadyInCampaign: 'Bereits hinzugefügt',
    campaignUpgradeToAddUsers: 'Upgraden, um weitere Benutzer hinzuzufügen',
    campaignBuyTokens: 'Kaufe Token, um weitere User hinzuzufügen',
    addMoreUsers: 'mehr User hinzuzufügen',
    trackedPosts: 'Treffer/Posts',
    trackedStories: 'Treffer/Storys',
    noMentions: '-',
    noHashtags: '-',
    noData: '-',
    date: 'Datum',
    mentions: 'Mentions',
    hashtags: 'Hashtags',
    likes: 'Likes',
    comments: 'Kommentare',
    goToPost: 'zum Post',
    linkedTo: 'Story-Link',
    goToStory: 'zur Story',
    storyExipred: 'Story abgelaufen',
    showTrackingDetails: 'Benutzer-Tracking-Details anzeigen',
    hideTrackingDetails: 'Benutzer-Tracking-Details ausblenden',
    noTrackingDetails: 'Wir haben keine Tracking-Details zu zeigen',
    help: 'Hilfe',
    reportForInfluencer: 'Report für den influencer',
    reportForInfluencerPlatform: platform => `Report für das ${platform} - Konto des Creators`,
    dateOfReport: 'Datum des Berichts',
    exportAsPdf: 'Als PDF exportieren',
    exporting: 'Exportieren...',
    page: 'Seite',
    profileInformation: 'Profil-Details',
    qualityRating: 'Qualitätsbewertung',
    qualityRatingAndMetrics: 'Qualitätsbewertung und Metriken',
    qualityRatingDetail: 'Detail der Qualitätsbewertung',
    audienceAnalysisGeneral: 'Audience-Analyse',
    outdatedPdf: 'Dieses PDF ist möglicherweise veraltet.',
    ifWantUpToDateUserInfo: 'Wenn du aktuelle Informationen zu diesem Benutzer wünschst',
    sortedBy: 'Sortiert nach',
    default: 'Standard',
    previewStory: 'Vorschau',
    previewPost: 'Vorschau',
    cantShowPreview: 'Leider können wir kein Vorschaubild anzeigen',
    storyCTALabel: 'Story Link ("Swipe up")',
    storyLink: 'Story Link',
    storyCTAOptionalLabel: 'Optional - funktioniert auch mit Teilen der Domain',
    pricing: 'Preisgestaltung',
    collections: 'Collections',
    confirmRemoveUserFromCampaign:
      'Möchtest du diesen Benutzer wirklich aus der Kampagne entfernen? Du verlierst alle Daten und wenn du diesen Benutzer später hinzufüst, werden keine alten Daten abgerufen.',
    noStoryLinkYet: 'noch kein Story-Link',
    showOnlyHits: 'Nur Treffer anzeigen',
    upgradeToProToAddMoreUsers: 'Upgrade auf Pro, um weitere Benutzer hinzuzufügen',
    relevance: 'Relevanz',
    dateAdded: 'Datum hinzugefügt',
    useSorting: 'die Sortierung zu nutzen',
    buyTokens: 'Seats kaufen',
    changeSeatPlan: 'Abo ändern',
    chooseNumberOfSeats: 'Wähle die Anzahl der Seats',
    upgradeLabel: 'Upgrade',
    downgradeLabel: 'Downgrade',
    plusPlan20SeatsIncluded: '+20 Seats aus deinem Premium-Abo',
    needMoreSeatsContactUs: 'Kontaktiere uns für ein individuelles Angebot!',
    seats: 'Seat',
    euroPerSeat: price => `${price} Euro pro Seat`,
    purchaseNewSeatsTip:
      'Dein Abo wird sofort aktiviert und der anteilige Betrag für deinen Abrechnungszeitraum wird sofort berechnet.',
    confirm: 'Bestätigen',
    allowedDowngradeSeatsTip:
      'Deine Änderungen werden sofort wirksam. Bitte beachte, dass du ggf. Benutzer aus Kampagnen löschen musst, die die neuen Limits überschreiten würden. Wir können keine Rückerstattung für möglicherweise nicht genutzte Zeiträume gewähren.',
    forbiddenDowngradeSeatsTip: count =>
      `Deine derzeit verwendeten Sitze würden die neuen Limits überschreiten. Bitte lösche ${count} ${
        count === 1 ? 'Creator' : 'Creator'
      } aus deinen Kampagnen.`,
    seatsSuccessChange: 'Dein Abo wurde erfolgreich geändert.',
    currentSeatsPlan:
      'Dein aktuelles Abo. Bewege den Schieberegler zum Upgrade nach rechts oder zum Downgrade nach links.',
    purchaseSeatsMonthlyCharge: price =>
      `Ihre monatlichen Gebühren für dieses Addon betragen ${price} Euro`,
    minGrowthRate: 'Mindestwachstum',
    minEngagementRate: 'Engagement Rate',
    growthRate: 'Wachstumsrate',
    off: 'Aus',
    onlyAvailableInPro: 'Nur in unserem Pro Paket verfügbar',
    onlyAvailableForAdmins: 'Nur für Administratoren verfügbar ',
    thanksForUseFreeVersion: 'Vielen Dank, dass du unsere kostenlose Version verwendest',
    freeUserAccessLimitMessage:
      'Um unser Produkt weiter zu verwenden, warte bitte einige Tage oder beginne eine kostenlose Testversion',
    showPlans: 'Abos anzeigen',
    reachTrialLimits: 'Du hast das Limit Deines Testzeitraums erreicht',
    activateTrial: 'Bitte aktiviere Dein Abo, um unsere Plattform weiter zu nutzen',
    activate: 'Aktivieren',
    askToActivate: 'Bitte aktiviere dein Abo',
    activateYourAccount: 'Aktiviere dein Abo',
    notAvailableInTrial: 'Nicht im Trial verfügbar',
    activateTrialFeature: 'Bitte aktiviere Dein Abo, um Zugriff auf diese Funktion zu erhalten.',
    useCitySearch: 'um nach Städten zu suchen',
    useKeywordsSearch: 'die Keyword-Suche zu nutzen',
    editYourAccount: 'Konto öffnen',
    audienceReportTokens: 'Audience-Token',
    remaining: 'verbleibend',
    of: 'von',
    influencerTrackingSeats: 'Creator Tracking-Seats',
    backToTop: 'Zurück nach oben',
    deleteMyAccount: 'Mein Konto löschen',
    deleteYourAccount: 'Lösche dein Konto',
    confirmDeleteAccount: 'Bitte bestätige, dass du dein Konto löschen möchtest.',
    weWillClearFollowingDatain60Days:
      'Wir kündigen sofort alle laufenden Abos (keine Erstattung) und wir löschen die folgenden Daten in 60 Tagen:',
    dataToDelete: {
      billingAndPaymentInfo: 'Rechnungs- und Zahlungsinformationen',
      allCollectionCampaignInfluencerData: 'Alle Collection-, Kampagnen- und Creator-Daten',
      //weMayCancelAnySubscriptions:
      //'Wir können laufende Abonnements kündigen (keine Rückerstattung)',
      allMailContentAndActivityLogs:
        'Alle Mailing-Inhalte und alle Aktivitätsprotokolle, falls dies nicht erforderlich ist',
      encryptedPasswords: 'Verschlüsselte Passwörter',
    },
    forLegalSecurityReasonsWeKeep:
      'Aus rechtlichen und Sicherheitsgründen bewahren wir Teile Ihrer Daten so lange auf, wie dies erforderlich ist:',
    dataToKeepWhenDelete: {
      invoices: 'Rechnungen',
      signup: 'E-Mail Adresse',
      //mail: 'Mail',
      companyName: 'Firmenbezeichnung',
    },
    deleteAccountReactivateTip:
      'Bitte beachte, dass du den Zugriff auf dein Konto verlierst. Innerhalb einer Frist von 60 Tagen kannst du dieses wieder aktivieren.',
    goToInfludataHome: 'Gehe zur Startseite',
    yourAccountWasdeactivated: 'Dein Konto wurde deaktiviert',
    willBeDeletedIn60Days: 'wird in 60 Tagen gelöscht',
    contactUsToReactivateDeletedAccount: 'Wenn du es reaktivieren möchtest, kontaktiere uns bitte',
    deletedAccountWarning:
      'Dein Konto ist deaktiviert und wird bald entfernt. Bitte wenden dich an den Support, um dein Konto zu aktivieren',

    yourAccount: 'Dein Konto',
    yourSubscription: 'Abo',
    please: 'Bitte',
    ifYouLikeToChangeEmail: 'wenn du deine E-Mail-Adresse ändern möchtest',
    changePassword: 'Passwort ändern',
    billingDetails: 'Rechnungsdetails',
    saveDetails: 'Details speichern',
    zip: 'PLZ',
    state: 'Stadt',
    vatId: 'UID',
    addressLine1: 'Adresszeile 1',
    addressLine2: 'Adresszeile 2',
    billingEmail: 'Billing E-Mail',
    changeMyPassword: 'Ändere mein Passwort',
    weWillSendPassChangeLink:
      'Wir senden dir eine E-Mail mit einem Link zur Änderung deines Passworts.',
    editDetails: 'Details bearbeiten',
    editCountry: 'bearbeite das land',
    payments: 'Zahlungen',
    invoices: 'Rechnungen',
    invoice: 'Rechnung',
    nextBillingDate: 'Nächstes Abrechnungsdatum',
    editPaymentMethod: 'Zahlungsmethode bearbeiten',
    amount: 'Betrag',
    download: 'Herunterladen',
    yourAddOns: 'Deine Add-Ons',
    usage: 'Nutzung',
    changeAudienceTokens: 'Upgrade um mehr Reports zu erhalten',
    tokens: 'Token',
    changeSeatPackage: 'Paket ändern',
    seatsLeft: n => `Noch ${n} Seats frei`,
    used: 'verbraucht',
    tokensLeft: n => `Noch ${n} Reports übrig`,
    seatsFromYourPlan: n => `${n} Seats aus deinem Paket`,
    audienceTokensFromProPackage: tokens => `${tokens} Reports aus dem Pro-Paket`,
    audienceTokensFromEnterprisePackage: tokens => `${tokens} Reports aus dem Enterprise-Paket`,
    perMonth: 'pro Monat',
    perYear: 'pro Jahr',
    perSeat: 'pro Seat',
    upgradeToProToAddMoreSeats: 'Aktualisiere auf Pro, um weitere Sitzplätze hinzuzufügen',
    yourSeatsWillBeActivatedImmediately:
      'Dein Plan wird sofort aktiviert und der anteilige Betrag für diese Laufzeit wird sofort berechnet.',
    eurPerMonth: 'EUR/Monat',
    eur: 'EUR',
    euro: 'EURO',
    currentPlan: 'Aktuelles Paket',
    notAvailableBillingDetails: 'Leider sind die Rechnungsdetails derzeit nicht verfügbar',
    notAvailablePayments: 'Leider sind derzeit keine Zahlungen verfügbar',
    billingEmailTip: 'Wenn nicht angegeben, senden wir Rechnungen an Ihre Konto-E-Mail',
    notInHereYet: 'Noch nicht hier',
    noProblem: 'Kein Problem',
    toAddUserToInfludata: 'um den Creator auf influData hinzuzufügen.',
    addInfluencerToInfludata: 'Creator zu influData hinzufügen',
    weWillAddProfileToInfludata: platform => `Wir werden das ${platform}-Profil hinzufügen.`,
    weCantAddInfluencerToInfludataIfAccount:
      'Wir können den Creator nicht hinzufügen, wenn das Profil',
    hasLessFollowers: followerNumber => `weniger als ${followerNumber} Follower hat`,
    hasLowPosts: 'eine geringe Zahl an Posts hat',
    isPrivateOrRestricted: 'privat ist',
    processCanTakeFewHours: 'Dieser Vorgang dauert normalerweise weniger als 10 Minuten.',
    shouldNotifyAboutProfileAdd:
      'Sollen wir dich via Mail benachrichtigen, wenn das Profil verfügbar ist?',
    notifyMe: 'Benachrichtige mich',
    addedToInfludataStatus: {
      [ADD_USER_RESPONSE_STATUS_MAP.created]:
        'Das Profil wird innerhalb weniger Minuten zu influData hinzugefügt',
      [ADD_USER_RESPONSE_STATUS_MAP.alreadyExists]:
        'Das Profil wurde bereits zu influData hinzugefügt oder ist bereits in der Warteschlange',
      [ADD_USER_RESPONSE_STATUS_MAP.invalid]:
        'Der Profilname ist ungültig. Bitte ändere den Namen und versuche es erneut',
    },
    notAllowedToDropToCollection: 'Du kannst dieser Collection kein Profil hinzufügen',
    notAllowedToDropToCollectionReasonuserExists:
      'Dieses Profil befindet sich bereits in der Collection',
    notAllowedToDropToCollectionReasonLimit:
      'Du hast das Limit an Profilen in dieser Collection erreicht',
    contactSales: 'Kontaktiere uns',
    unlockAnalysis: 'Report entsperren',
    analysisIsAlreadyUnlocked: 'Der Report ist freigeschaltet',
    avaliableInstantly: 'sofort verfügbar',
    available24h: 'verfügbar in 24h',
    avaliableWithin24h: 'innerhalb von 24h verfügbar',
    unlockAnalysisTip:
      'Wenn der Report entsperrt ist, halten wir ihn immer aktuell und du hast unbegrenzt Zugang.',
    unlockReportSpendTokenTip: '1 Token wird abgezogen',
    reportImpossibleTitle: 'Audience Report & CPMs sind für diesen Creator nicht verfügbar.',
    reportImpossibleContentFirst:
      'Der Grund dafür ist, dass die Zielgruppe zu klein ist, um verlässliche Daten zu liefern.',
    reportImpossibleContentLast:
      'Wir werden diese Informationen hinzufügen, sobald die Zahl der Besucher steigt.',
    unableToProvideAboutAudience:
      'Wir sind nicht in der Lage, Informationen über die Zielgruppe dieses Creators zur Verfügung zu stellen.',
    influencersRestrictsAccessToLikersFollowers:
      'Dies geschieht, wenn der Creator den Zugriff auf Likers und Follower einschränkt, eine Löschung des Reports gefordert hat, wir eine zu geringe Datengundlage haben oder wir aus anderen Gründen den Report entfernt haben.',
    unlockReportSpendNoTokenTip: 'Kein Token wird abgezogen.',
    noAudienceTokensLeft: 'Es sind keine Reports mehr verfügbar.',
    toUseMoreTokens: 'für mehr Reports',
    manageYourProPlan: 'Verwalte dein Pro-Abo',
    chooseHowManyTokensShouldBe: 'Wähle aus, wie viele Reports enthalten sein sollen',
    canAjustAnyTime: 'Du kannst dein Paket jederzeit anpassen',
    billedMonthly: 'Monatlich abgerechnet',
    billedAnnually: 'Jährlich abgerechnet',
    compareProfiles: 'Profilvergleich',
    useComapreProfiles: 'verwende Profilvergleich',
    noProfilesToCompare: 'Noch keine zu vergleichenden Profile',
    noProfilesToCompareTip:
      'Bitte füge Profile aus Suchergebnissen, Collections oder direkt aus der Profileseite hinzu.',
    audienceTokensTrialLimit: 'Du hast das Limit der Tokens in deinem Testzeitraum erreicht. ',
    toActivateTrialForAudienceTokens: 'um dein Abo zu aktivieren und alle Tokens zu nutzen.',
    activateTrialForAudienceTokens: 'dein Abo zu aktivieren und alle Tokens zu nutzen.',
    comparedProfiles: 'Vergleichte Profile',
    comparedParams: 'Vergleichte Parameter',
    category: 'Kategorie',
    website: 'Webseite',
    monthlyGrowthFollowers: 'Wachstum der Follower im letzten Monat',
    locked: 'gesperrt',
    unlocked: 'freigeschaltet',
    unlockedReports: 'Audience Reports',
    postsFrequencyDays: 'Posthäufigkeit (Tage)',
    billed: 'abgerechnet',
    needMoreTokens: 'Mehr Audience Reports nötig',
    comparePlansFeatures: 'Abo vergleichen und alle Funktionen ansehen',
    changePlanTip: 'um Änderungen vorzunehmen',
    audienceTokensMonthly: 'Tokens pro Monat',
    typeYourKeyword: 'Keyword eingeben',
    addNewValueToSelect: value =>
      `Klicke hier oder drücke die Tabulatortaste, um "${value}" hinzuzufügen`,
    cantAddManyPlatformsToCompare:
      'Du kannst keine Profile von mehr als einer Plattform hinzufügen',
    useAnotherCollection: 'Bitte verwende eine andere Collection',
    toClearAllComparedProfiles: 'um alle Profile aus der Liste der verglichenen Profile zu löschen',
    or: 'oder',
    comparedProfilesLimitReach: 'Das Listenlimit für verglichene Profile ist erreicht',
    compareTiktokCommingSoon: 'Der Vergleich von TikTok-Profilen kommt bald...',
    compareYouTubeComingSoon: 'Der Vergleich von YouTube-Profilen kommt bald...',
    audienceSearchForTikTokCommingSoon: 'Audience-Suche für TikTok kommt bald...',
    addToFavorites: 'Zu Favoriten hinzufügen',
    getAddToFavorites: isAdded =>
      isAdded ? 'Zu Favoriten hinzugefügt' : 'Zu den Favoriten hinzufügen',
    removeFromFavorites: 'Aus Favoriten entfernen',
    noPublicEmail: 'Profil hat keine E-Mail Adresse',
    addToCompareProfiles: 'Zum Vergleich hinzufügen',
    removeFromCompareProfiles: 'Aus der Vergleichsliste entfernen',
    clearAll: 'Alles löschen',
    clearList: 'Liste leeren',
    basicInfo: 'Basisinformation',
    scores: 'Scores',
    monthGrowthFollowers: 'absolutes Wachstum (Follower) 30d',
    averageLikes: 'Durchschnittliche Likes',
    averageComments: 'Durchschnittliche Kommentare',
    quality: 'Qualität',
    audience: 'Audience',
    averageAgeIs: age => `Ø ${age} Jahre`,
    noCompareForPrivateProfile:
      'Dieses Profil wurde privat und kann nicht zur Vergleichsliste hinzugefügt werden',
    searchByAudience: 'Suche nach Audience',
    useAudienceSearch: 'die Audience-Suche zu nutzen',
    useMinimumGrowthRate: 'die Mindestwachstumsrate',
    useEngagementGrowthRate: 'die Engagement Rate',
    pro: 'Pro',
    commingSoon: 'Kommt bald...',
    clearInput: 'Eingabe löschen',
    minAudienceFromCountry: 'Min Audience aus dem Land',
    minAudienceUsingLanguage: 'Min Audience mit Sprache',
    pleaseSelectCountry: 'Bitte Land auswählen',
    pleaseSelectLanguage: 'Bitte Sprache auswählen',
    audienceByRealPeople: 'Echte Follower',
    real: 'echt',
    audienceByAvgAge: 'Audience nach Altersschnitt',
    audienceByGender: 'Audience nach Geschlecht',
    audienceByLanguage: 'Audience nach Sprachgebrauch',
    subUserSettingsTip: 'Bitte wende dich an den Admin, um Änderungen vorzunehmen.',
    companyMembers: 'Zusätzliche Nutzer',
    companyMembersAbleTo: 'Zusätzliche Nutzer können',
    companyMembersNotAbleTo: 'Zusätzliche Nutzer sind NICHT in der Lage',
    companyMembersRules: {
      able: [
        'alle Funktionen deines Pro-Kontos nutzen',
        'die Token deines Kontos nutzen',
        'Creator zu öffentlichen Collections hinzufügen oder löschen',
        'öffentliche Kommentare hinzufügen und Bewertungen abgeben',
        'eigene Collections erstellen',
      ],
      notAble: [
        'Rechnungsdaten einsehen, ändern oder das Abo bearbeiten',
        'weitere Nutzer hinzufügen',
      ],
    },
    ableToAddMoreMembers: num =>
      `Du kannst noch ${num} Nutzer hinzufügen. Bitte gib\' eine E-Mail ein und wir werden den Nutzer benachrichtigen.`,
    unableToAddMoreMembers: 'Du hast das Limit der zusätzlichen Nutzer erreicht',
    enterCompanyMemberEmail: 'E-Mail des Nutzers',
    publicCollection: 'öffentliche Collection',
    privateCollection: 'private Collection',
    makePublic: 'Öffentlich stellen',
    makePrivate: 'Privat stellen',
    makeCollectionPublic: 'Collection öffentlich machen',
    makeCollectionPrivate: 'Collection privat machen',
    areYouSure: 'Bist du sicher',
    collectionWillBecomePrivateMainUser:
      'Die Collection wird privat gestellt und die weiteren Nutzer innerhalb des Unternehmens können nicht mehr darauf zugreifen',
    collectionWillBecomePublicMainUser: 'Collection wird unternehmensweit geteilt',
    collectionWillBecomePublicSubUser:
      'Deine Collection wird unternehmensweit verfügbar sein. Diese Aktion kann nicht rückgängig gemacht werden',
    resetsOn: 'Wird zurückgesetzt am',
    tokensWillBeAddedOnReports: 'Reports werden zurückgesetzt am',
    tokensWillBeAddedOnAnalytics: 'Limits werden zurückgesetzt am',
    trialEndsOn: 'Trial endet am',
    tokensResetOn: 'Wird zurückgesetzt am',
    moreTokensNow: count => `${count} zusätzliche Tokens für ${count === 50 ? 299 : 399} eur`,
    paymentForTokens:
      'Deine Zahlungsmethode wird sofort belastet und die zusätzlichen Tokens sind sofort verfügbar.',
    wantToContinue: 'Möchtest du fortfahren? ',
    getValidTokens: tokens => `+${tokens} Token gültig bis `,
    valid30Days: 'gültig 30 Tage - einmalige Zahlung - kein Abo - beliebig oft nutzbar',
    getMoreTokens: 'Keine Tokens mehr? Kein Problem!',
    thanksForSignUp: 'Danke fürs Anmelden! ',
    weWillGetInTouch:
      'Wir werden uns in Kürze bei dir melden. Bitte halte dein Postfach im Blick. Sobald wir wissen, welche Features für dich interessant sind, werden wir deinen maßgeschneiderten Trial freischalten.',
    youAreWelcomeToSchedule:
      'Um schneller mit uns in Kontakt zu kommen, kannst du hier gleich eine Demo vereinbaren.',
    scheduleAppDemo: 'Demo vereinbaren',
    invitationToFreeDemo: 'Einladung zur Demo',
    invitationText:
      'Hallo, schön dich an Bord zu haben! Deine Testversion ist jetzt 7 Tage lang aktiv und du kannst alle Funktionen von influData testen.',
    salesManagerText: sale =>
      `Ich bin ${sale} und würde dir gerne den vollen Umfang und die Möglichkeiten unserer Plattform zeigen. Interessiert? Vereinbare einfach eine kurze Demo mit mir. Jeder in deinem Team ist willkommen.`,
    scheduleLater: 'später',
    latestInstagramPictures: 'Neueste Instagram-Posts',
    latestTikTokPosts: 'Neueste TikTok-Posts',
    latestYoutuBeVideos: 'Neueste YouTube-Videos',
    latestYouTubeShorts: 'Neueste YouTube-Shorts',
    privateNotes: 'Kommentare',
    additionalInfo: 'Zusätzliche Informationen',
    addNewField: 'Neues Feld hinzufügen',
    youCanAddPrivateNotes:
      'Hier kannst du Kommentare für dich oder deine Teammitglieder hinzufügen',
    fieldName: 'Feldname',
    createField: 'Feld erstellen',
    saveField: 'Feld speichern',
    editField: 'Feld bearbeiten',
    duplicateField: 'Feld duplizieren',
    deleteField: 'Feld löschen',
    detailedQualityRating: 'Detaillierte influData-Scores',
    avg: 'durchschn',
    fieldType: 'Typ',
    typeInText: 'Bitte Text eingeben',
    typeInNumber: 'Bitte Zahl eingeben',
    pasteLink: 'Bitte Link einfügen',
    addAnOption: 'Option hinzufügen',
    comparisonToSimilarInfluencers: 'Vergleich mit ähnlichen Creatorn',
    whatDoesThisScoreMean: 'Was bedeutet dieser Score',
    infludataRating: 'Score',
    activity: 'Aktivität',
    everySecondDay: 'Jeden zweiten Tag',
    audienceEngagement: 'Engagement der Audience',
    audienceEngagementOverall: 'AUDIENCE ENGAGEMENT (ALLGEMEIN)',
    audienceEngagementVideos: 'Audience Engagement Videos',
    audienceEngagementShorts: 'Audience Engagement Shorts',
    ofViewers: 'der Viewer',
    lastNDays: n => `letzte ${n} Tage`,
    orLess: 'oder weniger',
    orMore: 'oder mehr',
    authenticity: 'Authentizität',
    moreThan: 'Mehr als',
    lessThan: 'Weniger als',
    brandValue: 'Brand-Value',
    sponsoredPostsAndStories: 'Posts, Stories & Reels mit Brand-Mentions',
    sponsoredPostsDesc:
      'Brands, die in den letzten 180 Tagen in Stories, Posts und Reels getaggt wurden:',
    none: 'keiner',
    perWeek: 'pro Woche',
    ofAllPostsAreSponsored: 'aller Posts enthalten Brand-Mentions',
    ofAllStoriesAreSponsored: 'aller Stories enthalten Brand-Mentions',
    ofAllReelsAreSponsored: 'aller Reels enthalten Brand-Mentions',
    smartMediaValue: 'Smart Media-Value',
    perPost: 'pro Post',
    perStory: 'pro Story',
    perReel: 'pro Reel',
    perVideo: 'pro Video',
    perShort: 'pro Short',
    per1000Views: 'pro 1000 Aufrufe',
    inCurrency: (c = 'USD') => `in ${c}`,
    found: 'gefunden',
    possible: 'möglich',
    notFound: 'nicht gefunden',
    appearsIn: 'erscheint in',
    reels: 'Reels',
    yourTargetAudience: 'Deine Zielgruppe',
    editValues: 'Werte bearbeiten',
    followersWithinTargetGroup: 'Follower innerhalb deiner Zielgruppe',
    countries: 'Länder',
    age: 'Alter',
    fromNToN: (from, to) => `Von ${from} bis ${to}`,
    audienceCountries: 'Herkunft der Audience',
    audienceGender: 'Geschlecht der Audience',
    audienceAge: 'Alter der Audience',
    apply: 'Speichern',
    profileData: 'Über das Profil',
    playsPerReel: 'Plays pro Reel',
    engagementPosts: 'Engagement (Posts)',
    engagementReels: 'Engagement (Reels)',
    engagementSponsored: 'ER (Sponsored Content)',
    engagementCombined: 'Engagement (kombiniert)',
    month: 'Monat',
    months: 'Monate',
    year: 'Jahr',
    years: 'Jahre',
    thisIsProFeature: 'Dies ist eine Pro-funktion',
    checkTheProPackage: 'Verfügbar im Pro-Paket',
    pleaseSubscribeToPro: 'Bitte abonniere unser Pro-Paket, um die Audience anzuzeigen',
    influencerAlreadyInCollection: 'Creator ist bereits in dieser Collection',
    youReachedTheLimitOfAdditionalFields: 'Du hast das Limit der zusätzlichen Felder erreicht',
    maxAvailableAdditionalFields:
      'Bitte setze dich mit unserem Support in Verbindung, um weitere Felder hinzuzufügen',
    youReachedTheLimitOfAdditionalFieldsInYourPlan: 'Du hast das Limit an Feldern erreicht',
    getSpaceForAdditionalFields: 'Upgrade auf Pro um weitere Felder zu erhalten.',
    upgradeToPro: 'Upgrade auf Pro',
    toSeePrivateNotes: 'um private Notizen zu verwenden',
    whatAreThePrivateNotes: 'Was sind die Private Notizen und warum sind sie hilfreich?',
    addNote: 'Notiz hinzufügen',
    pinNote: 'Notiz pinnen',
    unpinNote: 'Notiz unpinnen',
    editNote: 'Notiz bearbeiten',
    deleteNote: 'Notiz löschen',
    save: 'Speichern',
    from: 'von',
    noDataForQualityRating: 'Es sind leider keine Daten verfügbar.',
    noDataForSmartMediaValue: 'Wir haben leider für den Media-Value leider nicht genügend Daten.',
    nPostsPerWeek: n => `${n} Posts pro Woche`,
    nStoriesPerDay: n => `${n} Storys pro Tag`,
    nReelsPerWeek: n => `${n} Reels pro Woche`,
    nOfFollowers: n => `${n} der Follower`,
    nOfFollowersSusp: n => `${n} der Follower verdächtig`,
    nComments10000Followers: n => `${n} Kommentare pro 10.000 Follower`,
    nLast30Days: n => `${n} (Letzte 30 Tage)`,
    nPlaysOnAverage: n => `${n} Plays im Schnitt`,
    nOfViewers: n => `${n} der Viewer`,
    nHoursPerReel: n => `${n} Stunden pro Reel`,
    nEverySecondDay: n => `${n} Jeden zweiten Tag`,
    nCommentsPositive: n => `${n} der Kommentare sind positiv`,
    nCommentsNegative: n => `${n} der Kommentare sind negativ`,
    nVideosPerWeek: n => `${n} Videos pro Woche`,
    nShortPerWeek: n => `${n} Shorts pro Woche`,
    nVideoViewsGrowth: n => `${n} Wachstum der Viewzahlen`,
    nViewsOnAverage: n => `${n} Views im Schnitt`,
    nSecondsOnAverage: n => `${n} Sekunden im Schnitt`,
    nComments100000Followers: n => `${n} Kommentare pro 100.000 Follower`,
    nFollowerGrowth: n => `${n} Followerwachstum`,
    postEngagement: 'Post-Engagement',
    toSeeDetailedInfludataRating: 'um detaillierte influData-Scores zu sehen',
    whatIsDetailedInfludataRating:
      'Was sind detaillierte influData-Scores und warum sind sie hilfreich?',
    average: 'Schnitt',
    toSeeStatistic: 'um die Statistiken zu sehen',
    whatIsInfludataStatistic: 'Was ist die influData-Statistik und warum ist sie hilfreich?',
    confirmAdditionalFieldDeletion:
      'Wird das Feld entfernt, dann verschwindet es ebenfalls bei allen anderen Creatorn und alle gespeicherten Werte gehen verloren. Bist du dir sicher, dass du dieses Feld entfernen möchtest?',
    generatedOn: 'Erstellt am',
    thisPdfMightBeOutdated: 'Dieses PDF ist möglicherweise veraltet',
    ifYouWantUpToDateInformation: 'um aktuelle Informationen abzurufen.',
    unlockReportToShowScore: 'Bitte entsperre den Audience Report, um diesen Score anzuzeigen',
    valuesBasedOnEstimations: 'basierend auf Schätzungen – reale Werte können abweichen',
    valuesBasedOnSubscribers: 'basierend auf den Abonnenten',
    inK: 'in k',
    pageNotFound: 'Seite nicht gefunden',
    week: 'woche',
    day: 'Tag',
    days: 'Tage',
    list: 'Liste',
    audienceCollection: 'Audience',
    overlapCollection: 'Überschneidung',
    totalInfluencers: 'Creator einbezogen',
    onlyNFromNInfluencersAreUnlocked: (n1, n2) =>
      `Nur ${n1} von ${n2} Reports sind freigeschalten.`,
    includingNFromNOfInfluencers: (n1, n2) => `Report enthält ${n1} von ${n2} Creator.`,
    unlockAll: 'Alle entsperren',
    toShowExactMetrics: 'um vollständige Statistiken anzuzeigen.',
    followersInTotal: 'Follower gesamt',
    expectedReach: 'Reichweite gesamt',
    allInfluencersAreUnlocked: 'Alle Creator-Reports sind freigeschalten.',
    overlapUpTo50Influencers:
      'Wir können nur die Überschneidung von bis zu 50 Creatorn anzeigen. Bitte erstelle eine neue Collection, um die Überschneidung von mehr Creatorn zu sehen.',
    overallComparison: 'Gesamtüberschneidung',
    individualComparison: 'Individuelle Überschneidung',
    graphDescrOveral: `Dieser Graph zeigt die
      Homogenität aller Follower innerhalb dieser Collection.`,
    graphDescrIndividual: `Dieses Diagramm zeigt, welcher Teil der Follower beim Vergleich zweier Creator gleich ist.`,
    individualComparisonHoverDescr:
      'Bewege den Mauszeiger über den Schnittpunkt zwischen zwei Infuencern, um Details auf der rechten Seite zu sehen.',
    collectionAudienceOverlapTitle: 'Überschneidung der Audiences (%)',
    overalChartCalculatedTitle: 'Wie wird dieser Wert berechnet?',
    vennDiagramBottomTitle: 'der Follower überschneiden sich',
    overallCompareModalTitle: 'Wie wird dieser Wert berechnet?',
    overallCompareModalText:
      'Die Prozentzahl gibt den Anteil der Follower wider, welche mindestens zwei Creatorn folgen, die sich in dieser Collection befinden.',
    uniqueFollowers: 'Follower (einmalig)',
    uniqueReach: 'Reichweite (einmalig)',
    missingReports: 'Fehlende Reports werden in Kürze verfügbar sein.',
    modalUnlockReportsTitle: `Audience Reports Entsperren`,
    modalUnlockReportsBody: reports =>
      `Diese Reports werden von den verfügbaren Reports abgezogen. Anzahl der Reports: ${reports}`,
    updateProfileManually: 'Profil manuell aktualisieren',
    added: 'Hinzugefügt',
    updateProfileManuallyWasClicked: 'Bitte in ein paar Minuten aktualisieren',
    featureIsNotAvailable: 'Diese Funktion ist in Ihrem Tarif nicht verfügbar.',
    updateProfileManuallyWasClicked: 'Bitte einige Minuten warten',
    blockUserTipsFirstPart:
      'Für zukünftige Suchen sperren. Du kannst die Blockierung von Benutzern jederzeit aufheben.',
    blockUserTipsLastPart: 'Schwarze Liste anzeigen',
    blockCollectionDecription:
      'Diese Creator werden nicht in zukünftigen Suchen auftauchen. Du kannst sie aber jederzeit wieder freischalten und die Creator werden wieder erscheinen. Bitte beachte, dass diese Blacklist nur für dich und nicht unternehmensweit gilt.',
    removeFromBlackList: 'Von der schwarzen Liste entfernen',
    accountType: 'Account Typ',
    regularShortsAndCreators: 'Shorts Creator & Regular Creator',
    shortCreatorsOnly: 'Nur Shorts Creator',
    videoViews: 'Video-Aufrufe',
    shortsViews: 'Shorts-Aufrufe',
    subscribers: 'Subscribers',
    videos: 'Videos',
    viewsMedian: 'Views - Median',
    statisticsShorts: 'STATISTIK - SHORTS',
    statisticsVideos: 'STATISTIKEN - VIDEOS',
    averageViews: 'Durchschnittliche Views',
    negative: 'negativ',
    positive: 'positiv',
    neutral: 'neutral',
    wordCloudComments: 'Word Cloud - Comments',
    disabledFunctionInYourPlanTip:
      'Diese Funktion ist in deinem Abo nicht enthalten. Bitte kontaktiere unseren Support.',
    socialPlatformDisabledTip:
      'Plattform nicht in deinem Abo enthalten. Bitte kontaktiere unseren Support.',
    profileToolDisabledTip:
      ' Diese Funktion ist in deinem Abo nicht enthalten. Bitte kontaktiere unseren Support.',
    collectionDisabledTip:
      'Collections sind in deinem Plan nicht enthalten, bitte kontaktiere unseren Support.',
    addUserToBlackListTip: 'Benutzer blocken',
    reportIsUpToDate: 'Report ist aktuell',
    reportIsOutDated: 'Report ist veraltet',
    refreshingReport:
      'Wir führen im Moment eine Aktualisierung durch und die Daten könnten sich innerhalb von 15 Minuten ändern.',
    noDataAvailable: 'Keine Daten verfügbar',
    logInForUseSearchUsers:
      'Du musst eingeloggt sein und ein aktives Abonnement haben, um unsere Suche nutzen zu können',
    isAuthViewProfilePageWithoutPlan:
      'Entschuldigung, du musst angemeldet sein und ein aktives Abonnement haben, um Creator-Profile zu sehen.',
    isNoAuthViewProfilePageOrWithoutPlan:
      'Entschuldigung, Sie müssen angemeldet sein und ein aktives Abonnement haben, um Creator-Profile zu sehen.',
    wordCloudTip:
      'Diese Word Cloud zeigt die am häufigsten genannten Wörter in den Kommentaren zu den Videos/Shorts an. Je größer das Wort hier dargestellt ist, desto öfter kam es in den Kommentaren vor.',
    whatDoesThisWordCloudMean: 'Was bedeutet die Wordcloud?',
    whatDoesThisAudienceTypeMean: 'Was bedeutet die Audience-Aufteilung',
    audienceTypeTip: {
      [USER_INDEXES.instagram]:
        'Diese Aufteilung zeigt, welcher Anteil der Follower eines Creators Echte Follower, Verdächtige/Inaktive Follower, Massen-Follower oder Creator sind. Echte Follower zeigen aktives und gesundes Verhalten, wie es von normalen Konten zu erwarten ist. Verdächtige/Inaktive Follower haben Konten die nicht von realen Personen sein werden, weil sie z. B. keine Biographie oder kein Profilbild aufweisen oder inaktiv auf der Plattform sind. Massen-Follower folgen mehr als 1.500 Personen und sind damit keine klassischen Follower. Creator haben Accounts, die selbst mehr als 2.000 Follower haben und sind damit ebenfalls keine regulären Follower.',
      [USER_INDEXES.youtube]:
        'Diese Aufteilung zeigt, welcher Anteil der Kommentare auf die Videos/Shorts tendenziell positiv, neutral oder negativ war.',
    },
    reach: 'Reichweite',
    valueInEur: 'Wert in EUR',
    uploaded: 'Hochgeladen',
    estimatedReach: 'Geschätzte Reichweite',
    contentSearchNotAvailable: 'Unsere Inhaltssuche ist für TikTok derzeit nicht verfügbar.',
    locationRadius: 'Radius',
    tipSelectLocation: 'Bitte Standort auswählen',
    selectLocation: 'Ort auswählen',
    noLocationFound: 'Kein Standort gefunden',
    showMap: 'Karte anzeigen',
    hideMap: 'Karte ausblenden',
    pleaseSelectCategories: 'Bitte wähle eine Kategorie aus',
    sponsoredPostsAndStoriesPdf: 'GESPONSERTE BEITRÄGE / STORIES / REELS',
    sponsoredPostsAndStoriesDescPdf:
      'Dies sind Brands, die in den letzten 30 Tagen in Stories, Posts und Reels getaggt wurden:',
    timelineIsNotAvailable: 'Timeline ist nicht verfügbar',
    exportProfileAsAPdf: 'Profil als PDF exportieren',
    pleaseChoseYourOption: 'Bitte wähle die Optionen',
    modalPdfOptions: {
      ['hideYourCustomFields']: 'Deine Custom Fields ausblenden',
      ['showQualityScores']: 'Quality Scores einfügen',
      ['showAudienceReport']: 'Audience Report einfügen',
      ['showFirstPage']: 'Erste (Übersichts-)Seite einfügen',
      ['showFollowerTrendGraph']: 'Follower-Trend-Diagramm einfügen',
      ['includeInfludataBranding']: 'InfluData-Branding einfügen',
      ['showBrandMentions']: 'Brand Mentions einfügen',
      ['showSmartMediaValue']: 'Smart Media Value einfügen',
    },
    exportAsPdf: 'Als PDF exportieren',
    exportAsPPX: 'Als PPX exportieren',
    tableHeadLabels: {
      username: 'Username',
      displayName: 'Name',
      profileScore: 'Profilescore',
      country: 'Land',
      city: 'Stadt',
      followers: 'Followers',
      language: 'Sprache',
      engagementMean: 'Engagement-Rate',
      monthlyGrowthFollowers: 'Monatliches Wachstum (Followerzahl)',
      monthlyGrowthPercentage: 'Monatliche Wachstumsrate',
      impressions: 'Impressionen',
      dateAdded: 'Datum hinzugefügt',
      stars: 'Stars',
      _index: 'Plattform',
      comment: 'Kommentar',
    },
    editFields: 'Felder bearbeiten',
    viral: 'Viraler Content',
    uploadedContent: 'Neuste',
    previewVideo: 'Video-Preview',
    expectedAudiencePerPosts: 'Erwartete Audience pro Post',
    downloads: 'Herunterladen',
    infoForCollaborations: 'Info für Kooperationen',
    contactInformation: 'Kontaktinformationen',
    management: 'Management',
    contactForm: 'Kontakt-Link',
    verifiedInformation: 'Geprüfte Informationen',
    shorts: 'Shorts',
    averageViewsGrowthVideos: 'Durchschnittliches Wachstum der Aufrufe (Videos)',
    averageViewsGrowthShorts: 'Durchschnittliches Wachstum der Aufrufe (Shorts)',
    growthByAverageViews30dVideos: 'Wachstum nach durchschnittlichen Views 30 Tage (Videos)',
    growthByAverageViews30dShorts: 'Wachstum nach durchschnittlichen Views 30 Tage (Shorts)',
    loggedMultiplePartFirst: 'Du bist mehrfach mit dem Konto eingeloggt.',
    loggedMultiplePartLast:
      'Bitte melde dich von allen aktuellen Sitzungen ab und versuche es erneut.',
    addAllToComparison: 'Alle zum Vergleich hinzufügen',
    amountOfInfluencers: 'ANZAHL DER CREATOR, MIT DENEN DU DERZEIT ZUSAMMENARBEITEST',
    egAmountOfInfluencers: 'z. B. bei der Durchführung von Kampagnen, bei der Zusammenarbeit usw.',
    loggedMultiplePartLast:
      'Bitte melde dich von allen aktuellen Sitzungen ab und versuche es erneut.',
    googleAuthErrorText:
      'Es ist ein Fehler aufgetreten. Versuche es mit der Autorisierung/Registrierung per E-Mail.',
    removeContentPieces: `Wenn du das Content Piece aus dem Campaign Reporting entfernst, wird es nicht mehr in Dashboards
    und Analysen berücksichtigt.`,
    pageNotAllowed: 'Seite nicht erlaubt',
    goHome: 'Zum Home-Screen',

    /* new campaigns */
    filedLabels: {
      cpm_organic_all: 'CPM (Organisch) Alle',
      cpm_organic_ig: 'CPM (Organisch) Instagram',
      cpm_organic_tt: 'CPM (Organisch) TikTok',
      cpm_organic_yt: 'CPM (Organisch) YouTube',
      cpe_organic_all: 'CPE (Organisch) Alle',
      cpe_organic_ig: 'CPE (Organisch) Instagram',
      cpe_organic_tt: 'CPE (Organisch) TikTok',
      cpe_organic_yt: 'CPE (Organisch) YouTube',
      custom_tracking_counts: 'Benutzerdefinierte Trackings',
      goal_reached_widgets: 'Erreichte Ziele',
      reached_audience_country: 'Nach Land',
      reached_audience_language: 'Nach Sprache',
      reached_audience_gender: 'Nach Geschlecht',
      reached_audience_age: 'Erreichte Audience nach Alter',
      reached_audience_city: 'Nach Stadt',
      creators_in_campaigns_count: 'Aktive Creator',
      published_content_count: 'Veröffentlichte Inhalte',
      reached_users_sum: 'Erreichte Audience (Reach)',
      received_likes_count: 'Erhaltene Likes',
      received_comments_count: 'Erhaltene Kommentare',
      most_liked_comments: 'Kommentare mit den meisten Likes',
      latest_comments_with_brand: 'Neueste Kommentare mit Brand-Mentions',
      latest_comment_summaries_with_brand: 'Neueste Zusammenfassungen von Kommentaren',
      published_content_elements: 'Neueste veröffentlichte Inhalte & Reichweite',
      published_content_time_stacked_bar: 'Veröffentlicht nach Datum und Format',
      most_successful_creators_reach_stacked_bar: 'Erfolgreichste Creator nach Reichweite (Top 15)',
      most_used_keywords_bar: 'Meistgenutzte Keywords, Links und Erwähnungen',
      uploaded_content_news: 'Hochgeladene Inhalte',
      published_content_reach_linechart: 'Veröffentlichte Inhalte & Reichweite',
      widget_brand_account_growth: 'Follower-Wachstum',
      widget_earned_media_value: 'Earned Media Value',
      emv_all: 'EMV ALL',
      emv_ig: 'EMV Instagram',
      emv_tt: 'EMV TikTok',
      emv_yt: 'EMV YouTube',
      content_by_reach_bucket_graph: 'Veröffentlichte Inhaltsbeiträge nach Reichweite',
      geolocation_map: 'Tagged Ort Ihrer Kampagne',
      semantic_graph_brandfocus: 'Kommentar Markenfokus',
      semantic_graph_controversial: 'Kontroversitätsrate',
      semantic_graph_positivity: 'Positivitätsrate',
      main_metrics_table: 'Tabelle der Metriken'
    },
    ofTotalSelectedCreators: '(der gesamten ausgewählten Creator)',
    addANewCreator: 'Neuen Creator hinzufügen',
    typeUsername: 'Username eingeben',
    noCreatorsFound: 'Keine Creator gefunden',
    somethingWentWrong: 'Etwas ist schief gelaufen',
    notAllCreatorsAssignedPricing: c =>
      `Noch wurde nicht allen Creatorn ein Preis zugewiesen.\n Bitte beachte, dass dies möglicherweise die tatsächlichen ${c}-Berechnungen verzerren könnte.`,
    cpmInfo:
      'CPM, oder Kosten pro Tausend (TKP), repräsentiert die Kosten für 1.000 Impressionen. Es ist eine übliche Effizienzmetrik in der digitalen Werbung.\n\nAuf unserer Plattform berechnen wir den CPM basierend auf dieser Formel:\n\nCPM = (Preis für den Creator / Reichweite) * 1000\n\nDas bedeutet, wir nehmen den an den Creator gezahlten Preis, teilen ihn durch die erreichte Reichweite und multiplizieren das Ergebnis mit 1000.\n\nBitte beachte, dass wenn du einen Festpreis für eine Kampagne festlegst, der CPM sinkt, sobald mehr Inhalte veröffentlicht werden. Dies liegt daran, dass die Gesamtkosten über eine größere Anzahl von Impressionen verteilt werden, was die Kosten pro Tausend reduziert.',
    cpeInfo:
      'CPE, oder Kosten pro Engagement, repräsentiert die Kosten für eine Interaktion (Kommentar, Like, Teilen) mit einem Content Piece. Es ist eine übliche Effizienzmetrik im Creator-Marketing.\n\nAuf unserer Plattform berechnen wir den CPE basierend auf dieser Formel:\n\nCPE = Preis für den Creator / Summe aller Engagements\n\nDas bedeutet, wir nehmen den an den Creator gezahlten Preis und teilen ihn durch die Summe der erzielten Likes, Kommentare und Shares.\n\nBitte beachte, dass wenn du einen Festpreis für eine Kampagne festlegst, der CPE sinkt, sobald mehr Inhalte veröffentlicht werden. Dies liegt daran, dass die Gesamtkosten über eine größere Anzahl von Interaktionen verteilt werden, was die Kosten pro Engagement reduziert.',
    emvInfo:
      'EMV, oder Earned Media Value, schätzt den Wert von nicht-bezahlter Exposition und Interaktionen in den sozialen Medien.\n\nAuf unserer Plattform berechnen wir den EMV mit dieser Formel:\n\nEMV = (Reichweite (geschätzt oder real) / 1000) * Monetärer Wert pro 1000 Aufrufe\n\nDas bedeutet, wir multiplizieren die Reichweite mit einem festgelegten monetären Wert für 1000 erreichte Nutzer. Der zugewiesene Wert kann variieren, abhängig von der Zielgruppe, Engagementrate, Nische des Creators, Wachstum und mehr.\n\nObwohl der EMV keine genaue Darstellung des tatsächlichen Umsatzes ist, dient er als nützliche Metrik, um die Wirksamkeit verschiedener Marketingstrategien zu vergleichen.',
    keywordsMentionInputHelperTextTop: 'wird automatisch hinzugefügt.',
    keywordsMentionInputHelperTextMiddle: 'Links funktionieren auch als Teil von Links',
    keywordsMentionInputHelperTextBottom: 'Groß- und Kleinschreibung wird nicht beachtet.',
    editA: 'Bearbeite',
    addNew: 'Neu hinzufügen',
    searchTerm: 'Suchbegriff',
    selectType: 'Typ auswählen',
    inputSearchTerm: 'Suchbegriff eingeben',
    mention: '@ Erwähnung',
    freeText: 'Freier Text',
    keywordsMentionsManager: 'Keyword & Mention Manager',
    keywordsMentionsManagerSubTitle:
      'Richte Hashtags, Mentions oder benutzerdefinierte Texte ein, um Inhalte von Creator in der Kampagne zu verfolgen.\n Du kannst diese Elemente jederzeit ändern, und die Änderungen werden rückwirkend ab dem Start der Kampagne angewendet.\n Bitte beachte, dass sich alle Anpassungen auch sofort auf deine aktuellen Dashboards auswirken!',
    addNewTerm: 'Hinzufügen',
    type: 'Typ',
    lastActivity: 'Letzte Aktivität',
    rowsPerPage: 'Zeilen pro Seite:',
    dense: 'Dense',
    delete: 'löschen',
    edit: 'Bearbeiten',
    missingCreators: 'Fehlende Creator',
    createBtnText: 'Erstellen',
    aCustomField: 'ein Custom Field',
    newCustomFields: 'Neues Custom Field',
    customFields: 'Custom Fields',
    customFieldPageTitle: `Du kannst verschiedene Custom Fields einrichten, die du für deine Kampagnen nachverfolgen möchtest.\n Dies kann für Käufe, Klicks, Website-Besuche usw. verwendet werden.\n Du kannst die Ereignisse entweder manuell tracken oder einen Webhook für das automatische Tracking einrichten.`,
    customFieldName: 'Custom Field',
    metric: 'Metrik',
    metricName: 'Metrik-Name',
    appliesTo: 'für',
    trackingStatus: 'Trackingstatus',
    lastTracking: 'Letzte Trackingzeit',
    selectDataFormat: 'Datenformat auswählen',
    customFieldTitle: 'Titel des Custom Fields',
    typeMetricName: 'Name des Typs',
    customFieldTitleHelperText:
      'Name des Feldes, z.B. "Story Clicks to Website", "Kaufbetrag" usw. Wir empfehlen, so präzise wie möglich zu sein, um spätere Verwechslungen zu vermeiden',
    typeMetricNameHelperText: 'Name der Metrik, z.B. "Besuche", "EUR", "Klicks" usw.',
    customFieldSpecificTitle: `Bezieht sich das Feld direkt auf Content-Pieces (Klicks, Views, etc.), auf einzelne Creator (Verkäufe pro Creator, etc.) oder nur auf Kampagnenebene (Traffic durch Kampagne, etc.).`,
    customFieldSpecificSubTitle: `'Bitte wähle den Typ, den du individuell tracken kannst. Höhere Ebenen können immer mit den zugrunde liegenden Daten berechnet und angezeigt werden. Wenn du z. B. die Daten von Verkäufen auf einzelne Content Pieces zurückverfolgen und tracken kannst, können Metriken für Creator und Kampagnen berechnet werden, aber nicht umgekehrt.`,
    customFieldMetricAppliesTitle: 'Bitte wähle aus, für welchen Inhalt diese Metrik gilt',
    youCanChooseMoreThanOneOption: 'Du kannst mehr als eine Option auswählen.',
    addCreatorsLimitMessage: `Du hast dein Creator-Limit erreicht.\n Bitte kontaktiere den Support.`,
    addValueManuallyHoverText: `Manuelles Hinzufügen von Werten ist hier nur für Custom-Fields in Bezug auf Kampagnen oder Creator möglich.\n Um Werte für Felder auf Basis einzelnen Contents hinzuzufügen, gehe bitte zum "Content View" und wähle das Content Piece aus, für den du einen Wert hinzufügen möchtest.`,
    value: 'Wert',
    addValueManuallyFormHelperText:
      'Du kannst manuell Werte für Custom Felder hinzufügen, und wir werden diese Werte in Dashboards aufnehmen.\n Du kannst auch negative Werte hinzufügen, um falsche Werte zu korrigieren.',
    chooseCreators: 'Creator auswählen',
    forCustomField: 'für benutzerdefiniertes Feld',
    addValueManually: 'Wert manuell hinzufügen',
    editCustomField: 'Benutzerdefiniertes Feld bearbeiten',
    deleteCustomField: 'Benutzerdefiniertes Feld löschen',
    addWebhookTracking: 'Webhook-Tracking hinzufügen',
    reachedCreatorLimit: `Du hast da Limit der Creator erreicht.\n Bitte kontaktiere uns, um das Limit zu erhöhen.`,
    airTableIsNotCreated: `Airtable ist noch nicht erstellt. Dies dauert normalerweise bis zu 24 Stunden. Bitte kontaktiere uns im Chat, wenn hier nach einem Tag nichts angezeigt wird.`,
    airTableUseAllFeatures:
      'Kontaktiere uns, um Zugang zur Airtable Base zu bekommen, um individuelle Anbindungen, Workflows und Extentions zu nutzen. Bitte besuche airtable.com um einen vollen Überblick über die Features zu bekommen.',
    airTableAccessLink: `Klicke hier, um zur Airtable Base zu gelangen (Zugriff erforderlich).`,
    airTableCustomFields: `Airtable Ansicht`,
    airTableCustomFieldPageTitle: `Du kannst alle Daten auch in Airtable anzeigen und exportieren. Du kannst sortieren, filtern, suchen und die Ansicht anpassen`,
    airTableLimitStringLessThousand: n =>
      `Der Verlauf deiner Content Pieces ist für ${n} Tage sichtbar. Bitte kontaktiere unseren Support, um Content Pieces unbegrenzt zu sehen.`,
    airTableLimitStringMoreThousand:
      'Deine Content Pieces für die gesamte Kampagne sind unbegrenzt verfügbar.',
    geolocationMapTitle: 'Markierte Locations deiner Kampagne',
    geolocationMapSubheader: 'Nur für Instagram',
    geolocationMapClickToOpenInNewTab: 'In neuem Tab öffnen',
    notificationsIntervalOptions: {
      daily: 'Täglich',
      weekly: 'Wöchentlich',
      monthly: 'Monatlich',
      quarterly: 'Vierteljährlich',
    },
    selectDashboard: 'Dashboard auswählen',
    selectInterval: 'Intervall auswählen',
    emailRecipients: 'E-Mail-Empfänger (Du kannst bis zu 10 Mails hinzufügen)',
    notificationPageTitle: 'Benachrichtigungsmanager',
    notificationsPageDescription: `Konfiguriere E-Mail-Benachrichtigungen, um bestimmte Teammitglieder oder externe Benutzer auszuwählen und Auslöser festzulegen.\nFür erweiterte Benachrichtigungen und Automatisierungen, wie Content-Meilensteine oder die Integration mit Tools wie Slack, nutze die 'Bedingte Automatisierungen' in Airtable.com, verfügbar im linken Menü.`,
    clickEnterWarningNotifications:
      'Bitte bestätige mit Enter, nachdem du eine Email ins Feld getippt hast.',
    addNewNotifications: 'Neue Benachrichtigungen hinzufügen',
    notification: 'Benachrichtigung',
    notificationTableHead: {
      dashboard: 'Dashboard',
      interval: 'Intervall',
      recipients: 'Empfänger',
    },
    notificationPageRowInterval: n =>
      n === 'daily'
        ? 'täglich'
        : n === 'weekly'
        ? 'wöchentlich'
        : n === 'monthly'
        ? 'monatlich'
        : n === 'quarterly'
        ? 'quartalsweise'
        : 'jährlich',
    sentimentCharts: {
      brandFocus: {
        label: 'Kommentar-Fokus Verteilung (nach Creator)',
        legend: {
          brandFocusProduct: 'Brand/Produkt-Fokus',
          creatorFocus: 'Creator-Fokus',
          lowQuality: 'Niedrige Qualität',
          Other: 'Andere',
        },
        helperText:
          'Diese Metrik zeigt die durchschnittliche Verteilung des Kommentar-Fokus für jedes analysierte Content Piece, und kategorisiert sie in Bezug auf die Brand oder das Produkt, den Creator oder als von niedriger Qualität.\n\nSie gibt Dir einen Einblick, welcher Anteil der Kommentatoren tatsächlich auf das beworbene Produkt Bezug nimmt. Dies kann anzeigen, ob der beworbene Inhalt relevant genug dargestellt wurde. Umgekehrt könnte ein hoher Markenfokus darauf hinweisen, dass der Inhalt zu produktzentriert ist.\n\nDie Zahl in Klammern gibt die Gesamtzahl der für diese Berechnung verwendeten Content Pieces an.',
      },
      positivityRate: {
        label: 'Positivitätsrate (nach Creator)',
        legend: {
          positiveSentiment: 'Positiver Sentiment',
          negativeSentiment: 'Negativer Sentiment',
        },
        helperText:
          'Die Positivitätsrate stellt den durchschnittlichen Prozentsatz der Kommentare dar, die eine positive Stimmung oder positive Reaktionen auf die Inhalte zeigen.\n\nBeachte, dass dies nicht unbedingt auf die Marke oder das beworbene Produkt Bezug nimmt.\n\nDie Zahl in Klammern gibt die Gesamtzahl der für diese Berechnung verwendeten Content Pieces an.',
      },
      controversialRate: {
        label: 'Kontroversitätsrate (nach Creator)',
        legend: {
          controversial: 'kontrover',
          nonControversial: 'nicht kontrovers',
        },
        helperText:
          'Dieser Wert gibt den durchschnittlichen Prozentsatz der Kommentare an, die von den Creatorn als "kontrovers" kategorisiert werden.\nEin "kontroverser" Kommentar kritisiert oder hinterfragt in der Regel den Inhalt.\nDie Zahl in Klammern ist die Gesamtzahl der herangezogenen Content Pieces.',
      },
    },
    campaignCreatorOverview: {
      tableHead: {
        name: 'Name',
        platform: 'Plattform',
        reachInViews: 'Reichweite in Views',
        likes: 'Likes',
        matchMethod: 'Matching-Methode',
        lastActivity: 'Letzte Aktivität',
        added: 'Hinzugefügt',
        status: 'Status',
        collaborationPrice: `Gesamtpreis für Zusammenarbeit`,
        priceForPost: `Preis pro Beitrag (Video)`,
        priceForReel: `Preis pro Reel (YT Short)`,
        priceForStory: `Preis pro Story`,
        additionalCosts: `Zusätzliche Kosten (gesamt)`
      },
      statusOptions: {
        all: 'alle',
        active: 'aktiv',
        archived: 'archiviert',
        onboarding: 'onboarding',
      },
      popoverMenu: {
        viewEdit: 'Content anzeigen / bearbeiten',
        viewCreatorAnalytics: 'Creator im Dashboard anzeigen',
        editCreator: 'Creator bearbeiten',
        unarchiveCreator: 'Creator wieder aktivieren',
        archiveCreator: 'Creator archivieren',
        addNonRecognizedContent: 'Nicht gematchten Content hinzufügen',
        addAsBrandAccount: 'Als Brandaccount hinzufügen',
        removeAsBrandAccount: 'Als Brandaccount entfernen',
        addOnlyOneBrandAccount: `Du kannst im Moment nur einen Brand-Account hinzufügen.\nBitte entferne das aktuelle Profil.`,
        activateCreator: 'Creator aktivieren',
        deleteCreator: 'Creator löschen'
      },
      matchModal: {
        title: 'Content-Matching für Creator festlegen',
        contentTitle: `Manuell hinzugefügter Content bleibt im Dashboard und wird nicht überschrieben.\n\n"Phrases" ist die Standardoption und übernimmt gefundene Keywords, Mentions und Hashtags, die im Keyword & Mentions Manager eingestellt sind.\n"Keine" bedeutet, dass nur manuell hinzugefügte Inhalte einbezogen werden.\n"Alle" zieht jeden Beitrag seit dem Beitritt des Creators zur Kampagne hinzu.\n\nHinweis: Änderungen werden innerhalb von 24 Stunden im Dashboard angezeigt.`,
        selectTitle: 'Match-Typ auswählen',
        selectOptionsTitles: {
          phrase: 'Phrase',
          all: 'All',
          none: 'Keine',
        },
      },
      deleteModal: {
        attention: 'Achtung',
        bodyText: `Dadurch werden alle Daten für diesen Creator aus der Kampagne entfernt.`
      }
    },
    campaignContentOverview: {
      addToCampaign: 'Zur Kampagne hinzufügen +',
      addedToCampaign: 'Zur Kampagne hinzugefügt',
      titleRecognizedContent: 'Veröffentlichter Content',
      titleNonRecognizedContent: '- Nicht mit Keywords gematchter Content',
      subTitleRecognizedContent: 'Content Pieces gefunden (inkl. aller Storys von Story Groups)',
      subTitleTakesTimeUntilVisible: 'Bitte beachte, dass es bis zu 25 Stunden dauern kann bis der Content hier oder im Dashboard angezeigt wird!',
      subTitleNonRecognizedContent: `Alle hinzugefügten Content Pieces werden in deiner Kampagne erscheinen, es sei denn, du entfernst den
      Creator aus der Kampagne.`,
      export: 'Exportieren',
      exportTooltipText: `Um diese Ansicht zu exportieren, klicke bitte im linken Menü auf "Airtable View", dann auf "Views" > "Gallery" und dann auf das Dropdown-Menü "Gallery", um CSVs und PDFs des Inhalts zu exportieren. Sie können die Inhalte vorher filtern.`,
      creators: 'Creator',
    },
    cpmSettings: {
      titleModal: `Passe alle TKPs für deinen Business-Case an.`,
      titleInsideModal: `influData's TKP-Berechnung basiert auf der Audience und den Creator-Bewertungen. Sie passt möglicherweise nicht für jedes Unternehmen. Du kannst unsere Werte anpassen, wenn sie nicht stimmen. Dies beeinflusst alle zukünftige Berechnungen nur für deinen Account. Bitte passe sie nur an, wenn alle Media Values ungenau scheinen!`,
      confirmText: `*Standardwerte sind TKPs für Creator mit deutscher Audience und gutem Profil-Score`,
    },
    attentionDashboardModal: {
      titleModal: 'Achtung',
      titleInsideModal: 'Für deine Kampagne wird noch kein Content getrackt.',
      subtitleInsideModal: `Füge deiner Kampagne Keywords hinzu oder gehe zu "Creator Overview" und ändere die "Match Method" auf "All" für deine Creator, um Content zu tracken.`,
      btnText: `Gehe zum "Keywords & Mentions Manager"`,
    },
    wrongCampaign: {
      errorText: 'Angeforderte Kampagnen-ID ist nicht verfügbar',
      selectText: 'Bitte wähle eine verfügbare Kampagne',
    },
    campaignPipeline: {
      assignCreators: 'Creator zuweisen',
      assigned: 'Zugewiesen',
    },
    collectionOverlap: {
      btnTooltipTextDefault:
        'Bitte wähle zwischen 2 und 10 Profile aus, um einen Overlap zu erstellen.',
      btnTooltipTextDontMeetCriteria:
        'Du kannst zwischen 2 und 10 Profile von maximal einer Plattform hinzufügen.',
      btnText: number => `Overlap erstellen${number > 0 ? ` (${number})` : ''}`,
      modalOverlapContentText: `Wir werden die Überlappung für die ausgewählten Profile berechnen.\nDies kann bis zu 30 Minuten dauern. Du findest die Überlappung im linken Menü unter "Deine Overlaps"`,
      modalOverlapCofirmContentText: number =>
        `Es sind nicht für alle Profile die Audience Reports entsperrt. Da dies für den Overlap nötig ist, werden wir ${number} Profile entsperren und die Anzahl der Reports vom Kontingent abziehen.`,
      yourOverlaps: 'Deine Overlaps',
      tableFields: {
        platform: 'Plattform',
        creators: 'Creator',
        created: 'erstellt',
        overlap: 'Overlap',
      },
    },
    createCampaign: {
      createCampaignMainLabel: 'Erstelle Deine Kampagne in weniger als 2 Minuten',
      skip: 'Überspringen',
      continueWithoutBrandAccount: 'Ohne Brand-Account fortsetzen',
      add: 'Hinzufügen',
      steps: {
        one: {
          label: `Wie lautet der Name der Kampagne?`,
          helperText: `Dies wird verwendet, um die Kampagne in Zukunft zu identifizieren`,
          fieldLabel: `Kampagnenname`,
        },
        two: {
          label: `Wann soll Ihre Kampagne starten?`,
          optionLabels: {
          now: 'Jetzt',
          later: 'Später, inaktiv halten und manuell starten',
          laterKeepInactive: 'Später, inaktiv halten und Startdatum festlegen',
          subLabelLaterKeepInactive: `Ihre Kampagne wird nur gestartet, wenn zu diesem Zeitpunkt genügend Kampagnenplätze verfügbar sind.`,
          inThePast: 'In der Vergangenheit (ältere Inhalte werden hinzugefügt)',
          subLabelInThePast: `Nicht empfohlen. Höchstwahrscheinlich werden nicht alle Inhalte hinzugefügt, vor allem Instagram Stories könnten jetzt nicht mehr verfügbar sein. Achtung: Es kann einige Stunden dauern, bis der Content erscheint.`,
          selectedDate: 'Start-Datum'
          }
        },
        three: {
          label: `Welche Markenerwähnung (@nutzername) möchten Sie verfolgen?`,
          optionLabels: {
            true: `Ja, alle Inhalte vom Brand-Account in die Kampagne aufnehmen`,
            false: `Ja, aber nur Inhalte mit passenden Keywords/Hashtags einbeziehen`,
            null: `Nein, ich habe keinen Brand-Account oder möchte ihn nicht hinzufügen`,
          },
          helperText: 'Du kannst deine Einstellungen später jederzeit ändern.',
        },
        four: {
          label: creators => `Willst du diese Mention ${creators} auch als Brand-Account zu dieser Kampagne hinzufügen?`,
          optionLabels: {
            null: 'Nein, diesen Account nicht in mein Tracking einbeziehen.'
          }
        },
        five: {
          label: `Wähle die Währung`,
          helperText: `Wir brauchen dies für CPMs und Earned Media Values`,
          fieldLabel: `Währung wählen`,
        },
        six: {
          label: `Füge einige Creator hinzu, die du tracken möchtest`,
          suggestionLabel: 'Hier sind einige Vorschläge, bei denen in der Vergangenheit die Mentions verwendet wurden.',
          helperText: `Bitte klicke auf 'Creator-Overview', um mehr als 10 Creator hinzuzufügen oder die Creator zu bearbeiten.`,
        },
        seven: {
          label: `Füge Hashtags, Mentions oder Links hinzu, die du tracken möchtest`,
          suggestionLabel: 'Basierend auf deiner Mention findest du hier einige Hashtag-Vorschläge:',
          fieldLabel: `Typ`,
          helperText: `Bitte klicke auf 'Keywords und Mentions-Manager', um mehr als 10 Elemente hinzuzufügen oder die Liste zu bearbeiten.`,
        },
        eight: {
          label: `Fast fertig. Bitte füge den Brand-Account als Text hinzu \n(z.B. wie die Marke in den Kommentaren erscheinen würde).\nAuf diese Weise können wir herausfinden, ob Nutzer darüber kommentieren und können Kommentare gezielter filtern.`,
          fieldLabel: `Typ`,
          helperText: `Beispiele: 'Adidas' oder 'Nike' \n Bitte überspringen Sie diesen Schritt, wenn du die Marke bereits als freien
          Text im vorigen Schritt hinzugefügt hast.\n Groß- und Kleinschreibung wird ignoriert.\n
          Bitte klicke auf "Keywords & Mentions-Manager" und wähle den Typ
          "Freier Text", um weitere hinzuzufügen oder zu bearbeiten.`,
        },
        finish: {
          label: `Geschafft!`,
          helperText: `Wir werden deine Creator tracken und du wirst wöchentliche Updates per E-Mail erhalten. Natürlich kannst du alle Einstellungen und viele weitere individuelle Optionen innerhalb Deiner Kampagne aktualisieren oder ändern.`,
          createCampaignTitleStatusCode202: `Achtung: Nicht alle Creator sind aktiv!`,
          createCampaignTextStatusCode202: `Wir haben deine Kampagne eingerichtet, aber da du nicht genügend Plätze hast, wurden ein oder mehrere Creator als ONBOARDING zu dieser Kampagne hinzugefügt und werden nicht verfolgt. Du brauchst weitere Seats und musst danach die Creator manuell in Ihrem Creator Manager aktivieren.`
        }
      }
    },
    campaignSettings: 'Kampagneneinstellungen',
    saveChanges: 'Änderungen speichern',
    activateCampaign: 'Kampagne aktivieren',
    inactive: 'Inaktiv',
    inactiveCampaignText: `Diese Kampagne hat noch nicht begonnen.\n Bitte aktiviere die Kampagne, um das Tracking zu starten.`,
    running: 'Aktiv',
    latestPosts: 'Neueste Beiträge',
    mostViewedPosts: 'Meistgesehene Beiträge',
    modalDemoCampaign: {
      label: `Dies ist eine Demo-Kampagne mit echten Daten!`,
      subtitleOne: `Du kannst dich durch die Demo-Kampagnen klicken und alle Möglichkeiten, die "Campaigns" zu bieten hat, erkunden.`,
      subtitleTwo: `Bitte beachte, dass hier nichts geändert werden kann.`,
      btnLabel: `Demo-Kampagnen entfernen`
    },
    disabledTextForCampaignLimit: `Du hast nicht genügend Seats um diese Kampagne zu starten. Bitte archiviere Creator oder kontaktiere uns, um deine Seats zu erhöhen.`,
    archiveCampaign: 'Kampagne archivieren',
    confirmArchivingCampaign: 'Archivieren der Kampagne bestätigen',
    confirmDeletingCampaign: 'Löschen der Kampagne bestätigen',
    archivingModalBodyText: `Diese Kampagne wird gestoppt und alle Creator werden nicht mehr getracked und auf inaktiv gesetzt. Kampagnen-Seats werden frei und können für andere Kampagnen wieder genutzt werden\n\nAchtung: Du kannst diese Kampage nicht mehr aktivieren, sobald sie archiviert wurde!`,
    deletingModalBodyText: `Alle Daten in dieser Kampagne gehen verloren und wir können keine Daten wiederherstellen.\n\n Wir empfehlen, diese Kampagne zu archivieren, wenn du in Zukunft auf die Daten zugreifen möchtest.`,
    switchFollowersLikes: 'Follower/Liker wechseln',
    audienceReportsLikers: `Audience-Report Likers`,
    audienceReportsFollowers: `Audience-Report Follower`,
    audienceReport: 'Audience Report',
    editCPMs: 'CPMs bearbeiten',
    disabledText: 'Du kannst nur entweder einen Preis für die komplette Zusammenarbeit setzten ODER pro Content Piece + Zusätzliche Kosten. Bitte entferne die gesetzten Preise, um hier einen Preis zu setzen.',
    campaignOverview: 'Kampagnenübersicht',
    filteredByContentLabel: 'Gefiltert nach Inhalt',
    disabledTextForCampaignLimit: `Du hast nicht genügend Kampagnenplätze für diese Kampagne. Bitte archiviere Creator oder kontaktiere uns, um die Creator-Slots zu erhöhen.`,
    brandsTimelineText: {
      withinTheLast365Days: username => `Innerhalb der letzten 365 Tage hat @${username} zusammengearbeitet mit`,
      brands: countBrands => `${countBrands} Brands verlinkt`,
      shared: num => `${num} Inhalte mit Brand-Erwähnungen`,
      generated: (num, currency) => `${num} ${currency} EMV (Earned Media Value)`,
      reach: (num) => `${num} Views (Reichweite)`,
      creators: 'Creator',
      timeline: 'Zeitleiste',
      contentPcs: 'Content Pcs.',
      creatorsThatMentionedTheBrand: 'CREATOR, DIE DIE MARKE ERWÄHNT HABEN',
      withinTheLast365DaysWasTaggedBy: username => `Innerhalb der letzten 365 Tage,
      wurde @${username} getaggt von`,
      differentCreators: 'verschiedenen Creator',
      brandedContentPieces: 'gebrandete Content-Pieces',
      werePublished: 'wurden veröffentlicht',
      emv: `EMV (EARNED MEDIA VALUE)`,
      wasGenerated: 'wurde generiert',
      wereGenerated: 'wurden generiert',
      partnershipTypes: 'Partnerschaftstypen',
      oneOff: 'einmalig',
      longTerm: 'langfristig',
      brandAmbassadors: 'Markenbotschafter',
      month: 'Monat',
      top5CreatorLocations: 'TOP 3 Creator-Locations',
      from: `aus`,
      top3CreatorLanguages: 'TOP 3 Creator-Sprachen',
      speak: `sprechen`,
      creatorGender: `GESCHLECHT DER CREATOR`,
      are: `sind`,
      creatorsType: `CREATOR-TYPEN`,
      creatorInfludataScores: 'CREATOR INFLUDATA SCORES',
      reachFromCreatorsLabelChart: 'REICHWEITE DURCH CREATOR-TAGS NACH ZEIT',
      emvLabelChart: 'MEDIENWERT NACH ZEIT',
      brandedContent: 'GEBRANDETER CONTENT',
      filterContentLabel: 'Bitte wähle oben einen Creator, um einen anderen Content anzuzeigen.',
      clickToFilterContent: 'Klicke hier, um den Content zu filtern.',
      wereShared: 'wurden geteilt',
      contentStrategy: 'Content-Strategie',
      campaignHashtags: 'KAMPAGNEN-HASHTAGS'
    },
    confirmedAdsText: 'Bezahlte Zusammenarbeit - Wir haben Keywords gefunden, die auf eine (bezahlte) Zusammenarbeit hindeuten.',
    goToProfile: 'Zum Profil',
    reachedAudience: 'Erreichte Audience',
    latest: 'Neueste',
    addToCampaignPipeline: `Zur Kampagnen-Pipeline hinzufügen`,
    campaignPipelineModalSubtext: `Auf die Kampagnen-Pipelines kann innerhalb des Kampagnenmanagements zugegriffen werden. Du kannst Creator sammeln und sie für Kampagnen aktivieren`,
    chooseCampaign: `Kampagne wählen`,
    selectYourCampaign: `Wählen Sie Ihre Kampagne`,
    activateDirectInCampaign: `Direkt in der Kampagne aktivieren`,
    orChoosePipelineStage: `oder Pipeline-Stufe wählen`,
    activateInCampaigns: 'Aktiviert in Kampagnen',
    noCampaignCreatedYet: 'Es wurden noch keine Kampagnen erstellt.',
    noPipelineStagesCreatedYet: 'Es wurden noch keine Pipelines erstellt',
    dontHaveAnyCampaignSeatsLeft: `Du hast keine Kampagnen-Seats übrig.`,
    importantAddingToPipeline: `Important: Adding to Pipeline is still possible.`,
    removeCreator: `Bitte gehe in deine Kampagne, um diesen Creator zu entfernen.`,
    signupLocalization: {
      alreadyHaveAnAccount: 'Hast du bereits ein Konto?',
      createYouFreeAccount: 'Erstelle Deinen kostenlosen Zugang',
      noCreditNoCardNeeded: 'Keine Zahlungsdaten erforderlich.',
      workEmailAddress: 'Bitte gib deine geschäftliche E-Mail ein.',
      emailError: 'Deine E-Mail-Adresse scheint das falsche Format zu haben.',
      signupFree: 'Registrieren',
      verifyYourEmailAddress: 'Überprüfe deine E-Mail-Adresse',
      weveSentDigitalCode: email => `Wir haben einen 6-stelligen Code an ${email} gesendet.`,
      lovedByTheWorld: `Wir sind die bevorzugte Creator-Plattform für weltklasse Brands und Agenturen`,
      topRated: `Top-bewertet auf OMR Reviews und Capterra`,
      whatCustomersSaying: 'Mit ihren eigenen Worten: Die Erfahrungen unserer Kunden',
      emailSent: 'E-Mail gesendet',
      TheEmailIsOnItsWay: 'Die E-Mail ist unterwegs!',
      expiredCode: `Dieser Code ist ungültig oder abgelaufen. Bitte versuche es erneut.`,
      makeSureToKeepThisWindowOpen: 'Stelle sicher, dass dieses Fenster geöffnet bleibt, während du deinen Posteingang prüfst.',
      resendNow: 'Jetzt erneut senden',
      enterYourDigitCode: 'Geben Sie Ihren 6-stelligen Code ein',
      letsGetUpYouAndRunning: `Lass uns dein Creator Marketing auf die nächste Stufe bringen`,
      thankYouForVerify: 'Vielen Dank für die Bestätigung deiner E-Mail-Adresse.',
      continue: `Weiter`,
      welcome: 'Wilkommen',
      whichPlatformAreRelevant: 'Welche Plattformen sind relevant?',
      whatAreYouLooking: 'Wofür möchtest du influData nutzen?',
      pleaseClickAllAnswers: 'Bitte wähle alle Antworten, die zutreffen.',
      inviteTeammates: `Lade Teamkollegen zu deinem infludata Trial ein`,
      theyllbeAbleToUse: `Diese können alle verfügbaren Funktionen nutzen und an Kampagnen und Collections gemeinsam arbeiten.`,
      enterEmailAddress: 'E-Mail-Adressen eingeben',
      skipForNow: 'Überspringen',
      lastStep: 'Letzter Schritt: Bitte vereinbare einen kurzen Demo-Call mit unserem Team',
      aQuickDemo: `Vor dem Start einer Testversion der Discovery und Analytics-Funktionen ist eine kurze Demo erforderlich, damit wir influData auf deine Bedürfnisse anpassen können.`,
      ifYouAreOnlyInterested: `Wenn du nur daran interessiert bist, unser Kampagnen-Reporting zu testen, kannst du sofort loslegen.`,
      thankYouForSigningUp: `Vielen Dank für deine Anmeldung!`,
      weAreLookingForward: `Wir freuen uns darauf, dich kennenzulernen und dein Creator-Marketing auf die nächste Stufe zu bringen.`,
      followingPersonalDemo: `Nach deiner persönlichen Demo schalten wir die Testversion zur Discovery und Analyse frei`,
      inTheMeanTimePlease: `Du kannst dir in der Zwischenzeit gerne Campaigns by influData anschauen – unverbindlich und für einen
      ganzen Monat.`,
      weAlreadyAddedSomeDemoCamp: `Wir haben bereits einige Demo-Kampagnen mit echten Daten hinzugefügt.`,
      setUpMyFirstCampaign: `Jetzt meine erste Kampagne einrichten`,
      featuresObject: {
        discovery: 'Creator Discovery & Suche',
        audience: 'Creator Audience-Insights',
        analytics: 'Creator Profil-Analysen',
        overlaps: 'Audience-Überschneidung & Vergleich',
        reporting: 'Kampagnen-Reporting',
        management: 'Kampagnen-Management & Planung',
        sentiment: 'Semantische Analysen (z.B. "Shit-Storm-Erkennung")',
        exports: 'Datenexporte & Mailings'
      },
      trialCampaignsLabel: 'Starte jetzt die 30-Tage-Testversion von Campaigns',
      describeYourBusiness: "Wie würden Sie Ihr Unternehmen beschreiben?",
      consumerBrand: 'Consumer-Brand',
      agency: 'Agentur',
      marketingFreelancer: 'Marketing-Freelancer',
      other: `Andere`,
      collectLeadFirstPageTitle: `Deine nächste Creator-Marketingplattform!`,
      collectLeadFirstPageSubtitle: `Unter allen Anmeldungen während der OMR 2024 verlosen wir eine einjährige influData All-In-Lizenz (im Wert von ca. 15.000 €).`,
      notes: 'Notizen',
      collectLeadQuickDemoSubtitle: 'Bevor du mit dem Trial unserer Discovery und Analysen beginnen kannst, ist eine kurze Demo erforderlich, damit wir sie entsprechend den Anforderungen einrichten können.',
      restart: 'Neustart',
      startYour7DayTrialNow: `Starte jetzt Deinen 7-tägigen Trial`,
      startTrialNow: 'Jetzt Trial starten'
    },
    detailed: 'detailliert',
    customFieldWebHook: {
      popoverText: {
        creatorId: `Gehe zu "Creator Overview" und klicke auf die Option "Creator bearbeiten", dann findest du auf der linken Seite ein Feld mit der Bezeichnung "CreatorId". Verwende diesen Wert für diese Variable.`,
        contentId: `Gehe zu "Content Overview" und klicke auf die rechte Seite des Bildes, dann findest du auf der linken Seite ein Kästchen mit der Bezeichnung "ContentId". Verwende diesen Wert für diese Variable. Wir verwenden die contentId, die auch von den Plattformen verwendet wird. Du kannst die contentId also auch über die plattformspezifische URL abrufen.`
      },
      applyLevelTitles: {
        creator: `Dieses Custom Field ist spezifisch für Creator. Das bedeutet, dass dein Request immer die Creator-ID enthalten muss. Du kannst Reports nach Creator und Datum filtern`,
        content: `Dieses Custom Field ist spezifisch für Content Pieces. Das bedeutet, dass Sie Ihrer Anfrage immer die Content-ID hinzufügen müssen. Du kannst Reports nach Content Piece, Creator und Datum filtern`,
        campaign: `Dieses Custom Field ist spezifisch für die gesamte Kampagne. Du kannst keine Werte für Creator oder Content Pieces hinzufügen und nur nach Datum filtern.`
      },
      modalTitle: 'Verbinde den Webhook mit diesem Custom Field',
      sendAPostReq: `Sende einen POST-Request an diese URL`,
      addTheFollowingVariables: `Füge folgenden Content in den "body" deines Requests.`,
      noteText: `Es können negative Werte für "value" geschickt werden.`,
      noteSubText: `Das Datum ist wichtig, wenn Werte aus der Vergangenheit geschickt werden, damit Reports korrekt erstellt werden.`,
      required: `erforderlich`
    },
    hideCreatorsInCollectionLabel: 'Konten in Collections/Favoriten nicht anzeigen',
    limitCreateCampaign: `Du hast das Limit deiner Kampagnen erreicht.`,
    notIncludedInYourCurrentPlan: `Dies ist nicht in Deinem aktuellen Plan enthalten.`,
    youCanViewUpToDays: days => `Du kannst bis zu ${days} Tage vergangener Inhalte ansehen`,
    campaignsMetricsTable: {
      learnMore: 'Reichweite von Instagram Posts und Storys sind nicht öffentlich abrufbar und können daher nur vom Creator selbst herausgegeben werden. Du kannst diese Werte beim Post oder der Story in der Content Übersicht hinterlegen oder einfach unsere geschätzen Werte beibehalten.',
      overview: {
        reach: 'Reichweite',
        engagements: 'Engagements',
        contentPieces: 'Content-Pieces',
        engagementRate: 'Engagement-Rate'
      },
      engagementReach: {
        likes: 'Likes',
        comments: 'Kommentare',
        shares: 'Shares',
        saves: 'Saves',
        rateOfLikes: 'Like-Rate'
      },
      cpm: {
        costPreMille: 'Kosten pro Tausend',
        expenses: 'Ausgaben in',
        reachedAudience: 'Erreichte Audience',
        publishedContent: 'Veröffentlichter Inhalt',
        rangeMin: 'Bereich (min. von Creator in',
        rangeMax: 'Bereich (max. von Creator in',
        missingData: 'Fehlende Daten'
      },
      cpe: {
        costPerEngagement: 'Kosten pro Engagement in',
        interactions: 'Interaktionen',
      },
      emv: {
        emvFull: 'Verdienter Medienwert in',
        averagePerContent: 'Durchschnitt pro Inhalt',
        averagePerEngagement: 'Durchschnitt pro Engagement'
      },
      tabsLabels: {
        overview: 'Übersicht',
        engagementReach: 'Engagement & Reichweite',
        cpm: 'CPM',
        cpe: 'CPE',
        emv: 'EMV'
      },
      starLabel: '*Diese Berechnung enthält mindestens eine geschätzte Zahl.',
      learnMore: 'Mehr erfahren'
    },
    create: 'Erstellen',
    downloadHighQuality: `Hochwertige Bilder und Videos herunterladen`,
    campaignSettingsObj: {
      adjust: 'Anpassen',
      setUpYourCampaign: 'Richten Sie Ihre Kampagne ein',
      additionalSettings: 'Zusätzliche Einstellungen',
      trackReach: 'Reichweite für Instagram Stories und Posts manuell verfolgen',
      trackReachSubtitle: `Da es sich hierbei nicht um öffentlich verfügbare Daten handelt, können wir diese Kennzahlen nur schätzen.“ Es
      wird genauer sein, wenn die Plattformdaten verwendet werden. Wenn Sie dies auf „Automatisiert und
      Manuell“, dann fügen wir die geschätzten Daten hinzu, Sie können dies jedoch weiterhin tun
      Korrekturen. In beiden Einstellungen können Sie wählen, ob Sie die Daten abrufen möchten, indem Sie sie anfordern
      der Influencer.`,
      trackNonOrganic: 'Nicht-organische Metriken verfolgen',
      trackNonOrganicSubtitle: `Sie können auch die Reichweite von Inhalten verfolgen, die aus nicht-organischer Reichweite stammen, z. B. gesponsert.“
      Inhalt. Wir fügen die Felder zu jedem Inhalt hinzu und Sie können die Daten einfügen.`,
      includeCostPricing: 'Kosten und Preise für Creator aktivieren',
      includeCostPricingSubtitle: `Du kannst die Kosten pro Content Pieces festlegen und auf dieser Basis können wir die Kosten pro 1000 Aufrufe errechnen und liefern außerdem zusätzliche Statistiken über den Performance der Creator. Darüber hinaus kannst du benutzerdefinierte Felder für die Preisgestaltung erstellen, z. B. ROI, Einnahmen pro Content usw.`,
      adjustEMVCalc: 'EMV-Berechnung anpassen',
      adjustEMVCalcSubtitle: `Du kannst die Standardwerte für Media Values basierend auf Deinen Daten nach Format und Plattform erhöhen oder verringern. Das Dashboard wird sich innerhalb von 6 Stunden aktualisieren.`,
      onlyManually: 'Nur manuell',
      automatedAndManually: 'Automatisiert und manuell',
      selectCurrency: 'Kampagnenwährung ändern',
    },
    user: 'Benutzer',
    campaignsGoal: {
      customGoalForEachInfluencerTooltipTitle: 'Diese Option ist nur für Ziele möglich, die auf Influencer-Ebene getrackt werden.',
      goalTitle: 'Zieltitel',
      isPaused: 'Ist pausiert',
      selectAGoal: 'Wählen Sie ein Ziel',
      whenIsThisGoalReached: 'Wann ist dieses Ziel erreicht?',
      metricLabel: 'Metrikbezeichnung',
      whichLevel: 'Mit welcher Ebene soll dieses Ziel verbunden sein?',
      levelCant: ` Die Ebene kann nicht detaillierter sein als im benutzerdefinierten Feld definiert. Bitte
        Beachten Sie, dass Sie keinen Zeitraum für einzelne Ziele auswählen können
        Inhaltsstück.`,
      perContentPiece: 'Pro Content Piece',
      proInfluencer: 'Pro Influencer',
      perCampaign: `Pro Kampagne`,
      timePeriod: 'In welchem Zeitraum soll dieses Ziel erreicht werden?',
      setQuarterly: `Legen Sie vierteljährliche, monatliche, wöchentliche oder Ziele für die gesamte Kampagne fest.`,
      fullCampaign: `Vollständige Kampagne`,
      perWeek: 'Pro Woche (Mo-So)',
      perMonth: 'Pro Monat (1. des Monats)',
      perQuarter: 'Pro Quartal (1. Tag des Quartals)',
      customGoal:`Benutzerdefiniertes Ziel für jeden Influencer?“,
        whenAnAddingInfluencer: „Wenn wir einen Influencer zu einer Kampagne hinzufügen, fragen wir nach dem erwarteten Wert. Wenn die
        Wenn der Wert nicht festgelegt ist, wird der obige Standardwert verwendet.`,
      editGoal: 'Ein Ziel bearbeiten',
      addGoal: 'Ein Ziel hinzufügen',
      createGoal: 'Ein Ziel erstellen',
    },
    emvForContent: 'Earned Media Value (Medienwert) des Postings',
    sponsoredDetails: 'Die Reichweite dieses Postings kommt teilweise aus nicht-organischer Aktivität (bezahlte Ads).',
    mostViewed: 'Meist gesehen',
    duration: 'Dauer',
    reactions: 'Reaktionen',
    adLikeCountTooltip: 'Durch gesponserte Reichweite erzielte Likes. Diese Zahl ist in den gesamten Likes enthalten.',
    adCommentCountTooltip: 'Durch gesponserte Reichweite erzielte Kommentare. Diese Zahl ist in den gesamten Kommentaren enthalten.',
    adSaveCountTooltip: 'Durch gesponserte Reichweite erzielte Speicherungen (Saves). Diese Zahl ist in den gesamten Speicherungen (Saves) enthalten.',
    top5AudiencesCountries: 'Top 5 Audience Länder',
    top5AudiencesCities: 'Top 5 Audience Städte',
    top5AudiencesLanguages:'Top 5 Audience Sprachen',
    hideCreatorsInCollectionLabel: 'Account in Collections/Favoriten nicht anzeigen',
    explorePage: {
      explore: 'Erkunden',
      suggestions: 'Empfehlungen',
      recentSearches: `Aktuelles`,
      basedOn: `Basiert auf`,
      updatedOn: 'Aktualisiert am',
      searchedOn: 'Gesucht am',
      createdOn: `Erstellt am`,
      similarAccounts: `Ähnliche Creator`,
      newlyAddedTo: `Neu aufgenommen zu`,
      suggestionsTitle: `Liste basiert auf`,
      suggestionsSubtitle: type => `Wir haben ${type} Accounts gefunden, die auf den Creators in deiner Sammlung basieren. Wir aktualisieren diese Liste wöchentlich und zeigen Creator an, die von Interesse sein könnten.`,
      applyToSearch: `Auf die Suche anwenden`,
      useRecentSearchesOnTheLeft: `Bitte wähle eine Liste letzten Suchanfragen.`,
      noInfluencersInSuggestions: `Bitte wähle eine Liste mit Empfehlungen aus.`,
    },
    widgetBrandAccountGrowthHelperText: 'Das Datum und die Follower werden angezeigt, wenn du mit der Maus über das Diagramm fährst',
    thanDashboardStart: 'seit Startdatum',
    couldntFindAnyContent: `Es tut uns leid, aber wir konnten keinen Inhalt finden.`,
    emvInCurrency: currency => `EMV (in ${currency})`,
    reachTotal: 'Reichweite (gesamt)',
    active: 'Aktiv',
    buildLookalike: {
      btnText: 'Lookalike via AI erstellen',
      titleModal: 'Creator Lookalike-Liste erstellen',
      subtitleModal: `Du kannst wählen, ob du Lookalikes von Creatorn finden möchtest, die mit bestimmten Brands zusammengearbeitet haben (wähle "Brands"), oder ob du Lookalikes direkt auf Basis von Creatorn finden möchtest (wähle "Creators")`,
      brandsHelperText: `Füge bis zu drei Brands hinzu, um Lookalikes zu finden. Wir empfehlen, ähnliche Marken hinzuzufügen, um bessere Ergebnisse zu erzielen.`,
      creatorsHelperText: `Füge mindestens drei Creator (max. 20 Creator) hinzu, um Lookalikes zu finden.`,
      addToCreatorsLimitText: `Du hast die maximale Anzahl an Konten erreicht.`,
      pleaseAddALabel: `Bitte gib deiner Lookalike-Liste einen Namen:`,
      yourLookalikeList: `Deine Lookalike-Liste`,
      lookalikeSubLabel: `Dies sind die Lookalikes, die wir basierend auf den analysierten Konten gefunden haben.`,
      lookalikeTipText: `Lookalikes von Creatorn aus Markenkontokooperationen bzw. von Creatorn, die zur Liste hinzugefügt wurden.`,
      creatorBaseTipText: `Die Creator-Basis, von denen wir Lookalikes gebildet haben. Also entweder Creator, die direkt hinzugefügt wurden oder indirekt durch Brand-Kollaborationen von hinzugefügten Brands.`,
      creatorBase: 'Creator-Basis',
      lookalikes: 'Lookalikes',
      inProgress: `In Bearbeitung`,
      finished: 'Fertig',
      willTake5Minutes: 'Die Erstellung dauert ca. 5 Minuten. Du wirst per Mail benachrichtigt, sobald der Vorgang abgeschlossen ist.',
      deleteSuggestionsList: 'Möchten Sie diese Liste wirklich löschen?',
      selectedSuggestionsNotFound: `Die ausgewählten Vorschläge wurden nicht gefunden`
    },
    createOverlap: 'Überlappung erstellen',
    createOverlapHelperText: `Bitte wähle die Plattform aus und füge bis zu 10 Creator hinzu (min. 2).`,
    downloadResultsAsCSV: `Ergebnisse als CSV herunterladen`,
    downloadResultsAsCSVNotPossible: `Nur die ersten 500 Ergebnisse werden heruntergeladen`,
    percent: 'Prozent',
    creatorOverview: {
      editCreator: 'Einen Creator bearbeiten',
      creatorHandle: 'Creator-Handle',
      internalName: 'Interner Name',
      bitlyLinkName: `Bitly Link – Name`,
      setCreatorAsArchived: 'Creator als archiviert festlegen',
      setCreatorAsArchivedSubtitle: `Der Creator wird nicht zum Dashboard und zu den Berichten hinzugefügt und wir werden keine weiteren Daten von diesem Influencer sammeln.“ Sie können dies jederzeit rückgängig machen.`,
      overwriteAudienceDemographics:`Zielgruppendemografie überschreiben`,
      overwriteAudienceDemographicsSubtitle: `Wir verwenden die Zielgruppendaten von influData, aber wenn Sie Zugriff auf die Daten des Creator haben, kannst du manuell genauere Informationen hinzufügen.`,
      enablePricingAndCosts: `Preise und Kosten aktivieren`,
      enablePricingAndCostsSubtitle: `Du kannst die Preisgestaltung für jedes Content Piece dieses Creators aktivieren. Wir stellen zusätzliche Statistiken bereit, wenn diese aktiviert sind.`,
      requestMetrics: `Vom Creator Metriken anfordern`,
      requestMetricsSubtitle: `Wir kontaktieren den Creator nach 24 Stunden (Story) oder nach 3 Tagen (jeder andere Inhalt) nach der Veröffentlichung eines Inhalts und fordern Kennzahlen wie Aufrufe, Reichweite oder Klicks an. Du musst eine Kontaktmethode für unsere Benachrichtigungen hinzufügen.`,
      mergeOtherSocialMedia: 'Andere soziale Medien zusammenführen',
      audienceDemographics: `Zielgruppendemografie`,
      audienceDemographicsSubtitle: `Bitte füge nur die Messwerte für den nicht-organischen Traffic hinzu.`,
      countriesTopLocation: 'Länder (Top-Standorte)',
      ageRange: 'Altersspanne',
      pricingSubtitle: `Hier kannst du Kosten entweder für die gesamte Zusammenarbeit innerhalb dieser Kampagne mit diesem Creator oder pro Content Piece festlegen. Bitte beachte, dass du benutzerdefinierte Felder erstellen musst, falls du den ROI („Return on Investment“) dieser Zusammenarbeit der einzelnen Inhaltsteile verfolgen möchstest.`,
      pricingRecommended: `Wir empfehlen, Content-Ziele auf Influencer-Ebene einzurichten und die Ziele im Tab „Gesamt“ zu definieren, damit wir das geplante Gesamtbudget in Ihrem Dashboard berechnen können.`,
      createBitLinkForThisCreator: `Bit.ly-Link für diesen Creator erstellen`,
      priceForEntireCollaboration: `Preis für die gesamte Zusammenarbeit.`,
      trackEntireCollaboration: 'Gesamte Zusammenarbeit verfolgen',
      trackEachContentPiece: 'Jedes Content Piece verfolgen',
      pricingForReel: 'Preis für eine Instagram-Rolle',
      pricingForStory: 'Preis für eine Instagram Story',
      pricingForPost: 'Preis für einen Instagram-Beitrag',
      pricingForTikTok: 'Preis für ein TikTok-Video',
      pricingForYtVideo: 'Preis für ein YouTube-Video',
      pricingForYtShorts: 'Preis für einen YouTube-Shorts',
      additionalPayments: 'Zusätzliche Zahlungen (optional)',
      saveChanges: 'Änderungen speichern'
    },
    createBitLyField: 'Bit.ly-Feld erstellen',
    editBitlyField: 'Bit.ly-Feld bearbeiten',
    deleteBitlyField: 'Bit.ly-Feld löschen',
    bitLyFieldModal: {
      createNewField: 'Erstelle benutzerdefinierte Bit.ly-Links für Creator',
      step1: `Erstelle individuelle Bit.ly-Links für jeden Creator in deiner Kampagne mit nur einer Einrichtung. Diese Links ermöglichen eine präzise Verfolgung jedes Klicks und ein umfassendes Tracking.`,
      step2: `So funktioniert's:`,
      step3: `- Zugriff im Creator-Manager: Klicke auf die drei Punkte neben einem Creator und wähle "Bit.ly-Link anzeigen" oder innerhalb von "Creator bearbeiten".`,
      step4: `- Sende den creatorspezifischen Link an den Creator, damit er ihn in seiner Bio oder für Swipe-up-Links verwendet.`,
      step5: `- Der Benutzer wird zu deiner Weiterleitungs-URL weitergeleitet und der Klick wird automatisch verfolgt.`,
      step6: `- Ändere jederzeit einfach die Weiterleitungs-URL und sie wird für alle Creator geändert.`,
      yourForwardUrl: `Deine Weiterleitungs-URL`,
      yourForwardUrlExtended: `Deine Weiterleitungs-URL (z.B. Website, Produktseite usw.)`,
      yourLinkName: `Dein Link-Name`,
      enterValidUrl: `Bitte gib eine gültige HTTPS-URL ein`
    },
    yourCampaignIsNowSetUp: `Deine Kampagne ist jetzt eingerichtet und bereit`,
    weAreAlreadyStarted: `Wir haben bereits mit dem Tracking deiner Inhalte begonnen.`,
    yourDashboardWillAppearHere: `Dein Dashboard erscheint hier, sobald der erste Inhalt erkannt wird.`,
    bitlyDisableBtnText: `Pro Kampagne ist nur ein Bitly-Feld zulässig. Bitte entferne oder ändere dein aktuelles Bitly-Feld.`,
    campaignAllowBitlyEdit: `Bitte kontaktiere uns, um die Weiterleitung vorhandener bit.ly-URLs zu ändern. Da dies mit Kosten verbunden ist, empfehlen wir, das Feld zu löschen und ein neues bit.ly-Feld zu erstellen, wenn dieses Feld noch nicht verwendet wurde.`,
      customFieldDeleteAttention: `Achtung: Alle erfassten Daten für dieses Feld werden entfernt und dieses Feld wird nicht mehr
    im Dashboard angezeigt. Dies kann nicht rückgängig gemacht werden.`,
    customFieldDeleteBitlyTracking: `Bitly-Tracking oder Webhook-Tracking funktionieren für dieses Feld nicht mehr.`,
    customFieldDeleteConfirm: customFieldName => `Bitte gib zur Bestätigung den Namen "${customFieldName}" ein.`,
    deleteField: 'Feld löschen',
    confirmToDeleteField: customFieldName => `Möchtest du das benutzerdefinierte Feld "${customFieldName}" wirklich löschen?`,
    createBitlyGrantText: `Das Erstellen von Bit.Ly-Feldern ist in deinem aktuellen Paket nicht enthalten.`,
    bitlyTrackingTable: {
      title: 'Bit.Ly Link Tracking',
      audienceCountry: 'Audience-Land',
      audienceCity: 'Audience-Stadt',
      audienceReferral: 'Audience-Referral',
      audienceReferralDomain: 'Audience-Referraldomain',
      audienceDevice: 'Audience-Device',
      clicks: 'Klicks',
      clicksPDay: 'Klicks pro Tag (Durchschnitt)',
      lastClick: 'Letzter Klick',
      topCountry: 'Top-Land',
      topReferral: 'Top-Empfehlung',
      featureIsNotIncluded: `Diese Funktion ist in deinem Plan nicht enthalten.`
    },
    showAndCopyBitlyLink: `Bit.ly-Link anzeigen und kopieren`,
    outCampaignSeats: `Du hast keine Seats mehr frei.`,
    addCreateToCampaignPopup: `Achtung: Du hast keine Seats mehr frei und der Creator wurde als ONBOARDING hinzugefügt und wird noch nicht getrackt. Wenn Seats frei sind, muss der Creator manuell aktiviert werden.`,
    specifyNameCampaign: `Bitte Bezeichnung für neue Kampagne eingeben.`,
    copyCampaign: {
      first: {
        title: `Von bestehender Kampagne kopieren`,
        selectTitle: `Wähle die Kampagne aus, die du kopieren möchtest`,
        helperText: `Wir kopieren Creator, Keywords und Mentions, Ziele, Settings, Rollen, Dashboards und Pipelines.`,
        btnText: `In neue Kampagne kopieren`
      },
      finish: {
        title: `Neue Kampagne wird erstellt`,
        helperText: `Bitte beachte, dass die Kampagne im Onboarding-Modus erstellt wird und du die Kampagne manuell aktivieren musst, um das Tracking zu starten!`
      }
    },
    importCreatorsFromCollection: `Creator aus Collection importieren`,
    selectYourCollection: `Wählen Sie Ihre Collection aus`,
    noMatchingCreators: `Keine passenden Creator gefunden, bitte wähle eine andere Collection.`,
    activateCampaignFirst: `Bitte aktiviere zuerst die Kampagne, um mit dem Tracking der Creator zu beginnen.`,
    onceArchived: ` Nach der Archivierung kannst du den Creator für diese Kampagne nicht mehr reaktivieren und wir werden das Tracking für diesen Creator beenden.`,
    allDataWillStillAppear: `Alle Daten werden bis zu dem Tag, an dem der Creator archiviert wurde, weiterhin in Ihrem Dashboard angezeigt.`,
    showHide: `Anzeigen/Ausblenden`,
    searchSuggestions: {
      suggestionsPlaceholder: 'Finde jeden Creator - z.B. "Vegane Influencer aus DACH mit min. 100k Followern"',
      suggestionsContentPlaceholder: 'Beschreibe dein gesuchtes Bild. - Versuche "Woman with curly hair"',
      suggestionsBrandPlaceholder: `Finde jeden Brand Account – Versuche "Fashionbrands aus dem DACH-Raum"`,
      recentSearchTooltip: 'Öffne deine letzten Suchanfragen.',
      lookalikeTooltip: 'Baue eine Lookalike-Liste via AI und finde passende ähnliche Creator, die zu deiner Brand passen.',
      uploadTooltip: 'Lade ein Bild oder Logo hoch und zeige ähnliche Content Pieces.',
      resetSearch: 'Suche zurücksetzen',
      creatorCollaborationsTips: `Gib' einen oder mehrere Creator ein und suche nach Brands, mit denen diese Creator in der Vergangenheit zusammengearbeitet haben.`,
      promptsByChatGPT: `Prompts via Chat-GPT (Beta-Version)`,
      receiveSurveyResultLabel: 'Zufrieden mit den Ergebnissen?',
      receiveSurveyResultPositive: `Ja, alles super :)`,
      receiveSurveyResultNegative: `Nein, könnte besser sein`,
      disabledCity: `Bitte wähle zuerst ein Land aus, bevor du eine Stadt auswählen kannst.`,
      paramLabels: {
        city: "",
        country: "",
        language: "",
        followerMin: "",
        followerMax: "",
        gender: "",
        skipCount: "",
        additionalSkipsForBlacklisting: "",
        keywords: "",
        categories: "",
        matchAnyKeyword: "",
        mustIncludeEmail: "",
        appearsOn: "",
        index: "",
        sorting: "",
        growthRate: "",
        engagementRate: "",
        audienceCountries: "",
        audienceGender: "",
        audienceLanguages: "",
        audienceRealPeople: "",
        audienceAgeRanges: "",
        isShortsCreator: "",
        contentTypes: "",
        uploadedFrom: "",
        uploadedTo: "",
        placesId: "",
        radiusKM: "",
        hideCreatorsInCollection: "",
      }
    },
    invalidUser: 'Anmeldung fehlgeschlagen. Bitte überprüfe deine Anmeldeinformationen!',
    try: 'Try',
    previousSearch: 'Vergangene Suche',
    createLookalike: 'Erstelle Lookalike',
    notABrandAccount: `Kein Brand-Account?`,
    toChangeBrandAccount: `um dieses Profil in ein Creator-Profil zu ändern.`,
    notACreator: `Kein Creator?`,
    toChangeCreatorAccount: `wenn dieses Profil ein Brand-Account ist.`,
    inTrial: `In der Testphase`,
    canceled: `Abgebrochen`,
    subscriptionStatus: `Subscription-Status`,
    defaultSubscriptionsLabel: d => `Dein Abo ist aktiv.\nDeine nächste Verlängerung ist am ${d}`,
    statusDescription: date => `Wird am ${date} automatisch gekündigt.\nDu wirst nach Ablauf der Testphase nicht verlängert.`,
    addPaymentMethodAndSubScribe: `Zahlungsmethode hinzufügen und jetzt abonnieren`,
    addPaymentMethod: `Zahlungsmethode hinzufügen`,
    addPaymentMethodContinue: `Füge eine Zahlungsmethode hinzu, um eine Subscription abzuschließen`,
    pleaseAddBilling: `Bitte füge zuerst unten die Rechnungsdetails hinzu.`,
    pleaseAddBillingErrorMsg: 'Bitte Rechnungsadresse hinzufügen und speichern.',
    creatorFilter: 'Creator-Filter',
    contentFilter: 'Content-Filter',
    brandFilter: 'Marke Filter',
    otherSettings: 'Andere Einstellungen',
    menuKeywordslabel: 'Keyword',
    misc: 'Sonstiges',
    growthRate: 'Wachstumsrate',
    categories: 'Kategorien',
    brandMentions: 'Brand Mentions',
    creatorCollaborations: `Creator Kooperationen`,
    keywords: 'Keywords',
    hideBrandAccounts: 'Brand Accounts nicht anzeigen',
    sortingBy: 'SORTIERUNG NACH',
    similarContentTooltip: `Zeige ähnliche Bilder durch AI`,
    show: 'Anzeigen',
    hide: 'Ausblenden',
    queryMatchedTooltipText: 'text',
    found: 'Es wurden',
    contentPiecesContaining: 'Content Pieces gefunden, mit',
    creator: 'Creators',
    brand: 'Brands',
    similarContent: 'Ähnlicher Inhalt',
    signInAzureAdLabel: 'Anmelden mit Azure AD',
    azureAdAccountSettingsLabel: `Deine Organisation wird über Azure AD authentifiziert`,
    azureAdAccountSettingsCheckBoxLabel: `Azure AD-Tenant-Authentifizierung für Teammitglieder erzwingen.\nAktiviere diese Checkbox, damit nur User deines Tenants zu deinem Konto hinzugefügt werden können.\nAlle anderen Authentifizierungsmethoden werden für Teammitglieder deaktiviert.`,
    no_plan: {
      thankYouForTesting: `Vielen Dank für das Testen von influData`,
      yourTrial: `Deine Testversion ist abgelaufen und du kannst influData nicht mehr verwenden. Bitte aktiviere dein Paket, um influData weiterhin zu verwenden.`,
      cancelAnyTime: 'Jederzeit kündigen',
      perMonth: 'pro Monat',
      seeAllFeatures: `Alle Funktionen anzeigen`,
      subscribeNow: `Jetzt abonnieren`,
      allPlatformsAccess: `Zugriff auf alle Plattformen`,
      searchBy: `Suche nach Creatorn und Inhalten`,
      fullAccess: `Voller Zugriff auf Collections, KI-Lookalikes und mehr`,
      profileAnalytics: `150 Profilanalysen mit Audience Reports (monatlich)`,
      searchResults: `10000 Suchergebnisse (monatlich)`,
      campaignTracking: `10 Plätze im Campaign Reporting`,
    },
    searchResultsLimit: `Suchergebnisse (Seiten)`,
    profileAnalyticsLimit: `Profilanalyse`,
    searchOrAddContentByLink: `Content per Link suchen oder hinzufügen`,
    searchOrAddContentByLinkSubtitle: `Füge hier den Plattformlink ein.\n Du kannst hier nach einem Content Piece suchen und es hinzufügen, wenn es kein Teil der Kampagne ist.`,
    fullPlatformLink: `Vollständiger Plattformlink`,
    activateSubscriptions: 'Subscription jetzt starten.',
    subscriptionStatus: 'Subscription-Status',
    activateSubscriptionsSub: '199 Euro pro Monat. Automatische Verlängerung. Es kann Mehrwertsteuer hinzukommen.',
    activateSubscriptionsLabel: `Ihre Testversion ist noch aktiv und wird am automatisch in einen kostenpflichtigen Plan umgewandelt`,
    activateSubscriptions201: `Sie haben Ihren Plan erfolgreich aktiviert.`,
    activateSubscriptions400: `Achtung: Aktivierung nicht erfolgreich. Bitte überprüfe Deine Zahlungsmethode oder setze dich mit uns in Verbindung.`,
    cancelSubscription: 'Abonnement kündigen',
    subscriptionSuccessfullyCancelled: `Abonnement erfolgreich storniert`,
    errorWithCancelledSubscription: `Beim Stornieren Ihres Abonnements ist ein Fehler aufgetreten.`,
    attention: 'Achtung',
    cancelSubscriptionAttentionText: `Wenn Sie bestätigen, kündigen wir Ihren Plan zum Ende Ihrer Laufzeit. Bitte beachten Sie, dass Ihre Kontodaten nach der Kündigung verloren gehen.`,
    removeCancellation: `Stornierung entfernen`,
    removeCancellationLabel: date => `Deine Subscription wird am ${date} storniert.\n Klicke hier, um die Stornierung aufzuheben.`,
    removeCancellationSuccessful: `Stornierung erfolgreich entfernt`,
    removeCancellationError: `Beim Entfernen der Stornierung ist ein Fehler aufgetreten.`,
    manageYourPlan: `Verwalten Sie Ihren Plan`,
    cancelledPlanLabel: `Ihr Plan ist derzeit storniert und nicht mehr aktiv. Sie können Ihren Plan in den Einstellungen aktivieren oder uns per Chat kontaktieren.`,
    weCouldntFind: "Wir konnten keinen Content finden. Bitte beachte, dass es nach dem Posten bis zu 25 Stunden dauern kann.",
    theLinkYouPostedNotValid: "Der von dir gepostete Link ist ungültig und gehört zu keinem Content Piece. Bitte versuche es erneut.",
    deleteCreatorFromCampaignsTipLabel: `Bitte erst archivieren, dann kann der Creator gelöscht werden.`,
    createBitlyFieldTip: `Diese Funktion ist in deinem aktuellen Plan nicht verfügbar. Bitte nimmm Kontakt mit uns auf.`,
    showAll: 'Alle anzeigen',
    alreadyAddedToCampaign: 'Bereits zur Kampagne hinzugefügt',
    backToSearch: `Zurück zur Suche`,
    typeLabelName: `Bezeichnung eingeben`,
    buildLookalikesBasedOnBrandAccount: `Erstelle ein Creator-Lookalike basierend auf dem Brand-Account`,
    matchRate: 'Match-Rate',
    notificationTopic: {
      addUser: obj => {
        if (obj.additionalInfo?.includes('No Influencer')) {
          return `Deine Anfrage <b>"${obj.username}"</b> auf ${obj.platform[0].toUpperCase() + obj.platform.slice(1)} hinzuzufügen ist fehlgeschlagen. Account ist inaktiv oder hat zu wenige Follower.`
        } else if (obj.additionalInfo?.includes('Not Found')) {
          return `Deine Anfrage <b>"${obj.username}"</b> auf ${obj.platform[0].toUpperCase() + obj.platform.slice(1)} hinzuzufügen ist fehlgeschlagen. Username ist falsch oder wurde nicht gefunden.`
        } else {
          return `<b>"${obj.username}"</b> auf ${obj.platform[0].toUpperCase() + obj.platform.slice(1)} wurde hinzugefügt/aktualisiert. Zum Profil.`
        }
      },
      audience: obj => `AUDIENCE REPORT für <b>"${obj.username}"</b> auf ${obj.platform[0].toUpperCase() + obj.platform.slice(1)} ist bereit. Zum Report.`,
      lookalikeDone: obj => `LOOKALIKE List <b>"${obj.additionalInfo}"</b> fertig. Zur Liste.`,
      newOverlapCreated: obj => `Dein <b>OVERLAP</b> wurde erstellt. Zur Auswertung.`,
      tagged: obj => `Du wurdest von <b>${user.additionalInfo}</b> auf dem Profil von ${obj.username} verlinkt.`,
      newCampaignsContent: obj => `Neuer CAMPAIGNS CONTENT wurde geposted (<b>${obj.additionalInfo}</b>)`,
      suggestions: obj => 'SUGGESTIONS', 
      emptyNotificationList: 'Deine Notifications sind im Moment leer.'
    },
    generalAccess: 'Allgemeiner Zugriff',
    collectionsAccessDescriptions: {
      anyone: 'Alle Personen mit Link haben Zugriff.',
      restricted: 'Nur Personen mit denen die Collection geteilt wurde, haben Zugriff.',
      organization: 'Nur Personen innerhalb der Organisation haben Zugriff.'
    },
    changeEmail: 'E-Mail ändern',
    changeYourEmail: `Ändern Sie Ihre E-Mail`,
    enterNewEmail: `Bitte geben Sie hier Ihre neue E-Mail-Adresse ein`,
    changeEmailNotice: `Bitte beachten Sie, dass Ihre Änderungen erst wirksam werden, nachdem Sie die neue E-Mail-Adresse bestätigt haben. Bitte beachten Sie, dass Ihre neue E-Mail-Adresse auch für SSO aktiviert sein muss, wenn Sie sich über Google oder Azure SSO (Single-Sign-On) anmelden.`,
    confirmNewEmail: `Bitte bestätigen Sie Ihre neue E-Mail`,
    clickLinkEmail: `Bitte klicken Sie auf den Link, den wir an Ihre neue E-Mail-Adresse gesendet haben, um die Änderungen zu bestätigen.`,
    changeEmailSuccess: `E-Mail-Änderung erfolgreich`,
    weSuccessfullyChangedEmail: `Wir haben Ihre E-Mail erfolgreich geändert.`,
    continueToInfludata: `Weiter zu Infludata`,
    emailChangedFailed: `E-Mail-Änderung fehlgeschlagen`,
    pleaseTryAgain: `Bitte versuchen Sie es erneut.`,
    yourEmailIsNotValid: `Deine E-Mail ist ungültig. Bitte verwende Deine Firmen-E-Mail, um dich für die Testversion anzumelden.`,
    signupIsNotAllowed: `Die Anmeldung ist für allgemeine E-Mails nicht zulässig. Bitte melden Sie sich mit Ihrer Firmen-E-Mail an.`,
    companyMembersSeatsUsed: (usedSeats, maxSeats, availableSeats) => `Verwendete Plätze ${usedSeats} von ${maxSeats} (${availableSeats} Plätze verfügbar)`,
    pleaseReach: `Bitte kontaktieren Sie uns per Chat, um die maximale Anzahl an Plätzen zu erhöhen`,
    inviteNewTeamMembers: `Neue Teammitglieder einladen`,
    changeRole: `Rolle ändern`,
    companyMembersSubtitle: `Klicken Sie hier, um den Unterschied zwischen den Rollen der Teammitglieder anzuzeigen.`,
    role: `Rolle`,
    addEmailForTeamMember: `Bitte fügen Sie die E-Mail des Teammitglieds hinzu`,
    whatIsTheRoleOfTheTeamMember: `Welche Rolle hat das Teammitglied?`,
    member: 'Mitglied',
    admin: 'Administrator',
    invite: 'Einladen',
    enterAValidEmailAddress: `Bitte geben Sie eine gültige E-Mail-Adresse ein.`,
    changeTeamMemberRole: `Rolle des Teammitglieds ändern`,
    selectRoleMember: `Bitte wählen Sie die Rolle aus, die für das Teammitglied gelten soll:`,
    removeTeamMember: `Teammitglied entfernen`,
    removeTeamMemberWithEmail: username => `Möchten Sie Ihr Teammitglied mit der E-Mail-Adresse ${username} wirklich entfernen?`,
    privateCollectionsOfThatMember: `Alle privaten Sammlungen dieses Mitglieds werden für Ihre Organisation zugänglich.`,
    yourTrialsEnds: daysXX => `Ihre Testversion endet in ${daysXX} Tagen.`,
    yourTrialsEndsSubtitle: `Bitte klicken Sie hier, um influData zu abonnieren.`,
    yourSubscriptionsEnds: daysXX => `Ihr Abonnement endet in ${daysXX} Tagen.`,
    yourSubscriptionsEndsSubtitle: `Bitte klicken Sie hier, um Ihr Abonnement fortzusetzen.`,
    removeCancellationScheduled: `Sie sind dabei, Ihre geplante Kündigung zu entfernen und Ihr Abonnement wird nach dem aktuellen Zeitraum verlängert.`
  }
}
