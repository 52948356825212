import { actionProvider } from "./actionsProvider";

export const SET_NAVIGATION = 'SET_NAVIGATION';

const initialState = {
  navigate: null,
};

export const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NAVIGATION:
      return {
        ...state,
        navigate: action.payload,
      };
    default:
      return state;
  }
};

export const setNavigation = payload => actionProvider(SET_NAVIGATION, payload);