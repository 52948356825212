import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import LoadingSpinner from '../common/spinners/LoadingSpinner'
import { SectionsDivider } from '../common/styledWrappers'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 5%;
  margin: 40px 0;
  position: relative;
  ${props =>
    props.isDisabled &&
    `
    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      ${props.theme.disabled}
      background: ${props.theme.color.white};
      z-index: 10;
    }
  `}
`

const Title = styled.h1`
  font-size: 30px;
  min-width: 20%;
  font-family: ${props => props.theme.fontFamilyInter};
  font-weight: 600;
`

const ButtonWrapper = styled.div`
  min-width: 16%;
  display: flex;
  justify-content: flex-end;
`

const ContentWrapper = styled.div`
  // width: -webkit-fill-available;
  width: 100%;
  padding-left: 30px;
`

const ErrorWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1;
  top: 0;
  left: 0;
  ${props => props.theme.flex.centerAll}
`

const ErrorLabel = styled.div`
  font-size: 15px;
  padding: 20px;
  border-radius: 8px;
  background: ${props => props.theme.primaryColorLighter};
  color: ${props => props.theme.color.mineShaft};
`

const DisabledTipWrapper = styled.div`
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  ${props => props.theme.flex.centerAll}
`

const CommonSettingSection = ({
  title,
  Button = null,
  Content = null,
  withLoading = false,
  isLoading = true,
  withDivider = true,
  isError = false,
  errorLabel = '',
  isDisabled = false,
  DisabledTip = null,
}) => {
  return (
    <>
      <Wrapper isDisabled={isDisabled}>
        {isDisabled && DisabledTip && <DisabledTipWrapper>{DisabledTip}</DisabledTipWrapper>}

        {withLoading && isLoading && !isError && (
          <LoadingSpinner isTransparent height="100%" width="100%" />
        )}
        {isError && (
          <ErrorWrapper>{errorLabel && <ErrorLabel>{errorLabel}</ErrorLabel>}</ErrorWrapper>
        )}
        <Title>{title || ''}</Title>
        {Content && <ContentWrapper>{Content}</ContentWrapper>}
        {Button && <ButtonWrapper>{Button}</ButtonWrapper>}
      </Wrapper>
      {withDivider && <SectionsDivider width="90%" />}
    </>
  )
}

CommonSettingSection.propTypes = {
  Button: PropTypes.oneOfType([PropTypes.node, PropTypes.instanceOf(null)]),
  Content: PropTypes.oneOfType([PropTypes.node, PropTypes.instanceOf(null)]),
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  withLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  withDivider: PropTypes.bool,
  isError: PropTypes.bool,
  errorLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  DisabledTip: PropTypes.oneOfType([PropTypes.node, PropTypes.instanceOf(null)]),
}

export default CommonSettingSection
