// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useMsal } from '@azure/msal-react'
import {
  toggleSignUp,
  toggleSignIn,
  setAlreadyExistAccount,
} from '../../redux/ducks/appSettingsDucks'
import {
  changeProcessSignup,
  loginUser,
  setSalesManager,
  setUserEmailFromLanding,
  setUserTrack,
} from '../../redux/ducks/userDucks'
import { setError } from '../../redux/ducks/errorDucks'
import {
  userEmailFromLandingSelector,
  googleAnalyticsDataSelector,
  isSignUpOpenedSelector,
} from '../../redux/selectors'
import httpService from '../../services/httpService'
import { trackEventWithGA, gtagConversionAction } from '../../utils/gaTracking'
import { GA_EVENTS } from '../../constants/gaTracking'
import { ERROR_MSG } from '../../constants/errorMessages'

import { SignupNotVerifiedView } from './newSignUpDesign/views/SignupNotVerifiedView'
import { SignupVerifiedView } from './newSignUpDesign/views/SignupVerifiedView'
import { useLang } from '../../hooks/useLang'
import { LOGIN_REQUEST, loginRequest } from '../../authConfig'
import { validateEmail } from '../../utils'
import { useGeoLocation } from '../../hooks/useGeolacation'
import useLocalIP from '../../hooks/useLocalIp'

const SignUpContainer = () => {
  const dispatch = useDispatch()
  const { search, pathname } = useLocation()
  const lang = useLang()
  const { instance } = useMsal()

  const ip = useLocalIP()
  const getLocation = useGeoLocation({ ip })

  const params = new URLSearchParams(search)
  const isUtmSource = params.get('utm_source')

  const isTrialCampaigns = pathname === '/campaigns-trial' || pathname === '/campaigns-trial/'

  const isSignupOpened = useSelector(isSignUpOpenedSelector)

  const userEmailFromLanding = useSelector(userEmailFromLandingSelector)
  const googleAnalyticsData = useSelector(googleAnalyticsDataSelector)

  const [step, setSteps] = useState(!!userEmailFromLanding ? 1 : 0)

  const [signupState, changeSignupState] = useState({
    stepOneData: {
      email: userEmailFromLanding || '',
      requestedSignupToken: false,
      verifiedSignupToken: false,
    },
    stepTwoData: {
      firstName: '',
      lastName: '',
      company: '',
      country: '',
      phone: '',
      companySize: '',
      position: '',
      collaborationCount: '',
    },
    isLoading: false,
    authMethod: '',
  })
  const [afterSignupData, setAfterSignupData] = useState({
    businessType: '',
    platforms: [],
    features: {
      discovery: false,
      audience: false,
      overlaps: false,
      analytics: false,
      reporting: false,
      management: false,
      sentiment: false,
      exports: false,
    },
    teammatesEmails: [],
  })

  const changeLoadingSignup = loading => {
    changeSignupState(prev => ({
      ...prev,
      stepOneData: {
        ...prev.stepOneData,
      },
      stepTwoData: {
        ...prev.stepTwoData,
      },
      isLoading: loading,
    }))
  }

  useEffect(() => {
    if (isUtmSource) {
      changeSignupState({
        ...signupState,
        authMethod: 'utm',
      })
    }
  }, [isUtmSource])

  const goToStepTwo = async ({ email }) => {
    try {
      changeSignupState({ ...signupState, isLoading: true })
      const response = await httpService.fetchIsTakenEmail(email)
      if (response.ok) {
        const { subscribed } = await response.json()

        if (!subscribed) {
          const resRequestSignupToken = await httpService.requestSignupToken({ email })

          if (resRequestSignupToken) {
            changeSignupState({
              stepOneData: { email, requestedSignupToken: true },
              isLoading: false,
              authMethod: 'chargebee',
            })
            setSteps(0)
          }
        } else {
          dispatch(setAlreadyExistAccount())
        }
      } else {
        dispatch(setError(ERROR_MSG.failProceed))
        changeLoadingSignup(false)
      }
    } catch (err) {
      dispatch(setError(ERROR_MSG.failProceed))
      changeLoadingSignup(false)
    } finally {
      changeLoadingSignup(false)
    }
  }

  const stepTwoVerifiedSignupToken = async code => {
    const { stepOneData } = signupState || {}

    changeLoadingSignup(true)

    try {
      const res = await httpService.verifySignupToken({ email: stepOneData.email, code })
      if (res) {
        changeSignupState({
          stepOneData: {
            email: stepOneData.email,
            requestedSignupToken: false,
            verifiedSignupToken: true,
          },
          isLoading: false,
          authMethod: 'chargebee',
        })
        setSteps(1)
      }
    } catch (err) {
      changeLoadingSignup(false)
    } finally {
      changeLoadingSignup(false)
    }
  }

  const goToStepTwoGoogle = async googleData => {
    try {
      const token = googleData.credential // Replace 'credential' with the actual variable
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace('-', '+').replace('_', '/')
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )

      const { email } = JSON.parse(jsonPayload) || {}

      changeLoadingSignup(true)
      const response = await httpService.fetchIsTakenEmail(email)
      if (response.ok) {
        const { subscribed } = await response.json()
        if (subscribed) {
          dispatch([loginUser({ email: email, password: token, method: 'google', isOnboardingUser: true })])
        } else {
          changeSignupState(prev => ({
            ...prev,
            stepOneData: { email, token },
            isLoading: false,
            authMethod: 'google',
          }))
          changeLoadingSignup(false)
          setSteps(1)
        }
      } else {
        dispatch(setError(ERROR_MSG.failProceed))
        changeLoadingSignup(false)
      }
    } catch (err) {
      dispatch(setError(ERROR_MSG.failProceed))
      changeLoadingSignup(false)
    } finally {
      changeLoadingSignup(false)
    }
  }

  const goToStepAzure =  async () => {
    try {
      const signupResponse = await instance.loginPopup(LOGIN_REQUEST)
      const { accessToken, account } = signupResponse || {}
      const { idToken, username } = account || {}

      const userEmail = validateEmail(username) ? username : ''
      changeLoadingSignup(true)

      const response = await httpService.fetchIsTakenEmail(userEmail)
      if (response.ok) {
        const { subscribed } = await response.json()
        if (subscribed) {
          dispatch([loginUser({ email: userEmail, password: idToken, method: 'azuread', isOnboardingUser: true })])          
        } else {
          changeSignupState(prev => ({
            ...prev,
            stepOneData: { email: userEmail, token: idToken },
            isLoading: false,
            authMethod: 'azuread',
          }))
          changeLoadingSignup(false)
          setSteps(1)
        }
      } else {
        changeLoadingSignup(false)
        dispatch(setError(ERROR_MSG.failProceed))
      }
    } catch (err) {
      console.error(err)
      changeLoadingSignup(false)
      dispatch(setError(ERROR_MSG.failProceed))
    } finally {
      changeLoadingSignup(false)
    }
  }

  const onCloseClick = () => {
    dispatch([setUserEmailFromLanding(''), toggleSignUp()])
  }

  const finishSignup = async ({
    firstName,
    lastName,
    company,
    country,
    phone,
    password,
    companySize,
    position,
    collaborationCount,
    signUpMethod,
  }) => {
    if (signupState.isLoading) return
    const {
      stepOneData: { email, token = null },
    } = signupState
  
    try {
      const locale = lang?.startsWith('de') ? 'de' : 'en'
      changeLoadingSignup(true)
      window.onbeforeunload = () => '' // prevent from accidental closing while user is creating
    
      // Make the createNewCustomer request
      const { track, customerId } = await httpService.createNewCustomer({
        username: email,
        ...(signUpMethod === 'azuread' && token && { azureIdToken: token }),
        companyData: {
          firstName,
          lastName,
          company,
          phone,
          country,
          position,
          companySize,
          collaborationCount,
        },
        pass_through: JSON.stringify({ password }),
        isDirectTrialAvailable: false,
        googleAnalyticsData,
        signUpMethod,
        locale,
      });

      // Update signup state
      changeSignupState((prev) => ({
        ...prev,
        stepOneData: {
          ...prev.stepOneData,
        },
        stepTwoData: {
          firstName,
          lastName,
          company,
          country,
          phone,
          companySize,
          position,
          collaborationCount,
          customerId
        },
      }));
      
  
      window.onbeforeunload = null
  
      if (track) {
        window.dataLayer.push({
          event: GA_EVENTS.actions.signupHq,
        })
  
        trackEventWithGA({
          eventCategory: GA_EVENTS.categories.signupHq,
          eventAction: GA_EVENTS.actions.signupHq,
        })
        gtagConversionAction()
  
        dispatch([setSalesManager({ salesManager, calendlyLink }), setUserTrack(true)])
      }
      await dispatch([
        changeProcessSignup(true),
        loginUser({
          email,
          password: password || token,
          method: signUpMethod,
          isOnboardingUser: true,
        }),
      ])
  
      changeLoadingSignup(false)
      setSteps(2)
      // } else {
      //   throw ERROR_MSG.failProceed
      // }
    } catch (err) {
      setSteps(0)
      window.onbeforeunload = null
      changeLoadingSignup(false)
      dispatch(setError(ERROR_MSG.failProceed))
    } finally {
      changeLoadingSignup(false)
    }
  };  


  return (
    <>
      {step !== 0 ? (
        <SignupVerifiedView
          finishSignup={finishSignup}
          authMethod={signupState.authMethod}
          currentStep={step}
          onClose={onCloseClick}
          isOpened={isSignupOpened}
          userData={signupState.stepTwoData}
          afterSignupData={afterSignupData}
          setAfterSignupData={setAfterSignupData}
          setSteps={setSteps}
          isLoading={signupState.isLoading}
          isTrialCampaigns={isTrialCampaigns}
        />
      ) : (
        <SignupNotVerifiedView
          stepOneData={signupState.stepOneData}
          goToStepTwoGoogle={goToStepTwoGoogle}
          goToStepAzure={goToStepAzure}
          goToStepTwo={goToStepTwo}
          stepTwoVerifiedSignupToken={stepTwoVerifiedSignupToken}
          onClose={onCloseClick}
          isOpened={isSignupOpened}
          isLoading={signupState.isLoading}
          isTrialCampaigns={isTrialCampaigns}
        />
      )}
    </>
  )
}

export default SignUpContainer
