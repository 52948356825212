import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import {
  userGrantSelector,
  isUserAuthenticatedSelector,
  isUserDetailsPresentSelector,
  isUserDetailsLoadingSelector,
  isSubUserSelector,
  userRolesSelector,
} from "../../redux/selectors"
import { withReduxActions } from "../../hocs/withRedux"
import { getUserDetails } from "../../redux/ducks/userDucks"

import BackToTopOfPage from "../common/backToTopOfPage"
import DeleteAccountContainer from "./deleteAccount/DeleteAccountContainer"
import AccountSettingsContainer from "./accountSettings/AccountSettingsContainer"
import BillingDetailsContainer from "./billingDetails/BillingDetailsContainer"
import PaymentsContainer from "./payments/PaymentsContainer"
import AddOnsContainer from "./addOns/AddOnsContainer"
import SubUsersContainer from "./subUsers/SubUsersContainer"
import SubUserSettingsTip from "./SubUserSettingsTip"
import {
  SectionsDivider,
  SettingsContainerWrapper,
} from "../common/styledWrappers"

import { Box } from '@mui/material';


const SettingsContainer = ({ pageId = "", getUserDetails }) => {
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const {
    package_free: isFreeUser,
    no_plan: isNoPlan,
    no_payment_method,
    in_trial_infludata,
    ...otherGrants
  } = useSelector(userGrantSelector)
  const roles = useSelector(userRolesSelector)

  const isIncludeCanceledGrant = Object.keys(otherGrants)?.some((grant) =>
    grant?.startsWith("cancelled_"),
  )

  const isUserDetailsPresent = useSelector(isUserDetailsPresentSelector)
  const isUserDetailsLoading = useSelector(isUserDetailsLoadingSelector)
  const isSubUser = useSelector(isSubUserSelector)

  const [isOpenEditDetails, setIsOpenEditDetails] = useState(false)

  const showDeleteAccount = isNoPlan
  const isAddPaymentMethod = no_payment_method && in_trial_infludata
  const isCanceledStatus = no_payment_method && isIncludeCanceledGrant

  useEffect(() => {
    if (isUserAuthenticated) {
      !isUserDetailsPresent && !isUserDetailsLoading && getUserDetails()
    }
  }, [isUserAuthenticated])

  return (
    <Box sx={{ backgroundColor: 'white', paddingTop: '30px' }}>
      <SettingsContainerWrapper>
          <>
          {isUserAuthenticated && (
            <>
              <AccountSettingsContainer
                isAddPaymentMethod={isAddPaymentMethod}
                isCanceledStatus={isCanceledStatus}
                setIsOpenEditDetails={setIsOpenEditDetails}
              />
              {roles.includes('admin') && (
                <>
                  <BillingDetailsContainer
                    isOpenEditDetails={isOpenEditDetails}
                  />
                  <PaymentsContainer />
                </>
              )}
              {!isFreeUser && roles.includes('admin') && <AddOnsContainer />}
              {!isFreeUser && roles.includes('admin') && <SubUsersContainer />}
            </>
          )}
          {!roles.includes('admin') && <SubUserSettingsTip />}
        </>
        <SectionsDivider width='90%' />
        {isUserAuthenticated && (
          <>
            {showDeleteAccount && (
              <>
                <SectionsDivider width='90%' />
                <DeleteAccountContainer />
              </>
            )}
          </>
        )}
      </SettingsContainerWrapper>
    </Box>
  )
}

SettingsContainer.propTypes = {
  pageId: PropTypes.string,
  getUserDetails: PropTypes.func,
}

export default withReduxActions({ getUserDetails })(SettingsContainer)
