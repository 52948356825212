import React, { useEffect, useState } from "react"
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Divider as MuiDivider,
  Box,
  ListItemButton,
  Popover,
  IconButton,
  Tooltip,
} from "@mui/material"
import PublicRoundedIcon from "@mui/icons-material/PublicRounded"
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded"
import useTranslation from "../../../../../localization/useTranslation"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import { filterTruthyKeys } from "../utils"
import { SOCIAL_PLATFORMS_NAMES } from "../../../../../constants/appSettings"
import {
  AgeSvgIcon,
  GenderSvgIcon,
  LangSvgIcon,
  RealPeopleSvgIcon,
} from "../../icons"
import { AudienceByAgeFilter } from "../filters/AudienceByAgeFilter"
import { AudienceRealPeopleFilter } from "../filters/AudienceRealPeopleFilter"
import { AudienceGenderFilter } from "../filters/AudienceGenderFilter"
import { AudienceCountryFilter } from "../filters/AudienceCountryFilter"
import { AudienceLanguageFilter } from "../filters/AudienceLanguageFilter"
import { animationHideSearchFilter, animationShowSearchFilter } from "../../../../theme/animations"
import { infludataThemes } from "../../../../../themes/infludataThemes"

export const AudienceSearchParams = (props) => {
  const {
    query,
    permissions,
    selectedPlatform,
    handlersMap,
    searchType,
    currentOpenSearchParam,
    setCurrentOpenSearchParam,
  } = props || {}
  const { labelStrings } = useTranslation()

  const [anchorEl, setAnchorEl] = useState(null)
  const [currId, setCurrId] = useState(null)

  const activeQueryParams = filterTruthyKeys(query)

  const finedActiveParam = (searchedParam) => {
    return activeQueryParams?.includes(searchedParam)
  }

  const handlePopoverOpen = (event, id) => {
    setAnchorEl(event.currentTarget)
    setCurrId(id)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setCurrId(null)
    setCurrentOpenSearchParam("")
  }

  const menuForCreator = [
    {
      ...(selectedPlatform !== SOCIAL_PLATFORMS_NAMES.youtube.name && {
        name: labelStrings.country,
        icon: <PublicRoundedIcon />,
        id: "audienceCountries",
        isDisabled: false,
        tipText: "",
      }),
    }, 
    {
      name: labelStrings.gender,
      icon: <GenderSvgIcon />,
      id: "audienceGender",
      isDisabled: false,
      tipText: "",
    },
    {
      ...(selectedPlatform !== SOCIAL_PLATFORMS_NAMES.youtube.name && {
        name: labelStrings.realPeople,
        icon: <RealPeopleSvgIcon />,
        id: "audienceRealPeople",
        isDisabled: false,
        tipText: "",
      }),
    },
    {
      ...(selectedPlatform !== SOCIAL_PLATFORMS_NAMES.youtube.name && {
        name: labelStrings.age,
        icon: <AgeSvgIcon />,
        id: "audienceAgeRanges",
        isDisabled: false,
        tipText: "",
      }),
    },
    {
      ...(selectedPlatform === SOCIAL_PLATFORMS_NAMES.youtube.name && {
        name: labelStrings.language,
        icon: <LangSvgIcon />,
        id: "audienceLanguages",
        isDisabled: false,
        tipText: "",
      }),
    },
  ]

  const filterActions = {
    ["audienceCountries"]: (
      <AudienceCountryFilter
        query={query}
        handlersMap={handlersMap}
        isModalOpen={!!anchorEl}
      />
    ),
    ["audienceGender"]: (
      <AudienceGenderFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        selectedPlatform={selectedPlatform}
      />
    ),
    ["audienceRealPeople"]: (
      <AudienceRealPeopleFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        selectedPlatform={selectedPlatform}
      />
    ),
    ["audienceAgeRanges"]: (
      <AudienceByAgeFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        selectedPlatform={selectedPlatform}
      />
    ),
    ["audienceLanguages"]: (
      <AudienceLanguageFilter
        query={query}
        handlersMap={handlersMap}
        selectedPlatform={selectedPlatform}
        isModalOpen={!!anchorEl}
      />
    ),
  }

  const allSearchParamsIds = menuForCreator?.map((el) => el.id)
  useEffect(() => {
    if (
      currentOpenSearchParam &&
      allSearchParamsIds?.includes(currentOpenSearchParam)
    ) {
      const getActiveListItem = document.getElementById(currentOpenSearchParam)
      setAnchorEl(getActiveListItem)
      setCurrId(currentOpenSearchParam)
    }
  }, [currentOpenSearchParam, allSearchParamsIds?.length])

  return (
    <Stack>
      <Typography
        sx={{
          fontSize: "10px",
          fontWeight: 700,
          fontFamily: "Inter",
          textTransform: "uppercase",
          ml: 1.5,
          color: "#442424",
        }}
      >
        {labelStrings.audience}
      </Typography>
      <MuiDivider sx={{ height: "1px", borderColor: "#E3C5C5", ml: 1.5 }} />
      <List dense={false} disabled={!permissions.allowAudienceSearch}>
        {menuForCreator?.map((el) => {
          if (!el.id) return

          const isActiveMenuItem = finedActiveParam(el.id)
          return (
            <Tooltip
              arrow
              placement='right'
              title={el.isDisabled ? el.tipText : ""}
              key={el.id}
            >
              <ListItem
                aria-haspopup='true'
                onClick={(event) =>
                  el?.isDisabled ? null : handlePopoverOpen(event, el.id)
                }
                disablePadding
                id={el.id}
                sx={{
                  "& .MuiListItemText-primary": {
                    fontSize: "12px",
                    fontWeight: 700,
                    fontFamily: "Inter",
                    textTransform: 'capitalize',
                    color:
                      (currId === el.id && anchorEl) || finedActiveParam(el.id)
                        ? infludataThemes[selectedPlatform]?.platformColor
                        : "#442424",
                    "&:hover": {
                      color: infludataThemes[selectedPlatform]?.platformColor,
                    },
                  },
                  color:
                    (currId === el.id && anchorEl) || finedActiveParam(el.id)
                      ? infludataThemes[selectedPlatform]?.platformColor
                      : "#442424",
                  "&:hover": {
                    color: infludataThemes[selectedPlatform]?.platformColor,
                    "& .MuiTypography-root": {
                      color: infludataThemes[selectedPlatform]?.platformColor,
                    },
                  },
                  backgroundColor:
                    currId === el.id && anchorEl ? "white" : "transparent",
                  borderRadius: "8px",
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  boxShadow:
                    currId === el.id && anchorEl
                      ? `2px 4px 5px 0px rgba(107, 69, 69, 0.20)`
                      : "unset",
                  border:
                    currId === el.id && anchorEl
                      ? "0.5px solid #FBEBEB"
                      : "0.5px solid transparent",
                  borderRight: "unset",
                }}
                {...(isActiveMenuItem
                  ? {
                      secondaryAction: (
                        <Box>
                          <FiberManualRecordRoundedIcon
                            sx={{ fontSize: "12px" }}
                          />
                        </Box>
                      ),
                    }
                  : {})}
              >
                <ListItemButton sx={{ pl: 1 }} disabled={el.isDisabled}>
                  <ListItemIcon>{el.icon}</ListItemIcon>
                  <ListItemText primary={el.name} />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          )
        })}
      </List>

      <Popover
        sx={{
          ".MuiPopover-paper": {
            boxShadow: `unset`,
            borderTopRightRadius: "16px",
            borderBottomRightRadius: "16px",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: "16px",
            border: "1px solid #FBEBEB",
            borderLeft: "unset",
            p: 1,
          },
          animation: anchorEl
            ? `${animationShowSearchFilter} 0.2s ease-in-out 0s 1 normal both`
            : `${animationHideSearchFilter} 0.3s ease-in-out forwards`,
        }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Stack sx={{ p: 1, position: "relative" }}>
          {!!anchorEl && (
            <Stack
              direction='row'
              justifyContent='end'
              alignItems='start'
              sx={{ position: "absolute", top: "-10px", right: "-8px" }}
            >
              <IconButton size='small' onClick={handlePopoverClose}>
                <CloseRoundedIcon sx={{ fill: "#6B4545", fontSize: "14px" }} />
              </IconButton>
            </Stack>
          )}
          {filterActions[currId]}
        </Stack>
      </Popover>
    </Stack>
  )
}
