import { useSelector } from 'react-redux'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import useTranslation from '../../../../localization/useTranslation'
import AudienceReportsTokensStat from '../../../../components/tokens/stats/AudienceReportsTokensStat'
import CampaignsSeatsTokensStat from '../../../../components/tokens/stats/CampaignsSeatsTokensStat'
import { NavbarProfileIcon } from '../navbarIcons'
import { userDetailsSelector } from '../../../../redux/selectors'
import { Button, Box, Divider, Stack, Typography } from '@mui/material'
import PopupState, { bindPopover, bindHover } from 'material-ui-popup-state'
import HoverPopover from 'material-ui-popup-state/HoverPopover'

const ProfileNavMenu = ({
  onLogoutClick,
  goToSettings,
  showTokensStats,
  showCampaignsTrackStats,
  isTrial,
}) => {
  const { labelStrings } = useTranslation()
  const location = useLocation()
  const { profile_data } = useSelector(userDetailsSelector) || {}

  const isActive = location?.pathname?.includes('settings')

  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <Box
      sx={{
        width: '100%',
        fontFamily: 'Inter',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <PopupState variant="popover" popupId="profile-popup-menu">
        {popupState => (
          <Box>
            <Button
              sx={{
                minWidth: '40px',
                '&:hover': { backgroundColor: 'transparent' },
              }}
              {...bindHover(popupState)}
              onClick={goToSettings}
            >
              <NavbarProfileIcon isActive={isActive || isHovered} />
            </Button>
            <HoverPopover
              {...bindPopover(popupState)}
              // open={true}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              slotProps={{
                paper: {
                  sx: {
                    backgroundColor: 'transparent !important',
                    borderRadius: '16px !important',
                    fontFamily: 'Inter !important',
                  },
                },
              }}
            >
              <Box
                sx={{
                  border: `1px solid #e3c5c5`,
                  borderRadius: `16px`,
                  backgroundColor: `white`,
                  width: '237px',
                }}
              >
                {showTokensStats && (
                  <Box
                    sx={{
                      overflow: 'hidden',
                      position: 'relative',
                      borderTopLeftRadius: '16px',
                      borderTopRightRadius: '16px',
                    }}
                  >
                    <AudienceReportsTokensStat isTrial={isTrial} />
                    {showCampaignsTrackStats && (
                      <>
                        <Divider sx={{ width: '100%', borderColor: '#E3C5C5' }} />
                        <CampaignsSeatsTokensStat />
                      </>
                    )}
                  </Box>
                )}
                <Box>
                  <Divider sx={{ width: '100%', borderColor: '#E3C5C5' }} />
                  <Box sx={{ p: '5px', px: 1, minHeight: '50px' }}>
                    <Button
                      disableRipple
                      variant="text"
                      onClick={goToSettings}
                      sx={{
                        color: '#442424',
                        fontWeight: 700,
                        fontFamily: 'Inter',
                        textTransform: 'capitalize',
                        fontSize: '14px',
                        '&:hover': {
                          backgroundColor: 'unset',
                          textDecoration: 'underline #6B4545',
                        },
                      }}
                    >
                      {labelStrings.editYourAccount}
                    </Button>
                  </Box>
                  <Divider sx={{ width: '100%', borderColor: '#E3C5C5' }} />
                  <Box sx={{ p: '5px', px: 1, minHeight: '50px' }}>
                    <Button
                      disableRipple
                      variant="text"
                      onClick={onLogoutClick}
                      sx={{
                        '&:hover': {
                          backgroundColor: 'unset',
                          textDecoration: 'underline #6B4545',
                        },
                        '& .MuiTypography-root': {
                          fontSize: '14px',
                          color: '#442424',
                          fontWeight: 700,
                          fontFamily: 'Inter',
                          textTransform: 'capitalize',
                        },
                      }}
                    >
                      <Stack alignItems="start">
                        <Typography noWrap sx={{ maxWidth: '200px' }}>{`${labelStrings.logout} ${
                          profile_data?.email ? `(${profile_data?.email})` : ''
                        }`}</Typography>
                      </Stack>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </HoverPopover>
          </Box>
        )}
      </PopupState>
    </Box>
  )
}

export default ProfileNavMenu
