import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'
import CloseButton from '../buttons/CloseButton'
import PrimaryButton from '../buttons/PrimaryButton'
import CancelPrimaryButton from '../buttons/CancelPrimaryButton'
import CommonSingleRangeSlider from '../sliders/CommonSingleRangeSlider'
import { SectionsDivider } from '../styledWrappers'
import { EditWrapper, DialogBluredWrapper, DialogBluredTitle } from './dialogsCommonStyles'
import { DEFAULT_CAMPAIGN_SEATS } from '../../../constants/campaigns'

const DialogWrapper = styled(DialogBluredWrapper)`
  min-width: 489px;
  max-width: 489px;
  position: relative;
  margin-bottom: 0;
  padding: 29px 0 10px 0;
  & * {
    font-family: ${props => props.theme.fontFamilyInter};
  }
`

const DialogContent = styled(EditWrapper)`
  position: relative;
`

const ButtonsWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Text = styled.div`
  width: 95%;
  margin: 15px auto;
  text-align: center;
  font-size: 15px;
  ${props => props.minHeight && `min-height: 90px;`}

  ${props => props.theme.flex.centerAll}
`
const SliderSection = styled.div`
  padding: 30px 4% 10px 4%;
  background: ${props => props.theme.color.congoBrown};
`

const SelectedSeatsWrapper = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: ${props => props.theme.color.white} !important;
  text-transform: capitalize;
  text-align: center;
`

const DefaultSeatsWrapper = styled.div`
  font-size: 13px;
  color: ${props => props.theme.color.white} !important;
  text-align: center;
`

const PriceSection = styled.div`
  ${props => props.theme.flex.centerAll}
  background: ${props => props.theme.color.congoBrown};
  padding: 10px 0;
  color: ${props => props.theme.color.white} !important;
  font-weight: bold;
  font-size: 20px;
  min-height: 50px;
`

const SliderWrapper = styled.div`
  width: 90%;
  margin: 30px auto 10px auto;

  & .single-range-slider-tracks_single_track {
    background: ${props => props.theme.primaryColor};
  }
  & .single_range_slider_rail_one,
  & .single_range_slider_rail_two,
  & .single-range-slider-tracks_single_track {
    height: 7px;
  }

  & .single_range_slider_rail_two {
    background: #b99796;
  }
  & .single-range-slider-ticks_single_tick_label {
    color: #b99796;
    font-size: 13px;
    margin-top: 16px;
  }
  & .single-range-slider-ticks_single_tick_line {
    background-color: #b99796;
    margin-top: 4px;
    &::after {
      left: -55px;
      min-width: 115px;
      font-size: 12px;
      text-align: center;
    }
  }
`

const CampaignsBuySeatsDialog = ({
  closeModal,
  confirmHandler,
  contactUsHandler,
  seatsMap = {},
  initSeat = 0,
  onChangeSeats,
  needMoreSeats = false,
  isCurrentPlan = false,
  isUpgradeSeats = false,
  oneSeatPrice = 0,
  seatsCharge = 0,
  isDowngradeSeats = false,
  isNotAllowedDowngrade = false,
  downgradeExceedLimitSeatsCount = 0,
  selectedSeatsLabel = '',
  alreadyPurchasedSeats = 0,
}) => {
  const { labelStrings } = useTranslation()

  const createBelowTip = () => {
    if (needMoreSeats) return labelStrings.needMoreSeatsContactUs
    if (isUpgradeSeats) return labelStrings.purchaseNewSeatsTip
    if (isDowngradeSeats) return labelStrings.allowedDowngradeSeatsTip
    if (isCurrentPlan) return labelStrings.currentSeatsPlan
  }

  return (
    <DialogWrapper>
      <DialogBluredTitle>{labelStrings.changeSeatPlan}</DialogBluredTitle>
      <CloseButton close={closeModal} top="14px" right="14px" />

      <DialogContent>
        <Text>
          {labelStrings.please} {labelStrings.chooseNumberOfSeats.toLowerCase()}
        </Text>

        <SliderSection>
          <SliderWrapper>
            <CommonSingleRangeSlider
              initValue={initSeat}
              sliderRanges={seatsMap}
              onRangeChange={onChangeSeats}
              defaultValue={alreadyPurchasedSeats}
              defaultValueLabel={labelStrings.currentPlan}
            />
          </SliderWrapper>
          <SelectedSeatsWrapper>
            {selectedSeatsLabel} {labelStrings.seats}
          </SelectedSeatsWrapper>
          <DefaultSeatsWrapper>
            + {labelStrings.seatsFromYourPlan(DEFAULT_CAMPAIGN_SEATS.pro)}
          </DefaultSeatsWrapper>
        </SliderSection>
        <SectionsDivider />
        <PriceSection>
          {!needMoreSeats && (
            <>
              {seatsCharge} {labelStrings.eurPerMonth} ({oneSeatPrice} {labelStrings.eur}{' '}
              {labelStrings.perSeat})
            </>
          )}
        </PriceSection>

        <Text minHeight="90px">
          {isNotAllowedDowngrade && !!downgradeExceedLimitSeatsCount
            ? labelStrings.forbiddenDowngradeSeatsTip(downgradeExceedLimitSeatsCount)
            : createBelowTip()}
        </Text>

        <ButtonsWrapper>
          <PrimaryButton
            onClick={needMoreSeats ? contactUsHandler : confirmHandler}
            isDisabled={isCurrentPlan || isNotAllowedDowngrade}
            label={needMoreSeats ? labelStrings.contactUs : labelStrings.confirm}
          />

          <CancelPrimaryButton onClick={closeModal} />
        </ButtonsWrapper>
      </DialogContent>
    </DialogWrapper>
  )
}

CampaignsBuySeatsDialog.propTypes = {
  closeModal: PropTypes.func,
  confirmHandler: PropTypes.func,
  contactUsHandler: PropTypes.func,
  seatsMap: PropTypes.object,
  initSeat: PropTypes.number,
  onChangeSeats: PropTypes.func,
  needMoreSeats: PropTypes.bool,
  isCurrentPlan: PropTypes.bool,
  isUpgradeSeats: PropTypes.bool,
  oneSeatPrice: PropTypes.number,
  seatsCharge: PropTypes.number,
  isDowngradeSeats: PropTypes.bool,
  isNotAllowedDowngrade: PropTypes.bool,
  downgradeExceedLimitSeatsCount: PropTypes.number,
  selectedSeatsLabel: PropTypes.string,
  alreadyPurchasedSeats: PropTypes.number,
}

export default CampaignsBuySeatsDialog
