import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import Masonry from "react-masonry-css"
import { ProfileContentItem } from "./ProfileContentItem"
import { LoaderWrapper } from "./styles"
import LoadingSpinner from "../../../common/spinners/LoadingSpinner"
import { Box } from "@mui/material"
import { FAV_COLLECTION_NAME } from "../../../../constants/collections"

const breakpointColumnsObj = {
  default: 6,
  1890: 5,
  1550: 4,
  // 1390: 4,
  1330: 3,
  // 1250: 3,
  350: 1,
}

export const ProfileContentTable = ({
  content = [],
  isSearchLoading = false,
  setActiveCard,
  activeCard = {},
  openedCollection = {},
  currentQuery
}) => {
  const [renderedContent, setRenderedContent] = useState(content)

  const { id: currCollectionId } = openedCollection || {}
  const isContentFav = currCollectionId?.startsWith(FAV_COLLECTION_NAME)

  useEffect(() => {
    setRenderedContent(content)
  }, [content])

  return (
    <>
      {isSearchLoading ? (
        <LoaderWrapper>
          <LoadingSpinner />
        </LoaderWrapper>
      ) : (
        <Box sx={{ width: "100%", height: "100%" }}>
          <Box
            sx={{
              py: 1,
              px: 1,
              "& .content-masonry-grid": {
                display: `flex`,
                width: `auto`,
                justifyContent: `center`,
                gap: `16px`,
              },
            }}
          >
            <Masonry
              className='content-masonry-grid'
              columnClassName='content-masonry-grid_column'
              breakpointCols={breakpointColumnsObj}
            >
              {renderedContent?.map((profile, idx) => (
                <Fragment key={`${profile.profileId}${idx}`}>
                  <ProfileContentItem
                    profile={profile}
                    setActiveCard={setActiveCard}
                    activeCard={activeCard}
                    setRenderedContent={setRenderedContent}
                    isContentFav={isContentFav}
                    currentQuery={currentQuery}
                  />
                </Fragment>
              ))}
            </Masonry>
          </Box>
        </Box>
      )}
    </>
  )
}

ProfileContentTable.propTypes = {
  content: PropTypes.array,
  isSearchLoading: PropTypes.bool,
  setActiveCard: PropTypes.func,
  activeCard: PropTypes.object,
  openedCollection: PropTypes.object,
  currentQuery: PropTypes.object
}
