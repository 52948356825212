import * as React from "react"

export const NavbarInfludataIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={26}
    height={36}
    fill='none'
    {...props}
  >
    <path
      fill='url(#a)'
      d='M25.288 16.491c-.279-1.071-.885-1.664-1.863-2.635l-.757-.755-6.359-6.312-3.721-3.694C10.628 1.15 9.64.175 8.198.021A4.103 4.103 0 0 0 6.22.309a4.073 4.073 0 0 0-1.602 1.188c-.916 1.115-.916 2.565-.916 5.196a2.143 2.143 0 0 0 .574 1.506c.38.41.906.657 1.466.69a2.152 2.152 0 0 0 2.073-1.285c.114-.264.172-.549.17-.837v-1.37a.328.328 0 0 1 .213-.312.339.339 0 0 1 .372.07c.31.299.646.633.982.967L13.35 9.89l7.04 6.988.407.408a.333.333 0 0 1 .071.37.277.277 0 0 1-.07.109c-.125.127-.266.268-.408.404l-6.673 6.61-4.155 4.124c-.336.33-.673.668-.982.966a.346.346 0 0 1-.584-.241V28.06a9.183 9.183 0 0 1 2.26-6.105 5.771 5.771 0 0 0 .945-6.2 5.823 5.823 0 0 0-2.131-2.488 5.885 5.885 0 0 0-6.316-.072 5.826 5.826 0 0 0-2.189 2.44 5.772 5.772 0 0 0 .802 6.219A9.66 9.66 0 0 1 3.71 28.06v.198c0 2.75 0 4.133.916 5.27a4.091 4.091 0 0 0 3.164 1.493c.137 0 .279 0 .416-.022a3.9 3.9 0 0 0 2.022-.878c.112-.076.217-.16.315-.255.146-.118.291-.246.442-.386.482-.44 1.027-.98 1.606-1.555l4.102-4.071 6.74-6.69c.986-.979 1.584-1.572 1.858-2.635.171-.669.17-1.37-.004-2.038ZM5.844 20.883a2.79 2.79 0 0 1-1.54-.466 2.758 2.758 0 0 1-1.02-1.237 2.734 2.734 0 0 1 .604-3 2.794 2.794 0 0 1 3.023-.595c.507.21.94.562 1.245 1.015.304.453.467.985.467 1.53a2.748 2.748 0 0 1-.819 1.941 2.79 2.79 0 0 1-1.96.804v.008Z'
    />
    <defs>
      <linearGradient
        id='a'
        x1={30}
        x2={12.71}
        y1={4}
        y2={35.021}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF502A' />
        <stop offset={0.475} stopColor='#FF650F' />
        <stop offset={1} stopColor='#FFB504' />
      </linearGradient>
    </defs>
  </svg>
)

export const NavbarLookaLikeIcon = (props) => {
  return props.isActive ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={33}
      height={23}
      fill='none'
      {...props}
    >
      <defs>
        <linearGradient id='hoverGradient' x1='0' y1='0' x2='1' y2='0'>
          <stop offset='0.35%' stopColor='#FFC300' />
          <stop offset='100%' stopColor='#F50' />
        </linearGradient>
      </defs>
      <path
        fill='url(#hoverGradient)'
        className='hoverGradient'
        d='M5.514 17.777c.634-1.074 1.172-2.295 1.66-3.662a86.63 86.63 0 0 0 1.22 3.223 12.437 12.437 0 0 1-.83 1.611c-1.22 2.002-2.93 3.613-5.517 3.613-.684 0-1.172-.488-1.172-1.171 0-.635.488-1.172 1.172-1.172 1.416 0 2.49-.782 3.467-2.442Zm7.47-12.256c-.634 1.026-1.171 2.295-1.66 3.663-.342-1.075-.781-2.149-1.22-3.223a8.501 8.501 0 0 1 .878-1.611C12.154 2.348 13.863.688 16.5.688c2.588 0 4.297 1.66 5.518 3.662 1.171 1.904 2.002 4.443 2.783 6.835v.098c.83 2.49 1.611 4.785 2.637 6.494.976 1.66 2.1 2.442 3.515 2.442.635 0 1.172.537 1.172 1.172 0 .683-.537 1.172-1.172 1.172-2.637 0-4.346-1.612-5.517-3.614-1.172-1.904-2.051-4.492-2.832-6.836v-.097c-.83-2.49-1.612-4.786-2.637-6.495-.977-1.66-2.051-2.49-3.467-2.49s-2.54.83-3.516 2.49Zm6.983 12.256c.635-1.074 1.172-2.295 1.66-3.662.39 1.075.781 2.149 1.22 3.223a12.453 12.453 0 0 1-.83 1.611c-1.22 2.002-2.93 3.613-5.517 3.613-2.637 0-4.346-1.61-5.518-3.613-1.171-1.904-2.05-4.492-2.832-6.836v-.097C7.32 9.526 6.54 7.23 5.514 5.52c-.977-1.66-2.051-2.49-3.467-2.49-.684 0-1.172-.488-1.172-1.172 0-.634.488-1.171 1.172-1.171 2.588 0 4.297 1.66 5.517 3.662 1.172 1.904 2.002 4.443 2.784 6.835v.098c.83 2.49 1.611 4.785 2.636 6.494.977 1.66 2.1 2.442 3.516 2.442s2.49-.782 3.467-2.442Zm7.47-12.256c-.634 1.026-1.171 2.295-1.66 3.663-.341-1.075-.78-2.149-1.22-3.223a8.507 8.507 0 0 1 .879-1.611c1.171-2.002 2.88-3.662 5.517-3.662.635 0 1.172.537 1.172 1.171 0 .684-.537 1.172-1.172 1.172-1.416 0-2.539.83-3.515 2.49Z'
      />
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={33}
      height={23}
      fill='none'
      {...props}
    >
      <path
        fill='#A06E6E'
        d='M5.514 17.777c.634-1.074 1.172-2.295 1.66-3.662a86.63 86.63 0 0 0 1.22 3.223 12.437 12.437 0 0 1-.83 1.611c-1.22 2.002-2.93 3.613-5.517 3.613-.684 0-1.172-.488-1.172-1.171 0-.635.488-1.172 1.172-1.172 1.416 0 2.49-.782 3.467-2.442Zm7.47-12.256c-.634 1.026-1.171 2.295-1.66 3.663-.342-1.075-.781-2.149-1.22-3.223a8.501 8.501 0 0 1 .878-1.611C12.154 2.348 13.863.688 16.5.688c2.588 0 4.297 1.66 5.518 3.662 1.171 1.904 2.002 4.443 2.783 6.835v.098c.83 2.49 1.611 4.785 2.637 6.494.976 1.66 2.1 2.442 3.515 2.442.635 0 1.172.537 1.172 1.172 0 .683-.537 1.172-1.172 1.172-2.637 0-4.346-1.612-5.517-3.614-1.172-1.904-2.051-4.492-2.832-6.836v-.097c-.83-2.49-1.612-4.786-2.637-6.495-.977-1.66-2.051-2.49-3.467-2.49s-2.54.83-3.516 2.49Zm6.983 12.256c.635-1.074 1.172-2.295 1.66-3.662.39 1.075.781 2.149 1.22 3.223a12.453 12.453 0 0 1-.83 1.611c-1.22 2.002-2.93 3.613-5.517 3.613-2.637 0-4.346-1.61-5.518-3.613-1.171-1.904-2.05-4.492-2.832-6.836v-.097C7.32 9.526 6.54 7.23 5.514 5.52c-.977-1.66-2.051-2.49-3.467-2.49-.684 0-1.172-.488-1.172-1.172 0-.634.488-1.171 1.172-1.171 2.588 0 4.297 1.66 5.517 3.662 1.172 1.904 2.002 4.443 2.784 6.835v.098c.83 2.49 1.611 4.785 2.636 6.494.977 1.66 2.1 2.442 3.516 2.442s2.49-.782 3.467-2.442Zm7.47-12.256c-.634 1.026-1.171 2.295-1.66 3.663-.341-1.075-.78-2.149-1.22-3.223a8.507 8.507 0 0 1 .879-1.611c1.171-2.002 2.88-3.662 5.517-3.662.635 0 1.172.537 1.172 1.171 0 .684-.537 1.172-1.172 1.172-1.416 0-2.539.83-3.515 2.49Z'
      />
    </svg>
  )
}

export const NavbarSearchIcon = (props) => {
  return props.isActive ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={26}
      height={26}
      fill='none'
      {...props}
    >
      <path
        fill='url(#a)'
        d='M20.313 10.281c0 2.246-.733 4.346-1.954 6.006l6.153 6.201a1.506 1.506 0 0 1 0 2.198 1.506 1.506 0 0 1-2.198 0l-6.2-6.202c-1.66 1.27-3.76 1.953-5.958 1.953A10.148 10.148 0 0 1 0 10.281C0 4.715 4.541.125 10.156.125c5.567 0 10.156 4.59 10.156 10.156Zm-10.157 7.031c2.49 0 4.785-1.318 6.055-3.515 1.27-2.149 1.27-4.834 0-7.031-1.27-2.149-3.564-3.516-6.055-3.516-2.539 0-4.834 1.367-6.103 3.516-1.27 2.197-1.27 4.882 0 7.03a6.993 6.993 0 0 0 6.103 3.517Z'
      />
      <defs>
        <linearGradient
          id='a'
          x1={0}
          x2={25.701}
          y1={16.75}
          y2={14.935}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFB904' />
          <stop offset={0.465} stopColor='#FF7000' />
          <stop offset={1} stopColor='#FF403B' />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={26}
      height={26}
      fill='none'
      {...props}
    >
      <path
        fill='#A06E6E'
        d='M20.313 10.281c0 2.246-.733 4.346-1.954 6.006l6.153 6.201a1.506 1.506 0 0 1 0 2.198 1.506 1.506 0 0 1-2.198 0l-6.2-6.202c-1.66 1.27-3.76 1.953-5.958 1.953A10.148 10.148 0 0 1 0 10.281C0 4.715 4.541.125 10.156.125c5.567 0 10.156 4.59 10.156 10.156Zm-10.157 7.031c2.49 0 4.785-1.318 6.055-3.515 1.27-2.149 1.27-4.834 0-7.031-1.27-2.149-3.564-3.516-6.055-3.516-2.539 0-4.834 1.367-6.103 3.516-1.27 2.197-1.27 4.882 0 7.03a6.993 6.993 0 0 0 6.103 3.517Z'
      />
    </svg>
  )
}

export const NavbarCollectionsIcon = (props) => {
  return props.isActive ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={32}
      height={26}
      viewBox="0 0 32 26"
      fill='none'
      {...props}
    >
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0.35%" stopColor="#FFC300" />
          <stop offset="100%" stopColor="#F50" />
        </linearGradient>
      </defs>
      <path
        fill='url(#gradient)'
        d="M7.531 7.938c-1.416 0-2.685-.733-3.418-1.954-.683-1.171-.683-2.685 0-3.906C4.846.906 6.115.125 7.531.125c1.367 0 2.637.781 3.37 1.953.683 1.22.683 2.735 0 3.906-.733 1.221-2.003 1.954-3.37 1.954Zm17.969 0c-1.416 0-2.686-.733-3.418-1.954-.684-1.171-.684-2.685 0-3.906C22.814.906 24.084.125 25.5.125c1.367 0 2.637.781 3.37 1.953.683 1.22.683 2.735 0 3.906-.733 1.221-2.003 1.954-3.37 1.954Zm-25 6.787C.5 11.844 2.795 9.5 5.676 9.5h2.1c.78 0 1.513.195 2.197.488-.098.342-.098.733-.098 1.075 0 1.904.781 3.564 2.1 4.687H1.525C.94 15.75.5 15.31.5 14.725Zm19.775 1.025h-.048a6.098 6.098 0 0 0 2.148-4.688c0-.341-.049-.732-.098-1.074a5.142 5.142 0 0 1 2.149-.488h2.1a5.23 5.23 0 0 1 5.224 5.225c0 .585-.488 1.025-1.074 1.025h-10.4Zm-4.15-7.031c-.879 0-1.611.488-2.05 1.172-.44.732-.44 1.66 0 2.343.439.733 1.171 1.172 2.05 1.172.83 0 1.563-.44 2.002-1.172.44-.683.44-1.611 0-2.343-.44-.684-1.172-1.172-2.002-1.172Zm0 7.031c-1.709 0-3.223-.879-4.102-2.344-.83-1.416-.83-3.222 0-4.687.88-1.416 2.393-2.344 4.102-2.344 1.66 0 3.174.928 4.053 2.344.83 1.465.83 3.271 0 4.687-.88 1.465-2.393 2.344-4.053 2.344Zm-2.88 3.906c-1.954 0-3.565 1.367-4.054 3.125H23.01c-.489-1.758-2.1-3.125-4.053-3.125h-5.713Zm0-2.343h5.712a6.543 6.543 0 0 1 6.543 6.543c0 .732-.586 1.269-1.318 1.269H8.02a1.26 1.26 0 0 1-1.27-1.27c0-3.613 2.88-6.543 6.494-6.543Z"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={26}
      viewBox="0 0 32 26"
      fill="none"
      {...props}
    >
      <path
        fill="#A06E6E"
        d="M7.531 7.938c-1.416 0-2.685-.733-3.418-1.954-.683-1.171-.683-2.685 0-3.906C4.846.906 6.115.125 7.531.125c1.367 0 2.637.781 3.37 1.953.683 1.22.683 2.735 0 3.906-.733 1.221-2.003 1.954-3.37 1.954Zm17.969 0c-1.416 0-2.686-.733-3.418-1.954-.684-1.171-.684-2.685 0-3.906C22.814.906 24.084.125 25.5.125c1.367 0 2.637.781 3.37 1.953.683 1.22.683 2.735 0 3.906-.733 1.221-2.003 1.954-3.37 1.954Zm-25 6.787C.5 11.844 2.795 9.5 5.676 9.5h2.1c.78 0 1.513.195 2.197.488-.098.342-.098.733-.098 1.075 0 1.904.781 3.564 2.1 4.687H1.525C.94 15.75.5 15.31.5 14.725Zm19.775 1.025h-.048a6.098 6.098 0 0 0 2.148-4.688c0-.341-.049-.732-.098-1.074a5.142 5.142 0 0 1 2.149-.488h2.1a5.23 5.23 0 0 1 5.224 5.225c0 .585-.488 1.025-1.074 1.025h-10.4Zm-4.15-7.031c-.879 0-1.611.488-2.05 1.172-.44.732-.44 1.66 0 2.343.439.733 1.171 1.172 2.05 1.172.83 0 1.563-.44 2.002-1.172.44-.683.44-1.611 0-2.343-.44-.684-1.172-1.172-2.002-1.172Zm0 7.031c-1.709 0-3.223-.879-4.102-2.344-.83-1.416-.83-3.222 0-4.687.88-1.416 2.393-2.344 4.102-2.344 1.66 0 3.174.928 4.053 2.344.83 1.465.83 3.271 0 4.687-.88 1.465-2.393 2.344-4.053 2.344Zm-2.88 3.906c-1.954 0-3.565 1.367-4.054 3.125H23.01c-.489-1.758-2.1-3.125-4.053-3.125h-5.713Zm0-2.343h5.712a6.543 6.543 0 0 1 6.543 6.543c0 .732-.586 1.269-1.318 1.269H8.02a1.26 1.26 0 0 1-1.27-1.27c0-3.613 2.88-6.543 6.494-6.543Z"
      />
    </svg>
  )
}

export const NavbarCampaignsIcon = (props) => {
  return props.isActive ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={30}
      height={23}
      fill='none'
      {...props}
    >
      <path
        fill='url(#a)'
        d='M27.5.688c.83 0 1.563.732 1.563 1.562V21c0 .879-.733 1.563-1.563 1.563A1.543 1.543 0 0 1 25.937 21V2.25c0-.83.684-1.563 1.563-1.563ZM4.062 8.45 24.375 2.25V21l-6.445-1.904c-.489 2.002-2.344 3.466-4.492 3.466a4.689 4.689 0 0 1-4.688-4.687c0-.537.049-1.025.244-1.514l-4.931-1.513A1.555 1.555 0 0 1 2.5 16.312 1.543 1.543 0 0 1 .937 14.75V8.5c0-.83.684-1.563 1.563-1.563.83 0 1.514.684 1.563 1.514Zm11.67 9.961-4.492-1.367a2.282 2.282 0 0 0-.146.83 2.314 2.314 0 0 0 2.344 2.344 2.36 2.36 0 0 0 2.294-1.807Z'
      />
      <defs>
        <linearGradient
          id='a'
          x1={5.069}
          x2={25}
          y1={17.045}
          y2={17.045}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFC300' />
          <stop offset={1} stopColor='#F50' />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={29}
      height={23}
      fill='none'
      {...props}
    >
      <path
        fill='#A06E6E'
        d='M26.953.688c.635 0 1.172.537 1.172 1.171v19.532c0 .683-.537 1.172-1.172 1.172-.683 0-1.172-.489-1.172-1.172v-.244l-8.691-2.393c-.39 2.197-2.344 3.808-4.59 3.808a4.689 4.689 0 0 1-4.688-4.687c0-.537.098-1.074.245-1.563L2.344 14.75v.39c0 .684-.537 1.172-1.172 1.172-.684 0-1.172-.488-1.172-1.171V8.109c0-.634.488-1.171 1.172-1.171.635 0 1.172.537 1.172 1.171v.44L25.78 2.152V1.86c0-.634.489-1.171 1.172-1.171Zm-1.172 18.017V4.594L2.344 10.99v1.319l23.437 6.396Zm-15.625-.83a2.314 2.314 0 0 0 2.344 2.344c1.172 0 2.197-.88 2.295-2.051l-4.443-1.22c-.147.292-.196.585-.196.927Z'
      />
    </svg>
  )
}

export const NavbarFAQIcon = (props) => {
  return props.isActive ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={18}
      height={19}
      fill='none'
      {...props}
    >
      <path
        fill='url(#a)'
        d='M0 2.5C0 1.27.984.25 2.25.25h13.5C16.98.25 18 1.27 18 2.5v10.125c0 1.266-1.02 2.25-2.25 2.25h-4.887l-4.36 3.27c-.175.14-.386.14-.597.07-.176-.106-.281-.281-.281-.527v-2.813H2.25A2.221 2.221 0 0 1 0 12.625V2.5Zm5.941 1.898v.036c-.14.421.07.914.528 1.09a.855.855 0 0 0 1.054-.528l.036-.035c.035-.106.14-.211.246-.211h2.039c.316 0 .527.246.527.563 0 .175-.07.351-.246.457l-1.547.878a.82.82 0 0 0-.457.739v.457c0 .492.387.843.844.843.492 0 .844-.351.844-.808l1.16-.668a2.214 2.214 0 0 0 1.09-1.899c0-1.23-.985-2.25-2.215-2.25h-2.04c-.843 0-1.581.528-1.863 1.336Zm4.184 6.54c0-.598-.527-1.126-1.125-1.126-.633 0-1.125.528-1.125 1.126A1.11 1.11 0 0 0 9 12.062c.598 0 1.125-.492 1.125-1.124Z'
      />
      <defs>
        <linearGradient
          id='a'
          x1={0.063}
          x2={18}
          y1={13.485}
          y2={13.485}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFC300' />
          <stop offset={1} stopColor='#F50' />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={18}
      height={19}
      fill='none'
      {...props}
    >
      <path
        fill='#A06E6E'
        d='M7.313 14.875v.563l2.53-1.899a1.701 1.701 0 0 1 1.02-.351h4.887a.578.578 0 0 0 .563-.563V2.5c0-.281-.282-.563-.563-.563H2.25a.578.578 0 0 0-.563.563v10.125c0 .316.247.563.563.563h3.375c.914 0 1.688.773 1.688 1.687Zm-.036 2.707-.175.105-.598.457c-.176.141-.387.141-.598.07-.176-.105-.281-.28-.281-.526V14.875H2.25A2.221 2.221 0 0 1 0 12.625V2.5C0 1.27.984.25 2.25.25h13.5C16.98.25 18 1.27 18 2.5v10.125c0 1.266-1.02 2.25-2.25 2.25h-4.887l-3.55 2.672-.036.035ZM5.941 4.398a1.964 1.964 0 0 1 1.864-1.335h2.039c1.23 0 2.215 1.019 2.215 2.25 0 .773-.422 1.511-1.09 1.898l-1.16.668c0 .457-.352.809-.844.809a.833.833 0 0 1-.844-.844v-.457a.82.82 0 0 1 .457-.739l1.547-.878a.512.512 0 0 0 .246-.457c0-.317-.21-.563-.527-.563h-2.04c-.105 0-.21.105-.245.21l-.036.036a.855.855 0 0 1-1.054.527.878.878 0 0 1-.528-1.09v-.035Zm4.184 6.54c0 .632-.527 1.124-1.125 1.124a1.11 1.11 0 0 1-1.125-1.124c0-.598.492-1.126 1.125-1.126.598 0 1.125.528 1.125 1.126Z'
      />
    </svg>
  )
}

export const NavbarProfileIcon = (props) => {
  return props.isActive ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={16}
      height={19}
      fill='none'
      {...props}
    >
      <path
        fill='url(#a)'
        d='M7.875 9.25A4.474 4.474 0 0 1 3.973 7c-.809-1.371-.809-3.094 0-4.5A4.548 4.548 0 0 1 7.875.25c1.582 0 3.059.879 3.867 2.25.809 1.406.809 3.129 0 4.5a4.46 4.46 0 0 1-3.867 2.25Zm-1.617 1.688h3.199a6.287 6.287 0 0 1 6.293 6.292c0 .563-.492 1.02-1.055 1.02H1.02A1.02 1.02 0 0 1 0 17.23c0-3.48 2.777-6.293 6.258-6.293Z'
      />
      <defs>
        <linearGradient
          id='a'
          x1={0.056}
          x2={16}
          y1={13.485}
          y2={13.485}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFC300' />
          <stop offset={1} stopColor='#F50' />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={16}
      height={19}
      fill='none'
      {...props}
    >
      <path
        fill='#A06E6E'
        d='M10.688 4.75c0-.984-.563-1.898-1.407-2.426-.879-.492-1.969-.492-2.812 0-.88.528-1.407 1.442-1.407 2.426 0 1.02.528 1.934 1.407 2.46.843.493 1.933.493 2.812 0 .844-.526 1.406-1.44 1.406-2.46Zm-7.313 0A4.46 4.46 0 0 1 5.625.883c1.371-.809 3.094-.809 4.5 0 1.371.808 2.25 2.285 2.25 3.867a4.548 4.548 0 0 1-2.25 3.902c-1.406.809-3.129.809-4.5 0a4.474 4.474 0 0 1-2.25-3.902ZM1.723 16.563h12.27a4.586 4.586 0 0 0-4.536-3.938h-3.2a4.586 4.586 0 0 0-4.534 3.938ZM0 17.23c0-3.481 2.777-6.294 6.258-6.294h3.199a6.287 6.287 0 0 1 6.293 6.293c0 .563-.492 1.02-1.055 1.02H1.02A1.02 1.02 0 0 1 0 17.23Z'
      />
    </svg>
  )
}

export const NavbarMessageIcon = (props) => {
  const commonWidth = 41 // Example size matching other icons
  const commonHeight = 30 // Example size matching other icons

  return props.isActive ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={commonWidth}
      height={commonHeight}
      viewBox="0 0 30 23" // Adjusted to scale content
      fill="none"
      {...props}
    >
      <path
        fill="url(#a)"
        d="M14.875 0.25C15.473 0.25 16 0.777 16 1.375v0.703c3.566 0.492 6.5 2.777 6.5 5.485v0.667c0 1.653 0.598 3.27 1.688 4.5l0.246 0.282c0.316 0.351 0.386 0.808 0.21 1.23-0.175 0.387-0.597 0.633-1.019 0.633H4.5c-0.457 0-0.879-0.246-1.055-0.633a1.139 1.139 0 011.211-1.23l0.246-0.282a6.842 6.842 0 001.723-4.5v-0.668c0-2.707 2.934-4.992 5.5-5.484v-0.703C13.75 0.777 14.242 0.25 14.875 0.25Zm1.582 17.367c-0.422 0.422-0.984 0.633-1.582 0.633-0.598 0-1.195-0.21-1.617-0.633-0.422-0.422-0.633-1.02-0.633-1.617h4.5c0 0.598-0.246 1.195-0.668 1.617Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1={1.056}
          x2={30}
          y1={13.485}
          y2={13.485}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC300" />
          <stop offset={1} stopColor="#F50" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={commonWidth}
      height={commonHeight}
      viewBox="0 0 30 23" // Adjusted to scale content
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M14.875 0.25C15.473 0.25 16 0.777 16 1.375v0.703c3.566 0.492 6.5 2.777 6.5 5.485v0.914c0 1.582 0.527 3.128 1.512 4.394l0.527 0.633c0.211 0.281 0.246 0.633 0.105 0.914a0.82 0.82 0 01-0.738 0.457H7.844c-0.352 0-0.633-0.176-0.774-0.457a0.873 0.873 0 011.106-0.914l0.527-0.633c0.984-1.266 1.547-2.812 1.547-4.394v-0.915c0-2.707 2.934-4.992 5.5-5.484v-0.703C13.75 0.777 14.242 0.25 14.875 0.25Zm0 3.375c-2.18 0-3.938 1.793-3.938 3.938v0.914a8.604 8.604 0 01-1.406 4.71h10.653c-0.914-1.406-1.371-3.023-1.371-4.71v-0.915c0-2.144-1.793-3.937-3.938-3.937ZM17.125 16c0 0.598-0.246 1.195-0.668 1.617-0.422 0.422-0.984 0.633-1.582 0.633-0.598 0-1.195-0.21-1.617-0.633-0.422-0.422-0.633-1.02-0.633-1.617h4.5Z"
      />
    </svg>
  );
};

