import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { withReduxActions } from '../../../hocs/withRedux'
import useTranslation from '../../../localization/useTranslation'
import { FAV_COLLECTION_NAME } from '../../../constants/collections'
import { USER_INDEXES, SOCIAL_PLATFORMS_NAMES } from '../../../constants/appSettings'
import {
  addSelectedProfileToCollection,
  removeSelectedProfileFromCollection,
} from '../../../redux/ducks/collectionsDucks'
import { addProfileToInfludata } from '../../../redux/ducks/appSettingsDucks'
import { collectionsSelector, isUserAuthenticatedSelector, userGrantSelector } from '../../../redux/selectors'

import AddToCollectionModal from '../../collections/collectionTools/AddToCollectionModal'
import EditCollectionModal from '../../collections/collectionTools/EditCollectionModal'
import EditCampaignModal from '../../campaigns/campaignTools/EditCampaignModal'
import ProfilePdfRender from '../profilePdfDocument/ProfilePdfRender'

import {
  LikeIcon,
  CollectionIcon,
  CampaignIcon,
  ExportIcon,
  UpdateIcon,
} from '../../common/icons/icons'
import {
  ProfileOverviewToolsWrapper,
  ToolItem,
  ToolIconWrapper,
  ToolTitle,
  DisabledTipWrapper,
} from './styles'
import WithTip from '../../common/popups/WithTip'
import AddToCampaignPipelineModal from '../../campaigns/campaignTools/OverviewTools/AddToCampaignPipelineModal'

const ProfileOverviewTools = ({
  profile,
  removeSelectedProfileFromCollection,
  addSelectedProfileToCollection,
  isProfileVerified = false,
}) => {
  const { _index: platform, username, isPrivate, _id } = profile || {}
  const dispatch = useDispatch()

  const collectionsList = useSelector(collectionsSelector)
  const firstCollectionIdInList = collectionsList[0]?.collectionId || ''

  const [isFav, setIsFav] = useState(profile.isFav)
  const [isAddToCollectionOpened, setIsAddToCollectionOpened] = useState(false)
  const [isCreateCollectionOpened, setIsCreateCollectionOpened] = useState(false)
  const [isAddToCampaignOpened, setIsAddToCampaignOpened] = useState(false)
  const [isCreateCampaignOpened, setIsCreateCampaignOpened] = useState(false)
  const [isUpdateProfile, setIsUpdateProfile] = useState(false)

  const { labelStrings, currentLang } = useTranslation()

  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const {
    no_plan: isNoPlan,
    in_trial: isInTrial,
    enable_collections,
  } = useSelector(userGrantSelector, shallowEqual)

  const currentCollectionCount = profile.collectionArray.filter(c => !c?.startsWith(FAV_COLLECTION_NAME))
    ?.length

  const toggleIsFav = () => setIsFav(!isFav)

  const onFavClick = async () => {
    toggleIsFav()
    const handler = isFav ? removeSelectedProfileFromCollection : addSelectedProfileToCollection
    const isSuccessful = await handler(profile._id, firstCollectionIdInList)
    if (!isSuccessful) toggleIsFav()
  }

  const ExportAsPdfButton = ({ isExportInProgress, onExportClick }) => (
    <ToolItem
      platform={platform}
      isDisabled={isExportInProgress}
      onClick={onExportClick}
    >
      <ToolIconWrapper>
        <ExportIcon />
      </ToolIconWrapper>
      <ToolTitle>
        {isExportInProgress ? labelStrings.exporting : labelStrings.exportAsPdf}
      </ToolTitle>
    </ToolItem>
  )

  const onUpdateUserManually = () => {
    setIsUpdateProfile(true)
    dispatch(
      addProfileToInfludata({
        profileName: username.trim(),
        withNotify: false,
        platform: SOCIAL_PLATFORMS_NAMES[platform].index,
        _id: _id
      })
    )
  }

  const manuallyUpdateTitle = useMemo(() => {
    const titleBeforeClick = labelStrings.updateProfileManually
    const titleAfterClick = (
      <div>
        <div>{labelStrings.added}</div>
        <div>{labelStrings.updateProfileManuallyWasClicked}</div>
      </div>
    )
    return <>{isUpdateProfile ? titleAfterClick : titleBeforeClick}</>
  }, [currentLang, isUpdateProfile])

  if (!isUserAuthenticated || isNoPlan) return null

  return (
    <>
      {isPrivate ? (
        <ProfileOverviewToolsWrapper isProfileVerified={isProfileVerified}>
          <ToolItem platform={platform} onClick={onUpdateUserManually}>
            {!isUpdateProfile && (
              <ToolIconWrapper>
                <UpdateIcon />
              </ToolIconWrapper>
            )}
            <ToolTitle>{manuallyUpdateTitle}</ToolTitle>
          </ToolItem>
        </ProfileOverviewToolsWrapper>
      ) : (
        <ProfileOverviewToolsWrapper isProfileVerified={isProfileVerified}>
          {!enable_collections ? (
            <WithTip tipText={labelStrings.profileToolDisabledTip} position="bottom right">
              <DisabledTipWrapper>
                <ToolItem
                  isActive={isFav}
                  platform={platform}
                  onClick={onFavClick}
                  isDisabled={!enable_collections}
                >
                  <ToolIconWrapper>
                    <LikeIcon />
                  </ToolIconWrapper>
                  <ToolTitle isActive={isFav}>{labelStrings.getAddToFavorites(isFav)}</ToolTitle>
                </ToolItem>
              </DisabledTipWrapper>
            </WithTip>
          ) : (
            <ToolItem isActive={isFav} platform={platform} onClick={onFavClick}>
              <ToolIconWrapper>
                <LikeIcon />
              </ToolIconWrapper>
              <ToolTitle isActive={isFav}>{labelStrings.getAddToFavorites(isFav)}</ToolTitle>
            </ToolItem>
          )}

          {!enable_collections ? (
            <WithTip tipText={labelStrings.profileToolDisabledTip} position="bottom right">
              <DisabledTipWrapper>
                <ToolItem
                  isActive={!!currentCollectionCount}
                  numberInBubble={currentCollectionCount}
                  platform={platform}
                  onClick={() => setIsAddToCollectionOpened(true)}
                  isDisabled={!enable_collections}
                >
                  <ToolIconWrapper>
                    <CollectionIcon />
                  </ToolIconWrapper>
                  <ToolTitle isActive={!!currentCollectionCount}>
                    {labelStrings.addToCollection}
                  </ToolTitle>
                </ToolItem>
              </DisabledTipWrapper>
            </WithTip>
          ) : (
            <ToolItem
              isActive={!!currentCollectionCount}
              numberInBubble={currentCollectionCount}
              platform={platform}
              onClick={() => setIsAddToCollectionOpened(true)}
              isDisabled={!enable_collections}
            >
              <ToolIconWrapper>
                <CollectionIcon />
              </ToolIconWrapper>
              <ToolTitle isActive={!!currentCollectionCount}>
                {labelStrings.addToCollection}
              </ToolTitle>
            </ToolItem>
          )}

          {platform === USER_INDEXES.instagram && (
            <ToolItem
              isActive={!!profile.campaignCount}
              numberInBubble={profile.campaignCount}
              platform={platform}
              onClick={() => setIsAddToCampaignOpened(true)}
            >
              <ToolIconWrapper>
                <CampaignIcon />
              </ToolIconWrapper>
              <ToolTitle
                isActive={!!profile.campaignCount}
                style={{ lineHeight: currentLang === 'de' ? '9.5px' : '12px' }}
              >
                {labelStrings.addToCampaignPipeline}
              </ToolTitle>
            </ToolItem>
          )}

          {isInTrial ? (
            <WithTip tipText={labelStrings.profileToolDisabledTip} position="bottom right">
              <DisabledTipWrapper>
                <ProfilePdfRender
                  username={username}
                  platform={platform}
                  ButtonComponent={ExportAsPdfButton}
                />
              </DisabledTipWrapper>
            </WithTip>
          ) : (
            <ProfilePdfRender
              username={username}
              platform={platform}
              ButtonComponent={ExportAsPdfButton}
            />
          )}

          <ToolItem platform={platform} onClick={onUpdateUserManually}>
            {!isUpdateProfile && (
              <ToolIconWrapper>
                <UpdateIcon />
              </ToolIconWrapper>
            )}
            <ToolTitle>{manuallyUpdateTitle}</ToolTitle>
          </ToolItem>
        </ProfileOverviewToolsWrapper>
      )}

      {isAddToCollectionOpened && (
        <AddToCollectionModal
          profile={profile}
          platform={platform}
          collectionArray={profile.collectionArray}
          closeModal={() => setIsAddToCollectionOpened(false)}
          isContent={false}
          openCreateCollectionModal={() => {
            setIsCreateCollectionOpened(true)
            setIsAddToCollectionOpened(false)
          }}
        />
      )}

      {isCreateCollectionOpened && (
        <EditCollectionModal
          isOpened={isCreateCollectionOpened}
          platform={platform}
          isNewCollection
          closeModal={() => setIsCreateCollectionOpened(false)}
        />
      )}

      {isAddToCampaignOpened && (
        <AddToCampaignPipelineModal
          profile={profile}
          platform={platform}
          isOpened={isAddToCampaignOpened}
          closeModal={() => setIsAddToCampaignOpened(false)}
        />
      )}

      {isCreateCampaignOpened && (
        <EditCampaignModal
          platform={platform}
          isNewCampaign
          closeModal={() => setIsCreateCampaignOpened(false)}
        />
      )}
    </>
  )
}

ProfileOverviewTools.propTypes = {
  profile: PropTypes.object.isRequired,
  removeSelectedProfileFromCollection: PropTypes.func,
  addSelectedProfileToCollection: PropTypes.func,
  isProfileVerified: PropTypes.bool,
}

export default withReduxActions({
  addSelectedProfileToCollection,
  removeSelectedProfileFromCollection,
})(ProfileOverviewTools)
