import React from 'react'
import styled from 'styled-components'
import useTranslation from '../../localization/useTranslation'

const MessageContainer = styled.div`
  font-size: 16px;
  color: ${props => props.theme.color.mineShaft};
  text-align: center;
  font-weight: 600;
  padding: 200px;
  border-radius: 10px;
  margin: 20px 0;
  width: 100%;
`

const SubUserSettingsTip = () => {
  const { labelStrings } = useTranslation()

  return (
    <MessageContainer>
      {labelStrings.subUserSettingsTip}
    </MessageContainer>
  )
}

export default SubUserSettingsTip