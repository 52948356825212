import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import {
  isSignUpOpenedSelector,
  isSignInOpenedSelector,
  userCredsLoadProgressSelector,
  limitsExistSelector,
  isSignUpSuccessModalOpenedSelector,
  campaignDashboard,
  userTrackSelector,
  userCredsSelector,
  isUserAuthenticatedSelector,
  userGrantSelector,
  isUserLoggedInSelector,
} from '../redux/selectors'
import withPageMeta from '../hocs/withPageMeta'

import SignUpContainer from '../components/signup/SignUpContainer'
import SignUpSuccess from '../components/signup/SignUpSuccess'

import Footer from '../components/Footer'
import { PageContentWrapper } from '../components/common/styledWrappers'
import LoadingSpinner from '../components/common/spinners/LoadingSpinner'

import LoginContainer from '../components/signin/LoginContainer'
import LimitsContainer from '../components/limits/LimitsContainer'

import { ModalContentNews } from '../new-ui/components/modal-content-news'
import useWindowResize from '../hooks/useWindowResize'
import NavbarContainer from '../new-ui/components/navbar/NavbarContainer'
import { API_STATUS_CODES, TRIAL_COUNTRY_FOR_SIGNUP } from '../constants/appSettings'
import SignUpTrialContainer from '../components/signup/SignUpTrialContainer'
import { NoPlanModal } from '../new-ui/components/no-plan-modal/NoPlanModal'
import { PlanInactiveModal } from '../new-ui/components/no-plan-modal/PlanInactiveModal'
import Scrollbar from '../new-ui/components/scrollbar/Scrollbar'
import { Box } from '@mui/material'
import { SuccessChangeEmailModal } from '../new-ui/components/modal-success-change-email/SuccessChangeEmailModal'
import { verifyChangeEmail } from '../redux/ducks/userDucks'
import { setNavigation } from '../redux/ducks/navigation'
import { TopMessageBanner } from '../new-ui/components/top-message-banner/TopMessageBanner'
import { ModalScheduledCancellation } from '../new-ui/components/modal-scheduled-cancellation/ModalScheduledCancellation'

const PageContainer = ({ isNavbar = true, isFooter = true, children }) => {
  const { pathname, search } = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const params = new URLSearchParams(search)
  const isHasToken = params.get('token')
  const isChangedTokenUrl = pathname === '/changeemail' && isHasToken

  const isPageLoading = useSelector(userCredsLoadProgressSelector)
  const isSignupOpened = useSelector(isSignUpOpenedSelector)
  const isSignInOpened = useSelector(isSignInOpenedSelector)
  const isSignUpSuccessModalOpened = useSelector(isSignUpSuccessModalOpenedSelector)
  const isSomeLimitActivated = useSelector(limitsExistSelector)
  const isUserTrack = useSelector(userTrackSelector)
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const { showNoPlanModal } = useSelector(userCredsSelector) || {}
  const {
    is_self_service,
    in_trial_infludata,
    non_renewing_infludata,
    trial_end_date_infludata,
    expiration_date,
  } = useSelector(userGrantSelector, shallowEqual)
  const isLoggedIn = useSelector(isUserLoggedInSelector)

  const { isMobile } = useWindowResize()

  const currentCountryFromIP = localStorage.getItem('currentCountryFromIP')
  const isSevenDaysTrial = TRIAL_COUNTRY_FOR_SIGNUP?.includes(currentCountryFromIP)

  const isPreviewDashboardPage = pathname === '/dashboardPreview'

  const { data: dashboardStartupData, isLoading: isLoadingDashboard } = useSelector(
    campaignDashboard
  )

  const [isShowSuccessChangedEmailModal, setIsShowSuccessChangedEmailModal] = useState(false)
  const [isSuccessfullyChangedEmail, setIsSuccessfullyChangedEmail] = useState(false)
  const [isCancellationScheduled, setIsCancellationScheduled] = useState(false)
  const [isShowTopBannerMessage, setIsShowTopBannerMessage] = useState(false)

  const planModal = is_self_service ? <NoPlanModal /> : <PlanInactiveModal />

  const isYourTrialsEnds = in_trial_infludata && non_renewing_infludata
  const isYourSubscriptionsEnds = !in_trial_infludata && non_renewing_infludata

  const onOpenEmailSuccessfullyModal = () => {
    setIsShowSuccessChangedEmailModal(true)
  }

  useEffect(() => {
    if (isLoggedIn && isChangedTokenUrl) {
      const onChangeEmail = async () => {
        const statusCode = await dispatch(
          verifyChangeEmail({
            token: isHasToken,
            cb: onOpenEmailSuccessfullyModal,
          })
        )

        if (statusCode === API_STATUS_CODES.created) {
          setIsSuccessfullyChangedEmail(true)
          onOpenEmailSuccessfullyModal()
        } else {
          setIsSuccessfullyChangedEmail(false)
          onOpenEmailSuccessfullyModal()
        }
      }
      onChangeEmail()
    }
  }, [isLoggedIn, isChangedTokenUrl])

  useEffect(() => {
    const hiddenMessage = localStorage.getItem('hiddenTopMessageBanner')
    if ((isYourTrialsEnds || isYourSubscriptionsEnds) && hiddenMessage) {
      setIsShowTopBannerMessage(true)
    }
  }, [isYourTrialsEnds, isYourSubscriptionsEnds])

  useEffect(() => {
    dispatch(setNavigation(history))
  }, [dispatch, history])

  return (
    <Box sx={{ position: 'relative' }}>
      {isPageLoading && <LoadingSpinner isTransparent showOnFullPage isOuterPageLoad />}
      {isPreviewDashboardPage ? (
        <PageContentWrapper isLoading={isPageLoading} id="main-page-container">
          {children}
        </PageContentWrapper>
      ) : (
        <PageContentWrapper isLoading={isPageLoading} id="main-page-container">
          {isNavbar && <NavbarContainer />}
          {isSignupOpened &&
            (isSevenDaysTrial ? (
              <SignUpTrialContainer isSevenDaysTrial={isSevenDaysTrial} />
            ) : (
              <SignUpContainer />
            ))}
          {isSignInOpened && (
            <Box sx={{ height: '100vh' }}>
              <LoginContainer />
            </Box>
          )}

          {isLoggedIn && isShowTopBannerMessage && (
            <TopMessageBanner
              setIsShowTopBannerMessage={setIsShowTopBannerMessage}
              setIsCancellationScheduled={setIsCancellationScheduled}
              isTrialEnds={isYourTrialsEnds}
              trialsEndsDays={trial_end_date_infludata}
              subscriptionsEndsDays={expiration_date}
            />
          )}

          {isSomeLimitActivated && <LimitsContainer />}
          {showNoPlanModal && isUserAuthenticated && planModal}
          {isUserTrack
            ? isSignUpSuccessModalOpened &&
              dashboardStartupData?.length &&
              !isLoadingDashboard && <SignUpSuccess />
            : isSignUpSuccessModalOpened && <SignUpSuccess />}
          {pathname === '/settings' ? (
            <>{children}</>
          ) : pathname === '/new-campaigns/' ? (
            <>
              {children}
              {isFooter && <Footer />}
            </>
          ) : (
            <Scrollbar
              pathname={pathname}
              sx={{ maxHeight: `calc(100vh - ${64}px)`, height: '100%' }}
            >
              <Box sx={{ height: pathname === '/changeemail' ? `calc(100vh - 64px)` : 'auto' }}>
                {children}
              </Box>

              {isFooter && <Footer />}
            </Scrollbar>
          )}
        </PageContentWrapper>
      )}
      {!isMobile && <ModalContentNews />}
      {isShowSuccessChangedEmailModal && (
        <SuccessChangeEmailModal
          isOpenModal={isShowSuccessChangedEmailModal}
          onCloseModal={() => setIsShowSuccessChangedEmailModal(false)}
          isSuccessfully={isSuccessfullyChangedEmail}
        />
      )}
      {isCancellationScheduled && (
        <ModalScheduledCancellation
          isOpenModal={isCancellationScheduled}
          onCloseModal={() => setIsCancellationScheduled(false)}
        />
      )}
    </Box>
  )
}

PageContainer.propTypes = {
  isNavbar: PropTypes.bool,
  isFooter: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default withPageMeta(PageContainer)
