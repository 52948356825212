import React, { useState } from 'react'
import PropTypes from 'prop-types'

import useTranslation from '../../../localization/useTranslation'

import EditCollectionModal from '../../collections/collectionTools/EditCollectionModal'
import WithUpgradeTip from '../../common/popups/WithUpgradeTip'
import { CreateCollectionIcon } from '../../common/icons'
import {
  CreateCollectionWrapper,
  CreateCollectionIconWrapper,
  LabelWrapper,
} from './collectionsSidebarStyles'
import JoinInnerIcon from '@mui/icons-material/JoinInner'
import { useLocation } from 'react-router-dom'
import { COLLECTION_YOUR_OVERLAPS_PATH } from '../../../constants/collections'

const CreateCollectionItem = ({ canCreateCollection = true, goToYourOverlaps, isNavType = 'default' }) => {
  const { labelStrings } = useTranslation()
  const [isCreateCollectionOpened, setIsCreateCollectionOpened] = useState(false)

  const { pathname } = useLocation()
  const isYourOverlapsPage = pathname === COLLECTION_YOUR_OVERLAPS_PATH

  const toggleIsCreateCollectionOpened = () => {
    if (canCreateCollection) {
      setIsCreateCollectionOpened(!isCreateCollectionOpened)
    }
  }

  const CreateCollectionComponent = (
    <CreateCollectionWrapper
      disabled={!canCreateCollection}
      onClick={toggleIsCreateCollectionOpened}
      isNavType={isNavType}
    >
      <CreateCollectionIconWrapper isNavType={isNavType}>
        <CreateCollectionIcon width="22px" height="22px" />
      </CreateCollectionIconWrapper>
      <LabelWrapper isNavType={isNavType}>
        {isNavType === 'min' ? labelStrings.create : labelStrings.createNewCollection}
      </LabelWrapper>
    </CreateCollectionWrapper>
  )

  const YourOverlap = (
    <CreateCollectionWrapper
      style={
        isYourOverlapsPage ? { backgroundColor: 'rgb(244, 232, 232)', fontWeight: 'bold' } : {}
      }
      onClick={goToYourOverlaps}
      isNavType={isNavType}
    >
      <CreateCollectionIconWrapper isNavType={isNavType}>
        <JoinInnerIcon width="22px" height="22px" />
      </CreateCollectionIconWrapper>
      <LabelWrapper isNavType={isNavType}>
        {labelStrings.collectionOverlap.yourOverlaps}
      </LabelWrapper>
    </CreateCollectionWrapper>
  )

  if (!canCreateCollection) {
    return (
      <WithUpgradeTip tipText={labelStrings.createUnlimitedCollections}>
        {CreateCollectionComponent}
      </WithUpgradeTip>
    )
  }
  return (
    <>
      {CreateCollectionComponent}
      {YourOverlap}
      {isCreateCollectionOpened && (
        <EditCollectionModal isOpened={isCreateCollectionOpened} isNewCollection closeModal={toggleIsCreateCollectionOpened} />
      )}
    </>
  )
}

CreateCollectionItem.propTypes = {
  createCollectionHandler: PropTypes.func,
  canCreateCollection: PropTypes.bool,
  goToYourOverlaps: PropTypes.func,
  isNavType: PropTypes.string,
}

export default CreateCollectionItem
