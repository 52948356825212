import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  userAvaliableSeatsSelector,
  isUserSeatsChangeProgressSelector,
  userCredsSelector,
} from "../../../redux/selectors";
import {
  changeRoleTeamMembers,
  getTeamMembersAndSeats,
  inviteTeamMembers,
  removeTeamMembers,
} from "../../../redux/ducks/userDucks";
import SubUsers from "./SubUsers";
import { InviteTeamMemberModal } from "./modals/InviteTeamMember";
import { RemoveTeamMember } from "./modals/RemoveTeamMember";
import { ChangeTeamMemberRole } from "./modals/ChangeTeamMemberRole";

const SubUsersContainer = () => {
  const dispatch = useDispatch();

  const allSeats = useSelector(userAvaliableSeatsSelector);
  const isSeatsUpdateInProgress = useSelector(
    isUserSeatsChangeProgressSelector,
  );
  const { companyMembers } = useSelector(userCredsSelector);

  const [showInviteModal, setShowInviteModal] = useState(false);
  const [changeRoleData, setChangeRoleData] = useState({});
  const [removeMemberData, setRemoveMemberData] = useState({});

  const onChangeMemberRoleModal = (memberData) => {
    setChangeRoleData(memberData);
  };

  const onDeleteMemberModal = (memberData) => {
    setRemoveMemberData(memberData);
  };

  const onInviteNewTeamMemberModal = () => {
    setShowInviteModal((prev) => !prev);
  };

  const onCloseInviteModal = () => {
    setShowInviteModal(false);
  };

  const onCloseChangeTeamMemberRoleModal = () => {
    setChangeRoleData({});
  };

  const onCloseRemoveTeamMemberModal = () => {
    setRemoveMemberData({});
  };

  const onInviteTeamMember = (payload) => {
    dispatch(inviteTeamMembers(payload));
  };

  const onConfirmChangeRoleMember = (role) => {
    const { username } = changeRoleData || {};
    dispatch(changeRoleTeamMembers({ email: username, role }));
  };

  const onConfirmDeleteMember = () => {
    const { username } = removeMemberData || {};
    dispatch(removeTeamMembers({ email: username }));
  };

  useEffect(() => {
    dispatch(getTeamMembersAndSeats());
  }, []);

  return (
    <>
      <SubUsers
        allSeats={allSeats}
        isSeatsUpdateInProgress={isSeatsUpdateInProgress}
        companyMembersData={companyMembers}
        onChangeMemberRoleModal={onChangeMemberRoleModal}
        onDeleteMemberModal={onDeleteMemberModal}
        onInviteNewTeamMemberModal={onInviteNewTeamMemberModal}
      />
      {showInviteModal && (
        <InviteTeamMemberModal
          isOpenModal={showInviteModal}
          closeModal={onCloseInviteModal}
          onInviteTeamMember={onInviteTeamMember}
        />
      )}
      {!!changeRoleData?.username && (
        <ChangeTeamMemberRole
          isOpenModal={!!changeRoleData?.username}
          closeModal={onCloseChangeTeamMemberRoleModal}
          onConfirmChangeRoleMember={onConfirmChangeRoleMember}
          changeRoleData={changeRoleData}
        />
      )}
      {!!removeMemberData?.username && (
        <RemoveTeamMember
          isOpenModal={!!removeMemberData?.username}
          closeModal={onCloseRemoveTeamMemberModal}
          username={removeMemberData?.username}
          onConfirmDeleteMember={onConfirmDeleteMember}
        />
      )}
    </>
  );
};

export default SubUsersContainer;
