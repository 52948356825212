import React from "react"
import { shallowEqual, useSelector } from "react-redux"
import { withReduxActions } from "../../../../../hocs/withRedux"
import {
  createNewCollection,
  addSelectedProfileToCollection,
  removeSelectedProfileFromCollection,
  addElementToCollection,
  removeElementFromCollection,
  addElementToOpenedCollection,
  removeElementFromOpenedCollection,
} from "../../../../../redux/ducks/collectionsDucks"
import { limitAccess } from "../../../../../redux/ducks/appSettingsDucks"
import {
  userGrantSelector,
  canAddToCollectionDynamicSelector,
  noRerenderEqFn,
  isUserAuthenticatedSelector,
  isProfileAddedToCompareDynamicSelector,
  comparedProfilesCountSelector,
  comparedProfilesPlatformSelector,
  collectionsSelector,
} from "../../../../../redux/selectors"
import { FAV_COLLECTION_NAME } from "../../../../../constants/collections"
import ProfileToolsContainer from "./ProfileToolsContainer"
import { resetComparedProfiles } from "../../../../../redux/ducks/compareProfilesDucks"

const ProfileTools = ({
  isSearchView,
  isCollectionView,
  isSelectedProfileView,
  user,
  createNewCollection,
  addSelectedProfileToCollection,
  removeSelectedProfileFromCollection,
  addElementToCollection,
  removeElementFromCollection,
  addElementToOpenedCollection,
  removeElementFromOpenedCollection,
  limitAccess,
  stopEventBubbling,
  isExplore,
  isQuickPreview,
  resetComparedProfiles,
  onBlockUnblockUser,
  setIsOpenPipelineModal,
  handleClose,

  isAddToCollectionOpened,
  isCreateCollectionOpened,
  setIsAddToCollectionOpened,
  setIsCreateCollectionOpened,
}) => {
  const createCollectionsActions = () => {
    if (isSearchView)
      return {
        addElementToCollection: addElementToCollection,
        removeElementFromCollection: removeElementFromCollection,
        createCollection: createNewCollection,
      }
    if (isCollectionView)
      return {
        addElementToCollection: addElementToOpenedCollection,
        removeElementFromCollection: removeElementFromOpenedCollection,
        createCollection: createNewCollection,
      }
    if (isSelectedProfileView)
      return {
        addElementToCollection: addSelectedProfileToCollection,
        removeElementFromCollection: removeSelectedProfileFromCollection,
        createCollection: createNewCollection,
      }
  }

  const canAddToCollectionDynamic = useSelector(
    canAddToCollectionDynamicSelector,
    noRerenderEqFn,
  )
  const userGrant = useSelector(userGrantSelector, shallowEqual)
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const currentComparedPlatform = useSelector(comparedProfilesPlatformSelector)
  const checkIsProfileAddedToCompare = useSelector(isProfileAddedToCompareDynamicSelector)
  const comparedProfilesCount = useSelector(comparedProfilesCountSelector)
  const collectionsList = useSelector(collectionsSelector)
  
  const findFavCollectionId = collectionsList?.filter(collection => collection.collectionId?.startsWith(FAV_COLLECTION_NAME))[0]?.collectionId
  const favCollectionId = findFavCollectionId?.length ? findFavCollectionId[0]?.collectionId : collectionsList[0]?.collectionId

  const canAddToFavs = canAddToCollectionDynamic({
    collectionId: favCollectionId,
  })

  const showCollectionsTool = !isCollectionView

  const profileToolsProps = {
    user,
    userGrant,
    canAddToFavs,
    showCollectionsTool,
    isCollectionView,
    ...createCollectionsActions(),
    limitAccess,
    stopEventBubbling,
    isUserAuthenticated,
    isExplore,
    isQuickPreview,
    currentComparedPlatform,
    comparedProfilesCount,
    resetComparedProfiles,
    checkIsProfileAddedToCompare,
    onBlockUnblockUser,
    setIsOpenPipelineModal,
    handleClose,
    isAddToCollectionOpened,
    isCreateCollectionOpened,
    setIsAddToCollectionOpened,
    setIsCreateCollectionOpened,
  }

  return <ProfileToolsContainer {...profileToolsProps} />
}

export default withReduxActions({
  createNewCollection,
  addSelectedProfileToCollection,
  removeSelectedProfileFromCollection,
  addElementToCollection,
  removeElementFromCollection,
  addElementToOpenedCollection,
  removeElementFromOpenedCollection,
  limitAccess,
  resetComparedProfiles
})(ProfileTools)
