import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { shallowEqual, useSelector } from 'react-redux'
import { refreshGrantSelector, userGrantSelector, usernameSelector, userRolesSelector } from '../../../redux/selectors'

import { withReduxActions } from '../../../hocs/withRedux'
import { toggleContactUs } from '../../../redux/ducks/appSettingsDucks'
import { changeUserPassword } from '../../../redux/ducks/userDucks'

import AccountSettings from './AccountSettings'
import ChangePasswordDialog from '../../common/dialogs/ChangePasswordDialog'
import httpService from '../../../services/httpService'
import useTranslation from '../../../localization/useTranslation'
import { ChangePasswordModal } from './ChangePasswordModal'
import { API_STATUS_CODES } from '../../../constants/appSettings'
import { ModalComponent } from '../../../new-ui/components/modal/ModalComponent'

const AccountSettingsContainer = ({
  toggleContactUs,
  changeUserPassword,
  isCanceledStatus,
  isAddPaymentMethod,
  setIsOpenEditDetails,
}) => {
  const { labelStrings } = useTranslation()
  const history = useHistory()

  const refreshGrant = useSelector(refreshGrantSelector)
  const email = useSelector(usernameSelector)
  const {
    trial_end_date_infludata,
    in_trial_infludata,
    is_self_service,
    no_payment_method,
    non_renewing_infludata,
    expiration_date,
    current_term_end,
    associated_org_id, 
    associated_org_name
  } = useSelector(userGrantSelector, shallowEqual)

  const roles = useSelector(userRolesSelector, shallowEqual)
  const [subscriptionText, setSubscriptionText] = useState('')

  const [isModalOpened, setIsModalOpened] = useState(false)
  const toggleIsModalOpened = () => setIsModalOpened(!isModalOpened)

  const [isOpenChangePassword, setIsOpenChangePassword] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const changePassword = async () => {
    setIsLoading(true)
    await changeUserPassword(email)
    setIsLoading(false)
    toggleIsModalOpened()
  }

  const onAddSubscription = async () => {
    setSubscriptionText('')
    try {
      const { status } = (await httpService.forceImmediateActivation()) || {}

      if (status === API_STATUS_CODES.created) {
        history?.push('/search')
        await refreshGrant()
        toast.success(labelStrings.activateSubscriptions201, { theme: 'colored' })
      }
    } catch (err) {
      const errorText = err.response.data

      if (err.status === API_STATUS_CODES.badRequest) {
        toast.error(labelStrings.activateSubscriptions400, { theme: 'colored' })
        !!errorText?.length && setSubscriptionText(errorText)
      }
    }
  }

  const onCancelSubscription = async () => {
    try {
      const { status } = (await httpService.cancelSubscriptionAccountSettings()) || {}

      if (status === API_STATUS_CODES.created) {
        await refreshGrant()
        toast.success(labelStrings.subscriptionSuccessfullyCancelled, { theme: 'colored' })
      }
    } catch (err) {
      const errorText = err.response.data

      if (err.status === API_STATUS_CODES.badRequest) {
        toast.error(labelStrings.errorWithCancelledSubscription, { theme: 'colored' })
      }
    }
  }

  const onRemoveCancelation = async () => {
    try {
      const { status } = (await httpService.removeScheduledCancellation()) || {}

      if (status === API_STATUS_CODES.created) {
        await refreshGrant()
        toast.success(labelStrings.removeCancellationSuccessful, { theme: 'colored' })
      }
    } catch (err) {
      const errorText = err.response.data

      if (err.status === API_STATUS_CODES.badRequest) {
        toast.error(labelStrings.removeCancellationError, { theme: 'colored' })
      }
    }
  }

  const onAccountChangeEmail = () => {
    setIsOpenChangePassword(prev => !prev)
  }

  return (
    email && (
      <>
        <AccountSettings
          changePassword={toggleIsModalOpened}
          email={email}
          openContactUs={toggleContactUs}
          isCanceledStatus={isCanceledStatus}
          isAddPaymentMethod={isAddPaymentMethod}
          setIsOpenEditDetails={setIsOpenEditDetails}
          trial_end_date_infludata={trial_end_date_infludata}
          in_trial_infludata={in_trial_infludata}
          is_self_service={is_self_service}
          no_payment_method={no_payment_method}
          non_renewing_infludata={non_renewing_infludata}
          expiration_date={expiration_date}
          current_term_end={current_term_end}
          onAddSubscription={onAddSubscription}
          onCancelSubscription={onCancelSubscription}
          subscriptionText={subscriptionText}
          onRemoveCancelation={onRemoveCancelation}
          onAccountChangeEmail={onAccountChangeEmail}
          associated_org_id={associated_org_id}
          associated_org_name={associated_org_name}
          roles={roles}
        />
        {isModalOpened && (
          <ModalComponent
            isOpenModal={isModalOpened}
            closeModal={() => setIsModalOpened(false)}
            title={labelStrings.changeMyPassword}
            contentWidth={'489px'}
            contentHeight="auto"
            isCenterTitle
            withoutScrollWrapper
            isGradientTitle
            isGradientBg
          >
            <ChangePasswordDialog
              close={toggleIsModalOpened}
              confirm={changePassword}
              isLoading={isLoading}
            />
          </ModalComponent>
        )}
        {isOpenChangePassword && (
          <ChangePasswordModal
            isOpenModal={isOpenChangePassword}
            closeModal={() => setIsOpenChangePassword(false)}
          />
        )}
      </>
    )
  )
}

AccountSettingsContainer.propTypes = {
  toggleContactUs: PropTypes.func,
  changeUserPassword: PropTypes.func,
  isCanceledStatus: PropTypes.bool,
  isAddPaymentMethod: PropTypes.bool,
  setIsOpenEditDetails: PropTypes.func,
}

export default withReduxActions({ toggleContactUs, changeUserPassword })(AccountSettingsContainer)
