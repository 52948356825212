import React from 'react'
import { Box, IconButton, Button, Stack, Typography } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import useTranslation from '../../../localization/useTranslation'
import { useHistory } from 'react-router-dom'
import { daysUntilDate } from '../../../utils'

export const TopMessageBanner = props => {
  const { labelStrings } = useTranslation()
  const {
    setIsShowTopBannerMessage,
    setIsCancellationScheduled,
    trialsEndsDays,
    isTrialEnds,
    subscriptionsEndsDays,
  } = props || {}

  const history = useHistory()

  const goToSettings = () => {
    history?.push('/settings')
  }

  const onRemoveScheduledCancellation = () => {
    setIsCancellationScheduled(true)
  }

  const onCloseBanner = () => {
    localStorage.setItem('hiddenTopMessageBanner', 'true')
    setIsShowTopBannerMessage(false)
  }

  const daysLeft = isTrialEnds
    ? daysUntilDate(trialsEndsDays)
    : daysUntilDate(subscriptionsEndsDays)

  const labelTrials = (
    <Stack direction="row" alignItems="center">
      <Typography sx={{ fontSize: '16px', fontWeight: '700', fontFamily: 'Inter', color: 'white' }}>
        {isTrialEnds
          ? labelStrings.yourTrialsEnds(daysLeft)
          : labelStrings.yourSubscriptionsEnds(daysLeft)}
      </Typography>
      <Button
        variant="text"
        sx={{
          fontWeight: '500',
          fontFamily: 'Inter',
          color: 'white',
          textDecoration: 'none',
          fontSize: '16px',
          lineHeight: 'inherit',
          '&:hover': {
            color: 'white',
            textDecoration: 'underline',
          },
        }}
        onClick={isTrialEnds ? goToSettings : onRemoveScheduledCancellation}
        disableTouchRipple
      >
        {isTrialEnds
          ? labelStrings.yourTrialsEndsSubtitle
          : labelStrings.yourSubscriptionsEndsSubtitle}
      </Button>
    </Stack>
  )

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '52px',
        color: 'white',
        fontWeight: 700,
        background:
          'linear-gradient(87deg, #FFB904 1.15%, #FF6B00 30.19%, #FF403B 57.11%, #FF3B99 100%)',
        p: 1,
        zIndex: 8888,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box></Box>
        <Box>{labelTrials}</Box>
        <Box>
          <IconButton size="small" onClick={onCloseBanner}>
            <CloseRoundedIcon sx={{ color: 'white' }} />
          </IconButton>
        </Box>
      </Stack>
    </Box>
  )
}
