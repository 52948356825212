import React, { useState } from "react";
import PropTypes from "prop-types";
import useTranslation from "../../../localization/useTranslation";
import CommonSettingSection from "../CommonSettingSection";
import moment from 'moment';
import {
  Typography,
  Stack,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Link,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changePaymentMethod } from "../../../redux/ducks/userDucks";
import { userDetailsSelector, userAudienceTokensSelector } from "../../../redux/selectors";
import { setError } from "../../../redux/ducks/errorDucks";
import { themeCommonStyles } from "../../../themes/infludataThemes";

const _REQUIRED_ADDRESS_FIELDS = [
  "first_name",
  "last_name",
  "company",
  "line1",
  "zip",
  "city",
  "country",
];

const isAnyKeyMissingOrEmpty = (object, keys) => {
  if (!object) {
    return true;
  }
  return keys?.some(
    (key) =>
      !(key in object) ||
      object[key] === null ||
      object[key] === undefined ||
      object[key] === "",
  );
};

const MuiBtnStyles = {
  fontFamily: "Inter",
  fontSize: "13px",
  borderRadius: "10px",
  textTransform: "uppercase",
  width: "fit-content",
  color: "#FF5C00",
  borderColor: "#FF5C00",
  backgroundColor: "#fff",
  "&:hover": {
    color: "white",
    backgroundColor: "#FF5500",
  },
};

const SubscriptionSettings = ({
  isAddPaymentMethod,
  isCanceledStatus,
  setIsOpenEditDetails,
  trial_end_date_infludata,
  in_trial_infludata,
  is_self_service,
  no_payment_method,
  subscriptionText,
  onAddSubscription,
  onCancelSubscription,
  non_renewing_infludata,
  onRemoveCancelation,
  expiration_date,
  current_term_end,
}) => {
  const { labelStrings } = useTranslation();
  const { billing_address } = useSelector(userDetailsSelector) || {};
  const dispatch = useDispatch();

  const [isOpenCancelSubscription, setIsOpenCancelSubscription] = useState(false);

  const isSubscriptions = (in_trial_infludata || is_self_service) && !no_payment_method;
  const isShowCancelSubscription = is_self_service && !non_renewing_infludata;
  const isShowRemoveCancelled = non_renewing_infludata && !no_payment_method;
  const isOpenEditAddress = isAnyKeyMissingOrEmpty(billing_address, _REQUIRED_ADDRESS_FIELDS);

  const onAddPaymentMethod = () => {
    if (isOpenEditAddress) {
      dispatch(setError(labelStrings.pleaseAddBillingErrorMsg));
      setIsOpenEditDetails(true);
    } else {
      dispatch(changePaymentMethod());
    }
  };

  const onCloseDialog = () => {
    setIsOpenCancelSubscription(false);
  };

  const formattedDateTrialEnd = trial_end_date_infludata ? moment((trial_end_date_infludata + 1) * 1000).format('MMMM Do YYYY, hh:mm a') : '';
  const formattedDateCancellation = expiration_date ? moment((expiration_date + 1) * 1000).format('MMMM Do YYYY, hh:mm a') : '';
  const subscriptionEndDate = current_term_end ? moment((current_term_end + 1) * 1000).format('MMMM Do YYYY, hh:mm a') : '';

  const Content = (
    <Box>
      {isSubscriptions && in_trial_infludata && !isShowRemoveCancelled && (
        <Stack sx={{ mt: 2 }} spacing={0.5}>
          <Stack>
            <Typography
              sx={{
                fontSize: "15px",
                fontFamily: "Inter",
                mr: "5px",
                color: themeCommonStyles.color.black,
              }}
            >
              {labelStrings.subscriptionStatus}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "15px",
                fontWeight: 600,
                color: "#393939",
                paddingBottom: '20px'
              }}
            >
              {`${labelStrings.activateSubscriptionsLabel} ${formattedDateTrialEnd}`}
            </Typography>
          </Stack>
          <Button
            sx={{
              mt: 0.5,
              ...MuiBtnStyles,
            }}
            onClick={onAddSubscription}
            variant='outlined'
          >
            {labelStrings.activateSubscriptions}
          </Button>
          <Typography
            sx={{
              ml: 0.5,
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: 400,
              color: "#393939",
            }}
          >
            {labelStrings.activateSubscriptionsSub}
          </Typography>
          <Typography
            dangerouslySetInnerHTML={{
              __html: subscriptionText,
            }}
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 600,
              color: "#a60000",
            }}
          />
        </Stack>
      )}

      {isSubscriptions && !in_trial_infludata && !isShowRemoveCancelled && (
        <Stack sx={{ mt: 2 }} spacing={0.5}>
          <Stack>
            <Typography
              sx={{
                fontSize: "15px",
                fontFamily: "Inter",
                mr: "5px",
                color: themeCommonStyles.color.black,
              }}
            >
              {labelStrings.subscriptionStatus}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "15px",
                fontWeight: 600,
                color: "#0A6A47",
                paddingBottom: '8px'
              }}
            >
              {`${labelStrings.defaultSubscriptionsLabel(subscriptionEndDate)}`}
            </Typography>
            {isShowCancelSubscription && (
              <Box sx={{textAlign: 'right'}}>
                <Link
                  component="button"
                  onClick={() => setIsOpenCancelSubscription(true)}
                  sx={{
                    fontSize: "12px",
                    fontFamily: "Inter",
                    textDecoration: "underline",
                    color: "#FF5C00",
                    cursor: "pointer",
                    marginTop: "6px",
                    "&:hover": {
                      color: "#FF5500",
                    }
                  }}
                >
                  {labelStrings.cancelSubscription}
                </Link>
              </Box>
            )}
          </Stack>
        </Stack>
      )}

      {isShowRemoveCancelled && (
        <Stack spacing={0.5} sx={{ mt: 1 }}>
          <Typography
            sx={{
              fontSize: "15px",
              fontFamily: "Inter",
              mr: "5px",
              color: themeCommonStyles.color.black,
            }}
          >
            {labelStrings.subscriptionStatus}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "15px",
              fontWeight: 500,
              whiteSpace: "pre-wrap",
              paddingBottom: '20px'
            }}
          >
            {labelStrings.removeCancellationLabel(formattedDateCancellation)}
          </Typography>
          <Button
            sx={{
              ...MuiBtnStyles,
            }}
            onClick={onRemoveCancelation}
            variant='outlined'
          >
            {labelStrings.removeCancellation}
          </Button>
        </Stack>
      )}

      {(isAddPaymentMethod || isCanceledStatus) && (
        <Stack sx={{ maxWidth: "450px", mt: 1 }}>
          <Typography
            sx={{
              fontSize: "15px",
              fontFamily: "Inter",
              mr: "5px",
              color: themeCommonStyles.color.black,
            }}
          >
            {labelStrings.subscriptionStatus}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "15px",
              fontWeight: 500,
              color: isCanceledStatus ? "#FF5D5A" : themeCommonStyles.color.black,
            }}
          >
            {isCanceledStatus ? labelStrings.cancelled : labelStrings.inTrial}
          </Typography>

          {!isCanceledStatus && (
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                color: "#FF5D5A",
                whiteSpace: "pre-wrap",
                marginBottom: '20px'
              }}
            >
              {labelStrings.statusDescription(formattedDateTrialEnd)}
            </Typography>
          )}

          <Stack sx={{ mt: 1 }}>
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "Inter",
                mr: "5px",
                color: themeCommonStyles.color.black,
              }}
            >
              {labelStrings.addPaymentMethodContinue}
            </Typography>
            <Button
              sx={{
                mt: 0.5,
                ...MuiBtnStyles,
              }}
              onClick={() => onAddPaymentMethod()}
              variant='outlined'
            >
              {labelStrings.addPaymentMethod}
            </Button>
            <Typography
              sx={{
                ml: 2,
                mt: 0.5,
                fontFamily: "Inter",
                fontSize: "12px",
                color: "#FF5D5A",
              }}
            >
              *{labelStrings.pleaseAddBilling}
            </Typography>
          </Stack>
        </Stack>
      )}
    </Box>
  );

  return (
    <>
      <CommonSettingSection
        title={labelStrings.yourSubscription}
        Content={Content}
      />
      <Dialog
        fullScreen={false}
        open={isOpenCancelSubscription}
        onClose={onCloseDialog}
      >
        <DialogTitle sx={{ textAlign: "center", fontFamily: "Inter" }}>
          {labelStrings.attention}
        </DialogTitle>
        <DialogContent sx={{ maxWidth: "500px" }}>
          <DialogContentText sx={{ fontFamily: "Inter" }}>
            {labelStrings.cancelSubscriptionAttentionText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={() => {
              onCancelSubscription();
              onCloseDialog();
            }}
            autoFocus
          >
            {labelStrings.confirm}
          </Button>
          <Button variant='outlined' onClick={onCloseDialog}>
            {labelStrings.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AccountSettings = ({
  changePassword,
  email = "",
  openContactUs,
  isAddPaymentMethod,
  isCanceledStatus,
  setIsOpenEditDetails,
  trial_end_date_infludata,
  in_trial_infludata,
  is_self_service,
  no_payment_method,
  subscriptionText,
  onAddSubscription,
  onCancelSubscription,
  non_renewing_infludata,
  onRemoveCancelation,
  expiration_date,
  current_term_end,
  onAccountChangeEmail,
  associated_org_id, 
  associated_org_name,
  roles = []
}) => {
  const { labelStrings } = useTranslation();
  const { profile_data } = useSelector(userDetailsSelector) || {};

  const CustomButton = (
    <Stack spacing={1}>
      <Button
        size='small'
        sx={MuiBtnStyles}
        onClick={changePassword}
        variant='outlined'
      >
        {labelStrings.changePassword}
      </Button>
      <Button
        size='small'
        sx={MuiBtnStyles}
        onClick={onAccountChangeEmail}
        variant='outlined'
      >
        {labelStrings.changeEmail}
      </Button>
    </Stack>
  );

  const AccountContent = (
    <Box>
      <Box sx={{ width: "fit-content", display: "flex", alignItems: "center" }}>
        <Typography
          sx={{
            fontSize: "15px",
            fontFamily: "Inter",
            mr: "5px",
            color: themeCommonStyles.color.black,
          }}
        >
          {labelStrings.currentOrganization || 'Current Organization'}
        </Typography>
      </Box>

      <Typography
        sx={{
          color: themeCommonStyles.color.black,
          fontSize: "15px",
          fontFamily: "Inter",
          paddingBottom: '30px'
        }}
      >
        {`${associated_org_name} - Org. Id: ${associated_org_id}`}
      </Typography>
      
      <Box sx={{ width: "fit-content", display: "flex", alignItems: "center" }}>
        <Typography
          sx={{
            fontSize: "15px",
            fontFamily: "Inter",
            mr: "5px",
            color: themeCommonStyles.color.black,
          }}
        >
          {labelStrings.email}
        </Typography>
      </Box>

      <Typography
        sx={{
          color: themeCommonStyles.color.black,
          fontSize: "15px",
          fontFamily: "Inter",
          paddingBottom: '30px'
        }}
      >
        {profile_data && profile_data.email || email}
      </Typography>
    </Box>
  );

  return (
    <>
      <CommonSettingSection
        title={labelStrings.yourAccount}
        Button={CustomButton}
        Content={AccountContent}
      />
      
      {roles.includes('admin') && (
        <SubscriptionSettings
          isAddPaymentMethod={isAddPaymentMethod}
          isCanceledStatus={isCanceledStatus}
          setIsOpenEditDetails={setIsOpenEditDetails}
          trial_end_date_infludata={trial_end_date_infludata}
          in_trial_infludata={in_trial_infludata}
          is_self_service={is_self_service}
          no_payment_method={no_payment_method}
          subscriptionText={subscriptionText}
          onAddSubscription={onAddSubscription}
          onCancelSubscription={onCancelSubscription}
          non_renewing_infludata={non_renewing_infludata}
          onRemoveCancelation={onRemoveCancelation}
          expiration_date={expiration_date}
          current_term_end={current_term_end}
        />
      )}
    </>
  );
};

SubscriptionSettings.propTypes = {
  isAddPaymentMethod: PropTypes.bool,
  isCanceledStatus: PropTypes.bool,
  setIsOpenEditDetails: PropTypes.func,
  trial_end_date_infludata: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  is_self_service: PropTypes.bool,
  in_trial_infludata: PropTypes.bool,
  no_payment_method: PropTypes.bool,
  subscriptionText: PropTypes.string,
  onAddSubscription: PropTypes.func,
  onCancelSubscription: PropTypes.func,
  non_renewing_infludata: PropTypes.bool,
  onRemoveCancelation: PropTypes.func,
  expiration_date: PropTypes.string,
  current_term_end: PropTypes.string,
};

AccountSettings.propTypes = {
  changePassword: PropTypes.func,
  email: PropTypes.string,
  openContactUs: PropTypes.func,
  isAddPaymentMethod: PropTypes.bool,
  isCanceledStatus: PropTypes.bool,
  setIsOpenEditDetails: PropTypes.func,
  trial_end_date_infludata: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  is_self_service: PropTypes.bool,
  in_trial_infludata: PropTypes.bool,
  no_payment_method: PropTypes.bool,
  subscriptionText: PropTypes.string,
  onAddSubscription: PropTypes.func,
  onCancelSubscription: PropTypes.func,
  non_renewing_infludata: PropTypes.bool,
  onRemoveCancelation: PropTypes.func,
  expiration_date: PropTypes.string,
  current_term_end: PropTypes.string,
  onAccountChangeEmail: PropTypes.func,
  associated_org_id: PropTypes.string,
  associated_org_name: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string)
};

export default AccountSettings;
