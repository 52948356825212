import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { USER_INDEXES } from '../../../constants/appSettings'

const createFadeInAnimation = width => keyframes`
  from {
    width: 0;
    opacity: 0;
  }
  to {
    width: ${width}%;
    opacity: 1;
  }
`

const ProgressBarOuterWrapper = styled.div`
  height: 9px;
  width: 100%;
  border-radius: 7px;
  background: #f6dbdb;
  overflow: hidden;
  margin: 5px auto;
`

const ProgressBarInnerWrapper = styled.div`
  animation: ${props => createFadeInAnimation(props.barPercentWidth)} 0.5s ease forwards;
  transition: width 0.5s ease;
  width: ${props => props.barPercentWidth}%;
  height: 100%;
  background: ${props => {
    switch (props.platform) {
      case USER_INDEXES.instagram:
        return props.theme.gradients.main
      case USER_INDEXES.tiktok:
        return props.theme.gradients.blueSalmon
      default:
        return props.theme.primaryColor
    }
  }};
`

const ProgressBar = ({ current = 20, limit = 20, platform = '' }) => {
  const width = (current * 100) / limit
  const barPercentWidth = width <= 0 ? 0 : width

  return (
    <ProgressBarOuterWrapper className="progress_outer">
      <ProgressBarInnerWrapper
        barPercentWidth={barPercentWidth}
        className="progress_inner"
        platform={platform}
      />
    </ProgressBarOuterWrapper>
  )
}

ProgressBar.propTypes = {
  current: PropTypes.number,
  limit: PropTypes.number,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube]),
}

export default ProgressBar
