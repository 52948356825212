import React, { useEffect, useState } from 'react'
import useTranslation from '../../../../../localization/useTranslation'
import { Box, Chip, Autocomplete, TextField } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

export const CountryFilter = props => {
  const { query, handlersMap, isModalOpen } = props || {}

  const { labelStrings, countryOptions } = useTranslation()

  const countriesFromQuery = query?.country

  const countryOptionsData = [{ value: '', label: 'All' }, ...countryOptions]

  const [open, setOpen] = useState(false)

  const onChange = (event, value) => {
    const isAllValue = value?.find(el => el.value === '')
    handlersMap?.country(isAllValue ? [] : value.map(v => v.value))
  }

  const onDelete = deleteValue => {
    const payload = countriesFromQuery?.split(',')?.filter(el => el !== deleteValue)
    if (payload?.length) {
      handlersMap?.country(payload)
    } else {
      handlersMap?.country([])
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isModalOpen) {
        setOpen(true)
      } else {
        setOpen(false)
      }
    }, 220)

    return () => clearTimeout(timer)
  }, [isModalOpen])

  return (
    <Box sx={{ maxWidth: '250px' }}>
      <Autocomplete
        sx={{ width: '250px' }}
        multiple
        open={open}
        size="small"
        options={countryOptionsData}
        getOptionLabel={option => option.label}
        value={
          countriesFromQuery
            ? countriesFromQuery
                .split(',')
                .map(country => countryOptionsData.find(opt => opt.value === country))
            : []
        }
        onChange={onChange}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        disableCloseOnSelect
        renderOption={(props, option) => (
          <Box
            component="li"
            {...props}
            sx={{
              justifyContent: 'space-between',
              fontFamily: 'Inter',
              fontWeight: 'bold !important',
              color: '#442424',
            }}
          >
            {option.label}
          </Box>
        )}
        renderInput={params => (
          <TextField
            size="small"
            {...params}
            variant="outlined"
            label={labelStrings.selectMultiCountries}
          />
        )}
        renderTags={(value, getTagProps) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {value?.map((option, index) => (
              <Chip
                size="small"
                key={option.value}
                label={<Box sx={{ display: 'flex', alignItems: 'center' }}>{option?.label}</Box>}
                variant="contained"
                onDelete={() => onDelete(option.value)}
                deleteIcon={<CloseRoundedIcon sx={{ fill: '#442424' }} />}
                {...getTagProps({ index })}
                sx={{
                  color: '#442424',
                  fontWeight: 'bold',
                  backgroundColor: '#F6DBDB',
                }}
              />
            ))}
          </Box>
        )}
      />
    </Box>
  )
}
