import React from "react";
import { useHistory } from "react-router-dom";
import { ModalComponent } from "../modal/ModalComponent";
import { Box, Stack, Typography, Button } from "@mui/material";
import useTranslation from "../../../localization/useTranslation";
import urlQueryService from "../../../services/urlQueryService";
import { defaultSearchQuery } from "../../pages/Search/utils";
import { useDispatch } from "react-redux";
import { resetSearchResults } from "../../../redux/ducks/searchDucks";
import { getUserDetails } from "../../../redux/ducks/userDucks";

export const SuccessChangeEmailModal = (props) => {
  const { isOpenModal, onCloseModal, isSuccessfully } = props || {};
  const { labelStrings } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const onContinue = () => {
    onCloseModal();
    urlQueryService.setNewSearchQuery({
      latestQuery: defaultSearchQuery,
    });
    history?.push("/search");
    dispatch([resetSearchResults(), getUserDetails()]);
  };

  return (
    <ModalComponent
      isOpenModal={isOpenModal}
      closeModal={onCloseModal}
      title={
        isSuccessfully
          ? labelStrings.changeEmailSuccess
          : labelStrings.emailChangedFailed
      }
      contentWidth={"520px"}
      contentHeight='auto'
      isCenterTitle
      withoutScrollWrapper
      isGradientTitle
      isGradientBg
    >
      <Box sx={{ pb: 3 }}>
        <Stack spacing={2} alignItems='center'>
          <Typography
            textAlign='center'
            sx={{ fontFamily: "Inter", fontWeight: 600 }}
          >
            {isSuccessfully
              ? labelStrings.weSuccessfullyChangedEmail
              : labelStrings.pleaseTryAgain}
          </Typography>
          <Button
            onClick={onContinue}
            variant='contained'
            color='primary'
            sx={{
              backgroundColor: "#FF5C00",
              width: "fit-content",
              minWidth: "100px",
            }}
          >
            {labelStrings.continueToInfludata}
          </Button>
        </Stack>
      </Box>
    </ModalComponent>
  );
};
